import React from 'react'
import Translate from "../../constants/translate"

export const pipelineCountVM = {
    monthActivity: [],
    monthDelivered: [],
    monthLost: [],
    monthOpen: [],
    monthNew: [],
    monthTestdrive: [],
    monthTradein: [],
    monthWon: [],
    monthWalkin: [],

    overdueActivity: [],
    todayActivity: [],
    todayDelivered: [],
    todayLost: [],
    todayOpen: [],
    todayTestdrive: [],
    todayTradein: [],
    todayWon: [],
    todayNew: [],
    todayWalkin: [],

    weekActivity: [],
    weekDelivered: [],
    weekLost: [],
    weekOpen: [],
    weekNew: [],
    weekTestdrive: [],
    weekTradein: [],
    weekWon: [],
    weekWalkin: [],
}

export const pipelineCountVMNEW = {
    monthActivity: 0,
    monthDelivered: 0,
    monthLost: 0,
    monthOpen: 0,
    monthNew: 0,
    monthTestdrive: 0,
    monthTradein: 0,
    monthWon: 0,
    monthWalkin: 0,

    overdueActivity: 0,
    todayActivity: 0,
    todayDelivered: 0,
    todayLost: 0,
    todayOpen: 0,
    todayTestdrive: 0,
    todayTradein: 0,
    todayWon: 0,
    todayNew: 0,
    todayWalkin: 0,

    weekActivity: 0,
    weekDelivered: 0,
    weekLost: 0,
    weekOpen: 0,
    weekNew: 0,
    weekTestdrive: 0,
    weekTradein: 0,
    weekWon: 0,
    weekWalkin: 0,
}

export const leadBucketCountVM = {
    pendingInboundleads: [],
    todayConverted: [],
    monthConverted: [],
    lostApprovalList: []
}

export const pipelinestatus = [
    {
        name: "Open",
        value: "open",
        color: "#67b7dc"
    },
    {
        name: "Won",
        value: "won",
        color: "#2bb673"
    },
    {
        name: "Lost",
        value: "lost",
        color: "#ef4136"
    },
    {
        name: "Delivered",
        value: "delivered",
        color: "#049b8c"
    }
]


export const pipelineLabels = [
    {
        name: "Hot",
        value: "hot",
        color: "#ed1c24"
    },
    {
        name: "Warm",
        value: "warm",
        color: "#f7931e"
    },
    {
        name: "Cold",
        value: "cold",
        color: "#29abe2"
    },
    {
        name: "Other/Blank",
        value: "others",
        color: "#67b7dc"
    }
]

export const initialLayoutArr = [
    { x: 0, y: 0, w: 4, h: 4, i: 'LeadsBucket', minW: 4, maxW: 12, minH: 3, maxH: 3, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 4, w: 4, h: 7, i: 'LeadsByLabels', minW: 4, maxW: 12, minH: 7, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'Activities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'LeadsByOrigin', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 12, w: 4, h: 11, i: 'LeadsByEnquiryType', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 11, w: 4, h: 11, i: 'SalesFunnel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 11, w: 4, h: 11, i: 'LeadsByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 24, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 22, w: 4, h: 11, i: 'SalesTarget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 22, w: 4, h: 11, i: 'DealerTarget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 4, i: 'ActivitiesWidget', minW: 4, maxW: 4, minH: 3, maxH: 3, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 0, y: 34, w: 4, h: 11, i: 'FleetCounts', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LoanBookingsWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 3, i: 'InboundActivitiesWidget', minW: 4, maxW: 4, minH: 3, maxH: 3, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 0, y: 34, w: 4, h: 11, i: 'PerformanceReviewWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'InboundActivities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'CampaignPerformance', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'OriginPerformance', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LostLeadsReasons', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LostEnquiryReasons', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LeadsCreated', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'TestDriveCompleted', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'TradeInCompleted', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LostEnquiryReignition', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'EnquiryQualificationDevelopment', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LeadQualificationDevelopment', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'ShowroomVisits', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LeadsByModel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'EnquiriesByModel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'EnquiriesByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'QualifiedEnquiriesByModel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'QualifiedEnquiriesByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LeadsBySource', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'EnquiriesBySource', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LeadsByOriginPorsche', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LeadsByEnquiryTypePorsche', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'NewEnquiries', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LeadsCreatedInCAP', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'TestDrives', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'EnquiriesByPipeline', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LeadsWonInCAP', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
]


export const widgetSettingsNames = [
    { name: <Translate text={'Leads Bucket'} />, value: 'LeadsBucket' },
    { name: <Translate text={'New Enquiries By Labels'} />, value: 'LeadsByLabels' },
    { name: <Translate text={'activities'} />, value: 'Activities' },
    { name: <Translate text={'Inbound Activities'} />, value: 'InboundActivities' },
    { name: <Translate text={'New Enquiries By Origin'} />, value: 'LeadsByOrigin' },
    { name: <Translate text={'New Enquiries By Enquiry Type'} />, value: 'LeadsByEnquiryType' },
    { name: <Translate text={'Sales Funnel'} />, value: 'SalesFunnel' },
    { name: <Translate text={'New Enquiries By Campaign'} />, value: 'LeadsByCampaign' },
    { name: <Translate text={'New Enquiries By Status'} />, value: 'LeadsByStatus' },
    { name: <Translate text={'Sales Target'} />, value: 'SalesTarget' },
    { name: <Translate text={'dealerTarget'} />, value: 'DealerTarget' },
    { name: <Translate text={'Activities Counts'} />, value: 'ActivitiesWidget' },
    { name: <Translate text={'LoanTestDriveVehicle'} />, value: 'FleetCounts' },
    { name: <Translate text={'loanVehicleBookings'} />, value: 'LoanBookingsWidget' },
    { name: <Translate text={'Inbound Activities Counts'} />, value: 'InboundActivitiesWidget' },
    { name: <Translate text={'Daily Performance Review'} />, value: 'PerformanceReviewWidget' },
    { name: <Translate text={'Campaign Performance'} />, value: 'CampaignPerformance' },
    { name: <Translate text={'Origin Performance'} />, value: 'OriginPerformance' },
    { name: <Translate text={'Lost Leads Reasons'} />, value: 'LostLeadsReasons' },
    { name: <Translate text={'Lost Enquiry Reasons'} />, value: 'LostEnquiryReasons' },
    { name: <Translate text={'Leads Conversion'} />, value: 'LeadsCreated' },
    { name: <Translate text={'Test Drives Completed'} />, value: 'TestDriveCompleted' },
    { name: <Translate text={'Trade Ins'} />, value: 'TradeInCompleted' },
    { name: <Translate text={'Lost Enquiry Reignition'} />, value: 'LostEnquiryReignition' },
    { name: <Translate text={'Enquiry Qualification Development'} />, value: 'EnquiryQualificationDevelopment' },
    { name: <Translate text={'Lead Qualification Development'} />, value: 'LeadQualificationDevelopment' },
    { name: <Translate text={'Showroom Visits'} />, value: 'ShowroomVisits' },
    { name: <Translate text={'Leads By Model'} />, value: 'LeadsByModel' },
    { name: <Translate text={'New Enquiries By Model'} />, value: 'EnquiriesByModel' },
    { name: <Translate text={'Leads By Campaign'} />, value: 'EnquiriesByCampaign' },
    { name: <Translate text={'Qualified Enquiries By Model'} />, value: 'QualifiedEnquiriesByModel' },
    { name: <Translate text={'Qualified Enquiries By Campaign'} />, value: 'QualifiedEnquiriesByCampaign' },
    { name: <Translate text={'Leads By Source'} />, value: 'LeadsBySource' },
    { name: <Translate text={'New Enquiries By Source'} />, value: 'EnquiriesBySource' },
    { name: <Translate text={'Leads By Origin'} />, value: 'LeadsByOriginPorsche' },
    { name: <Translate text={'Leads By Enquiry Type'} />, value: 'LeadsByEnquiryTypePorsche' },
    { name: <Translate text={'New Enquiries'} />, value: 'NewEnquiries' },
    { name: <Translate text={'Leads Created in C@P'} />, value: 'LeadsCreatedInCAP' },
    { name: <Translate text={'Test Drive Completed'} />, value: 'TestDrives' },
    { name: <Translate text={'New Enquiries By Pipeline'} />, value: 'EnquiriesByPipeline' },
    { name: <Translate text={'Leads Won in C@P'} />, value: 'LeadsWonInCAP' },

]

export const dynamicBarChartWidgets = [
    'LeadsCreated',
    'TestDriveCompleted',
    'TradeInCompleted',
    'LostEnquiryReignition',
    'EnquiryQualificationDevelopment',
    'LeadQualificationDevelopment',
    'ShowroomVisits',
    'NewEnquiries',
    'LeadsCreatedInCAP',
    'TestDrives',
    'LeadsWonInCAP',
]

export const dynamicPieChartWidgets = [// true, if filtertype contains 'lead }
    {
        name: 'Leads By Model', id: 'LeadsByModel',
        kpi: 'models', fieldName: 'ml', fieldFullForm: 'model', filterType: 'lead', isPorsche: true
    },
    {
        name: 'New Enquiries By Model', id: 'EnquiriesByModel',
        kpi: 'models', fieldName: 'ml', fieldFullForm: 'model', filterType: 'enquiry', isPorsche: null
    },
    {
        name: 'Qualified Enquiries By Model', id: 'QualifiedEnquiriesByModel',
        kpi: 'models', fieldName: 'ml', fieldFullForm: 'model', filterType: 'qualified', isPorsche: null
    },
    {
        name: 'New Enquiries By Campaign', id: 'EnquiriesByCampaign',
        kpi: 'campaigns', fieldName: 'cpgv', fieldFullForm: 'campaign', filterType: 'lead', isPorsche: true
    },
    {
        name: 'Qualified Enquiries By Campaign', id: 'QualifiedEnquiriesByCampaign',
        kpi: 'campaigns', fieldName: 'cpgv', fieldFullForm: 'campaign', filterType: 'qualified', isPorsche: null
    },
    {
        name: 'Leads By Source', id: 'LeadsBySource',
        kpi: 'leadSource', fieldName: 'lsv', fieldFullForm: 'leadSource', filterType: 'lead', isPorsche: true
    },
    {
        name: 'New Enquiries By Source', id: 'EnquiriesBySource',
        kpi: 'leadSource', fieldName: 'lsv', fieldFullForm: 'leadSource', filterType: 'enquiry', isPorsche: null
    },
    {
        name: 'Leads By Origin', id: 'LeadsByOriginPorsche',
        kpi: 'origins', fieldName: 'orgv', fieldFullForm: 'origin', filterType: 'lead', isPorsche: true
    },
    {
        name: 'Leads By Enquiry Type', id: 'LeadsByEnquiryTypePorsche',
        kpi: 'enquiryTypes', fieldName: 'etv', fieldFullForm: 'enquiryType', filterType: 'lead', isPorsche: true
    },
    {
        name: 'New Enquiries By Pipeline', id: 'EnquiriesByPipeline',
        kpi: 'pipeline', fieldName: 'plnv', fieldFullForm: 'pipeline', filterType: 'enquiry', isPorsche: null
    },
]

export const defaultWidgetSettings = [
    'LeadsBucket',
    'LeadsByLabels',
    'Activities',
    'InboundActivities',
    'LeadsByOrigin',
    'LeadsByEnquiryType',
    'SalesFunnel',
    'LeadsByCampaign',
    'LeadsByStatus',
    'SalesTarget',
    'DealerTarget',
    'ActivitiesWidget',
    'FleetCounts',
    'LoanBookingsWidget',
    'InboundActivitiesWidget',
    'PerformanceReviewWidget',
    'CampaignPerformance',
    'OriginPerformance',
    'LostLeadsReasons',
    'LostEnquiryReasons',
    'LeadsCreated',
    'TestDriveCompleted',
    'TradeInCompleted',
    'LostEnquiryReignition',
    'EnquiryQualificationDevelopment',
    'LeadQualificationDevelopment',
    'ShowroomVisits',
    'LeadsByModel',
    'EnquiriesByModel',
    'EnquiriesByCampaign',
    'QualifiedEnquiriesByModel',
    'QualifiedEnquiriesByCampaign',
    'LeadsBySource',
    'EnquiriesBySource',
    'LeadsByOriginPorsche',
    'LeadsByEnquiryTypePorsche',
    'NewEnquiries',
    'LeadsCreatedInCAP',
    'TestDrives',
    'EnquiriesByPipeline',
    'LeadsWonInCAP'
]

export const defaultPorscheWidgets = [
    'LostLeadsReasons',
    'LostEnquiryReasons',
    'LeadsCreated',
    'TestDriveCompleted',
    'TradeInCompleted',
    'LostEnquiryReignition',
    'EnquiryQualificationDevelopment',
    'LeadQualificationDevelopment',
    'ShowroomVisits',
    'LeadsByModel',
    'EnquiriesByModel',
    'EnquiriesByCampaign',
    'QualifiedEnquiriesByModel',
    'QualifiedEnquiriesByCampaign',
    'LeadsBySource',
    'EnquiriesBySource',
    'LeadsByOriginPorsche',
    'LeadsByEnquiryTypePorsche',
    'NewEnquiries',
    'LeadsCreatedInCAP',
    'TestDrives',
    'EnquiriesByPipeline',
    'LeadsWonInCAP'
]

export const liteVersionWidgetSettings = [
    'SalesFunnel',
    'Activities',
    'FleetCounts',
]

export const defaultStatsIcons = [
    { name: 'New Enquiries', icon: 'icon-new-leads', value: 'new' },
    //{ name: 'Assigned Enquiries', icon: 'icon-allocated-leads', value: 'allocated', color: '#34c68d' },
    { name: 'Showroom Visits', icon: 'icon-showroom-visit', value: 'walkin', color: '#f7931e' },
    { name: 'Leads Created in C@P', icon: 'icon-delivered', value: 'newCAP', cap: true },
    { name: 'In Process in C@P', icon: 'icon-open-leads', value: 'open', cap: true },
    { name: 'Test Drive Completed', icon: 'icon-oem-testdrive', value: 'testdrive' },
    { name: 'Leads Won in C@P', icon: 'icon-won-leads', value: 'won', cap: true },
    //{ name: 'Delivered from C@P', icon: 'icon-delivered', value: 'delivered', cap: true },
    //{ name: 'Lost from C@P', icon: 'icon-lost-leads', value: 'lost', cap: true },
    //{ name: 'Leads Closed', icon: 'icon-delivered', value: 'leadClosed', cap: true },
    { name: 'Lead Conversion', icon: 'icon-lead-conversion', value: 'leadConversion', color: '#3b589e' },
    { name: 'Outdated', icon: 'icon-overdue-activities', value: 'outdated', subList: [{ name: 'enquiries', value: 'outdated' }, { name: 'leads', value: 'outdatedLeads', cap: true }] }
]

export const colorPieArray = ["#7b90b5", "#808080", "#364d6d", "#007771", "#da702c", "#747a85", "#5d7aa6", "#8a96a3",
    '#0d263d', '#586e91', '#7b90b5', '#9fb4da', '#c4d9ff', '#DEE2E9', '#586e91', '#6f7788', '#3f4756', '#EEF1F4'
];

export const colorPieArrayOld = ['#4c6c9c', '#7b90b5', '#586e91', '#6f7788', '#3f4756', '#586e91', '#2d6469', '#281d3b', '#0d3e8c', '#4f778c', '#364d6e', '#0d263d',
    '#262d3b', '#437394', '#2d5a69', '#615546', '#04494a', '#04374a', '#095680', '#093e80'];