export const templateVM = {
    documentID: null,
    title: '',
    subject: '',
    type: '',
    bodyHTML: '',
    templateJSON: '',
    clientID: null,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    visibility: '',
    oemID: null,
    groupID: null,
    regionID: null,
    rawHTML: '',
    dealerName: null,
    dynamicFields:[]
}

export const tvTemplateVM = {
    documentID: null,
    title: '',
    type: '',
    bodyHTML: '',
    templateJSON: '',
    clientID: null,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    visibility: '',
    oemID: null,
    groupID: null,
    regionID: null,
    rawHTML: '',
    dealerName: null,
    bgImage: null,
    bgColor: null,
    opacity: null,
    items: [],
    displayResolution: null,
    displayZoom: null
}

export const visibilities = [
    { active: true, value: 'private', label: 'Private' },
    { active: true, value: 'shared', label: 'Shared' }
]

export const resolutions = [
    { active: true, value: '1280x1024', label: '1280 x 1024 Standard Quality' },
    { active: true, value: '1366x768', label: '1366 x 768 High Definition (HD)' },
    { active: true, value: '1600x900', label: '1600 x 900 High Definition Plus (HD+)' },
    { active: true, value: '1920x1080', label: '1920 x 1080 Full High Definition (FHD)' },
    { active: true, value: '1920x1200', label: '1920 x 1200 Wide Ultra High Definition (WHD)' },
    { active: true, value: '2560x1440', label: '2560 x 1440 Quad High Definition (QHD)' },
    { active: true, value: '3440x1440', label: '3440 x 1440 Wide Quad High Definition (WQHD)' },
    { active: true, value: '3840x2160', label: '3840 x 2160 4K or Ultra High Definition (UHD)' }
]

export const displayZooms = [
    { active: true, value: 10, label: '10%' },
    { active: true, value: 20, label: '20%' },
    { active: true, value: 30, label: '30%' },
    { active: true, value: 40, label: '40%' },
    { active: true, value: 50, label: '50%' },
    { active: true, value: 60, label: '60%' },
    { active: true, value: 70, label: '70%' },
    { active: true, value: 80, label: '80%' },
    { active: true, value: 90, label: '90%' },
    { active: true, value: 100, label: '100%' }
]


export const templatelistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Email',
        value: 'emailContent',
        sortValue: 'emailContent',
        flex: 2,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        subText: 'updatedBy',
        flex: 1,
        default: true
    },
    {
        name: 'Visibility',
        value: 'visibilityDOM',
        width: 80,
        flex: 0,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const smslistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Message',
        value: 'message',
        sortValue: 'message',
        flex: 2,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        subText: 'updatedBy',
        flex: 1,
        default: true
    },
    {
        name: 'Visibility',
        value: 'visibilityDOM',
        width: 80,
        flex: 0,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const tvtemplatelistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 2,
        default: true
    },
    {
        name: 'Created On',
        value: 'createdOn',
        sortValue: 'createdOn',
        subText: 'createdBy',
        flex: 1,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        subText: 'updatedBy',
        flex: 1,
        default: true
    },
    {
        name: 'Visibility',
        value: 'visibilityDOM',
        width: 80,
        flex: 0,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]
export const mentions = [{
    name: 'First Name',
    value: '@FIRST_NAME'
},
{
    name: 'Last Name',
    value: '@LAST_NAME'
},
{
    name: 'Preferred Name',
    value: '@PREF_NAME'
},
{
    name: 'Full Name',
    value: '@FULL_NAME'
},
{
    name: 'Customer Email',
    value: '@CUSTOMER_EMAIL'
},
{
    name: 'Customer Phone',
    value: '@CUSTOMER_PHONE'
},
{
    name: 'Company Name',
    value: '@COMPANY_NAME'
},
{
    name: 'User Name',
    value: '@USER_NAME'
},
{
    name: 'User Email',
    value: '@USER_EMAIL'
},
{
    name: 'User Phone',
    value: '@USER_PHONE'
},
{
    name: 'User Rating',
    value: '@USER_RATING'
},
{
    name: 'Designation',
    value: '@USER_DESIGNATION'
},
{
    name: 'Inbound User Name',
    value: '@INBOUND_USER_NAME'
},
{
    name: 'Inbound User Email',
    value: '@INBOUND_USER_EMAIL'
},
{
    name: 'Inbound User Phone',
    value: '@INBOUND_USER_PHONE'
},
{
    name: 'Inbound User Rating',
    value: '@INBOUND_USER_RATING'
},
{
    name: 'Inbound User Designation',
    value: '@INBOUND_USER_DESIGNATION'
},
{
    name: 'Vehicle Make',
    value: '@REQ_MAKE'
},
{
    name: 'Vehicle Model',
    value: '@REQ_MODEL'
},
{
    name: 'Vehicle',
    value: '@REQ_VEHICLE'
},
{
    name : 'Vehicle Group',
    value : '@MODEL_GROUP'
},
{
    name : 'Vehicle Type',
    value : '@MODEL_TYPE'
},
{
    name: 'Delivery Date',
    value: '@DELIVERY_DATE'
},
{
    name: 'Warranty Expiry',
    value: '@WARRANTY_EXPIRY'
},
{
    name: 'Test Drive Vehicle',
    value: '@TESTDRIVE_VEHICLE'
},
{
    name: 'Trade-In Vehicle',
    value: '@TRADEIN_VEHICLE'
},
{
    name: 'Vehicle Reg#',
    value: '@VEHICLE_REGO'
},
{
    name: 'Dealer Name',
    value: '@DEALER_NAME'
},
{
    name: 'Dealer Phone',
    value: '@DEALER_PHONE'
},
{
    name: 'Dealer Email',
    value: '@DEALER_EMAIL'
},
{
    name: 'Dealer Address',
    value: '@DEALER_ADDRESS'
},
{
    name: 'Facebook URL',
    value: '@FACEBOOK_URL'
},
{
    name: 'X URL',
    value: '@X_URL'
},
{
    name: 'Instagram URL',
    value: '@INSTAGRAM_URL'
},
{
    name: 'YouTube URL',
    value: '@YOUTUBE_URL'
},
{
    name: 'Unsubscribe URL',
    value: '@UNSUBSCRIBE_URL'
},
{
    name: 'Schedule Date',
    value: '@SCHEDULE_DATE'
},
{
    name: 'Schedule Time',
    value: '@SCHEDULE_TIME'
},
{
    name: 'Completed Date',
    value: '@COMPLETED_DATE'
},
{
    name: 'Completed Time',
    value: '@COMPLETED_TIME'
},
{
    name: 'Display Name',
    value: '@ID'
},
{
    name: 'Owner Name',
    value: '@OWNER_NAME'
},
{
    name: 'Owner Email',
    value: '@OWNER_EMAIL'
},
{
    name: 'Owner Phone',
    value: '@OWNER_PHONE'
},
{
    name: 'Owner Designation',
    value: '@OWNER_DESIGNATION'
},
{
    name: 'Business Manager',
    value: '@BUSINESS_MANAGER'
},
{
    name: 'Business Manager Email',
    value: '@FINANCEBM_EMAIL'
},
{
    name: 'Business Manager Phone',
    value: '@FINANCEBM_PHONE'
},
{
    name: 'Business Manager Designation',
    value: '@FINANCEBM_DESIGNATION'
},
{
    name: 'After MArket Manager',
    value: '@AFTER_MARKET_MANAGER'
},
{
    name: 'After MArket Manager Email',
    value: '@AFTER_MARKET_MANAGER_EMAIL'
},
{
    name: 'After MArket Manager Phone',
    value: '@AFTER_MARKET_MANAGER_PHONE'
},
{
    name: 'After MArket Manager Designation',
    value: '@AFTER_MARKET_MANAGER_DESIGNATION'
},
{
    name: 'Current Month',
    value: '@CURRENT_MONTH'
},
{
    name: 'Enquiry Link',
    value: '@ENQUIRY_LINK'
},
{
    name: 'Enquiry No.',
    value: '@ENQUIRY_NO'
},
{
    name: 'Deposit Amount',
    value: '@DEPOSIT_AMOUNT'
},
{
    name: 'Deposit Recipt Number',
    value: '@DEPOSIT_RECIPT_NUMBER'
},
{
    name: 'Int. Color',
    value: '@INT_COLOR'
},
{
    name: 'Ext. Color',
    value: '@EXT_COLOR'
},
{
    name: 'Vehicle Price',
    value: '@PRICE'
}]

export const specialLinks = [
    {
        type: 'Enquiry Link',
        label: 'SpecialLink.EnquiryLink',
        link: '@ENQUIRY_LINK'
    },
    {
        type: 'Video Link',
        label: 'SpecialLink.VideoryLink',
        link: '@VIDEO_LINK'
    },
    {
        type: 'Facebook URL',
        label: 'Link.Facebook',
        link: '@FACEBOOK_URL'
    },
    {
        type: 'X URL',
        label: 'Link.X',
        link: '@X_URL'
    },
    {
        type: 'Instagram URL',
        label: 'Link.Instagram',
        link: '@INSTAGRAM_URL'
    },
    {
        type: 'Youtube URL',
        label: 'Link.Youtube',
        link: '@YOUTUBE_URL'
    },
    {
        type: 'Unsubscribe URL',
        label: 'Link.Unsubscribe',
        link: '@UNSUBSCRIBE_URL'
    }];