/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import htmlToText from 'html-to-text';
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import CommonHelper from '../../../services/common'
import EditLogs from "./editLogs";
import Translate from '../../../constants/translate';
import { callTypes } from '../../../services/enum'
import { AudioPlayer, PopUpModal } from '../../../components';
import AddCallNotes from '../details/addCallNotes';
import TranscriptModal from '../details/transcriptModal';

const CustomerlogDetails = React.memo(({ type, handlechangelog, handlecancellog,
    customerlog, clientUsers, handlesavelog, handleEditlog, textareaRef, activeLogTab,
    handleDeletelog, handleDateChangelog, loading, completedTab, tipOffers, handleEDMsPreviewEmail,
    dealersettings, handleUnlickEmail, handlePreviewEmail, handleReplylog, sendLoading, groupUsers,
    handleRadioChange, originType, updateActivity, handleFileChange, contact }) => {

    const [arrcallstatus, setCallStatus] = useState([]);
    const [editshow, setEditshow] = useState(false)
    const node = useRef();
    const [notesModel, setNotesModel] = useState({ show: false })
    const [showTranscriptModal, setShowTranscriptModal] = useState({ show: false });

    const aircallSettings = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
        dealersettings.client.integrations.filter(e => e.type === "aircall")[0] &&
        dealersettings.client.integrations.filter(e => e.type === "aircall")[0].active === true &&
        dealersettings.client.integrations.filter(e => e.type === "aircall")[0].enabled === true
    ) ? dealersettings.client.integrations.filter(e => e.type === "aircall")[0].settings : null);

    const justcallSettings = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
        dealersettings.client.integrations.filter(e => e.type === "justcall")[0] &&
        dealersettings.client.integrations.filter(e => e.type === "justcall")[0].active === true &&
        dealersettings.client.integrations.filter(e => e.type === "justcall")[0].enabled === true
    ) ? dealersettings.client.integrations.filter(e => e.type === "justcall")[0].settings : null);

    const ringcentralSettings = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
        dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0] &&
        dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0].active === true &&
        dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0].enabled === true
    ) ? dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0].settings : null);

    useEffect(() => {
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const setttings = dealersettings.client.settings;

            let options = [];
            setttings.callStatus && setttings.callStatus.forEach((doc) => {
                options.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    active: doc.active ? doc.active : true
                });
            });
            setCallStatus(options);
        }

    }, [dealersettings]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const _permissions = (customerlog.clientID === dealersettings.client.id && (!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

    const deleteActivity = ((!_.isEmpty(_permissions) && _permissions.deleteActivity) ? true : false);

    const modifyOtherActivity = ((!_.isEmpty(_permissions) && _permissions.modifyOtherActivity) ? true : false);

    const modifyNotes = ((!_.isEmpty(_permissions) && _permissions.modifyNotes) ? true : false);

    const unlinkEnquiry = ((!_.isEmpty(_permissions) && _permissions.unlinkEnquiry) ? true : false);

    const editActivity = customerlog.clientID !== dealersettings.client.id ? false : (((type !== 'message' && type !== 'call') &&
        ((!_.isEmpty(customerlog.searchUsers) && customerlog.searchUsers.indexOf(dealersettings.id) >= 0 && customerlog.type === CommonHelper.getActivityType('activity', originType))
            || (modifyOtherActivity && customerlog.type === CommonHelper.getActivityType('activity', originType))
            || (modifyNotes && type === 'note'))) ? true : false);

    let _allUser = _.union((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), Object.assign([], dealersettings.wholesalers));

    const emailContent = (!_.isEmpty(customerlog.email) && !_.isEmpty(customerlog.email.body) && customerlog.triggerType === 'email') ? htmlToText.fromString(customerlog.email.body.replace(/<img[^>]*>/g, "")) : '';

    //console.log('customerlog', modifyOtherActivity, modifyNotes, editActivity, modifyNotes)


    return (
        (!_.isEmpty(dealersettings))
            ?
            <><div>

                {
                    (customerlog.isNewlog || customerlog.isEditable) ? (
                        <EditLogs
                            handlechangelog={handlechangelog}
                            handlesavelog={handlesavelog}
                            handlecancellog={handlecancellog}
                            customerlog={customerlog}
                            textareaRef={textareaRef}
                            handleDateChangelog={handleDateChangelog}
                            loading={loading}
                            completedTab={completedTab}
                            dealersettings={dealersettings}
                            sendLoading={sendLoading}
                            handleRadioChange={handleRadioChange}
                            originType={originType}
                            handleFileChange={handleFileChange}
                        />
                    ) :
                        (!_.isEmpty(customerlog.thread)) ?
                            (<>
                                <div className="related-item">
                                    <div className="flow-activity-title"><a href="#" onClick={(e) => { e.preventDefault(); handlePreviewEmail(customerlog.thread, customerlog.documentID, customerlog.token) }} >{customerlog.thread ? customerlog.thread.subject : 'No Subject'}</a></div>
                                    {/* <div className="flow-activity-date mt-2"><span>{customerlog.startDate ? moment.unix(customerlog.startDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</span>
                                       {
                                            !_.isEmpty(customerlog.thread) && !_.isEmpty(customerlog.thread.participants) && customerlog.thread.participants.length > 0 ? (
                                                <span className="flow-email-id">• &nbsp;From: {customerlog.thread.participants[0].name} </span>
                                            ) : (<></>)
                                        } 
                                    </div>*/}
                                    <div className="flow-email-content">{customerlog.thread ? customerlog.thread.snippet : ''}</div>
                                    <div className="related-item-sub">{customerlog.addedDate ? moment.unix(customerlog.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''} - {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), customerlog.owner)}</div>

                                    {
                                        (unlinkEnquiry && !_.isEmpty(customerlog.thread)) ? (
                                            <Dropdown className="quickview-activity-more" alignRight>
                                                <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                                    <i className="ico icon-more"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                    <Dropdown.Item eventKey="1" onClick={(e) => { handleUnlickEmail(e, customerlog, completedTab) }}> <Translate text={'unlink'} /> </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ) : (<></>)
                                    }

                                </div>
                            </>) :
                            (<div className={`${(type === "note" || type === "allnotes" || activeLogTab === "Notes") ? "notes-item text-area-space" : "related-item"} customerlog-${customerlog.documentID} text-area-space`}>
                                {
                                    ((((deleteActivity || editActivity) && _.isEmpty(customerlog.tags))
                                        || (aircallSettings)
                                        || (justcallSettings)
                                        || (ringcentralSettings)) && type !== 'edms' && type !== 'allnotes')
                                        ?
                                        <>
                                            <div className="quickview-activity-more">
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    setEditshow(!editshow)
                                                    if (editshow)
                                                        document.addEventListener('click', setEditshow(false));
                                                }}><i className="ico icon-more"></i></a>
                                                <div ref={node} style={{ whiteSpace: `normal` }} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                                    <ul className="more-dropdown" >
                                                        {
                                                            (aircallSettings || ringcentralSettings || justcallSettings) &&
                                                                (customerlog?.tags?.includes('aircall') || customerlog?.tags?.includes('ringcentral') || customerlog?.tags?.includes('justcall')) ? (
                                                                <li> <a href="#" onClick={(e) => {
                                                                    setNotesModel({
                                                                        show: true,
                                                                        title: 'Add Notes',
                                                                        customerlog: {
                                                                            ...customerlog,
                                                                            type: 'log',
                                                                            subType: 'note',
                                                                            notes: '',
                                                                            mentions: [],
                                                                        }
                                                                    })
                                                                }}><i className="ico icon-note"></i> <Translate text={'Add Notes'} /> </a>
                                                                </li>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            (editActivity && _.isEmpty(customerlog.tags))
                                                                ?
                                                                <>
                                                                    <li> <a href="#" onClick={(e) => {
                                                                        handleEditlog(e, customerlog, completedTab)
                                                                    }}> <i className="ico icon-edit"></i>  <Translate text={'edit'} /></a> </li>
                                                                </>
                                                                :
                                                                <></>

                                                        }
                                                        {
                                                            (deleteActivity && _.isEmpty(customerlog.tags))
                                                                ?
                                                                <li> <a href="#" onClick={(e) => {
                                                                    handleDeletelog(e, customerlog, completedTab)
                                                                }}> <i className="ico icon-delete"></i>  <Translate text={'delete'} /></a> </li>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            aircallSettings && customerlog?.aircall?.recordingVisibility === 'private' && aircallSettings?.emails?.some(a => a === dealersettings.email) ? (
                                                                <li> <a href="#" onClick={(e) => { updateActivity(e, { ...customerlog, aircall: { ...customerlog.aircall, recordingVisibility: 'shared' } }) }}><i className="ico icon-activity-meeting"></i>  <Translate text={'Public'} /></a> </li>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            aircallSettings && customerlog?.aircall?.recordingVisibility === 'shared' && aircallSettings?.emails?.some(a => a === dealersettings.email) ? (
                                                                <li> <a href="#" onClick={(e) => { updateActivity(e, { ...customerlog, aircall: { ...customerlog.aircall, recordingVisibility: 'private' } }) }}><i className="ico icon-reserve"></i>  <Translate text={'Private'} /></a> </li>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            (ringcentralSettings || justcallSettings) && customerlog?.recordingVisibility === 'private' && (ringcentralSettings?.emails?.some(a => a === dealersettings.email) || justcallSettings?.emails?.some(a => a === dealersettings.email)) ? (
                                                                <li> <a href="#" onClick={(e) => { updateActivity(e, { ...customerlog, recordingVisibility: 'shared' }) }}><i className="ico icon-activity-meeting"></i>  <Translate text={'Public'} /></a> </li>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            (ringcentralSettings || justcallSettings) && customerlog?.recordingVisibility === 'shared' && (ringcentralSettings?.emails?.some(a => a === dealersettings.email) || justcallSettings?.emails?.some(a => a === dealersettings.email)) ? (
                                                                <li> <a href="#" onClick={(e) => { updateActivity(e, { ...customerlog, recordingVisibility: 'private' }) }}><i className="ico icon-reserve"></i>  <Translate text={'Private'} /></a> </li>
                                                            ) : (<></>)
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                        </>
                                }

                                {
                                    customerlog.subType === 'call' && customerlog.notes && (customerlog?.tags?.includes('aircall') || customerlog?.tags?.includes('ringcentral') || customerlog?.tags?.includes('justcall')) ?
                                        (<div className='flow-activity-notes-inner ml-0 mt-0 mr-2'>{customerlog.notes}</div>) :
                                        (<>{
                                            ((!_.isEmpty(customerlog.email) && customerlog.triggerType === 'email') || (customerlog?.email?.body && customerlog.subType === 'email')) ? (
                                                <div className="flow-activity-title pb-2">
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEDMsPreviewEmail(e, customerlog)
                                                    }}>{customerlog.title || customerlog.notes}</a>
                                                </div>
                                            ) :
                                                <div dangerouslySetInnerHTML={{
                                                    __html: CommonHelper.stringtoURL(customerlog.notes ? _.trim(customerlog.notes) : ''),
                                                }}
                                                ></div>
                                        }</>)
                                }

                                {
                                    type === "note" || type === "allnotes" || activeLogTab === "Notes" ? (
                                        <div className="notes-item-sub">
                                            {
                                                activeLogTab === "Notes" && customerlog.type === CommonHelper.getActivityType('activity', originType)
                                                    ?
                                                    <></>
                                                    :
                                                    <>
                                                        {/* {
                                                            customerlog.owner
                                                                ?
                                                                <>
                                                                    {CommonHelper.getUserNamebyId(_allUser, customerlog.owner)}
                                                                    <span>{customerlog.addedDate ? moment.unix(customerlog.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                                </>
                                                                :
                                                                <span className={'pl-0'}>{customerlog.addedDate ? moment.unix(customerlog.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                        } */}

                                                        {customerlog.addedFrom ?
                                                            <>
                                                                &nbsp;{` - `}&nbsp;
                                                                <i className={`ico icon-${customerlog.addedFrom === 'web' ? 'monitor' : customerlog.addedFrom} mr-1`}></i>
                                                                {customerlog.addedFrom} </> : <></>}

                                                        <><span className={'pl-0'}>{moment.unix(customerlog.addedDate.seconds).format("DD/MM/YYYY h:mm A")}</span>{customerlog.owner ? ` - ${CommonHelper.getUserNamebyId(_allUser, customerlog.owner)}` : ""}  </>
                                                        {customerlog.addedFrom ? (
                                                            <>  &nbsp;{` - `}&nbsp;  <i className={`ico icon-${customerlog.addedFrom === "web" ? "monitor" : customerlog.addedFrom} mr-1`}  ></i>  {customerlog.addedFrom}{" "}</>
                                                        ) : (<></>)}

                                                        {customerlog.modifiedDate && customerlog.addedDate && customerlog.addedDate.seconds !== customerlog.modifiedDate.seconds && (<div >
                                                            <Translate text={"Last Edited"} />:&nbsp;
                                                            <span className={'pl-0'}>{moment.unix(customerlog.modifiedDate.seconds).format("DD/MM/YYYY h:mm A")}</span>
                                                            {customerlog.modifiedBy ? ` - ${CommonHelper.getUserNamebyId(_allUser, customerlog.modifiedBy)}` : ""}
                                                            {/* {customerlog.modifiedFrom ? (
                                <>  &nbsp;{` - `}&nbsp;  <i className={`ico icon-${customerlog.modifiedFrom === "web" ? "monitor" : customerlog.modifiedFrom} mr-1`}  ></i>  {customerlog.modifiedFrom}{" "}</>
                              ) : (<></>)} */}
                                                        </div>)}

                                                    </>
                                            }


                                            {
                                                type === "allnotes" || (activeLogTab === "Notes" && type !== "note")
                                                    ?
                                                    <div className="badge badge-pill badge-input-white ml-2 text-capitalize">
                                                        <Translate text={customerlog.type === CommonHelper.getActivityType('activity', originType) ? 'activity' : customerlog.subType} toUpperCase={true} />
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                            {
                                                (customerlog.offerID && customerlog.originType && !_.isEmpty(tipOffers) && _.find(tipOffers, { documentID: customerlog.offerID }).wholeSellerId) ? (
                                                    <div className="badge badge-input-white ml-1">Offer:&nbsp;{CommonHelper.getUserNamebyId(dealersettings.wholesalers, _.find(tipOffers, { documentID: customerlog.offerID }).wholeSellerId, '')}</div>
                                                ) : (<></>)
                                            }
                                            {
                                                (customerlog.originType === 'serviceNotes') ? (
                                                    <div className="badge badge-pill badge-input-white ml-1 text-uppercase"><Translate text={'serviceNotes'} /></div>
                                                ) : (<></>)
                                            }
                                            {
                                                (customerlog.wholeSalerNotes) ? (
                                                    <div className="badge badge-pill badge-input-white ml-1 text-uppercase"><Translate text={'wholeSaler'} /></div>
                                                ) : (<></>)
                                            }
                                            {
                                                (customerlog.isInternalNotes) ? (
                                                    <div className="badge badge-pill badge-input-white ml-1 text-uppercase"><Translate text={'Internal'} /></div>
                                                ) : (<></>)
                                            }

                                        </div>
                                    ) : (
                                        <>
                                            {
                                                type === "call" ? (<div className="related-item-sub">
                                                    {
                                                        !_.isEmpty(customerlog.callStatus) ? (<><Translate text={'status'} /> : {CommonHelper.getLabelByValue(arrcallstatus, customerlog.callStatus, '--')}</>) : (<></>)
                                                    }

                                                    <span className="call-time"><i className="ico icon-time"></i>{CommonHelper.convertsecstostr(customerlog.callDuration)}</span>
                                                </div>) : (<></>)
                                            }
                                            {
                                                (type === "call" && !_.isEmpty(customerlog.callType)) ? (<div className="related-item-sub"><Translate text={'type'} /> : {CommonHelper.getNameByValue(callTypes, customerlog.callType, '--')}</div>) : (<></>)
                                            }
                                            {
                                                (!_.isEmpty(customerlog.email) && customerlog.triggerType === 'email') ? (
                                                    <>{emailContent.length > 200 ? emailContent.substring(0, 200) + '...' : emailContent}</>
                                                ) : (<></>)
                                            }
                                            {
                                                !_.isEmpty(customerlog?.aircall?.recording)
                                                    ?
                                                    <>
                                                        <div className="text-area-none w-100 mt-2 mb-2 position-relative">
                                                            {
                                                                customerlog?.aircall?.recordingVisibility === 'private' && !aircallSettings?.emails?.some(a => a === dealersettings.email) ? (<>
                                                                    <div className="audio-overlay-lock">
                                                                        <div className="audio-lock-insideico"><div className="badge badge-pill badge-private-call"><i className="ico icon-activity-lock"></i> Private </div></div>
                                                                    </div>
                                                                    <AudioPlayer
                                                                        id={`aircall-quickview-${type}-${customerlog.documentID}`}
                                                                        recording={'https://firebasestorage.googleapis.com/v0/b/ams-pro-qa.appspot.com/o/audio%2FBlank.mp3?alt=media&token=b703df18-780c-4ab7-a0a3-432de23c1613'}
                                                                    >
                                                                    </AudioPlayer>
                                                                </>) : (
                                                                    <AudioPlayer
                                                                        id={`aircall-quickview-${type}-${customerlog.documentID}`}
                                                                        recording={customerlog.aircall.recording}
                                                                    >
                                                                    </AudioPlayer>
                                                                )
                                                            }

                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            {
                                                !_.isEmpty(customerlog?.recording)
                                                    ?
                                                    <>
                                                        <div className="text-area-none w-100 mt-2 mb-2 position-relative">
                                                            {
                                                                customerlog?.recordingVisibility === 'private'
                                                                    && !ringcentralSettings?.emails?.some(a => a === dealersettings.email)
                                                                    && !justcallSettings?.emails?.some(a => a === dealersettings.email)
                                                                    ? (<>
                                                                        <div className="audio-overlay-lock">
                                                                            <div className="audio-lock-insideico"><div className="badge badge-pill badge-private-call"><i className="ico icon-activity-lock"></i> Private </div></div>
                                                                        </div>
                                                                        <AudioPlayer
                                                                            id={`recording-quickview-${type}-${customerlog.documentID}`}
                                                                            recording={'https://firebasestorage.googleapis.com/v0/b/ams-pro-qa.appspot.com/o/audio%2FBlank.mp3?alt=media&token=b703df18-780c-4ab7-a0a3-432de23c1613'}
                                                                        >
                                                                        </AudioPlayer>
                                                                    </>) : (<>
                                                                        <AudioPlayer
                                                                            id={`recording-quickview-${type}-${customerlog.documentID}`}
                                                                            recording={customerlog.recording}
                                                                        >
                                                                        </AudioPlayer>
                                                                        {
                                                                            !_.isEmpty(customerlog?.justcall) && !_.isEmpty(customerlog?.recording) && (
                                                                                <div className="call-transcript-link"> <a href="#" onClick={(e) => {
                                                                                    e.preventDefault(); setShowTranscriptModal({
                                                                                        ...showTranscriptModal,
                                                                                        show: true,
                                                                                        activitylog: customerlog,
                                                                                        justcallSettings: justcallSettings,
                                                                                        contact: contact
                                                                                    })
                                                                                }}><i className="ico icon-chat"></i> Transcript</a></div>)
                                                                        }
                                                                        {
                                                                            !_.isEmpty(customerlog?.transcript) && (
                                                                                <div className="call-transcript-link"> <a href="#" onClick={(e) => {
                                                                                    e.preventDefault(); setShowTranscriptModal({
                                                                                        ...showTranscriptModal,
                                                                                        show: true,
                                                                                        activitylog: customerlog,
                                                                                        transcript: customerlog?.transcript,
                                                                                        contact: contact
                                                                                    })
                                                                                }}><i className="ico icon-chat"></i> Transcript</a></div>)
                                                                        }
                                                                    </>)
                                                            }

                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                            {
                                                customerlog?.files?.length > 0 ? (
                                                    <div className="flow-mms-wrapper">
                                                        {
                                                            customerlog.files.map((rec, index) => {
                                                                return <div className="flow-mms-item-image">
                                                                    {
                                                                        rec?.type?.includes("image") ? (
                                                                            <a data-fancybox={`file-${index}`} href={rec.url} onClick={(e) => { e.preventDefault(); }}>
                                                                                <img id={`file-${index}`} src={rec.url} alt={rec.name} className="" width="40" height="40" />
                                                                            </a>
                                                                        )
                                                                            : (
                                                                                <a href={rec.url} target='_blank' className='hyperlink-blue'> {rec.name} </a>
                                                                            )
                                                                    }
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                customerlog.owner
                                                    ?
                                                    <div className="related-item-sub 1">{customerlog.addedDate ? moment.unix(customerlog.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''} - {CommonHelper.getUserNamebyId(_allUser, customerlog.owner)}</div>
                                                    : customerlog.addedByName
                                                        ?
                                                        <div className="related-item-sub 1">{customerlog.addedDate ? moment.unix(customerlog.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''} - {customerlog.addedByName}</div>
                                                        :
                                                        <div className="related-item-sub 2">{customerlog.addedDate ? moment.unix(customerlog.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</div>
                                            }

                                        </>
                                    )
                                }
                                {
                                    customerlog.clientID && (customerlog.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ?
                                        (<span className="tbl-sub-dealer mt-1">{CommonHelper.getOtherDealerName(dealersettings, customerlog.clientID)}</span>)
                                        : (<></>)
                                }
                                {
                                    !_.isEmpty(customerlog.replies) && customerlog.subType === 'message'
                                        ?
                                        <>
                                            {
                                                customerlog.replies.map((rec, index) => {
                                                    return <div key={index} className="flow-message-out">
                                                        {rec.text}
                                                        {
                                                            rec?.files?.length > 0 ? (
                                                                <div className="flow-mms-wrapper">
                                                                    {
                                                                        rec.files.map((rec, index) => {
                                                                            return <div className="flow-mms-item-image">
                                                                                {
                                                                                    rec?.type?.includes("image") ? (
                                                                                        <a data-fancybox={`file-${index}`} href={rec.url} onClick={(e) => { e.preventDefault(); }}>
                                                                                            <img id={`file-${index}`} src={rec.url} alt={rec.name} className="" width="40" height="40" />
                                                                                        </a>
                                                                                    )
                                                                                        : (
                                                                                            <a href={rec.url} target='_blank' className='hyperlink-blue'> {rec.name} </a>
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        {rec.date ? <div className="flow-activity-date">
                                                            {moment.unix(rec.date.seconds).format('DD/MM/YYYY h:mm A')}
                                                            {
                                                                (index + 1) === customerlog.replies.length
                                                                    ?
                                                                    <span className="sms-reply">
                                                                        <a href="#" onClick={e => {
                                                                            handleReplylog(e, customerlog);
                                                                        }}>
                                                                            <i className="ico icon-mail-reply"></i>
                                                                            <Translate text={'Reply'} />
                                                                        </a>
                                                                    </span>
                                                                    :
                                                                    <></>
                                                            }

                                                        </div> : <></>}
                                                    </div>
                                                })
                                            }
                                        </>

                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(customerlog.tags) && customerlog.tags.length > 0 ?
                                        (<div className="flow-notes-sub">
                                            {
                                                customerlog.tags.map((rec, index) => {
                                                    return <div key={index} className="badge badge-pill badge-input-white mr-1 text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, (_.isObject(rec) ? rec.type : rec), (_.isObject(rec) ? rec.type : rec))}</div>
                                                })
                                            }
                                            {
                                                customerlog?.aircall?.recordingVisibility ? (
                                                    <div className={`badge badge-pill badge-${customerlog?.aircall?.recordingVisibility.toLowerCase()}-audio text-uppercase`}>
                                                        {customerlog?.aircall?.recordingVisibility}
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                customerlog?.recordingVisibility ? (
                                                    <div className={`badge badge-pill badge-${customerlog?.recordingVisibility.toLowerCase()}-audio text-uppercase`}>
                                                        {customerlog?.recordingVisibility}
                                                    </div>
                                                ) : (<></>)
                                            }
                                        </div>)
                                        : (<></>)
                                }
                                {
                                    (!_.isEmpty(customerlog.triggerType) && customerlog.subType !== 'gdpr') ? (
                                        <div className="badge badge-pill badge-input-white mr-1 text-capitalize mt-2"><Translate text={'edms'} /></div>
                                    ) : (<></>)
                                }
                                {
                                    (!_.isEmpty(customerlog.triggerType) && customerlog.subType !== 'gdpr' && !customerlog.isDone) ? (
                                        <div className="badge badge-pill badge-input-white mr-1 text-capitalize mt-2"><Translate text={'scheduled'} /></div>
                                    ) : (<></>)
                                }
                            </div>
                            )
                }
            </div>
                <PopUpModal show={notesModel.show}>
                    <AddCallNotes
                        {...notesModel}
                        handleClose={(log, mentions) => {
                            if (log?.notes) {
                                let notes = '';
                                notes = notes + log?.notes + '\n';
                                notes = notes + moment().format('DD/MM/YYYY hh:mm A') + ' - ' + dealersettings.name + '\n\n';

                                updateActivity(null, {
                                    ...customerlog,
                                    notes: notes + (customerlog.notes ? customerlog.notes : ''),
                                    mentions: mentions?.length > 0 ? mentions.map(r => { return r.id }) : []
                                });
                                setNotesModel({ show: false })

                            } else {
                                setNotesModel({ show: false })
                            }
                        }}
                    />
                </PopUpModal>
                <PopUpModal show={showTranscriptModal.show}>
                    <TranscriptModal
                        {...showTranscriptModal}
                        show={showTranscriptModal.show}
                        handleClose={() =>
                            setShowTranscriptModal({ ...showTranscriptModal, show: false })
                        }
                    />
                </PopUpModal>
            </>

            :
            <></>
    )
});

export default CustomerlogDetails;