import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { ContentOverlay, BarChart, PieChart, LineChart, NoDataPieChart } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import { reportGroupBy, reportDynColumns } from '../../reports/viewModel'
import NumberFormat from 'react-number-format';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";



const OEMDynamicReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [progressReport, setProgressReport] = useState([])
    const [dynReport, setDynReport] = useState()
    const [dealersettings, setDealersettings] = useState()
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [allUsers, setAllUsers] = useState([]);
    const [timezone, setTimezone] = useState(moment.tz.guess());
    const [clientReport, setClientReport] = useState([])
    const [oemSettings, setOEMSettings] = useState();
    const [dymamicCols, setDymamicColumns] = useState([]);
    const [isInbound, setisInbound] = useState()
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [calcCurrentStatus, setcalcCurrentStatus] = useState('false')
    const [currencySymbol, setcurrencySymbol] = useState('');
    const [regions, setRegions] = useState([])
    const [groups, setGroups] = useState([])
    const [multiTDperLead, setMultiTDperLead] = useState('false');
    const [inclInboundBuckeinRpt, setinclInboundBuckeinRpt] = useState('false');
    const [inclInboundLeadsinRpt, setinclInboundLeadsinRpt] = useState('false');

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    let _startdate = params.get('startDate');
    let _enddate = params.get('endDate');

    useEffect(() => {
        window.firebase.firestore().doc(`reports/${props.reportid}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    const schedularDoc = await window.firebase.firestore().doc(`schedular/${props.reportid}`).get()
                    setDynReport({
                        ...doc.data(),
                        'documentID': doc.id,
                        'columns': _.isArray(doc.data().columns) ? doc.data().columns : [doc.data().columns],
                        'upcomingDays': schedularDoc?.data()?.upcomingDays ? schedularDoc?.data().upcomingDays : [],
                        'dataParam': schedularDoc?.data()?.dataParam ? schedularDoc?.data().dataParam : ''
                    })
                    setisInbound(doc.data().pipeline && doc.data().pipeline.some(b => b === 'LeadsBucket') && doc.data().pipeline.length === 1 ? true : false)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(dynReport) || _.isEmpty(dynReport.clientIDs))
            return
        async function getClientSettings() {
            const promises = []
            const clientpromises = []
            const users = [];
            const settings = [];
            let _timezone = '';
            dynReport.clientIDs.forEach(cid => {
                promises.push(window.firebase.firestore().doc(`clients/${cid}/currentSettings/${cid}`).get())
            })

            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                if (snap.exists) {
                    if (snap.data().users) {
                        users.push([...snap.data().users])
                    }
                    settings.push({
                        ...snap.data(),
                        id: snap.id
                    });
                }
                else {
                    clientpromises.push(window.firebase.firestore().doc(`clients/${snap.id}`).get())
                }
            });
            const clientsnapshots = await Promise.all(clientpromises);
            clientsnapshots.forEach(snap => {
                if (snap.exists) {
                    settings.push({
                        ...snap.data(),
                        id: snap.id
                    });
                }
            });
            if (dynReport.level === 'group') {
                const groupsSnapshot = await window.firebase.firestore().collection(`clientSettings/${dynReport.settingsID}/groups`).get();
                if (groupsSnapshot.docs.length > 0) {
                    var _groups = []
                    let i = 0;
                    groupsSnapshot.docs.forEach(rec => {
                        i++;
                        _groups.push({
                            ...rec.data(),
                            documentID: rec.id,
                            id: rec.id,
                            sortOrder: rec.data().sortOrder && Number(rec.data().sortOrder) > 0 ? Number(rec.data().sortOrder) : i
                        })
                    })
                    setGroups(_.orderBy(_groups, ['sortOrder'], ['asc']))
                }
            }
            if (dynReport.level === 'region') {
                const regionsSnapshot = await window.firebase.firestore().collection(`clientSettings/${dynReport.settingsID}/regions`).get();
                if (regionsSnapshot.docs.length > 0) {
                    var _regions = []
                    let i = 0;
                    regionsSnapshot.docs.forEach(rec => {
                        i++;
                        _regions.push({
                            name: rec.data().name,
                            documentID: rec.id,
                            id: rec.id,
                            code: rec.id,
                            sortOrder: rec.data().sortOrder && Number(rec.data().sortOrder) > 0 ? Number(rec.data().sortOrder) : i
                        })
                    })
                    setRegions(_.orderBy(_regions, ['sortOrder'], ['asc']))
                }
            }
            const oempromises = []
            if (dynReport.oemID) {
                oempromises.push(window.firebase.firestore().doc(`clientSettings/${dynReport.oemID}`).get());
            }
            else if (dynReport.regionID) {
                oempromises.push(window.firebase.firestore().doc(`clientSettings/${dynReport.settingsID}/regions/${dynReport.regionID}`).get());
            }
            else if (dynReport.groupID) {
                oempromises.push(window.firebase.firestore().doc(`clientSettings/${dynReport.settingsID}/groups/${dynReport.groupID}`).get());
            }
            let weekStart = '';
            const oemsnapshots = await Promise.all(oempromises)
            oemsnapshots.forEach(snap => {
                if (snap.exists) {
                    setOEMSettings(snap.data());
                    _timezone = snap.data().timezone;
                    weekStart = snap.data().weekStart;
                    setcurrencySymbol(!_.isEmpty(snap.data().currency) ? snap.data().currency.symbol + ' ' : '$ ');
                    if (!_.isEmpty(snap.data().clientSettings) && snap.data().clientSettings.calcCurrentStatus)
                        setcalcCurrentStatus('true');
                    if (!_.isEmpty(snap.data().clientSettings) && snap.data().clientSettings.multiTDperLead)
                        setMultiTDperLead('true');
                    if (!_.isEmpty(snap.data().clientSettings) && snap.data().clientSettings.inclInboundBuckeinRpt)
                        setinclInboundBuckeinRpt('true');
                    if (!_.isEmpty(snap.data().clientSettings) && snap.data().clientSettings.inclInboundLeadsinRpt)
                        setinclInboundLeadsinRpt('true');
                }
            });
            if (_.isEmpty(_timezone))
                _timezone = settings.some(e => !_.isEmpty(e.timezone)) ? settings.filter(e => !_.isEmpty(e.timezone))[0].timezone : moment.tz.guess();
            setTimezone(_timezone);
            const _currentDate = moment().tz(timezone).format('YYYY-MM-DD HH:mm');
            if (_startdate && _enddate) {
                setDateRange({
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_startdate).tz(timezone).startOf('day').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_enddate).tz(timezone).endOf('day').toDate())
                });
            }
            else if (_date === 'today') {
                setDateRange({
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('day').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                });
            }
            else if (_date === 'lwd') {
                setDateRange({
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('day', -6).startOf('day').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                });
            }
            else if (_date === 'wtd') {
                if (!_.isEmpty(weekStart)) {
                    moment.updateLocale('en', {
                        week: {
                            dow: moment().day(weekStart).day(),
                        },
                    })
                    setDateRange({
                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week').toDate()),
                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate()),
                    });
                }
                else {
                    setDateRange({
                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('day', -6).startOf('day').toDate()),
                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate())
                    });
                }
            }
            else if (_date === 'pwd') {
                if (!_.isEmpty(weekStart)) {
                    moment.updateLocale('en', {
                        week: {
                            dow: moment().day(weekStart).day(),
                        },
                    })
                }
                setDateRange({
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('week', -1).startOf('week').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('week', -1).endOf('week').toDate()),
                });
            }
            else if (_date === 'lmd') {
                setDateRange({
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('month', -1).startOf('month').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('month', -1).endOf('month').toDate()),
                });
            }
            else if (dynReport.dataParam === 'upcoming' && !_.isEmpty(dynReport.upcomingDays)) {
                if (!_.isEmpty(weekStart)) {
                    moment.updateLocale('en', {
                        week: {
                            dow: moment().day(weekStart).day(),
                        },
                    })
                }
                if (moment().startOf('week').day() >= 1 && dynReport.upcomingDays.includes('0')) {
                    setDateRange({
                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays.filter(a => a !== '0'))).toDate()),
                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('week').toDate())
                    })
                }
                else if (moment().startOf('week').day() === 0 && dynReport.upcomingDays.includes('0')) {
                    setDateRange({
                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week').toDate()),
                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays.filter(a => a !== '0'))).toDate()),
                    })
                }
                else {
                    setDateRange({
                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays)).toDate()),
                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays)).toDate()),
                    })
                }

            }
            else {
                setDateRange({
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().tz(_timezone).startOf('month').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().tz(_timezone).endOf('month').toDate())
                });
            }
            setDealersettings(settings);
            setAllUsers(users);
        }
        getClientSettings();

    }, [dynReport])

    useEffect(() => {
        if (_.isEmpty(dealersettings))
            return
        let _leadSource = []
        const enqStatus = dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryStatus ?
            dealersettings[0].settings.enquiryStatus : [];
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryTypes.filter(a => a.active === true && a.level === 'oem')
                .forEach(rec => {
                    const _sold = _.find(enqStatus, { value: 'won' })?.name || 'Won';
                    _leadSource.push({
                        label: rec.name,
                        value: `enqtype-${rec.value}`,
                        name: `${rec.name} (Enquiry Type)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Enquiry Type',
                    })
                    _leadSource.push({
                        label: `${rec.name} ${_sold}`,
                        value: `enqtypesold-${rec.value}`,
                        name: `${rec.name} ${_sold} (Enquiry Type)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Enquiry Type',
                    })
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.origins.filter(a => a.active === true && a.level === 'oem')
                .forEach(rec => {
                    const _sold = _.find(enqStatus, { value: 'won' })?.name || 'Won';
                    _leadSource.push({
                        label: rec.name,
                        value: `origin-${rec.value}`,
                        name: `${rec.name} (Origin)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Origin',
                    })
                    _leadSource.push({
                        label: `${rec.name} ${_sold}`,
                        value: `originsold-${rec.value}`,
                        name: `${rec.name} ${_sold} (Origin)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Origin',
                    })
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.leadSource &&
            dealersettings[0].settings.leadSource.filter(a => a.active === true && a.level === 'oem')
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `leadSource-${rec.value}`,
                        name: `${rec.name} (Lead Source)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lead Source',
                    })
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.lostReasons.filter(a => a.active === true && a.level === 'oem')
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `lostReason-${rec.value}`,
                        name: `${rec.name} (Lost Reason)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lost Reason',
                    })
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.activityOutcome &&
            dealersettings[0].settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `outcome-${rec.value}`,
                        name: `${rec.name} (Activity Outcome)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Activity Outcome',
                        type: 'activity'
                    })
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.callStatus &&
            dealersettings[0].settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `callstatus-${rec.value}`,
                        name: `${rec.name} (Call Status)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Call Status',
                        type: 'activity'
                    })
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryStatus &&
            dealersettings[0].settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `enqstatus-${rec.value}`,
                        name: `${rec.name} (Enquiry Status)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                    _leadSource.push({
                        label: `${rec.name} Conv.`,
                        value: `enqstatus-${rec.value}Conv`,
                        name: `${rec.name} Conv.`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['percentage'],
                        percentage: true,
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                })
        dealersettings[0] &&
            dealersettings[0].settings?.financeStatus &&
            dealersettings[0].settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `financestatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Finance Status'
                    })
                })
        dealersettings[0] &&
            dealersettings[0].settings?.enquiryOptionsDF &&
            dealersettings[0].settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        _leadSource.push({
                            label: rec,
                            value: `${item.type};${item.value};${rec}`,
                            name: item.name,
                            view: ['table', 'column', 'pie', 'target'],
                            calculationType: ['count'],
                            color: '#4967CC',
                            sourceType: item.name
                        })
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            _leadSource.push({
                searchlabel: rec,
                value: rec,
                name: rec === '15' ? '15+' : rec,
                label: rec === '15' ? '15+' : rec,
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#4967CC',
                sourceType: 'Persistency'
            })
            if (rec === '4' || rec === '15') {
                _leadSource.push({
                    searchlabel: 'Total',
                    value: `${rec}plus`,
                    name: rec === '4' ? (<>0-4 <br /> Total</>) : (<>5-15+ <br /> Total</>),
                    label: rec === '4' ? (<>0-4 <br /> Total</>) : (<>5-15+ <br /> Total</>),
                    view: ['table', 'column', 'pie', 'target'],
                    calculationType: ['count'],
                    color: '#4967CC',
                    sourceType: 'Persistency',
                    cls: 'table-grey-sub-total'
                })
            }
        })
        if (dealersettings?.[0]?.isPorsche === true) {
            _leadSource.push({
                label: 'Leads Created in C@P',
                searchlabel: 'Leads Created in C@P',
                value: 'newCAP',
                name: 'Leads Created in C@P',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Lost From Enquiries',
                searchlabel: 'Lost From Enquiries',
                value: 'lostEnq',
                name: 'Lost From Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Assigned Enquiries',
                searchlabel: 'Assigned Enquiries',
                value: 'allocated',
                name: 'Assigned Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Outdated',
                searchlabel: 'Outdated',
                value: 'outdated',
                name: 'Outdated',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
        }
        setReportColumns([...reportColumns.map(rec => {
            return rec.status === true && _.find(enqStatus, { value: rec.value }) ? {
                ...rec,
                name: rec.value === 'won' && dynReport.columnType === 'persistency' ? 'Total' : `${_.find(enqStatus, { value: rec.value }).name} ${dealersettings?.[0]?.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                label: rec.value === 'won' && dynReport.columnType === 'persistency' ? 'Total' : `${_.find(enqStatus, { value: rec.value }).name} ${dealersettings?.[0]?.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                cls: rec.value === 'won' && dynReport.columnType === 'persistency' ? 'table-sub-total' : null
            } : { ...rec }
        }), ..._leadSource]);
        setRefreshClientData(true)
    }, [dealersettings])

    useEffect(() => {
        if (_.isEmpty(dealersettings) || !refreshClientData)
            return

        try {
            let _filters = {};
            if (!_.isEmpty(dynReport.objFilters) && dynReport.objFilters.length > 0) {
                dynReport.objFilters.forEach(rec => {
                    _filters = rec.name === 'date' ? {
                        ..._filters,
                        startDate: moment.unix(rec.value.startDate.seconds).format('YYYY-MM-DD'),
                        endDate: moment.unix(rec.value.endDate.seconds).format('YYYY-MM-DD')
                    } : rec?.name?.includes('select-') || rec?.name?.includes('multiselect-') ? {
                        ..._filters,
                        dynamicFilter: _filters?.dynamicFilter ? [
                            ..._filters?.dynamicFilter, {
                                name: rec.name,
                                value: rec.value[rec.name]
                            }
                        ] : [{
                            name: rec.name,
                            value: rec.value[rec.name]
                        }]
                    } : {
                        ..._filters,
                        ...rec.value,
                        ...(rec.subList ? rec.subList : {})
                    }
                })
            }
            else {
                _filters = {
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                }
            }
            if (_filters.dynamicFilter) {
                _filters = {
                    ..._filters,
                    dynamicFilter: JSON.stringify(_filters.dynamicFilter)
                }
            }
            if (dealersettings?.[0]?.isPorsche === true) {
                _filters.isPorsche = true;
            }
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getDynamicReport',
                params: JSON.stringify({
                    timezone: timezone,
                    reportID: props.reportid,
                    calcCurrentStatus,
                    multiTDperLead,
                    inclInboundBuckeinRpt,
                    inclInboundLeadsinRpt,
                    ..._filters,
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD')
                })
            }).then((response) => {
                setLoader(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
            });
        }
        catch (error) {
            setLoader(false)
        }

    }, [dealersettings, refreshClientData])


    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'] : []
        let reports = [];
        let _dynCols = [];
        _report.forEach(el => {
            dealersettings.forEach(client => {
                if (el[client.id]) {
                    el[client.id].filter(a => dynReport.groupBy === 'client' ? a.id === client.id : true).forEach(rec => {
                        if (Object.keys(rec).filter(r => Boolean(dynReport.includeZero) ? true : _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                            reports.push({
                                ...rec,
                                arrcarryover: rec.arrcarryover ? rec.arrcarryover.filter(e => rec.arrexisitngLeads ? !rec.arrexisitngLeads.some(a => a === e) : true) : 0,
                                profileImage: dynReport.groupBy === 'user' && _.find(allUsers, { id: rec.id }) ? _.find(allUsers, { id: rec.id }).profileImage : '',
                                clientID: client.id,
                                clientName: client.name,
                                groupName: _.find(groups, { id: rec.groupID }) ? _.find(groups, { id: rec.groupID }).name : 'No Group',
                                regionName: _.find(regions, { id: rec.regionID }) ? _.find(regions, { id: rec.regionID }).name : 'No Region',
                                newLeads: rec.arrnewLeads ? rec.arrnewLeads.length : 0,
                                exisitngLeads: rec.arrexisitngLeads ? rec.arrexisitngLeads.length : 0,
                                pendingLeads: rec.arrpendingLeads ? rec.arrpendingLeads.length : 0,
                                totalInbound: rec.arrtotalInbound ? rec.arrtotalInbound.length : 0,
                                overallPending: rec.arroverallPending ? rec.arroverallPending.length : 0,
                                convertedLeads: rec.arrconvertedLeads ? rec.arrconvertedLeads.length : 0,
                                show: rec.arrshow ? rec.arrshow.length : 0,
                                showCount: rec.arrshow ? rec.arrshow.length : 0,
                                noshow: rec.arrnoshow ? rec.arrnoshow.length : 0,
                                //avg response time
                                arrresponseLeads: rec.arrresponseTime ? _.uniq(rec.arrresponseTime.map(r => { return r.documentID })) : [],
                                totalresponseTime: _.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0,
                                responseTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0, client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                                //max reposnse time
                                arrmaxresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                                arrmaxresponseLeads: _.maxBy(rec.arrresponseTime, 'responseTime') ? [_.maxBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                                totalmaxresponseTime: _.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                                maxresponseTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0), client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                                //min response time
                                arrminresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                                arrminresponseLeads: _.minBy(rec.arrresponseTime, 'responseTime') ? [_.minBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                                totalminresponseTime: _.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                                minresponseTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0), client, (dynReport.reportType === 'table' ? '' : 'seconds')),

                                //avg allocated time
                                arrallocatedLeads: rec.arrallocatedTime ? _.uniq(rec.arrallocatedTime.map(r => { return r.documentID })) : [],
                                totalallocatedTime: _.sumBy(rec.arrallocatedTime, 'allocatedTime') > 0 ? _.meanBy(rec.arrallocatedTime, "allocatedTime") : 0,
                                allocatedTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrallocatedTime, 'allocatedTime') > 0 ? _.meanBy(rec.arrallocatedTime, "allocatedTime") : 0, client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                                //max allocated time
                                arrmaxallocatedTime: rec.arrallocatedTime ? rec.arrallocatedTime : [],
                                arrmaxallocatedLeads: _.maxBy(rec.arrallocatedTime, 'allocatedTime') ? [_.maxBy(rec.arrallocatedTime, 'allocatedTime').documentID] : [],
                                totalmaxallocatedTime: _.maxBy(rec.arrallocatedTime, 'allocatedTime') ? _.maxBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0,
                                maxallocatedTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrallocatedTime, 'allocatedTime') ? _.maxBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0), client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                                //min allocated time
                                arrminallocatedTime: rec.arrallocatedTime ? rec.arrallocatedTime : [],
                                arrminallocatedLeads: _.minBy(rec.arrallocatedTime, 'allocatedTime') ? [_.minBy(rec.arrallocatedTime, 'allocatedTime').documentID] : [],
                                totalminallocatedTime: _.minBy(rec.arrallocatedTime, 'allocatedTime') ? _.minBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0,
                                minallocatedTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrallocatedTime, 'allocatedTime') ? _.minBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0), client, (dynReport.reportType === 'table' ? '' : 'seconds')),

                                //avg contacted time
                                arrcontactedLeads: rec.arrcontactedTime ? _.uniq(rec.arrcontactedTime.map(r => { return r.documentID })) : [],
                                totalcontactedTime: _.sumBy(rec.arrcontactedTime, 'contactedTime') > 0 ? _.meanBy(rec.arrcontactedTime, "contactedTime") : 0,
                                contactedTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrcontactedTime, 'contactedTime') > 0 ? _.meanBy(rec.arrcontactedTime, "contactedTime") : 0, client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                                //max contacted time
                                arrmaxcontactedTime: rec.arrcontactedTime ? rec.arrcontactedTime : [],
                                arrmaxcontactedLeads: _.maxBy(rec.arrcontactedTime, 'contactedTime') ? [_.maxBy(rec.arrcontactedTime, 'contactedTime').documentID] : [],
                                totalmaxcontactedTime: _.maxBy(rec.arrcontactedTime, 'contactedTime') ? _.maxBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0,
                                maxcontactedTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrcontactedTime, 'contactedTime') ? _.maxBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0), client, (dynReport.reportType === 'table' ? '' : 'seconds')),
                                //min contacted time
                                arrmincontactedTime: rec.arrcontactedTime ? rec.arrcontactedTime : [],
                                arrmincontactedLeads: _.minBy(rec.arrcontactedTime, 'contactedTime') ? [_.minBy(rec.arrcontactedTime, 'contactedTime').documentID] : [],
                                totalmincontactedTime: _.minBy(rec.arrcontactedTime, 'contactedTime') ? _.minBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0,
                                mincontactedTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrcontactedTime, 'contactedTime') ? _.minBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0), client, (dynReport.reportType === 'table' ? '' : 'seconds')),

                                open: rec.arropen ? rec.arropen.length : 0,
                                statusModified: rec.arrstatusModified ? rec.arrstatusModified.length : 0,
                                won: rec.arrwon ? _.sumBy(rec.arrwon, "soldCount") : 0,
                                lost: rec.arrlost ? rec.arrlost.length : 0,
                                totalLost: rec.arrtotalLost ? rec.arrtotalLost.length : 0,
                                prospectLost: rec.arrprospectLost ? rec.arrprospectLost.length : 0,
                                leadLost: rec.arrleadLost ? rec.arrleadLost.length : 0,
                                delivered: rec.arrdelivered ? _.sumBy(rec.arrdelivered, "soldCount") : 0,
                                walkin: rec.arrwalkin ? rec.arrwalkin.length : 0,
                                newwalkin: rec.arrnewwalkin ? rec.arrnewwalkin.length : 0,
                                repeatwalkin: rec.arrrepeatwalkin ? rec.arrrepeatwalkin.length : 0,
                                testdrive: rec.arrtestdrive ? rec.arrtestdrive.length : 0,
                                tradein: rec.arrtradein ? rec.arrtradein.length : 0,
                                tradeinwon: rec.arrtradeinwon ? rec.arrtradeinwon.length : 0,
                                tradeininprogress: rec.arrtradeininprogress ? rec.arrtradeininprogress.length : 0,
                                tradeincompleted: rec.arrtradeincompleted ? rec.arrtradeincompleted.length : 0,
                                tradeinlost: rec.arrtradeinlost ? rec.arrtradeinlost.length : 0,
                                inboundCall: rec.arrinboundCall ? rec.arrinboundCall.length : 0,
                                outboundCall: rec.arroutboundCall ? rec.arroutboundCall.length : 0,
                                totalCall: rec.arrtotalCall ? rec.arrtotalCall.length : 0,
                                messageLog: rec.arrmessageLog ? rec.arrmessageLog.length : 0,
                                emailLog: rec.arremailLog ? rec.arremailLog.length : 0,
                                noteLog: rec.arrnoteLog ? rec.arrnoteLog.length : 0,
                                appointmentScheduled: rec.arrappointmentScheduled ? rec.arrappointmentScheduled.length : 0,
                                testdriveScheduled: rec.arrtestdriveScheduled ? rec.arrtestdriveScheduled.length : 0,
                                callScheduled: rec.arrcallScheduled ? rec.arrcallScheduled.length : 0,
                                mailScheduled: rec.arrmailScheduled ? rec.arrmailScheduled.length : 0,
                                deliveryScheduled: rec.arrdeliveryScheduled ? rec.arrdeliveryScheduled.length : 0,
                                appointment: rec.arrappointment ? rec.arrappointment.length : 0,
                                testdriveActivity: rec.arrtestdriveActivity ? rec.arrtestdriveActivity.length : 0,
                                callActivity: rec.arrcallActivity ? rec.arrcallActivity.length : 0,
                                mailActivity: rec.arrmailActivity ? rec.arrmailActivity.length : 0,
                                deliveryActivity: rec.arrdeliveryActivity ? rec.arrdeliveryActivity.length : 0,
                                total: rec.arrtotal ? rec.arrtotal.length : 0,
                                tdappt: rec.arrtdappt ? rec.arrtdappt.length : 0,
                                tdtestdrive: rec.arrtdtestdrive ? rec.arrtdtestdrive.length : 0,
                                apptsold: rec.arrapptsold ? rec.arrapptsold.length : 0,
                                target: dynReport.target ? dynReport.target : 0,
                                totalnewLeads: rec.arrdataQualitynewLeads ? rec.arrdataQualitynewLeads.length : 0,
                                totalexisitngLeads: rec.arrdataQualityexisitngLeads ? rec.arrdataQualityexisitngLeads.length : 0,
                                totalLeads: rec.arrdataQualitytotal ? rec.arrdataQualitytotal.length : 0,
                                noActivity: rec.arrnoActivity ? rec.arrnoActivity.length : 0,
                                unactionedLeads: rec.arrunactionedLeads ? rec.arrunactionedLeads.length : 0,
                                noNotes: rec.arrnoNotes ? rec.arrnoNotes.length : 0,
                                noCalls: rec.arrnoCalls ? rec.arrnoCalls.length : 0,
                                noMessage: rec.arrnoMessage ? rec.arrnoMessage.length : 0,
                                doneActivity: rec.arrdoneActivity ? rec.arrdoneActivity.length : 0,
                                allocated: rec.arrallocated ? rec.arrallocated.length : 0,
                                newCAP: rec.arrnewCAP ? rec.arrnewCAP.length : 0,
                                lostEnq: rec.arrlostEnq ? rec.arrlostEnq.length : 0,
                                outdated: rec.arroutdated ? rec.arroutdated.length : 0,
                                wonTD: rec.arrwonTD ? rec.arrwonTD.length : 0,
                                overdueActivity: rec.arroverdueActivity ? rec.arroverdueActivity.length : 0,
                                quotes: rec.arrquotes ? rec.arrquotes.length : 0,
                                arrquotes: rec.arrquotes ? _.uniq(rec.arrquotes.map(r => { return r.documentID })) : [],
                                arrwonAmount: rec.arrwonAmount ? _.uniq(rec.arrwonAmount.map(r => { return r.documentID })) : [],
                                arrquoteAmount: rec.arrquoteAmount ? _.uniq(rec.arrquoteAmount.map(r => { return r.documentID })) : [],
                                wonAmount: rec.arrwonAmount ? Math.round((_.sumBy(rec.arrwonAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                                quoteAmount: rec.arrquoteAmount ? Math.round((_.sumBy(rec.arrquoteAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                                ...getDynamicColumnLength(rec)
                            })
                            _dynCols.push({
                                name: rec.name,
                                value: `${dynReport.groupBy}-${rec.id}`
                            })
                        }
                    })
                }
            })
        });

        if (dynReport.level && dynReport.level !== 'individual') {
            let _groupBy = _.chain(reports)
                .groupBy(`${dynReport.level}ID`)
                .map((value, key) => ({ [key]: value }))
                .value();
            reports = [];
            (dynReport.level === 'region' ? regions : groups).forEach(doc => {
                _groupBy.forEach(rec => {
                    if (rec[doc.id]) {
                        reports = [
                            ...reports,
                            ..._.chain(rec[doc.id])
                                .groupBy('id')
                                .map((value, key) => (
                                    {
                                        ...getDocumentIDs(value),
                                        id: key,
                                        clientID: value[0].clientID,
                                        name: value[0].name,
                                        displayName: value[0].displayName,
                                        clientName: value[0].clientName,
                                        regionID: value[0].regionID,
                                        groupID: value[0].groupID,
                                        groupName: value[0].groupName,
                                        regionName: value[0].regionName,
                                        ...sumByValues(value, value[0].clientID)
                                    }
                                )).value()]
                    }
                })

            })
        }

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
                scheduledConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.appointmentScheduled) : 0,
                showConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.show) : 0,
                noshowConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
            }
        })

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
            }
        })

        dynReport.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setDymamicColumns(_dynCols)
        setProgressReport(reports)
    }

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return

        var reports = _.chain(progressReport)
            .groupBy(`${(_.isEmpty(dynReport.displayBy) || dynReport.displayBy === 'client') ? ((_.isEmpty(dynReport.level) || dynReport.level === 'individual') ? 'clientID' : `${dynReport.level}ID`) : 'id'}`)
            .map((value, key) => (
                {
                    id: key,
                    clientID: (_.isEmpty(dynReport.level) || dynReport.level === 'individual') ? value[0].clientID : key,
                    name: `${(_.isEmpty(dynReport.displayBy) || dynReport.displayBy === 'client') ? ((_.isEmpty(dynReport.level) || dynReport.level === 'individual') ? value[0].clientName : value[0][`${dynReport.level}Name`]) : value[0].name}`,
                    displayName: `${(_.isEmpty(dynReport.displayBy) || dynReport.displayBy === 'client') ? ((_.isEmpty(dynReport.level) || dynReport.level === 'individual') ? value[0].clientName : value[0][`${dynReport.level}Name`]) : value[0].displayName}`,
                    clientName: (_.isEmpty(dynReport.level) || dynReport.level === 'individual') ? value[0].clientName : value[0][`${dynReport.level}Name`],
                    clientLogo: _.find(dealersettings, { id: key }) ? _.find(dealersettings, { id: key }).logoURL : null,
                    ...sumByValues(value, value[0].clientID)
                }
            )).value();
        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
                scheduledConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.appointmentScheduled) : 0,
                showConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.show) : 0,
                noshowConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,

            }
        })

        dynReport.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setClientReport(reports);
    }, [progressReport])

    useEffect(() => {
        if (_.isEmpty(clientReport))
            return

        const _score = _.sumBy(clientReport, '4plus') > 0 ? Math.round((_.sumBy(clientReport, '15plus') / _.sumBy(clientReport, '4plus')) * 100) : 0;
        var chart = am4core.create(`progressive-${dynReport.documentID}`, am4charts.PieChart);
        chart.logo.disabled = true;
        chart.data = [{
            "category": "",
            "value": _score,
            "color": "#4466f2"

        }, {
            "category": "",
            "value": 100 - _score,
            "color": '#d3d9e6'
        }];

        chart.innerRadius = am4core.percent(90)
        //chart.radius = am4core.percent(90)

        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.tooltip.disabled = true;
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        var label = pieSeries.createChild(am4core.Label)
        label.text = `${_score}%`;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = "15px";
        //chart.validateNow();

        pieSeries.slices.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && target.dataItem.dataContext.color)
                return am4core.color(target.dataItem.dataContext.color);
            return color
        });

        window.persistencyChart = chart;
    }, [clientReport])

    const getDynamicColumnLength = (data) => {
        let leadSourceData = {};

        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: data[`arrenqtype-${rec.value}`] ? data[`arrenqtype-${rec.value}`].length : 0,
                        [`enqtypesold-${rec.value}`]: data[`arrenqtypesold-${rec.value}`] ? data[`arrenqtypesold-${rec.value}`].length : 0
                    }
                })

        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: data[`arrorigin-${rec.value}`] ? data[`arrorigin-${rec.value}`].length : 0,
                        [`originsold-${rec.value}`]: data[`arroriginsold-${rec.value}`] ? data[`arroriginsold-${rec.value}`].length : 0
                    }
                })

        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.leadSource &&
            dealersettings[0].settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: data[`arrleadSource-${rec.value}`] ? data[`arrleadSource-${rec.value}`].length : 0
                    }
                })

        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: data[`arrlostReason-${rec.value}`] ? data[`arrlostReason-${rec.value}`].length : 0
                    }
                })

        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.activityOutcome &&
            dealersettings[0].settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: data[`arroutcome-${rec.value}`] ? data[`arroutcome-${rec.value}`].length : 0
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.callStatus &&
            dealersettings[0].settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: data[`arrcallstatus-${rec.value}`] ? data[`arrcallstatus-${rec.value}`].length : 0
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryStatus &&
            dealersettings[0].settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = data[`arrenqstatus-${rec.value}`] ? _.sumBy(data[`arrenqstatus-${rec.value}`], "soldCount") : 0;
                    let _totalCount = data[`arrtotal`] ? data[`arrtotal`].length : 0;
                    let _convertedCount = data[`arrconvertedLeads`] ? data[`arrconvertedLeads`].length : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: (isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0,
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings?.financeStatus &&
            dealersettings[0].settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = data[`arrfinancestatus-${rec.value}`] ? _.sumBy(data[`arrfinancestatus-${rec.value}`], "soldCount") : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrfinancestatus-${rec.value}`]: data[`arrfinancestatus-${rec.value}`] ? _.uniq(data[`arrfinancestatus-${rec.value}`].map(r => { return r.documentID })) : [],
                        [`financestatus-${rec.value}`]: _stsCount,
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings?.enquiryOptionsDF &&
            dealersettings[0].settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: data[`arr${item.type};${item.value};${rec}`] ? data[`arr${item.type};${item.value};${rec}`].length : 0
                        }
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            let _stsCount = data[`arr${rec}`] ? _.sumBy(data[`arr${rec}`], "soldCount") : 0;
            leadSourceData = {
                ...leadSourceData,
                [rec]: _stsCount,
            }
            if (rec === '4' || rec === '15') {
                let __stsCount = data[`arr${rec}plus`] ? _.sumBy(data[`arr${rec}plus`], "soldCount") : 0;
                leadSourceData = {
                    ...leadSourceData,
                    [`${rec}plus`]: __stsCount,
                }
            }
        })
        if (dynReport?.dynColumns?.length > 0) {
            dynReport.dynColumns.forEach(rec => {
                if (rec.action === 'percentage') {
                    let ptg = _.sumBy(rec.columns.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }));
                    let total = _.sumBy(rec.columns1.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }));
                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: ptgValue > 100 ? 100 : ptgValue
                    }
                }
                else {
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: _.sumBy(rec.columns.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }))
                    }
                }
            })
        }
        return leadSourceData;
    }

    const getDynamicTotalLength = (data) => {
        let leadSourceData = {};
        dealersettings[0] &&
            dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: _.some(data, `arrenqtype-${rec.value}`) ? _.sum(data.map(r => r[`arrenqtype-${rec.value}`].length)) : 0,
                        [`enqtypesold-${rec.value}`]: _.some(data, `arrenqtypesold-${rec.value}`) ? _.sum(data.map(r => r[`arrenqtypesold-${rec.value}`].length)) : 0
                    }
                })
        dealersettings[0] &&
            dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: _.some(data, `arrorigin-${rec.value}`) ? _.sum(data.map(r => r[`arrorigin-${rec.value}`].length)) : 0,
                        [`originsold-${rec.value}`]: _.some(data, `arroriginsold-${rec.value}`) ? _.sum(data.map(r => r[`arroriginsold-${rec.value}`].length)) : 0
                    }
                })
        dealersettings[0] &&
            dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.leadSource &&
            dealersettings[0].settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: _.some(data, `arrleadSource-${rec.value}`) ? _.sum(data.map(r => r[`arrleadSource-${rec.value}`].length)) : 0
                    }
                })
        dealersettings[0] &&
            dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: _.some(data, `arrlostReason-${rec.value}`) ? _.sum(data.map(r => r[`arrlostReason-${rec.value}`].length)) : 0
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.activityOutcome &&
            dealersettings[0].settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: _.some(data, `arroutcome-${rec.value}`) ? _.sum(data.map(r => r[`arroutcome-${rec.value}`].length)) : 0
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.callStatus &&
            dealersettings[0].settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: _.some(data, `arrcallstatus-${rec.value}`) ? _.sum(data.map(r => r[`arrcallstatus-${rec.value}`].length)) : 0
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryStatus &&
            dealersettings[0].settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = _.some(data, `enqstatus-${rec.value}`) ? _.sum(data.map(r => r[`enqstatus-${rec.value}`])) : 0;
                    let _totalCount = _.some(data, `arrtotal`) ? _.sum(data.map(r => r[`arrtotal`].length)) : 0;
                    let _convertedCount = _.some(data, `arrconvertedLeads`) ? _.sum(data.map(r => r[`arrconvertedLeads`].length)) : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: (isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0,
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings?.financeStatus &&
            dealersettings[0].settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`financestatus-${rec.value}`]: _.some(data, `arrfinancestatus-${rec.value}`) ? _.sum(data.map(r => r[`arrfinancestatus-${rec.value}`].length)) : 0,
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings?.enquiryOptionsDF &&
            dealersettings[0].settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: _.some(data, `arr${item.type};${item.value};${rec}`) ? _.sum(data.map(r => r[`arr${item.type};${item.value};${rec}`].length)) : 0,
                        }
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            let _stsCount = _.some(data, `${rec}`) ? _.sum(data.map(r => r[`${rec}`])) : 0;
            leadSourceData = {
                ...leadSourceData,
                [rec]: _stsCount,
            }
            if (rec === '4' || rec === '15') {
                let __stsCount = _.some(data, `${rec}plus`) ? _.sum(data.map(r => r[`${rec}plus`])) : 0;
                leadSourceData = {
                    ...leadSourceData,
                    [`${rec}plus`]: __stsCount,
                }
            }
        })
        if (dynReport?.dynColumns?.length > 0) {
            dynReport.dynColumns.forEach(rec => {
                if (rec.action === 'percentage') {
                    let ptg = _.sumBy(rec.columns.map(r => { return _.sum(data.map(d => d[`${r}`])) }));
                    let total = _.sumBy(rec.columns1.map(r => { return _.sum(data.map(d => d[`${r}`])) }));
                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: ptgValue > 100 ? 100 : ptgValue
                    }
                }
                else {
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: _.some(data, `${rec.id}`) ? _.sum(data.map(r => r[`${rec.id}`])) : 0
                    }
                }
            })
        }
        if (data) {
            let cols = _.isArray(dynReport.columns) ? dynReport.columns : [dynReport.columns];
            data.forEach(rec => {
                let arr = [];
                cols.forEach(col => {
                    if (rec[`arr${col}`])
                        arr = [...arr, ...rec[`arr${col}`]]
                })
                leadSourceData = {
                    ...leadSourceData,
                    [`${dynReport.groupBy}-${rec.id}`]: arr.length
                }
            })

        }
        return leadSourceData;
    }

    const getLeadsByEnquiryTypeTotal = (_progressReport) => {
        let leadSourceData = {};

        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: _.sumBy(_progressReport, `enqtype-${rec.value}`),
                        [`enqtypesold-${rec.value}`]: _.sumBy(_progressReport, `enqtypesold-${rec.value}`)
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: _.sumBy(_progressReport, `origin-${rec.value}`),
                        [`originsold-${rec.value}`]: _.sumBy(_progressReport, `originsold-${rec.value}`)
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.leadSource &&
            dealersettings[0].settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: _.sumBy(_progressReport, `leadSource-${rec.value}`)
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: _.sumBy(_progressReport, `lostReason-${rec.value}`)
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.activityOutcome &&
            dealersettings[0].settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: _.sumBy(_progressReport, `outcome-${rec.value}`)
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.callStatus &&
            dealersettings[0].settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: _.sumBy(_progressReport, `callstatus-${rec.value}`)
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings.enquiryStatus &&
            dealersettings[0].settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = _.some(_progressReport, `arrenqstatus-${rec.value}`) ? _.sumBy(_progressReport, `arrenqstatus-${rec.value}`) : 0;
                    let _totalCount = _.some(_progressReport, `arrtotal`) ? _.sumBy(_progressReport, `arrtotal`) : 0;
                    let _convertedCount = _.some(_progressReport, `arrconvertedLeads`) ? _.sumBy(_progressReport, `arrconvertedLeads`) : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: (isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0,
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings &&
            dealersettings[0].settings?.financeStatus &&
            dealersettings[0].settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = _.sumBy(_progressReport, `financestatus-${rec.value}`);
                    leadSourceData = {
                        ...leadSourceData,
                        [`financestatus-${rec.value}`]: _stsCount,
                    }
                })
        dealersettings[0] &&
            dealersettings[0].settings?.enquiryOptionsDF &&
            dealersettings[0].settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        let _stsCount = _.sumBy(_progressReport, `${item.type};${item.value};${rec}`);
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: _stsCount
                        }
                    })
                })
        return leadSourceData;
    }

    const getDynamicCOls = (data) => {
        let cols = [];
        data.forEach(rec => {
            let vals = Object.keys(rec).filter(r => r.includes(`${dynReport.groupBy}-`))
            cols = [...cols, ...vals];
        })
        return _.uniq([...cols.filter(a => a !== `${dynReport.groupBy}-others`), `${dynReport.groupBy}-others`]);
    }

    const getDocumentIDs = (data) => {
        let _result = {};
        Object.keys(data[0]).filter(r => r.startsWith('arr')).forEach(val => {
            _result = {
                ..._result,
                [val]: data.map(r => { return _.join(r[val]) })
                    .join(',')
                    .split(',')
                    .filter(r => !_.isEmpty(r))
            }
        })
        return _result;
    }

    const sumByValues = (value, clientID) => {
        return {
            newLeads: _.some(value, 'arrnewLeads') ? _.sum(value.map(r => r.arrnewLeads.length)) : 0,
            exisitngLeads: _.some(value, 'arrexisitngLeads') ? _.sum(value.map(r => r.arrexisitngLeads.length)) : 0,
            pendingLeads: _.some(value, 'arrpendingLeads') ? _.sum(value.map(r => r.arrpendingLeads.length)) : 0,
            totalInbound: _.some(value, 'arrtotalInbound') ? _.sum(value.map(r => r.arrtotalInbound.length)) : 0,
            overallPending: _.some(value, 'arroverallPending') ? _.sum(value.map(r => r.arroverallPending.length)) : 0,
            convertedLeads: _.some(value, 'arrconvertedLeads') ? _.sum(value.map(r => r.arrconvertedLeads.length)) : 0,
            show: _.some(value, 'arrshow') ? _.sum(value.map(r => r.arrshow.length)) : 0,
            showCount: _.some(value, 'arrshow') ? _.sum(value.map(r => r.arrshow.length)) : 0,
            noshow: _.some(value, 'arrnoshow') ? _.sum(value.map(r => r.arrnoshow.length)) : 0,
            totalresponseTime: _.some(value, 'totalresponseTime') > 0 ? _.meanBy(value, 'totalresponseTime') : 0,
            totalmaxresponseTime: _.some(value, 'totalmaxresponseTime') > 0 ? _.meanBy(value, 'totalmaxresponseTime') : 0,
            totalminresponseTime: _.some(value, 'totalminresponseTime') > 0 ? _.meanBy(value, 'totalminresponseTime') : 0,
            responseTime: (_.some(value, 'totalresponseTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalresponseTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),
            maxresponseTime: (_.some(value, 'totalmaxresponseTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalmaxresponseTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),
            minresponseTime: (_.some(value, 'totalminresponseTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalminresponseTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),

            totalallocatedTime: _.some(value, 'totalallocatedTime') > 0 ? _.meanBy(value, 'totalallocatedTime') : 0,
            totalmaxallocatedTime: _.some(value, 'totalmaxallocatedTime') > 0 ? _.meanBy(value, 'totalmaxallocatedTime') : 0,
            totalminallocatedTime: _.some(value, 'totalminallocatedTime') > 0 ? _.meanBy(value, 'totalminallocatedTime') : 0,
            allocatedTime: (_.some(value, 'totalallocatedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalallocatedTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),
            maxallocatedTime: (_.some(value, 'totalmaxallocatedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalmaxallocatedTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),
            minallocatedTime: (_.some(value, 'totalminallocatedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalminallocatedTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),

            totalcontactedTime: _.some(value, 'totalcontactedTime') > 0 ? _.meanBy(value, 'totalcontactedTime') : 0,
            totalmaxcontactedTime: _.some(value, 'totalmaxcontactedTime') > 0 ? _.meanBy(value, 'totalmaxcontactedTime') : 0,
            totalmincontactedTime: _.some(value, 'totalmincontactedTime') > 0 ? _.meanBy(value, 'totalmincontactedTime') : 0,
            contactedTime: (_.some(value, 'totalcontactedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalcontactedTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),
            maxcontactedTime: (_.some(value, 'totalmaxcontactedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalmaxcontactedTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),
            mincontactedTime: (_.some(value, 'totalmincontactedTime') > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(value, (p) => p.totalmincontactedTime), _.find(dealersettings, { id: clientID })) : '00:00:00'),


            open: _.some(value, 'arropen') ? _.sum(value.map(r => r.arropen.length)) : 0,
            statusModified: _.some(value, 'arrstatusModified') ? _.sum(value.map(r => r.arrstatusModified.length)) : 0,
            won: _.some(value, 'arrwon') ? _.sum(value.map(r => r.won)) : 0,
            lost: _.some(value, 'arrlost') ? _.sum(value.map(r => r.arrlost.length)) : 0,
            totalLost: _.some(value, 'arrtotalLost') ? _.sum(value.map(r => r.arrtotalLost.length)) : 0,
            prospectLost: _.some(value, 'arrprospectLost') ? _.sum(value.map(r => r.arrprospectLost.length)) : 0,
            leadLost: _.some(value, 'arrleadLost') ? _.sum(value.map(r => r.arrleadLost.length)) : 0,
            delivered: _.some(value, 'arrdelivered') ? _.sum(value.map(r => r.delivered)) : 0,
            walkin: _.some(value, 'arrwalkin') ? _.sum(value.map(r => r.arrwalkin.length)) : 0,
            newwalkin: _.some(value, 'arrnewwalkin') ? _.sum(value.map(r => r.arrnewwalkin.length)) : 0,
            repeatwalkin: _.some(value, 'arrrepeatwalkin') ? _.sum(value.map(r => r.arrrepeatwalkin.length)) : 0,
            testdrive: _.some(value, 'arrtestdrive') ? _.sum(value.map(r => r.arrtestdrive.length)) : 0,
            tradein: _.some(value, 'arrtradein') ? _.sum(value.map(r => r.arrtradein.length)) : 0,
            tradeinwon: _.some(value, 'arrtradeinwon') ? _.sum(value.map(r => r.arrtradeinwon.length)) : 0,
            tradeininprogress: _.some(value, 'arrtradeininprogress') ? _.sum(value.map(r => r.arrtradeininprogress.length)) : 0,
            tradeincompleted: _.some(value, 'arrtradeincompleted') ? _.sum(value.map(r => r.arrtradeincompleted.length)) : 0,
            tradeinlost: _.some(value, 'arrtradeinlost') ? _.sum(value.map(r => r.arrtradeinlost.length)) : 0,
            inboundCall: _.some(value, 'arrinboundCall') ? _.sum(value.map(r => r.arrinboundCall.length)) : 0,
            outboundCall: _.some(value, 'arroutboundCall') ? _.sum(value.map(r => r.arroutboundCall.length)) : 0,
            totalCall: _.some(value, 'arrtotalCall') ? _.sum(value.map(r => r.arrtotalCall.length)) : 0,
            messageLog: _.some(value, 'arrmessageLog') ? _.sum(value.map(r => r.arrmessageLog.length)) : 0,
            emailLog: _.some(value, 'arremailLog') ? _.sum(value.map(r => r.arremailLog.length)) : 0,
            noteLog: _.some(value, 'arrnoteLog') ? _.sum(value.map(r => r.arrnoteLog.length)) : 0,
            appointmentScheduled: _.some(value, 'arrappointmentScheduled') ? _.sum(value.map(r => r.arrappointmentScheduled.length)) : 0,
            testdriveScheduled: _.some(value, 'arrtestdriveScheduled') ? _.sum(value.map(r => r.arrtestdriveScheduled.length)) : 0,
            callScheduled: _.some(value, 'arrcallScheduled') ? _.sum(value.map(r => r.arrcallScheduled.length)) : 0,
            mailScheduled: _.some(value, 'arrmailScheduled') ? _.sum(value.map(r => r.arrmailScheduled.length)) : 0,
            deliveryScheduled: _.some(value, 'arrdeliveryScheduled') ? _.sum(value.map(r => r.arrdeliveryScheduled.length)) : 0,
            appointment: _.some(value, 'arrappointment') ? _.sum(value.map(r => r.arrappointment.length)) : 0,
            testdriveActivity: _.some(value, 'arrtestdriveActivity') ? _.sum(value.map(r => r.arrtestdriveActivity.length)) : 0,
            callActivity: _.some(value, 'arrcallActivity') ? _.sum(value.map(r => r.arrcallActivity.length)) : 0,
            mailActivity: _.some(value, 'arrmailActivity') ? _.sum(value.map(r => r.arrmailActivity.length)) : 0,
            deliveryActivity: _.some(value, 'arrdeliveryActivity') ? _.sum(value.map(r => r.arrdeliveryActivity.length)) : 0,
            total: _.some(value, 'arrtotal') ? _.sum(value.map(r => r.arrtotal.length)) : 0,
            tdappt: _.some(value, 'arrtdappt') ? _.sum(value.map(r => r.arrtdappt.length)) : 0,
            tdtestdrive: _.some(value, 'arrtdtestdrive') ? _.sum(value.map(r => r.arrtdtestdrive.length)) : 0,
            apptsold: _.some(value, 'arrapptsold') ? _.sum(value.map(r => r.arrapptsold.length)) : 0,
            target: dynReport.target ? dynReport.target : 0,
            totalnewLeads: _.some(value, 'arrdataQualitynewLeads') ? _.sum(value.map(r => r.arrdataQualitynewLeads.length)) : 0,
            totalexisitngLeads: _.some(value, 'arrdataQualityexisitngLeads') ? _.sum(value.map(r => r.arrdataQualityexisitngLeads.length)) : 0,
            totalLeads: _.some(value, 'arrdataQualitytotal') ? _.sum(value.map(r => r.arrdataQualitytotal.length)) : 0,
            carryover: _.some(value, 'arrcarryover') ? _.sum(value.map(r => r.arrcarryover.length)) : 0,
            noActivity: _.some(value, 'arrnoActivity') ? _.sum(value.map(r => r.arrnoActivity.length)) : 0,
            unactionedLeads: _.some(value, 'arrunactionedLeads') ? _.sum(value.map(r => r.arrunactionedLeads.length)) : 0,
            noNotes: _.some(value, 'arrnoNotes') ? _.sum(value.map(r => r.arrnoNotes.length)) : 0,
            noCalls: _.some(value, 'arrnoCalls') ? _.sum(value.map(r => r.arrnoCalls.length)) : 0,
            noMessage: _.some(value, 'arrnoMessage') ? _.sum(value.map(r => r.arrnoMessage.length)) : 0,
            doneActivity: _.some(value, 'arrdoneActivity') ? _.sum(value.map(r => r.arrdoneActivity.length)) : 0,
            overdueActivity: _.some(value, 'arroverdueActivity') ? _.sum(value.map(r => r.arroverdueActivity.length)) : 0,
            wonTD: _.some(value, 'arrwonTD') ? _.sum(value.map(r => r.arrwonTD.length)) : 0,
            allocated: _.some(value, 'arrallocated') ? _.sum(value.map(r => r.arrallocated.length)) : 0,
            newCAP: _.some(value, 'arrnewCAP') ? _.sum(value.map(r => r.arrnewCAP.length)) : 0,
            lostEnq: _.some(value, 'arrlostEnq') ? _.sum(value.map(r => r.arrlostEnq.length)) : 0,
            outdated: _.some(value, 'arroutdated') ? _.sum(value.map(r => r.arroutdated.length)) : 0,
            quotes: _.some(value, 'arrquotes') ? _.sum(value.map(r => r.arrquotes.length)) : 0,
            wonAmount: _.some(value, 'arrwonAmount') ? Math.round((_.sum(value.map(r => r.wonAmount)) + Number.EPSILON) * 100) / 100 : 0,
            quoteAmount: _.some(value, 'arrquoteAmount') ? Math.round((_.sum(value.map(r => r.quoteAmount)) + Number.EPSILON) * 100) / 100 : 0,
            dealerData: value,
            ...getDynamicTotalLength(value)
        }
    }


    return (_.isEmpty(dealersettings) || loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        oemSettings && oemSettings.logoURL
                                            ?
                                            <div className="logo-union"> <img src={oemSettings.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{oemSettings.name}</div>
                                            </>
                                    }
                                </div></td>
                                <td width="40%"><div className="header-right"> {dynReport.name} <br></br>
                                    {
                                        _date === 'wtd' || _date === 'lwd' || _date === 'pwd' || _date === 'lmd' || _startdate || _enddate || dynReport.dataParam === 'upcoming' ? (
                                            <span> {`${moment.unix(dateRange.startDate.seconds).format('DD MMM')}-${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`}</span>
                                        ) : !_.isEmpty(dealersettings.timezone) ? (
                                            <span> {moment().tz(dealersettings.timezone).format('DD MMMM YYYY')}</span>
                                        ) : (
                                            <span> {moment().format('DD MMMM YYYY')}</span>
                                        )
                                    }
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : clientReport && clientReport.length === 0 ? (
                        <div className="table-wraper">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> No records found</div>
                            </div>
                        </div>
                    ) : dynReport.reportType === 'table' ? (<div className="table-wraper table-page-break">
                        {
                            dynReport.columnType === 'persistency' ? (
                                <div className="table-progressive-wrapper mb-2">
                                    <div className="table-progressive-graph">
                                        <div className="table-progressive-graph-image">
                                            <div id={`progressive-${dynReport.documentID}`} style={{ width: "60px", height: '55px', marginLeft: '5px', marginTop: '2px' }}></div>
                                        </div>
                                        <div className="table-progressive-graph-title">Progressive Sales <br /> ({_.sumBy(clientReport, '15plus')}/{_.sumBy(clientReport, '4plus')}) x 100</div>

                                    </div>
                                    <div className="table-progressive-table">

                                        <div className="common-table">
                                            <table className="table table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="head-light" width="25%">Sales at first contact</th>
                                                        <th scope="col" className="head-light" width="25%">Sales within 4 contacts</th>
                                                        <th scope="col" className="head-light" width="25%">Sales after 5 contacts</th>
                                                        <th scope="col" className="head-light table-sub-total" width="25%">Total</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{_.sumBy(clientReport, '0')}</td>
                                                        <td>{_.sumBy(clientReport, '4plus')}</td>
                                                        <td>{_.sumBy(clientReport, '15plus')}</td>
                                                        <td className='table-sub-total'>{_.sumBy(clientReport, 'won')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        <table className="table-loop">
                            <thead>
                                <tr>
                                    <th className="cell-head" width="10%">
                                        {(_.isEmpty(dynReport.level) || dynReport.level === 'individual') ? 'Dealer' : `${CommonHelper.autoCaps(dynReport.level)}`}
                                    </th>
                                    {
                                        dynReport.columns.map((rec, index) => {
                                            return <Fragment key={index}><th key={index} className={`head-light text-center ${reportColumns.find(item => item.value === rec) && reportColumns.find(item => item.value === rec).cls ? reportColumns.find(item => item.value === rec).cls : ''}`} width={`${(90 / (dynReport.columns.length + (dynReport.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                {
                                                    reportColumns.find(item => item.value === rec) ?
                                                        (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                        (<span className="pipeline-inactive-span">(In Active)</span>)
                                                }
                                                {
                                                    rec.includes('enqtype-') || rec.includes('enqtypesold-') ? (
                                                        <span className="th-subhead">(Enquiry Type)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('origin-') || rec.includes('originsold-') ? (
                                                        <span className="th-subhead">(Origin)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('leadSource-') ? (
                                                        <span className="th-subhead">(Lead Source)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('lostReason-') ? (
                                                        <span className="th-subhead">(Lost Reason)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('outcome-') ? (
                                                        <span className="th-subhead">(Activity Outcome)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('callstatus-') ? (
                                                        <span className="th-subhead">(Call Status)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('enqstatus-') ? (
                                                        <span className="th-subhead">(Enquiry Status)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('financestatus-') ? (
                                                        <span className="th-subhead">(Finance Status)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('select;') ? (
                                                        <span className="th-subhead">({reportColumns.find(item => item.value === rec)?.name || 'Dynamic Field'})</span>
                                                    ) : (<></>)
                                                }
                                            </th>
                                                {
                                                    dynReport?.dynColumns?.filter(a => a.moveAfter === rec)?.length ? dynReport.dynColumns.filter(a => a.moveAfter === rec).map((dynCol) => {
                                                        return <th key={dynCol.id} className={`head-light text-center`} width={`${(90 / (dynReport.columns.length + (dynReport.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                            {dynCol.name}
                                                        </th>
                                                    }) : (<></>)
                                                }
                                            </Fragment>
                                        })
                                    }
                                    {
                                        dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((rec, index) => {
                                            return <th key={index} className={`head-light text-center`} width={`${(90 / (dynReport.columns.length + (dynReport.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                {rec.name}
                                            </th>
                                        }) : (<></>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    clientReport.length > 0 ?
                                        clientReport.map((rec, index) => {
                                            return <tr key={index}>
                                                <td>
                                                    <div className="dealership-tbl text-left">
                                                        {rec.clientName}
                                                    </div>

                                                </td>
                                                {
                                                    dynReport.columns.map((val, index) => {
                                                        return <Fragment key={index}><td key={index} className={`count-large ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}>
                                                            {
                                                                reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).currency ? (
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={rec[val]}
                                                                    />
                                                                )
                                                                    : `${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                            }
                                                        </td>
                                                            {
                                                                dynReport?.dynColumns?.filter(a => a.moveAfter === val)?.length ? dynReport.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                                    return <td key={dynCol.id} className={`count-large `}>
                                                                        {rec[dynCol.id]}{dynCol.action === 'percentage' ? '%' : ''}
                                                                    </td>
                                                                }) : (<></>)
                                                            }
                                                        </Fragment>
                                                    })
                                                }
                                                {
                                                    dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                        return <td key={index} className={`count-large `}>
                                                            {rec[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                        </td>
                                                    }) : (<></>)
                                                }
                                            </tr>
                                        }) : (
                                            <tr>
                                                <td colSpan={dynReport.columns.length + 1}>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                            <div className="no-data-txt mt-2"> No data</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                }
                            </tbody>
                            {
                                clientReport.length > 0 ?
                                    (<tfoot>
                                        <tr>
                                            <td className="td-head table-sub-total">Totals</td>
                                            {
                                                dynReport.columns.map((val, index) => {
                                                    return <Fragment key={index}><td key={index} className="count-large text-center table-sub-total">
                                                        {
                                                            (val === 'walkintestdrive' ? (_.sumBy(clientReport, "walkin") > 0 ? Math.round((100 / _.sumBy(clientReport, "walkin")) * _.sumBy(clientReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, "walkin")) * _.sumBy(clientReport, "testdrive")) : 0) :
                                                                val === 'walkinsold' ? (_.sumBy(clientReport, "walkin") > 0 ? Math.round((100 / _.sumBy(clientReport, "walkin")) * _.sumBy(clientReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, "walkin")) * _.sumBy(clientReport, "won")) : 0) :
                                                                    val === 'appointmenttestdrive' ? (_.sumBy(clientReport, "appointment") > 0 ? Math.round((100 / _.sumBy(clientReport, "appointment")) * _.sumBy(clientReport, "tdappt")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, "appointment")) * _.sumBy(clientReport, "tdappt")) : 0) :
                                                                        val === 'testdrivetotestdrive' ? (_.sumBy(clientReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(clientReport, "testdriveActivity")) * _.sumBy(clientReport, "tdtestdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, "testdriveActivity")) * _.sumBy(clientReport, "tdtestdrive")) : 0) :
                                                                            val === 'appointmentsold' ? (_.sumBy(clientReport, "appointment") > 0 ? Math.round((100 / _.sumBy(clientReport, "appointment")) * _.sumBy(clientReport, "apptsold")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, "appointment")) * _.sumBy(clientReport, "apptsold")) : 0) :
                                                                                val === 'testdriveConv' ? (_.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "testdrive")) : 0) :
                                                                                    val === 'tradeinwonConv' ? (_.sumBy(clientReport, "tradein") > 0 ? Math.round((100 / _.sumBy(clientReport, "tradein")) * _.sumBy(clientReport, "tradeinwon")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, "tradein")) * _.sumBy(clientReport, "tradeinwon")) : 0) :
                                                                                        val === 'wonConv' ? (_.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(clientReport, "won")) : 0) :
                                                                                            val === 'responseTime' ? (_.sumBy(clientReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalresponseTime), null) : '00:00:00') :
                                                                                                val === 'maxresponseTime' ? (_.sumBy(clientReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalmaxresponseTime), null) : '00:00:00') :
                                                                                                    val === 'minresponseTime' ? (_.sumBy(clientReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalminresponseTime), null) : '00:00:00') :
                                                                                                        val === 'allocatedTime' ? (_.sumBy(clientReport, `totalallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalallocatedTime), null) : '00:00:00') :
                                                                                                            val === 'maxallocatedTime' ? (_.sumBy(clientReport, `totalmaxallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalmaxallocatedTime), null) : '00:00:00') :
                                                                                                                val === 'minallocatedTime' ? (_.sumBy(clientReport, `totalminallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalminallocatedTime), null) : '00:00:00') :
                                                                                                                    val === 'contactedTime' ? (_.sumBy(clientReport, `totalcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalcontactedTime), null) : '00:00:00') :
                                                                                                                        val === 'maxcontactedTime' ? (_.sumBy(clientReport, `totalmaxcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalmaxcontactedTime), null) : '00:00:00') :
                                                                                                                            val === 'mincontactedTime' ? (_.sumBy(clientReport, `totalmincontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(clientReport, (p) => p.totalmincontactedTime), null) : '00:00:00') :
                                                                                                                                val === 'wonAmount' || val === 'quoteAmount' ? <NumberFormat
                                                                                                                                    thousandSeparator={true}
                                                                                                                                    prefix={currencySymbol}
                                                                                                                                    displayType={'text'}
                                                                                                                                    value={Math.round((_.sumBy(clientReport, val) + Number.EPSILON) * 100) / 100}
                                                                                                                                /> : reportColumns.some(item => item.value === val && val.includes('enqstatus-') && item.percentage === true) ?
                                                                                                                                    reportColumns.filter(item => item.value === val && val.includes('enqstatus-') && item.percentage === true).map(col => {
                                                                                                                                        return col.value === val ?
                                                                                                                                            (isInbound ? _.sumBy(clientReport, `convertedLeads`) : _.sumBy(clientReport, `total`)) > 0 ? Math.round((100 / (isInbound ? _.sumBy(clientReport, `convertedLeads`) : _.sumBy(clientReport, `total`))) * _.sumBy(clientReport, `${val.replace('Conv', '')}`)) : 0
                                                                                                                                            : 0
                                                                                                                                    }) : _.sumBy(clientReport, val))
                                                        }
                                                        {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                                    </td>
                                                        {
                                                            dynReport?.dynColumns?.filter(a => a.moveAfter === val)?.length ? dynReport.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                                let ptg = dynCol.columns ? _.sumBy(dynCol.columns.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                                                let total = dynCol.columns1 ? _.sumBy(dynCol.columns1.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                                                let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                                return <td key={index} className="count-large text-center table-sub-total">
                                                                    {
                                                                        dynCol.action === 'percentage' ? (
                                                                            <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                                        ) : (<>{_.sumBy(clientReport, dynCol.id)}</>)
                                                                    }
                                                                </td>
                                                            }) : (<></>)
                                                        }
                                                    </Fragment>
                                                })
                                            }
                                            {
                                                dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                    let ptg = val.columns ? _.sumBy(val.columns.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                                    let total = val.columns1 ? _.sumBy(val.columns1.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                    return <td key={index} className="count-large text-center table-sub-total">
                                                        {
                                                            val.action === 'percentage' ? (
                                                                <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                            ) : (<>{_.sumBy(clientReport, val.id)}</>)
                                                        }
                                                    </td>
                                                }) : (<></>)
                                            }
                                        </tr>
                                    </tfoot>
                                    ) : (<></>)
                            }
                        </table>
                    </div>
                    ) : dynReport.reportType === 'column' ?
                        (
                            <div className="text-center w-100 float-left">
                                <BarChart
                                    id={'view-report-bar-chart'}
                                    height={`550px`}
                                    data={clientReport}
                                    columns={dynReport.displayBy === 'client' ? getDynamicCOls(clientReport) : dynReport.columns ? _.isArray(dynReport.columns) ? dynReport.columns : [dynReport.columns] : []}
                                    dynamicColumns={dynReport.displayBy === 'client' ? dymamicCols : reportColumns}
                                    xCategory={'displayName'}
                                    calculationType={dynReport.calculationType}
                                    handleShowEnquiryList={(val, rec) => {
                                        console.log(val)
                                    }}
                                />
                            </div>
                        ) : dynReport.reportType === 'pie' ? (<>
                            {
                                clientReport.some(item => item[dynReport.columns] > 0) ? (
                                    <div className="text-center w-100 float-left">
                                        <PieChart
                                            id={'view-report-pie-chart'}
                                            height={`550px`}
                                            data={clientReport}
                                            dynamicReport={true}
                                            columns={_.isArray(dynReport.columns) ? dynReport.columns[0] : dynReport.columns}
                                            handleShowEnquiryList={(val, rec) => {
                                                console.log(val)
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <NoDataPieChart
                                        id={'no-data-pie-chart'}
                                        height={`550px`}
                                    />)
                            }
                        </>) : dynReport.reportType === 'target' ? (
                            <div className="text-center w-100 float-left">
                                <LineChart
                                    id={'view-report-line-chart'}
                                    height={`550px`}
                                    data={clientReport}
                                    dynamicColumns={reportColumns}
                                    xCategory={'displayName'}
                                    columns={dynReport.target > 0 ? (_.isArray(dynReport.columns) ? [...dynReport.columns, 'target'] : [dynReport.columns, 'target']) : (_.isArray(dynReport.columns) ? dynReport.columns : [dynReport.columns])}
                                    calculationType={dynReport.calculationType}
                                    handleShowEnquiryList={(val, rec) => {
                                        console.log(val)
                                    }}
                                />
                            </div>
                        ) : (<></>)
                }
                {
                    clientReport.length > 0 && (dynReport.displayBy === 'client' || _.isEmpty(dynReport.displayBy)) ? (
                        clientReport.map((client, index) => {
                            return <div key={index} className="table-wraper table-page-break">
                                <div className="summary-table-wraper-head">{client.clientName}</div>
                                {/* {
                                    client.logoURL
                                        ?
                                        <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                        :
                                        <>
                                            <div className="dealer">{client.name}</div>
                                        </>
                                } */}
                                <table className="table-loop">
                                    <thead>
                                        <tr>
                                            <th className="cell-head" width="10%">
                                                {CommonHelper.getCustomValue(reportGroupBy, dynReport.groupBy, 'tableHeader', 'value')}
                                            </th>
                                            {
                                                dynReport.columns.map((client, index) => {
                                                    return <Fragment key={index}><th key={index} className={`head-light text-center ${reportColumns.find(item => item.value === client) && reportColumns.find(item => item.value === client).cls ? reportColumns.find(item => item.value === client).cls : ''}`} width={`${(90 / (dynReport.columns.length + (dynReport.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                        {
                                                            reportColumns.find(item => item.value === client) ?
                                                                (<>{CommonHelper.getLabelByValue(reportColumns, client)}</>) :
                                                                (<span className="pipeline-inactive-span">(In Active)</span>)
                                                        }
                                                        {
                                                            client.includes('enqtype-') || client.includes('enqtypesold-') ? (
                                                                <span className="th-subhead">(Enquiry Type)</span>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            client.includes('origin-') || client.includes('originsold-') ? (
                                                                <span className="th-subhead">(Origin)</span>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            client.includes('leadSource-') ? (
                                                                <span className="th-subhead">(Lead Source)</span>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            client.includes('lostReason-') ? (
                                                                <span className="th-subhead">(Lost Reason)</span>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            client.includes('outcome-') ? (
                                                                <span className="th-subhead">(Activity Outcome)</span>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            client.includes('callstatus-') ? (
                                                                <span className="th-subhead">(Call Status)</span>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            client.includes('enqstatus-') ? (
                                                                <span className="th-subhead">(Enquiry Status)</span>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            client.includes('financestatus-') ? (
                                                                <span className="th-subhead">(Finance Status)</span>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            client.includes('select;') ? (
                                                                <span className="th-subhead">({reportColumns.find(item => item.value === client)?.name || 'Dynamic Field'})</span>
                                                            ) : (<></>)
                                                        }
                                                    </th>
                                                        {
                                                            dynReport?.dynColumns?.filter(a => a.moveAfter === client)?.length ? dynReport.dynColumns.filter(a => a.moveAfter === client).map((dynCol) => {
                                                                return <th key={dynCol.id} className={`head-light text-center`} width={`${(90 / (dynReport.columns.length + (dynReport.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                                    {dynCol.name}
                                                                </th>
                                                            }) : (<></>)
                                                        }
                                                    </Fragment>
                                                })
                                            }
                                            {
                                                dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((rec, index) => {
                                                    return <th key={index} className={`head-light text-center`} width={`${(90 / (dynReport.columns.length + (dynReport.dynColumns?.length > 0 ? dynReport.dynColumns.length : 0))).toFixed(2)}%`}>
                                                        {rec.name}
                                                    </th>
                                                }) : (<></>)
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            progressReport.length > 0 ?
                                                progressReport.filter(e => e[(_.isEmpty(dynReport.level) || dynReport.level === 'individual') ? 'clientID' : `${dynReport.level}ID`] === client.id).map((rec, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            {
                                                                dynReport.groupBy === 'user' ? (<div className="table-user-list">
                                                                    <a href="#">
                                                                        <div className="table-user-avatar">
                                                                            <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.displayName)} /></div>
                                                                        {rec.displayName}
                                                                    </a>
                                                                </div>) : (
                                                                    <div className="dealership-tbl text-left">
                                                                        {rec.displayName}
                                                                    </div>
                                                                )
                                                            }

                                                        </td>
                                                        {
                                                            dynReport.columns.map((val, index) => {
                                                                return <Fragment key={index}><td key={index} className={`count-large ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}>
                                                                    {
                                                                        reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).currency ? (
                                                                            <NumberFormat
                                                                                thousandSeparator={true}
                                                                                prefix={currencySymbol}
                                                                                displayType={'text'}
                                                                                value={rec[val]}
                                                                            />
                                                                        )
                                                                            : `${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                                    }
                                                                </td>
                                                                    {
                                                                        dynReport?.dynColumns?.filter(a => a.moveAfter === val)?.length ? dynReport.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                                            return <td key={dynCol.id} className={`count-large`}>
                                                                                {rec[dynCol.id]}{dynCol.action === 'percentage' ? '%' : ''}
                                                                            </td>
                                                                        }) : (<></>)
                                                                    }
                                                                </Fragment>
                                                            })
                                                        }
                                                        {
                                                            dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                                return <td key={index} className={`count-large`}>
                                                                    {rec[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                                </td>
                                                            }) : (<></>)
                                                        }
                                                    </tr>
                                                }) : (
                                                    <tr>
                                                        <td colSpan={dynReport.columns.length + 1}>
                                                            <div className="spinner-loader h-100 p-5">
                                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                    <div className="no-data-txt mt-2"> No data</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                        }
                                    </tbody>
                                    {
                                        progressReport.length > 0 ?
                                            (<tfoot>
                                                <tr>
                                                    <td className="td-head table-sub-total">Totals</td>
                                                    {
                                                        dynReport.columns.map((val, index) => {
                                                            return <Fragment key={index}><td key={index} className="count-large text-center table-sub-total">
                                                                {
                                                                    (val === 'walkintestdrive' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "walkin")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "walkin")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "testdrive")) : 0) :
                                                                        val === 'walkinsold' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "walkin")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "walkin")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "won")) : 0) :
                                                                            val === 'appointmenttestdrive' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "appointment")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "tdappt")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "appointment")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "tdappt")) : 0) :
                                                                                val === 'testdrivetotestdrive' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "testdriveActivity")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "tdtestdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "testdriveActivity")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "tdtestdrive")) : 0) :
                                                                                    val === 'appointmentsold' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "appointment")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "apptsold")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "appointment")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "apptsold")) : 0) :
                                                                                        val === 'testdriveConv' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "testdrive")) : 0) :
                                                                                            val === 'tradeinwonConv' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), "tradein") > 0 ? Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "tradein")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "tradeinwon")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), "tradein")) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "tradeinwon")) : 0) :
                                                                                                val === 'wonConv' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport.filter(e => e.clientID === client.id), `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport.filter(e => e.clientID === client.id), "won")) : 0) :
                                                                                                    val === 'responseTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalresponseTime), null) : '00:00:00') :
                                                                                                        val === 'maxresponseTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalmaxresponseTime), null) : '00:00:00') :
                                                                                                            val === 'minresponseTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalminresponseTime), null) : '00:00:00') :
                                                                                                                val === 'allocatedTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalallocatedTime), null) : '00:00:00') :
                                                                                                                    val === 'maxallocatedTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalmaxallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalmaxallocatedTime), null) : '00:00:00') :
                                                                                                                        val === 'minallocatedTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalminallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalminallocatedTime), null) : '00:00:00') :
                                                                                                                            val === 'contactedTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalcontactedTime), null) : '00:00:00') :
                                                                                                                                val === 'maxcontactedTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalmaxcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalmaxcontactedTime), null) : '00:00:00') :
                                                                                                                                    val === 'mincontactedTime' ? (_.sumBy(progressReport.filter(e => e.clientID === client.id), `totalmincontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport.filter(e => e.clientID === client.id), (p) => p.totalmincontactedTime), null) : '00:00:00') :
                                                                                                                                        val === 'wonAmount' || val === 'quoteAmount' ? <NumberFormat
                                                                                                                                            thousandSeparator={true}
                                                                                                                                            prefix={currencySymbol}
                                                                                                                                            displayType={'text'}
                                                                                                                                            value={Math.round((_.sumBy(clientReport, val) + Number.EPSILON) * 100) / 100}
                                                                                                                                        /> : reportColumns.some(item => item.value === val && val.includes('enqstatus-') && item.percentage === true) ?
                                                                                                                                            reportColumns.filter(item => item.value === val && val.includes('enqstatus-') && item.percentage === true).map(col => {
                                                                                                                                                return col.value === val ?
                                                                                                                                                    (isInbound ? _.sumBy(progressReport.filter(e => e.clientID === client.id), `convertedLeads`) : _.sumBy(progressReport.filter(e => e.clientID === client.id), `total`)) > 0 ? Math.round((100 / (isInbound ? _.sumBy(progressReport.filter(e => e.clientID === client.id), `convertedLeads`) : _.sumBy(progressReport.filter(e => e.clientID === client.id), `total`))) * _.sumBy(progressReport.filter(e => e.clientID === client.id), `${val.replace('Conv', '')}`)) : 0
                                                                                                                                                    : 0
                                                                                                                                            }) : _.sumBy(progressReport.filter(e => e.clientID === client.id), val))
                                                                }
                                                                {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                                            </td>
                                                                {
                                                                    dynReport?.dynColumns?.filter(a => a.moveAfter === val)?.length ? dynReport.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                                        let ptg = dynCol.columns ? _.sumBy(dynCol.columns.map(r => { return _.sumBy(progressReport.filter(e => e.clientID === client.id), `${r}`) })) : 0;
                                                                        let total = dynCol.columns1 ? _.sumBy(dynCol.columns1.map(r => { return _.sumBy(progressReport.filter(e => e.clientID === client.id), `${r}`) })) : 0;
                                                                        let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                                        return <td key={index} className="count-large text-center table-sub-total">
                                                                            {
                                                                                dynCol.action === 'percentage' ? (
                                                                                    <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                                                ) : (<>{_.sumBy(progressReport.filter(e => e.clientID === client.id), dynCol.id)}</>)
                                                                            }

                                                                        </td>
                                                                    }) : (<></>)
                                                                }
                                                            </Fragment>
                                                        })
                                                    }
                                                    {
                                                        dynReport?.dynColumns?.length > 0 ? dynReport.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                            let ptg = val.columns ? _.sumBy(val.columns.map(r => { return _.sumBy(progressReport.filter(e => e.clientID === client.id), `${r}`) })) : 0;
                                                            let total = val.columns1 ? _.sumBy(val.columns1.map(r => { return _.sumBy(progressReport.filter(e => e.clientID === client.id), `${r}`) })) : 0;
                                                            let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                            return <td key={index} className="count-large text-center table-sub-total">
                                                                {
                                                                    val.action === 'percentage' ? (
                                                                        <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                                    ) : (<>{_.sumBy(progressReport.filter(e => e.clientID === client.id), val.id)}</>)
                                                                }

                                                            </td>
                                                        }) : (<></>)
                                                    }
                                                </tr>
                                            </tfoot>
                                            ) : (<></>)
                                    }
                                </table>
                                <div style={{ pageBreakAfter: 'always' }}></div>
                            </div>
                        })

                    ) : (<></>)
                }
                {/* <div style={{ pageBreakBefore: 'avoid' }}></div> */}
                {/* {
                    dealersettings ? (<div className="footer">
                        <div className="footer-cpy">
                            <div className="footer-left">{'Powered by FusionSD.'}</div>
                            <div className="footer-right"></div>
                        </div>
                        <div className="clear"></div>
                    </div>) : (<></>)
                } */}

            </section>
        </div>
    </>)
}

export default OEMDynamicReport;