import React, { useState, useEffect, useContext } from "react";
import { isBoolean, isEmpty } from "lodash";
import { Modal, Tabs, Tab } from "react-bootstrap";
import {
  InputCheckBox,
  InputMultipleText,
  InputText,
  ReactMultiSelect,
  ReactSelect,
  PopUpModal,
} from "../../../../components";
import Translate from "../../../../constants/translate";
import images from "../../../../images";
import _ from "lodash";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import moment from "moment";
import { TemplateContext } from "../templateContext";
import CommonHelper from "../../../../services/common";
import PublishForm from "./publishModal";
import axios from "axios";
import CustomTable from "../../../../components/customTable";
import { autograbDynCols, revolutionDynCols } from "../../moduleSettings/addOns/viewModel";

const saleTypeOptions = [
  { value: "new", label: "New" },
  { value: "preowned", label: "Preowned" },
  { value: "demo", label: "Demo" },
];

// const saleTypeOptionsForSalesLogs = [
// 	{ value: 'ALL', label: 'ALL' },
// 	{ value: 'new', label: 'New' },
// 	{ value: 'preowned', label: 'Preowned' },
// 	{ value: 'demo', label: 'Demo' },
// ];

const Options = ({
  title,
  show,
  handleClose,
  dealersettings,
  setOptions,
  options,
  enableAddons,
  moduleToChange,
  selectedLevel,
  isEdit,
  currentSettings,
  clientUsers,
  drivaTC,
  authenticateSalesForce,
  authenticateMailup,
  integrationKey,
  levelName,
  hideEmailFields
}) => {
  const [addSubList, setAddSubList] = useState({
    saleType: "",
    make: "",
    id: "",
    value: "",
  });
  const [addGenericList, setAddGenericList] = useState({
    enquiryType: "",
    make: "",
    id: "",
    value: "",
    origin: "",
    campaign: "",
    accessKey: "",
    secretKey: "",
    active: false,
    integration: "",
  });
  const [editSubList, setEditSubList] = useState();
  const [textEditor, setTexteditor] = useState({});
  const [subList, setSubList] = useState([]);
  const [genericList, setGenericList] = useState([]);
  const [lookup, setLookup] = useState(null);
  const [state, setState] = useState({});
  const [oldState, setOldState] = useState({});
  const [data, setData] = useState({});
  const [makes, setMakes] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [modules, setModules] = useState({});
  const [phoneCodes, setphoneCodes] = useState([]);
  const [podiumLocations, setPodiumLocations] = useState([]);
  const [previousPodiumLocation, setPreviousPodiumLocation] = useState([]);
  const [addleadSourceList, setAddleadSourceList] = useState({
    saleType: "",
    make: "",
    id: "",
    value: "",
  });
  const [editleadSourceList, setEditleadSourceList] = useState();
  const [leadSourcetextEditor, setleadSourceTexteditor] = useState({});
  const [leadSourceKWList, setLeadSourceKWList] = useState([]);

  const [addcampaignList, setAddcampaignList] = useState({
    saleType: "",
    make: "",
    id: "",
    value: "",
  });
  const [editcampaignList, setEditcampaignList] = useState();
  const [addenquirytypeList, setAddenquirytypeList] = useState({
    saleType: "",
    make: "",
    id: "",
    value: "",
  });
  const [editenquirytypeList, setEditenquirytypeList] = useState();
  const [enquirytypetextEditor, setenquirytypeTexteditor] = useState({});
  const [enquirytypeKWList, setenquirytypeKWList] = useState([]);
  const [campaigntextEditor, setcampaignTexteditor] = useState({});
  const [campaignKWList, setcampaignKWList] = useState([]);

  const [addformList, setAddformList] = useState({
    saleType: "",
    make: "",
    id: "",
    value: "",
  });
  const [editformList, setEditformList] = useState();
  const [formtextEditor, setformTexteditor] = useState({});
  const [formList, setformList] = useState([]);

  const { clients } = useContext(TemplateContext);
  const [tdLoader, setTdLoader] = useState(false);

  const [adddealercodeList, setAdddealercodeList] = useState({
    id: "",
    value: "",
  });
  const [editdealercodeList, setEditdealercodeList] = useState();
  const [dealercodetextEditor, setdealercodeTexteditor] = useState({});
  const [dealerCodeList, setdealerCodeList] = useState([]);
  const [publishForm, setPublishForm] = useState({
    show: false,
    title: "",
    feedbackForm: "",
  });
  const [addForm, setAddForm] = useState({
    show: false,
    title: "",
    template: null,
  });
  const [btnLoader, setBtnLoader] = useState(false)
  const dynCols = [
    { name: 'Keywords', value: 'kw', type: 'text', mandatory: true, width: '42.5' },
    { name: 'Origin', value: 'v', type: 'select', mandatory: true, width: '42.5' }
  ]
  useEffect(() => {
    let _phoneCodes = [];
    dealersettings.countries &&
      dealersettings.countries.forEach((doc) => {
        if (doc.phone_code) {
          _phoneCodes.push({
            country_code: doc.code,
            value: doc.phone_code,
            active: _.isBoolean(doc.active) ? doc.active : true,
            label:
              doc.name + " (" + (doc.phone_code ? doc.phone_code : "--") + ")",
          });
          setphoneCodes(_phoneCodes);
        }
      });
  }, []);

  useEffect(() => {
    if (!state.refresh_token || moduleToChange.module !== "podium") {
      return;
    }

    const getLocations = async () => {
      const getPodiumLocations = window.firebase
        .functions()
        .httpsCallable("podium-getLocations");
      const data = await getPodiumLocations({
        refreshToken: state.refresh_token,
      });

      console.log("getPodiumLocations", data);
      if (data.data.success === true && data.data.locations) {
        setPodiumLocations(
          data.data.locations.map((l) => {
            return {
              ...l,
              value: l.uid,
              label: l.name,
            };
          })
        );
      } else {
        console.log("getLocations failed", data);
      }
    };

    getLocations();
  }, [moduleToChange.module, state]);

  useEffect(() => {
    // first check if its a edit modal
    // if its edit modal load integrations
    // if its titan then just remove titan settin
    if (!isEdit) return;

    if (
      !(
        !_.isEmpty(selectedLevel) &&
        (selectedLevel.level === "oem" || !_.isEmpty(selectedLevel.levelID))
      )
    ) {
      return;
    }

    let integrationRef;
    if (selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("integrations");
    } else if (selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID)
        .collection("integrations");
    } else if (selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID)
        .collection("integrations");
    } else if (selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(selectedLevel.levelID)
        .collection("integrations");
    }
    //console.log("selectedLevel.levelID", selectedLevel.levelID);
    const integrationsSnapshot = integrationRef.onSnapshot((querySnapshot) => {
      let _integrations = [];
      let addList = [];
      querySnapshot.docs.forEach((rec) => {
        let data = rec.data();
        if (data.generic) {
          let payload = {
            docID: rec.id,
            appraisal: data?.appraisal,
            inbound: data?.inbound,
            generic: data?.generic,
            enabled: data?.enabled,
            active: data?.active,
            name: data?.name,
            value: data?.type,
            integration: data?.type,
            enquiryType: data?.settings?.enquiryType,
            secretKey: data?.settings?.secretKey,
            accessKey: data?.settings?.accessKey,
            origin: data?.settings?.origin,
            campaign: data?.settings?.campaign,
          };
          addList.push(payload);
        } else {
          _integrations.push({
            documentID: rec.id,
            ...data,
          });
        }
      });
      // console.log(
      //   "these are integrations",
      //   _integrations,
      //   selectedLevel.levelID,
      //   selectedLevel.level,
      //   addList
      // );
      if (!_.isEmpty(_integrations)) {
        let _modules = {};
        if (!isEmpty(_.find(_integrations, { type: "imotor" }))) {
          _modules.imotor = _.find(_integrations, { type: "imotor" });
        }
        if (!isEmpty(_.find(_integrations, { type: "messagemedia" }))) {
          _modules.messagemedia = _.find(_integrations, {
            type: "messagemedia",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "podium" }))) {
          _modules.podium = _.find(_integrations, { type: "podium" });
        }
        if (!isEmpty(_.find(_integrations, { type: "driva" }))) {
          _modules.driva = _.find(_integrations, { type: "driva" });
        }
        if (!isEmpty(_.find(_integrations, { type: "digitallicence_nsw" }))) {
          _modules.digitallicence_nsw = _.find(_integrations, {
            type: "digitallicence_nsw",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "smshub" }))) {
          _modules.smshub = _.find(_integrations, { type: "smshub" });
        }
        if (!isEmpty(_.find(_integrations, { type: "cmdotcom" }))) {
          _modules.cmdotcom = _.find(_integrations, { type: "cmdotcom" });
        }
        if (!isEmpty(_.find(_integrations, { type: "titan" }))) {
          _modules.titan = _.find(_integrations, { type: "titan" });
        }
        if (!isEmpty(_.find(_integrations, { type: "autogate" }))) {
          _modules.autogate = _.find(_integrations, { type: "autogate" });
        }
        if (!isEmpty(_.find(_integrations, { type: "revolution" }))) {
          _modules.revolution = _.find(_integrations, { type: "revolution" });
        }
        if (!isEmpty(_.find(_integrations, { type: "autotrader" }))) {
          //   console.log('autogazte is presenet')
          _modules.autotrader = _.find(_integrations, { type: "autotrader" });
        }
        if (!isEmpty(_.find(_integrations, { type: "dealersolutions" }))) {
          _modules.dealersolutions = _.find(_integrations, {
            type: "dealersolutions",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "drivechat" }))) {
          _modules.drivechat = _.find(_integrations, { type: "drivechat" });
        }
        if (!isEmpty(_.find(_integrations, { type: "carsguide" }))) {
          _modules.carsguide = _.find(_integrations, { type: "carsguide" });
        }
        if (!isEmpty(_.find(_integrations, { type: "erapower" }))) {
          _modules.erapower = _.find(_integrations, { type: "erapower" });
        }
        if (!isEmpty(_.find(_integrations, { type: "ppsr" }))) {
          _modules.ppsr = _.find(_integrations, { type: "ppsr" });
        }
        if (!isEmpty(_.find(_integrations, { type: "d365" }))) {
          _modules.d365 = _.find(_integrations, { type: "d365" });
        }
        if (!isEmpty(_.find(_integrations, { type: "saleslogs" }))) {
          _modules.saleslogs = _.find(_integrations, { type: "saleslogs" });
        }
        if (!isEmpty(_.find(_integrations, { type: "mailchimp" }))) {
          _modules.mailchimp = _.find(_integrations, { type: "mailchimp" });
        }
        if (!isEmpty(_.find(_integrations, { type: "mailjet" }))) {
          _modules.mailjet = _.find(_integrations, { type: "mailjet" });
        }
        if (!isEmpty(_.find(_integrations, { type: "mailup" }))) {
          _modules.mailup = _.find(_integrations, { type: "mailup" });
        }
        if (!isEmpty(_.find(_integrations, { type: "aircall" }))) {
          _modules.aircall = _.find(_integrations, { type: "aircall" });
        }
        if (!isEmpty(_.find(_integrations, { type: "justcall" }))) {
          _modules.justcall = _.find(_integrations, { type: "justcall" });
        }
        if (!isEmpty(_.find(_integrations, { type: "tss" }))) {
          _modules.tss = _.find(_integrations, { type: "tss" });
        }
        if (!isEmpty(_.find(_integrations, { type: "pandadoc" }))) {
          _modules.pandadoc = _.find(_integrations, { type: "pandadoc" });
        }
        if (!isEmpty(_.find(_integrations, { type: "impel" }))) {
          _modules.impel = _.find(_integrations, { type: "impel" });
        }
        if (!isEmpty(_.find(_integrations, { type: "ringcentral" }))) {
          _modules.ringcentral = _.find(_integrations, { type: "ringcentral" });
        }
        if (!isEmpty(_.find(_integrations, { type: "vision6" }))) {
          _modules.vision6 = _.find(_integrations, { type: "vision6" });
        }
        if (!isEmpty(_.find(_integrations, { type: "evalexpert" }))) {
          _modules.evalexpert = _.find(_integrations, { type: "evalexpert" });
        }
        if (!isEmpty(_.find(_integrations, { type: "nylas" }))) {
          _modules.nylas = _.find(_integrations, { type: "nylas" });
          // NEWONES
        }
        if (!isEmpty(_.find(_integrations, { type: "adtorqueedge" }))) {
          _modules.adtorqueedge = _.find(_integrations, {
            type: "adtorqueedge",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "appraisal_solutions" }))) {
          _modules.appraisal_solutions = _.find(_integrations, {
            type: "appraisal_solutions",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "autoit" }))) {
          _modules.autoit = _.find(_integrations, { type: "autoit" });
        }
        if (!isEmpty(_.find(_integrations, { type: "pmds" }))) {
          _modules.pmds = _.find(_integrations, { type: "pmds" });
        }
        if (!isEmpty(_.find(_integrations, { type: "keyloop" }))) {
          _modules.keyloop = _.find(_integrations, { type: "keyloop" });
        }
        if (!isEmpty(_.find(_integrations, { type: "bentley_cab" }))) {
          _modules.bentley_cab = _.find(_integrations, { type: "bentley_cab" });
        }
        if (!isEmpty(_.find(_integrations, { type: "carsales" }))) {
          _modules.carsales = _.find(_integrations, { type: "carsales" });
        }
        if (!isEmpty(_.find(_integrations, { type: "conversica" }))) {
          _modules.conversica = _.find(_integrations, { type: "conversica" });
        }
        if (!isEmpty(_.find(_integrations, { type: "creativo" }))) {
          _modules.creativo = _.find(_integrations, { type: "creativo" });
        }
        if (!isEmpty(_.find(_integrations, { type: "dubizzle" }))) {
          _modules.dubizzle = _.find(_integrations, { type: "dubizzle" });
        }
        if (!isEmpty(_.find(_integrations, { type: "easycars" }))) {
          _modules.easycars = _.find(_integrations, { type: "easycars" });
        }
        if (!isEmpty(_.find(_integrations, { type: "facebook" }))) {
          _modules.facebook = _.find(_integrations, { type: "facebook" });
        }
        if (!isEmpty(_.find(_integrations, { type: "autograb" }))) {
          _modules.autograb = _.find(_integrations, { type: "autograb" });
        }
        if (!isEmpty(_.find(_integrations, { type: "fca" }))) {
          _modules.fca = _.find(_integrations, { type: "fca" });
        }
        if (!isEmpty(_.find(_integrations, { type: "ferrari_modis" }))) {
          _modules.ferrari_modis = _.find(_integrations, {
            type: "ferrari_modis",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "gumtree" }))) {
          _modules.gumtree = _.find(_integrations, { type: "gumtree" });
        }
        if (!isEmpty(_.find(_integrations, { type: "heroleads" }))) {
          _modules.heroleads = _.find(_integrations, { type: "heroleads" });
        }
        if (!isEmpty(_.find(_integrations, { type: "c2native" }))) {
          _modules.c2native = _.find(_integrations, { type: "c2native" });
        }
        if (!isEmpty(_.find(_integrations, { type: "gubagoo" }))) {
          _modules.gubagoo = _.find(_integrations, { type: "gubagoo" });
        }
        if (!isEmpty(_.find(_integrations, { type: "autoforce" }))) {
          _modules.autoforce = _.find(_integrations, { type: "autoforce" });
        }
        if (!isEmpty(_.find(_integrations, { type: "amsme" }))) {
          _modules.amsme = _.find(_integrations, { type: "amsme" });
        }
        if (!isEmpty(_.find(_integrations, { type: "mattaki" }))) {
          _modules.mattaki = _.find(_integrations, { type: "mattaki" });
        }
        if (!isEmpty(_.find(_integrations, { type: "motorweb" }))) {
          _modules.motorweb = _.find(_integrations, { type: "motorweb" });
        }
        if (!isEmpty(_.find(_integrations, { type: "cap" }))) {
          _modules.cap = _.find(_integrations, { type: "cap" });
        }
        if (!isEmpty(_.find(_integrations, { type: "red_book" }))) {
          _modules.red_book = _.find(_integrations, { type: "red_book" });
        }
        if (!isEmpty(_.find(_integrations, { type: "roi" }))) {
          _modules.roi = _.find(_integrations, { type: "roi" });
        }
        if (!isEmpty(_.find(_integrations, { type: "salesforce" }))) {
          _modules.salesforce = _.find(_integrations, { type: "salesforce" });
        }
        if (!isEmpty(_.find(_integrations, { type: "shiftdigital" }))) {
          _modules.shiftdigital = _.find(_integrations, {
            type: "shiftdigital",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "sms_broadcast" }))) {
          _modules.sms_broadcast = _.find(_integrations, {
            type: "sms_broadcast",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "wildjar" }))) {
          _modules.wildjar = _.find(_integrations, { type: "wildjar" });
        }
        if (!isEmpty(_.find(_integrations, { type: "dubaicars" }))) {
          _modules.dubaicars = _.find(_integrations, { type: "dubaicars" });
        }
        if (!isEmpty(_.find(_integrations, { type: "aapwebsite" }))) {
          _modules.aapwebsite = _.find(_integrations, { type: "aapwebsite" });
        }
        if (!isEmpty(_.find(_integrations, { type: "projectcoavme" }))) {
          _modules.projectcoavme = _.find(_integrations, {
            type: "projectcoavme",
          });
        }
        if (!isEmpty(_.find(_integrations, { type: "nexa" }))) {
          _modules.nexa = _.find(_integrations, { type: "nexa" });
        }
        if (!isEmpty(_.find(_integrations, { type: "gforces" }))) {
          _modules.gforces = _.find(_integrations, { type: "gforces" });
        }
        setModules(_modules);

        if (moduleToChange.module === "titan") {
          if (_.isEmpty(_modules.titan.settings)) {
            setState({});
            return;
          }
          let DatabaseName = _modules.titan.settings.DatabaseName;
          let subList = _modules.titan.settings.locIds;
          subList = subList.map((item) => {
            item.value = uuid();
            return item;
          });
          setState({
            ..._modules.titan.settings,
          });
          setLookup({
            subList: [...subList],
          });
          setSubList([...subList]);
        } else if (moduleToChange.module === "saleslogs") {
          if (_.isEmpty(_modules.saleslogs.settings)) {
            setState({});
            return;
          }
          let Code = _modules.saleslogs.settings.Code;
          let username = _modules.saleslogs.settings.username;
          let password = _modules.saleslogs.settings.password;
          let subList = _modules.saleslogs.settings.Dept;
          subList = subList.map((item) => {
            item.value = uuid();
            item.id = item.DeptName;
            delete item["DeptName"];
            return item;
          });
          setState({
            Code,
            username,
            password,
          });
          setLookup({
            subList: [...subList],
          });
          setSubList([...subList]);
        } else if (moduleToChange.module === "salesforce") {
          if (_.isEmpty(_modules.salesforce.settings)) {
            setState({});
            setOldState({});
            return;
          }
          let endpoint = _modules.salesforce.settings.endpoint;
          let clientId = _modules.salesforce.settings.clientId;
          let clientSecret = _modules.salesforce.settings.clientSecret;
          let success = _modules.salesforce.settings.success;
          let documentID = _modules.salesforce.documentID;
          setState({
            endpoint,
            clientId,
            clientSecret,
            success,
            documentID,
            recTypeCompany: _modules.salesforce.settings.recTypeCompany,
            recTypeInbound: _modules.salesforce.settings.recTypeInbound,
            recTypeEnquiry: _modules.salesforce.settings.recTypeEnquiry,
          });
          setOldState({
            endpoint,
            clientId,
            clientSecret,
          });
        } else if (
          moduleToChange.module === "autogate" ||
          moduleToChange.module === "dealersolutions" ||
          moduleToChange.module === "autotrader" ||
          moduleToChange.module === "facebook" ||
          moduleToChange.module === "autograb" ||
          moduleToChange.module === "drivechat" ||
          moduleToChange.module === "carsguide" ||
          moduleToChange.module === "imotor" ||
          moduleToChange.module === "adtorqueedge" ||
          moduleToChange.module === "adtorqueedgeloan" ||
          moduleToChange.module === "appraisal_solutions" ||
          moduleToChange.module === "bentley_cab" ||
          moduleToChange.module === "carsales" ||
          moduleToChange.module === "conversica" ||
          moduleToChange.module === "creativo" ||
          moduleToChange.module === "dubizzle" ||
          moduleToChange.module === "easycars" ||
          moduleToChange.module === "fca" ||
          moduleToChange.module === "ferrari_modis" ||
          moduleToChange.module === "gumtree" ||
          moduleToChange.module === "heroleads" ||
          moduleToChange.module === "c2native" ||
          moduleToChange.module === "gubagoo" ||
          moduleToChange.module === "autoforce" ||
          moduleToChange.module === "amsme" ||
          moduleToChange.module === "mattaki" ||
          moduleToChange.module === "motorweb" ||
          moduleToChange.module === "cap" ||
          moduleToChange.module === "red_book" ||
          moduleToChange.module === "roi" ||
          moduleToChange.module === "shiftdigital" ||
          moduleToChange.module === "wildjar" ||
          moduleToChange.module === "aapwebsite" ||
          moduleToChange.module === "projectcoavme" ||
          moduleToChange.module === "nexa" ||
          moduleToChange.module === "dubaicars" ||
          moduleToChange.module === "revolution"
        ) {
          if (_.isEmpty(_modules[moduleToChange.module].settings)) {
            setState({});
            return;
          }
          let _state = _modules[moduleToChange.module].settings;
          setState(_state);

          const _subList = _modules[moduleToChange.module].settings.originKW;
          const _leadsourceList =
            _modules[moduleToChange.module].settings.leadSourceKW;
          const _campaignList =
            _modules[moduleToChange.module].settings.campaignKW;
          const _dealercodeList =
            _modules[moduleToChange.module].settings.dealerCodes;
          const _formList =
            _modules[moduleToChange.module].settings.formIDs;
          if (
            !_.isEmpty(_subList) ||
            !_.isEmpty(_leadsourceList) ||
            !_.isEmpty(_campaignList) ||
            !_.isEmpty(_formList) ||
            !_.isEmpty(_dealercodeList)
          ) {
            setLookup({
              subList: _subList ? [..._subList] : [],
              leadSourceList: _leadsourceList ? [..._leadsourceList] : [],
              campaignList: _campaignList ? [..._campaignList] : [],
              formList: _formList ? [..._formList] : [],
              dealerCodes: _dealercodeList ? [..._dealercodeList] : [],
            });
            setSubList(_subList ? [..._subList] : []);
            setLeadSourceKWList(_leadsourceList ? [..._leadsourceList] : []);
            setcampaignKWList(_campaignList ? [..._campaignList] : []);
            setformList(_formList ? [..._formList] : []);
            setdealerCodeList(_dealercodeList ? [..._dealercodeList] : []);
          }
        } else if (moduleToChange.module === "mailchimp") {
          setState(
            !_.isEmpty(_modules.mailchimp.settings)
              ? _modules.mailchimp.settings
              : {}
          );
        } else if (moduleToChange.module === "mailjet") {
          setState(
            !_.isEmpty(_modules.mailjet.settings)
              ? _modules.mailjet.settings
              : {}
          );
        } else if (moduleToChange.module === "mailup") {
          setState(
            !_.isEmpty(_modules.mailup.settings) ? _modules.mailup.settings : {}
          );
        } else if (moduleToChange.module === "aircall") {
          setState(
            !_.isEmpty(_modules.aircall.settings)
              ? _modules.aircall.settings
              : {}
          );
        } else if (moduleToChange.module === "justcall") {
          setState(
            !_.isEmpty(_modules.justcall.settings)
              ? _modules.justcall.settings
              : {}
          );
        } else if (moduleToChange.module === "tss") {
          setState(
            !_.isEmpty(_modules.tss.settings)
              ? _modules.tss.settings
              : {}
          );
        } else if (moduleToChange.module === "pandadoc") {
          setState(
            !_.isEmpty(_modules.pandadoc.settings)
              ? _modules.pandadoc.settings
              : {}
          );
        } else if (moduleToChange.module === "impel") {
          setState(
            !_.isEmpty(_modules.impel.settings)
              ? _modules.impel.settings
              : {}
          );
        } else if (moduleToChange.module === "ringcentral") {
          setState(
            !_.isEmpty(_modules.ringcentral.settings)
              ? _modules.ringcentral.settings
              : {}
          );
        } else if (moduleToChange.module === "vision6") {
          setState(
            !_.isEmpty(_modules.vision6.settings)
              ? _modules.vision6.settings
              : {}
          );
        } else if (moduleToChange.module === "evalexpert") {
          setState(
            !_.isEmpty(_modules.evalexpert.settings)
              ? _modules.evalexpert.settings
              : {}
          );
        } else if (moduleToChange.module === "nylas") {
          setState(
            !_.isEmpty(_modules.nylas.settings) ? _modules.nylas.settings : {}
          );
        } else if (moduleToChange.module === "messagemedia") {
          setState(
            !_.isEmpty(_modules.messagemedia.settings)
              ? _modules.messagemedia.settings
              : {}
          );
        } else if (moduleToChange.module === "podium") {
          setState(
            !_.isEmpty(_modules.podium.settings) ? _modules.podium.settings : {}
          );
        } else if (moduleToChange.module === "driva") {
          setState(
            !_.isEmpty(_modules.driva.settings) ? _modules.driva.settings : {}
          );
        } else if (moduleToChange.module === "digitallicence_nsw") {
          setState(
            !_.isEmpty(_modules.digitallicence_nsw.settings)
              ? _modules.digitallicence_nsw.settings
              : {}
          );
        } else if (moduleToChange.module === "smshub") {
          setState(
            !_.isEmpty(_modules.smshub.settings) ? _modules.smshub.settings : {}
          );
        } else if (moduleToChange.module === "cmdotcom") {
          setState(
            !_.isEmpty(_modules.cmdotcom.settings)
              ? _modules.cmdotcom.settings
              : {}
          );
        } else if (moduleToChange.module === "autogate") {
          setState(
            !_.isEmpty(_modules.autogate.settings)
              ? _modules.autogate.settings
              : {}
          );
        } else if (moduleToChange.module === "revolution") {
          setState(
            !_.isEmpty(_modules.revolution.settings)
              ? _modules.revolution.settings
              : {}
          );
        } else if (moduleToChange.module === "autotrader") {
          setState(
            !_.isEmpty(_modules.autotrader.settings)
              ? _modules.autotrader.settings
              : {}
          );
        } else if (moduleToChange.module === "dealersolutions") {
          setState(
            !_.isEmpty(_modules.dealersolutions.settings)
              ? _modules.dealersolutions.settings
              : {}
          );
        } else if (moduleToChange.module === "drivechat") {
          setState(
            !_.isEmpty(_modules.drivechat.settings)
              ? _modules.drivechat.settings
              : {}
          );
        } else if (moduleToChange.module === "carsguide") {
          setState(
            !_.isEmpty(_modules.carsguide.settings)
              ? _modules.carsguide.settings
              : {}
          );
        } else if (moduleToChange.module === "erapower") {
          setState(
            !_.isEmpty(_modules.erapower.settings)
              ? _modules.erapower.settings
              : {}
          );
        } else if (moduleToChange.module === "ppsr") {
          setState(
            !_.isEmpty(_modules.ppsr.settings) ? _modules.ppsr.settings : {}
          );
        } else if (moduleToChange.module === "d365") {
          setState(
            !_.isEmpty(_modules.d365.settings) ? _modules.d365.settings : {}
          );
        } else if (moduleToChange.module === "saleslogs") {
          setState(
            !_.isEmpty(_modules.saleslogs.settings)
              ? _modules.saleslogs.settings
              : {}
          );
        } else if (moduleToChange.module === "adtorqueedge") {
          setState(
            !_.isEmpty(_modules.adtorqueedge.settings)
              ? _modules.adtorqueedge.settings
              : {}
          );
        } else if (moduleToChange.module === "appraisal_solutions") {
          setState(
            !_.isEmpty(_modules.appraisal_solutions.settings)
              ? _modules.appraisal_solutions.settings
              : {}
          );
        } else if (moduleToChange.module === "autoit") {
          setState(
            !_.isEmpty(_modules.autoit.settings) ? _modules.autoit.settings : {}
          );
        } else if (moduleToChange.module === "pmds") {
          setState(
            !_.isEmpty(_modules.pmds.settings) ? _modules.pmds.settings : {}
          );
        } else if (moduleToChange.module === "keyloop") {
          setState(
            !_.isEmpty(_modules.keyloop.settings)
              ? _modules.keyloop.settings
              : {}
          );
        } else if (moduleToChange.module === "bentley_cab") {
          setState(
            !_.isEmpty(_modules.bentley_cab.settings)
              ? _modules.bentley_cab.settings
              : {}
          );
        } else if (moduleToChange.module === "carsales") {
          setState(
            !_.isEmpty(_modules.carsales.settings)
              ? _modules.carsales.settings
              : {}
          );
        } else if (moduleToChange.module === "conversica") {
          setState(
            !_.isEmpty(_modules.conversica.settings)
              ? _modules.conversica.settings
              : {}
          );
        } else if (moduleToChange.module === "creativo") {
          setState(
            !_.isEmpty(_modules.creativo.settings)
              ? _modules.creativo.settings
              : {}
          );
        } else if (moduleToChange.module === "dubizzle") {
          setState(
            !_.isEmpty(_modules.dubizzle.settings)
              ? _modules.dubizzle.settings
              : {}
          );
        } else if (moduleToChange.module === "easycars") {
          setState(
            !_.isEmpty(_modules.easycars.settings)
              ? _modules.easycars.settings
              : {}
          );
        } else if (moduleToChange.module === "facebook") {
          setState(
            !_.isEmpty(_modules.facebook.settings)
              ? _modules.facebook.settings
              : {}
          );
        } else if (moduleToChange.module === "autograb") {
          setState(
            !_.isEmpty(_modules.autograb.settings)
              ? _modules.autograb.settings
              : {}
          );
        } else if (moduleToChange.module === "fca") {
          setState(
            !_.isEmpty(_modules.fca.settings)
              ? _modules.fca.settings
              : {}
          );
        } else if (moduleToChange.module === "ferrari_modis") {
          setState(
            !_.isEmpty(_modules.ferrari_modis.settings)
              ? _modules.ferrari_modis.settings
              : {}
          );
        } else if (moduleToChange.module === "gumtree") {
          setState(
            !_.isEmpty(_modules.gumtree.settings)
              ? _modules.gumtree.settings
              : {}
          );
        } else if (moduleToChange.module === "heroleads") {
          setState(
            !_.isEmpty(_modules.heroleads.settings)
              ? _modules.heroleads.settings
              : {}
          );
        } else if (moduleToChange.module === "c2native") {
          setState(
            !_.isEmpty(_modules.c2native.settings)
              ? _modules.c2native.settings
              : {}
          );
        } else if (moduleToChange.module === "gubagoo") {
          setState(
            !_.isEmpty(_modules.gubagoo.settings)
              ? _modules.gubagoo.settings
              : {}
          );
        } else if (moduleToChange.module === "autoforce") {
          setState(
            !_.isEmpty(_modules.autoforce.settings)
              ? _modules.autoforce.settings
              : {}
          );
        } else if (moduleToChange.module === "amsme") {
          setState(
            !_.isEmpty(_modules.amsme.settings) ? _modules.amsme.settings : {}
          );
        } else if (moduleToChange.module === "mattaki") {
          setState(
            !_.isEmpty(_modules.mattaki.settings)
              ? _modules.mattaki.settings
              : {}
          );
        } else if (moduleToChange.module === "motorweb") {
          setState(
            !_.isEmpty(_modules.motorweb.settings)
              ? _modules.motorweb.settings
              : {}
          );
        } else if (moduleToChange.module === "cap") {
          setState(
            !_.isEmpty(_modules.cap.settings)
              ? _modules.cap.settings
              : {}
          );
        } else if (moduleToChange.module === "red_book") {
          setState(
            !_.isEmpty(_modules.red_book.settings)
              ? _modules.red_book.settings
              : {}
          );
        } else if (moduleToChange.module === "roi") {
          setState(
            !_.isEmpty(_modules.roi.settings) ? _modules.roi.settings : {}
          );
        } else if (moduleToChange.module === "salesforce") {
          setState(
            !_.isEmpty(_modules.salesforce.settings)
              ? _modules.salesforce.settings
              : {}
          );
        } else if (moduleToChange.module === "shiftdigital") {
          setState(
            !_.isEmpty(_modules.shiftdigital.settings)
              ? _modules.shiftdigital.settings
              : {}
          );
        } else if (moduleToChange.module === "sms_broadcast") {
          setState(
            !_.isEmpty(_modules.sms_broadcast.settings)
              ? _modules.sms_broadcast.settings
              : {}
          );
        } else if (moduleToChange.module === "wildjar") {
          setState(
            !_.isEmpty(_modules.wildjar.settings)
              ? _modules.wildjar.settings
              : {}
          );
        } else if (moduleToChange.module === "dubaicars") {
          setState(
            !_.isEmpty(_modules.dubaicars.settings)
              ? _modules.dubaicars.settings
              : {}
          );
        } else if (moduleToChange.module === "aapwebsite") {
          setState(
            !_.isEmpty(_modules.aapwebsite.settings)
              ? _modules.aapwebsite.settings
              : {}
          );
        } else if (moduleToChange.module === "projectcoavme") {
          setState(
            !_.isEmpty(_modules.projectcoavme.settings)
              ? _modules.projectcoavme.settings
              : {}
          );
        } else if (moduleToChange.module === "nexa") {
          setState(
            !_.isEmpty(_modules.nexa.settings) ? _modules.nexa.settings : {}
          );
        } else if (moduleToChange.module === "gforces") {
          setState(
            !_.isEmpty(_modules.gforces.settings)
              ? _modules.gforces.settings
              : {}
          );
        } else if (moduleToChange.module === "emailIntg") {
          setState(
            !_.isEmpty(moduleToChange?.data?.settings)
              ? moduleToChange.data.settings
              : {}
          );
          const _subList = moduleToChange?.data?.settings?.originKW;
          const _leadsourceList =
            moduleToChange?.data?.settings?.leadSourceKW;
          const _campaignList =
            moduleToChange?.data?.settings?.campaignKW;
          const _enquirytypeList =
            moduleToChange?.data?.settings?.enquiryTypeKW;
          if (
            !_.isEmpty(_subList) ||
            !_.isEmpty(_leadsourceList) ||
            !_.isEmpty(_campaignList)
          ) {
            setLookup({
              subList: _subList ? [..._subList] : [],
              leadSourceList: _leadsourceList ? [..._leadsourceList] : [],
              campaignList: _campaignList ? [..._campaignList] : [],
              enquiryTypeList: _enquirytypeList ? [..._enquirytypeList] : []
            });
            setSubList(_subList ? [..._subList] : []);
            setLeadSourceKWList(_leadsourceList ? [..._leadsourceList] : []);
            setcampaignKWList(_campaignList ? [..._campaignList] : []);
            setenquirytypeKWList(_enquirytypeList ? [..._enquirytypeList] : []);
          }
        }
      }
      if (!_.isEmpty(addList) && moduleToChange.module === "genericApis") {
        setGenericList(addList);
        setLookup({
          ...lookup,
          ["subList"]: addList,
        });
      }
    });

    return () => {
      integrationsSnapshot && integrationsSnapshot();
    };
  }, [moduleToChange.module]);

  useEffect(() => {
    if (isEmpty(dealersettings)) return;
    if (isEmpty(dealersettings.client)) return;
    if (isEmpty(dealersettings.client.brands)) return;

    const brands = dealersettings.client.brands;
    const makes = [];
    makes.push({
      value: "ALL",
      label: "ALL",
    });
    _.orderBy(brands, ["name"], ["asc"]).forEach((doc) => {
      makes.push({
        value: doc.value,
        label: doc.name,
        // models: doc.models,
        // active: _.isBoolean(doc.active) ? doc.active : true,
      });
    });

    if (makes.length == 1) {
      makes = [];
    }

    setMakes(makes);
  }, [dealersettings.client.brands]);

  useEffect(() => {
    if (isEmpty(currentSettings)) return;

    let _settings = {};

    if (!isEmpty(currentSettings.settings)) {
      _settings = { ...currentSettings.settings };
    } else if (!isEmpty(currentSettings) && isEmpty(currentSettings.settings)) {
      _settings = { ...currentSettings };
    }

    let _campaigns = [];
    let _origins = [];
    let _enquiryTypes = [];
    let _leadSource = [];
    let _saleTypeOptionsForSalesLogs = [];

    !isEmpty(_settings.campaigns) &&
      _settings.campaigns.forEach((campaign) => {
        _campaigns.push({
          label: campaign.name,
          value: campaign.value,
          data: campaign,
          active: isBoolean(campaign.active) ? campaign.active : true,
        });
      });

    !isEmpty(_settings.origins) &&
      _settings.origins.forEach((origin) => {
        _origins.push({
          label: origin.name,
          value: origin.value,
          data: origin,
          active: isBoolean(origin.active) ? origin.active : true,
        });
      });

    !isEmpty(_settings.enquiryTypes) &&
      _settings.enquiryTypes.forEach((enquiryType) => {
        _enquiryTypes.push({
          label: enquiryType.name,
          value: enquiryType.value,
          data: enquiryType,
          active: isBoolean(enquiryType.active) ? enquiryType.active : true,
        });
      });

    !isEmpty(_settings.salesType) &&
      _settings.salesType.forEach((saleType) => {
        _saleTypeOptionsForSalesLogs.push({
          label: saleType.name,
          value: saleType.value ? saleType.value.toLowerCase() : saleType.value,
          data: saleType,
          active: isBoolean(saleType.active) ? saleType.active : true,
        });
      });

    !isEmpty(_settings.leadSource) &&
      _settings.leadSource.forEach((leadSource) => {
        _leadSource.push({
          label: leadSource.name,
          value: leadSource.value,
          data: leadSource,
          active: isBoolean(leadSource.active) ? leadSource.active : true,
        });
      });

    _saleTypeOptionsForSalesLogs.unshift({
      value: "ALL",
      label: "ALL",
      active: true,
    });
    // console.log("currentSettings", {
    //   _origins,
    //   _enquiryTypes,
    //   _saleTypeOptionsForSalesLogs,
    // });

    setData({
      campaigns: _campaigns,
      origins: _origins,
      enquiryTypes: _enquiryTypes,
      leadSources: _leadSource,
      saleTypeOptionsForSalesLogs: _saleTypeOptionsForSalesLogs,
    });

    // const clientSnapshot = window.firebase.firestore().collection(`clients`)
    //     .where('documentID', 'in', dealersettings.clients.map(v => _.pick(v, ['id']).id))
    //     .onSnapshot(querySnapshot => {
    //         let _clients = [];
    //         querySnapshot.docs.forEach(rec => {
    //             _clients.push({
    //                 label: rec.data().name,
    //                 value: rec.id,
    //                 data: rec.data(),
    //                 active: _.isBoolean(rec.active) ? rec.active : true,
    //             })
    //         })
    //     })
  }, [currentSettings]);

  const handleSelectChange = (e, data) => {
    let evt = e;
    if (data.action === "clear" && !e) {
      evt = { value: "" };
    }

    if (evt) {
      setState({ ...state, [data.name]: evt.value });
    }
  };

  const handlemultiSelectChange = (selectedOptions, name) => {
    const value = [];

    !_.isEmpty(selectedOptions) &&
      selectedOptions.map((data) => {
        value.push(data.value);
      });
    setState({ ...state, [name]: value });
  };

  const handleDynamicSelectChange = (e, data, isNew) => {
    // console.log('handleDynamicSelectChange', e, data, isNew);
    let evt = e;
    if (data.action === "clear" && !e) {
      evt = { value: "" };
    }
    if (isNew) {
      handleOnSubChange({
        target: {
          name: data.name,
          value: evt.value,
        },
      });
    } else {
      handleOnEditSubChange({
        target: {
          name: data.name,
          value: evt.value,
        },
      });
    }
    // console.log('value, name', evt.value, data.name);
  };

  const handleMultiSelectChange = (selectedOptions, name, isNew) => {
    const value = [];

    !_.isEmpty(selectedOptions) &&
      selectedOptions.map((data) => {
        value.push(data.value);
      });
    if (isNew) {
      setAddSubList({
        ...addSubList,
        [name]: value.join(","),
      });
    } else {
      setEditSubList({
        ...editSubList,
        [name]: value.join(","),
      });
    }
  };

  const handleLeadSourceSelectChange = (e, data, isNew) => {
    // console.log('handleDynamicSelectChange', e, data, isNew);
    let evt = e;
    if (data.action === "clear" && !e) {
      evt = { value: "" };
    }
    if (isNew) {
      handleLeadSourceSubChange({
        target: {
          name: data.name,
          value: evt.value,
        },
      });
    } else {
      handleOnEditLeadSourceChange({
        target: {
          name: data.name,
          value: evt.value,
        },
      });
    }
    // console.log('value, name', evt.value, data.name);
  };

  const handlecampaignSelectChange = (e, data, isNew) => {
    // console.log('handleDynamicSelectChange', e, data, isNew);
    let evt = e;
    if (data.action === "clear" && !e) {
      evt = { value: "" };
    }
    if (isNew) {
      handlecampaignSubChange({
        target: {
          name: data.name,
          value: evt.value,
        },
      });
    } else {
      handleOnEditcampaignChange({
        target: {
          name: data.name,
          value: evt.value,
        },
      });
    }
    // console.log('value, name', evt.value, data.name);
  };

  const handleenquirytypeSelectChange = (e, data, isNew) => {
    // console.log('handleDynamicSelectChange', e, data, isNew);
    let evt = e;
    if (data.action === "clear" && !e) {
      evt = { value: "" };
    }
    if (isNew) {
      handleenquirytypeSubChange({
        target: {
          name: data.name,
          value: evt.value,
        },
      });
    } else {
      handleOnEditenquirytypeChange({
        target: {
          name: data.name,
          value: evt.value,
        },
      });
    }
    // console.log('value, name', evt.value, data.name);
  };

  const handleOnSubChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    // console.log('this is arrat 1', array1);
    var str = value;
    if (name === "value") {
      setAddSubList({
        ...addSubList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setAddSubList({
          ...addSubList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addSubList.value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setAddSubList({
          ...addSubList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addSubList.value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["target-" + name]: "",
    });
  };

  const handleLeadSourceSubChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    // console.log('this is arrat 1', array1);
    var str = value;
    if (name === "value") {
      setAddleadSourceList({
        ...addleadSourceList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setAddleadSourceList({
          ...addleadSourceList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addleadSourceList.value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setAddleadSourceList({
          ...addleadSourceList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addleadSourceList.value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["leadSource-" + name]: "",
    });
  };

  const handlecampaignSubChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    // console.log('this is arrat 1', array1);
    var str = value;
    if (name === "value") {
      setAddcampaignList({
        ...addcampaignList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setAddcampaignList({
          ...addcampaignList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addcampaignList.value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setAddcampaignList({
          ...addcampaignList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addcampaignList.value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["campaign-" + name]: "",
    });
  };

  const handleenquirytypeSubChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    // console.log('this is arrat 1', array1);
    var str = value;
    if (name === "value") {
      setAddenquirytypeList({
        ...addenquirytypeList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setAddenquirytypeList({
          ...addenquirytypeList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addenquirytypeList.value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setAddenquirytypeList({
          ...addenquirytypeList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addenquirytypeList.value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["enquirytype-" + name]: "",
    });
  };

  const handledealercodeSubChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    // console.log('this is arrat 1', array1);
    var str = value;
    if (name === "value") {
      setAdddealercodeList({
        ...adddealercodeList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setAdddealercodeList({
          ...adddealercodeList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : adddealercodeList.value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setAdddealercodeList({
          ...adddealercodeList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : adddealercodeList.value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["code-" + name]: "",
    });
  };

  const handleOnEditSubChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    var str = value;
    if (name === "value") {
      setEditSubList({
        ...editSubList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setEditSubList({
          ...editSubList,
          [name]: value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setEditSubList({
          ...editSubList,
          [name]: value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["target-edit" + name]: "",
    });
  };

  const handleOnEditLeadSourceChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    var str = value;
    if (name === "value") {
      setEditleadSourceList({
        ...editleadSourceList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setEditleadSourceList({
          ...editleadSourceList,
          [name]: value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setEditleadSourceList({
          ...editleadSourceList,
          [name]: value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["leadSource-edit" + name]: "",
    });
  };

  const handleOnEditcampaignChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    var str = value;
    if (name === "value") {
      setEditcampaignList({
        ...editcampaignList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setEditcampaignList({
          ...editcampaignList,
          [name]: value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setEditcampaignList({
          ...editcampaignList,
          [name]: value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["campaign-edit" + name]: "",
    });
  };

  const handleOnEditenquirytypeChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    var str = value;
    if (name === "value") {
      setEditenquirytypeList({
        ...editenquirytypeList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setEditenquirytypeList({
          ...editenquirytypeList,
          [name]: value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setEditenquirytypeList({
          ...editenquirytypeList,
          [name]: value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["campaign-edit" + name]: "",
    });
  };

  const handleOnEditdealercodeChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    var str = value;
    if (name === "value") {
      setEditdealercodeList({
        ...editdealercodeList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setEditdealercodeList({
          ...editdealercodeList,
          [name]: value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setEditdealercodeList({
          ...editdealercodeList,
          [name]: value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["code-edit" + name]: "",
    });
  };

  const handleOnChangeMultipleText = (val, name) => {
    setState({
      ...state,
      [name]: !_.isEmpty(val) ? val : [],
    });
  };

  const handleformSubChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    // console.log('this is arrat 1', array1);
    var str = value;
    if (name === "value") {
      setAddformList({
        ...addformList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setAddformList({
          ...addformList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addformList.value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setAddformList({
          ...addformList,
          [name]: value,
          ["value"]: _.isEmpty(textEditor) ? uuid() : addformList.value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["form-" + name]: "",
    });
  };

  const handleOnEditformChange = (e) => {
    // console.log(e.target);
    e.preventDefault && e.preventDefault();
    const { name, value } = e.target;
    var array1 = value.split(" ");
    var str = value;
    if (name === "value") {
      setEditformList({
        ...editformList,
        [name]: value,
      });
    } else {
      if (array1.length > 0) {
        var newarray1 = [];
        for (var x = 0; x < array1.length; x++) {
          newarray1.push(array1[x].charAt(0) + array1[x].slice(1));
        }
        setEditformList({
          ...editformList,
          [name]: value,
        });
      } else {
        if (str && str.length >= 1) {
          var firstChar = str.charAt(0);
          var remainingStr = str.slice(1);
          str = firstChar + remainingStr;
        }
        setEditformList({
          ...editformList,
          [name]: value,
        });
      }
    }
    setErrorFields({
      ...errorFields,
      ["form-edit" + name]: "",
    });
  };

  const removeEmail = (index, name) => {
    let arr = state[name] ? state[name] : [];
    arr.splice(index, 1);
    setState({
      ...state,
      [name]: arr,
    });
  };

  const handleSublistSave = (rec, newlist) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["id", "make", "saleType"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`target-${field}`] = errorClass;
        else errors[`target-edit-${field}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (_.isEmpty(rec["value"])) {
      formIsValid = false;
      return;
    }
    // if (
    //   moduleToChange.module !== "saleslogs" &&
    //   subList
    //     .filter((item) => item.value !== rec.value)
    //     .some((item) => item.id === rec.id)
    // ) {
    //   if (moduleToChange.module === "saleslogs") {
    //     Swal.fire(
    //       CommonHelper.showLocale(
    //         { dealersettings },
    //         "Entry with same department name exists"
    //       ),
    //       "",
    //       "error"
    //     );
    //   } else {
    //     Swal.fire(
    //       CommonHelper.showLocale(
    //         { dealersettings },
    //         "Entry with same location exists"
    //       ),
    //       "",
    //       "error"
    //     );
    //   }
    //   formIsValid = false;
    // }
    if (!formIsValid) {
      return;
    }
    let addList = newlist
      ? [...subList, rec]
      : [
        ..._.map(subList, function (obj) {
          return obj.value === rec.value ? { ...rec } : { ...obj };
        }),
      ];
    // console.log('this is the addList', addList);
    setSubList(addList);
    setLookup({
      ...lookup,
      ["subList"]: addList,
    });
    setAddSubList({ id: "", saleType: "", make: "", value: "" });
    setEditSubList(null);
    setTexteditor({});
  };

  const handleSublistOriginSave = (rec, newlist) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["kw", "v"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`target-${field}`] = errorClass;
        else errors[`target-edit-${field}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (_.isEmpty(rec["kw"]) || _.isEmpty(rec["v"])) {
      formIsValid = false;
      return;
    }
    // if (
    // 	subList
    // 		.filter(item => item.value !== rec.value)
    // 		.some(item => item.id === rec.id)
    // ) {
    // 	if (moduleToChange.module === 'saleslogs') {
    // 		Swal.fire('Entry with same department name exists', '', 'error');
    // 	} else {
    // 		Swal.fire('Entry with same location exists', '', 'error');
    // 	}
    // 	formIsValid = false;
    // }
    if (!formIsValid) {
      return;
    }
    let addList = newlist
      ? [...subList, rec]
      : [
        ..._.map(subList, function (obj) {
          return obj.value === rec.value ? { ...rec } : { ...obj };
        }),
      ];
    let _addList = {};
    _addList.v = addList.v;
    _addList.kw = addList.kw;
    _addList.value = addList.value;
    console.log("this is the addList", addList);
    console.log("this is the _addList", _addList);

    setSubList(addList);
    setLookup({
      ...lookup,
      ["subList"]: addList,
    });
    setAddSubList({ kw: "", v: "", value: "" });
    setEditSubList(null);
    setTexteditor({});
  };
  const handleSublistGenericIntegrationSave = async (
    rec,
    newlist,
    docID,
    checkDublication = true
  ) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["integration"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`target-${field}`] = errorClass;
        else errors[`target-edit-${field}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (!formIsValid) {
      return;
    }
    let integrationExistance = {};
    if (newlist) {
      integrationExistance = subList.find((x) => x.name === rec.name);
    } else {
      integrationExistance = subList.find(
        (x) => x.name === rec.name && x.docID !== rec?.docID
      );
    }
    if (!_.isEmpty(integrationExistance) && checkDublication) {
      Swal.fire("Integration already exist.", "", "error");
      return;
    } else {
      let integrationRef;
      if (selectedLevel.level === "oem") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clientSettings`)
          .doc(dealersettings.client.settingsID)
          .collection("integrations");
      } else if (selectedLevel.level === "region") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clientSettings`)
          .doc(dealersettings.client.settingsID)
          .collection("regions")
          .doc(selectedLevel.levelID)
          .collection("integrations");
      } else if (selectedLevel.level === "group") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clientSettings`)
          .doc(dealersettings.client.settingsID)
          .collection("groups")
          .doc(selectedLevel.levelID)
          .collection("integrations");
      } else if (selectedLevel.level === "individual") {
        integrationRef = window.firebase
          .firestore()
          .collection(`clients`)
          .doc(selectedLevel.levelID)
          .collection("integrations");
      }
      if (!docID) {
        docID = window.firebase.firestore().collection("clients").doc().id;
      }
      let addList = newlist
        ? [
          ...genericList,
          {
            ...rec,
            docID,
            accessKey: docID,
            secretKey: integrationKey,
            enabled: true,
            active: true,
          },
        ]
        : [
          ..._.map(genericList, function (obj) {
            return obj.docID === rec.docID ? { ...rec } : { ...obj };
          }),
        ];
      const reqData = {
        inbound: true,
        generic: true,
        enabled: newlist ? true : rec?.enabled,
        active: newlist ? true : rec?.enabled,
        appraisal: rec?.appraisal || null,
        name: rec?.name || "",
        type: rec?.value || "",
        settings: {
          enquiryType: rec?.enquiryType || "",
          origin: rec?.origin || "",
          campaign: rec?.campaign || "",
          accessKey: docID,
          secretKey: integrationKey || "",
          active: newlist ? true : rec?.enabled,
        },
      };
      integrationRef
        .doc(docID)
        .set(reqData, { merge: true })
        .then(async (snapshot) => {
          setGenericList(addList);
          setLookup({
            ...lookup,
            ["subList"]: addList,
          });
          setAddGenericList({
            enquiryType: "",
            make: "",
            id: "",
            value: "",
            origin: "",
            campaign: "",
            active: false,
            accessKey: "",
            secretKey: "",
          });
          setEditSubList(null);
          setTexteditor({});
        })
        .catch((err) => {
          setAddSubList({
            enquiryType: "",
            make: "",
            id: "",
            value: "",
            origin: "",
            campaign: "",
            accessKey: "",
            secretKey: "",
            active: false,
          });
          setEditSubList(null);
          setTexteditor({});
        });
    }
  };
  const handleSublistLeadSourceSave = (rec, newlist) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["kw", "v"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`leadSource-${field}`] = errorClass;
        else errors[`leadSource-edit-${field}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (_.isEmpty(rec["kw"]) || _.isEmpty(rec["v"])) {
      formIsValid = false;
      return;
    }
    if (!formIsValid) {
      return;
    }
    let addList = newlist
      ? [...leadSourceKWList, rec]
      : [
        ..._.map(leadSourceKWList, function (obj) {
          return obj.value === rec.value ? { ...rec } : { ...obj };
        }),
      ];
    let _addList = {};
    _addList.v = addList.v;
    _addList.kw = addList.kw;
    _addList.value = addList.value;

    setLeadSourceKWList(addList);
    setLookup({
      ...lookup,
      ["leadSourceList"]: addList,
    });
    setAddleadSourceList({ kw: "", v: "", value: "" });
    setEditleadSourceList(null);
    setleadSourceTexteditor({});
  };
  useEffect(() => {
    //console.log("d settings", dealersettings);
  }, []);


  const handleSublistformSave = (rec, newlist) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["id", "name"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`form-${field}`] = errorClass;
        else errors[`form-edit-${field}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (_.isEmpty(rec["id"]) || _.isEmpty(rec["name"])) {
      formIsValid = false;
      return;
    }
    if (!formIsValid) {
      return;
    }
    let addList = newlist
      ? [...formList, rec]
      : [
        ..._.map(formList, function (obj) {
          return obj.value === rec.value ? { ...rec } : { ...obj };
        }),
      ];
    let _addList = {};
    _addList.id = addList.id;
    _addList.value = addList.value;

    setformList(addList);
    setLookup({
      ...lookup,
      ["formList"]: addList,
    });
    setAddformList({ id: "", name: "", value: "" });
    setEditformList(null);
    setformTexteditor({});
  };

  const handleSublistcampaignSave = (rec, newlist) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["kw", "v"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`campaign-${field}`] = errorClass;
        else errors[`campaign-edit-${field}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (_.isEmpty(rec["kw"]) || _.isEmpty(rec["v"])) {
      formIsValid = false;
      return;
    }
    if (!formIsValid) {
      return;
    }
    let addList = newlist
      ? [...campaignKWList, rec]
      : [
        ..._.map(campaignKWList, function (obj) {
          return obj.value === rec.value ? { ...rec } : { ...obj };
        }),
      ];
    let _addList = {};
    _addList.v = addList.v;
    _addList.kw = addList.kw;
    _addList.value = addList.value;

    setcampaignKWList(addList);
    setLookup({
      ...lookup,
      ["campaignList"]: addList,
    });
    setAddcampaignList({ kw: "", v: "", value: "" });
    setEditcampaignList(null);
    setcampaignTexteditor({});
  };


  const handleSublistenquirytypeSave = (rec, newlist) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["kw", "v"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`enquiryType-${field}`] = errorClass;
        else errors[`enquiryType-edit-${field}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (_.isEmpty(rec["kw"]) || _.isEmpty(rec["v"])) {
      formIsValid = false;
      return;
    }
    if (!formIsValid) {
      return;
    }
    let addList = newlist
      ? [...enquirytypeKWList, rec]
      : [
        ..._.map(enquirytypeKWList, function (obj) {
          return obj.value === rec.value ? { ...rec } : { ...obj };
        }),
      ];
    let _addList = {};
    _addList.v = addList.v;
    _addList.kw = addList.kw;
    _addList.value = addList.value;

    setenquirytypeKWList(addList);
    setLookup({
      ...lookup,
      ["enquiryTypeList"]: addList,
    });
    setAddenquirytypeList({ kw: "", v: "", value: "" });
    setEditenquirytypeList(null);
    setenquirytypeTexteditor({});
  };

  const handleSublistdealercodeSave = (rec, newlist) => {
    let formIsValid = true;
    let errorClass = "input_error";
    let errors = {};
    let mandatoryFields = ["t", "v", "c"];
    mandatoryFields.forEach((field) => {
      if (_.isEmpty(rec[field])) {
        formIsValid = false;
        if (newlist) errors[`code-${field}`] = errorClass;
        else errors[`code-edit-${field}`] = errorClass;
      }
    });
    setErrorFields(errors);
    if (_.isEmpty(rec["c"]) || _.isEmpty(rec["v"]) || _.isEmpty(rec["t"])) {
      formIsValid = false;
      return;
    }
    if (!formIsValid) {
      return;
    }
    let addList = newlist
      ? [...dealerCodeList, rec]
      : [
        ..._.map(dealerCodeList, function (obj) {
          return obj.value === rec.value ? { ...rec } : { ...obj };
        }),
      ];
    let _addList = {};
    _addList.v = addList.v;
    _addList.t = addList.t;
    _addList.c = addList.c;

    setdealerCodeList(addList);
    setLookup({
      ...lookup,
      ["dealerCodes"]: addList,
    });
    setAdddealercodeList({ kw: "", v: "", value: "" });
    setEditdealercodeList(null);
    setdealercodeTexteditor({});
  };

  const handleEditSubList = (rec) => {
    // console.log('handleEditSubList', rec)
    setEditSubList(rec);
    setTexteditor({
      [rec.value]: true,
    });
  };
  const handleEditGenericSubList = (rec) => {
    // setEditSubList(rec);
    // setTexteditor({
    //   [rec.docID]: true,
    // });
  };

  const handleEditLeadSourceList = (rec) => {
    setEditleadSourceList(rec);
    setleadSourceTexteditor({
      [rec.value]: true,
    });
  };

  const handleEditcampaignList = (rec) => {
    setEditcampaignList(rec);
    setcampaignTexteditor({
      [rec.value]: true,
    });
  };

  const handleEditenquirytypeList = (rec) => {
    setEditenquirytypeList(rec);
    setenquirytypeTexteditor({
      [rec.value]: true,
    });
  };


  const handleEditformList = (rec) => {
    setEditformList(rec);
    setformTexteditor({
      [rec.value]: true,
    });
  };

  const handleEditdealercodeList = (rec) => {
    setEditdealercodeList(rec);
    setdealercodeTexteditor({
      [rec.value]: true,
    });
  };

  const handleCancelSubList = () => {
    setEditSubList(null);
    setTexteditor({});
  };

  const handleCancelLeadSourceList = () => {
    setEditleadSourceList(null);
    setleadSourceTexteditor({});
  };

  const handleCancelcampaignList = () => {
    setEditcampaignList(null);
    setcampaignTexteditor({});
  };

  const handleCancelenquirytypeList = () => {
    setEditenquirytypeList(null);
    setenquirytypeTexteditor({});
  };

  const handleCancelformList = () => {
    setEditformList(null);
    setformTexteditor({});
  };

  const handleCanceldealercodeList = () => {
    setEditdealercodeList(null);
    setdealercodeTexteditor({});
  };

  const handleDeleteSubList = (rec) => {
    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
      text: CommonHelper.showLocale(
        { dealersettings },
        "Do you want to delete"
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
    }).then((result) => {
      if (result.value) {
        let deleteList = [
          ...subList.filter((item) => item.value !== rec.value),
        ];
        setSubList(deleteList);
        setLookup({
          ...lookup,
          ["subList"]: deleteList,
        });
      }
    });
  };
  const handleEditForm = (template) => {
    setAddForm({ show: true, title: template.title, template: template });
  };
  const handledetailsClick = (e, id, data) => {
    e.preventDefault();
    if (
      data.clientID === dealersettings.client.id ||
      data.addedBy === localStorage.uid
    )
      handleEditForm(data);
  };
  const handleEmbedOpen = (e, data) => {
    e.preventDefault();
    if (_.isEmpty(data)) {
      Swal.fire(
        CommonHelper.showLocale("something", "No integration detail found."),
        "",
        "info"
      );
      return;
    }
    let feedbackForm = {
      ...data,
      secretKey: data.secretKey || dealersettings.client.integrationKey,
      accessKey: data.accessKey || data.docID,
      value: data.value || data.type
    };
    // OPEN EMBED MODAL
    setPublishForm({
      feedbackForm: feedbackForm,
      show: true,
      title: levelName,
    });
  };
  const handleDeleteGenericSubList = (rec) => {
    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
      text: CommonHelper.showLocale(
        { dealersettings },
        "Do you want to delete"
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
    }).then((result) => {
      if (result.value) {
        let integrationRef;
        if (selectedLevel.level === "oem") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(dealersettings.client.settingsID)
            .collection("integrations");
        } else if (selectedLevel.level === "region") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(dealersettings.client.settingsID)
            .collection("regions")
            .doc(selectedLevel.levelID)
            .collection("integrations");
        } else if (selectedLevel.level === "group") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(dealersettings.client.settingsID)
            .collection("groups")
            .doc(selectedLevel.levelID)
            .collection("integrations");
        } else if (selectedLevel.level === "individual") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clients`)
            .doc(selectedLevel.levelID)
            .collection("integrations");
        }
        integrationRef = integrationRef
          .doc(rec?.docID)
          .delete()
          .then(() => {
            let deleteList = [
              ...subList.filter((item) => item.docID !== rec.docID),
            ];
            setGenericList(deleteList);
            setLookup({
              ...lookup,
              ["subList"]: deleteList,
            });
          })
          .catch((error) => {
            console.error("Error deleting document:", error);
          });
      }
    });
  };

  const handleDeleteLeadSourceList = (rec) => {
    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
      text: CommonHelper.showLocale(
        { dealersettings },
        "Do you want to delete"
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
    }).then((result) => {
      if (result.value) {
        let deleteList = [
          ...leadSourceKWList.filter((item) => item.value !== rec.value),
        ];
        setLeadSourceKWList(deleteList);
        setLookup({
          ...lookup,
          ["leadSourceList"]: deleteList,
        });
      }
    });
  };

  const handleDeletecampaignList = (rec) => {
    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
      text: CommonHelper.showLocale(
        { dealersettings },
        "Do you want to delete"
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
    }).then((result) => {
      if (result.value) {
        let deleteList = [
          ...campaignKWList.filter((item) => item.value !== rec.value),
        ];
        setcampaignKWList(deleteList);
        setLookup({
          ...lookup,
          ["campaignList"]: deleteList,
        });
      }
    });
  };

  const handleDeleteenquirytypeList = (rec) => {
    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
      text: CommonHelper.showLocale(
        { dealersettings },
        "Do you want to delete"
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
    }).then((result) => {
      if (result.value) {
        let deleteList = [
          ...enquirytypeKWList.filter((item) => item.value !== rec.value),
        ];
        setenquirytypeKWList(deleteList);
        setLookup({
          ...lookup,
          ["enquiryTypeList"]: deleteList,
        });
      }
    });
  };

  const handleDeletedealercodeList = (rec) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        let deleteList = [
          ...dealerCodeList.filter((item) => item.value !== rec.value),
        ];
        setdealerCodeList(deleteList);
        setLookup({
          ...lookup,
          ["dealerCodes"]: deleteList,
        });
      }
    });
  };

  const handleDeleteformList = (rec) => {
    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
      text: CommonHelper.showLocale(
        { dealersettings },
        "Do you want to delete"
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
    }).then((result) => {
      if (result.value) {
        let deleteList = [
          ...formList.filter((item) => item.value !== rec.value),
        ];
        setformList(deleteList);
        setLookup({
          ...lookup,
          ["formList"]: deleteList,
        });
      }
    });
  };

  const onHandleTelephoneChange = (e) => {
    const { name, value } = e.target;
    var regexp = /^[0-9+ \b]+$/;
    let telephone = _.trim(value);

    // if value is not blank, then test the regex
    if (telephone === "" || regexp.test(telephone)) {
      let val = _.trim(value);
      setState({
        ...state,
        [name]: val,
        dedicatednumber: `${state.phoneCode ? state.phoneCode : ""}${val}`,
      });
    }
  };

  const handleAddPhoneCode = (name, value) => {
    let val = value ? value : "";
    setState({
      ...state,
      [name]: val,
      dedicatednumber: `${val}${state.phone ? state.phone : ""}`,
    });
  };

  const handleChange = (e) => {
    if (e.target.name === "nylasSeats") {
      if (e.target.value === "" || /^\d+$/.test(e.target.value)) {
        if (e.target.value >= 50000) {
          return;
        }
        setState({ ...state, [e.target.name]: e.target.value });
        if (e.target.value.length > 0) {
          setErrorFields({
            ...errorFields,
            [e.target.name]: "",
          });
        }
      }
    } else if (moduleToChange.module === "salesforce") {
      if (
        oldState &&
        oldState[e.target.name] &&
        oldState[e.target.name] !== e.target.value
      ) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
          success: false,
        });
      } else {
        setState({ ...state, [e.target.name]: e.target.value });
      }
      if (e.target.value.length > 0) {
        setErrorFields({
          ...errorFields,
          [e.target.name]: "",
        });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
      if (e.target.value.length > 0) {
        setErrorFields({
          ...errorFields,
          [e.target.name]: "",
        });
      }
    }
  };

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    //console.log(name, checked);
    setState({ ...state, [name]: checked });
  };

  const handleAddCheckChange = (e) => {
    const { name, checked } = e.target;
    //console.log(name, checked);
    setAddformList({ ...addformList, [name]: checked });
  };

  const handleEditCheckChange = (e) => {
    const { name, checked } = e.target;
    //console.log(name, checked);
    setEditformList({ ...editformList, [name]: checked });
  };

  const handleTermsChange = (name, checked) => {
    console.log(name, checked);
    setState({
      ...state,
      [name]: checked,
      lastTermsAcceptedBy: checked ? localStorage.uid : "",
      lastTermsAcceptedByTime: checked
        ? window.firebase.firestore.Timestamp.now()
        : "",
    });
  };

  return (
    <>
      <Modal
        id="add-cafe"
        show={show}
        onHide={handleClose}
        backdrop="static"
        backdropClassName={show ? "overlay-modal active" : ""}
        enforceFocus={false}
        dialogClassName={`modal-dialog modal-dialog-centered ${moduleToChange.module === "genericApis"
          ? "modal-configure-integration-generic-api"
          : "modal-configure-integration"
          }`}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">
              <Translate text={`${title} Integration`} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="form-style w-100">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="configure-integration-logo">
                      {moduleToChange.module === "titan" ? (
                        <img src={images.titandms} alt="" />
                      ) : moduleToChange.module === "imotor" ? (
                        <img src={images.imotor} alt="" />
                      ) : moduleToChange.module === "messagemedia" ? (
                        <img src={images.messagemedia} alt="" />
                      ) : moduleToChange.module === "podium" ? (
                        <img src={images.podium} alt="" />
                      ) : moduleToChange.module === "driva" ? (
                        <img
                          src={images.driva}
                          alt=""
                          style={{ maxHeight: "60px", maxWidth: "137px" }}
                        />
                      ) : moduleToChange.module === "digitallicence_nsw" ? (
                        <img src={images.digitallicence_nsw} alt="" />
                      ) : moduleToChange.module === "smshub" ? (
                        <img src={images.smshub} alt="" />
                      ) : moduleToChange.module === "cmdotcom" ? (
                        <img src={images.cmdotcom} alt="" />
                      ) : moduleToChange.module === "autogate" ? (
                        <img src={images.autogate} alt="" />
                      ) : moduleToChange.module === "revolution" ? (
                        <img src={images.tune} alt="" />
                      ) : moduleToChange.module === "autotrader" ? (
                        <img src={images.autotrader} alt="" />
                      ) : moduleToChange.module === "dealersolutions" ? (
                        <img src={images.dealersolutions} alt="" />
                      ) : moduleToChange.module === "drivechat" ? (
                        <img src={images.drivechat} alt="" />
                      ) : moduleToChange.module === "carsguide" ? (
                        <img src={images.carsguide} alt="" />
                      ) : moduleToChange.module === "erapower" ? (
                        <img src={images.erapower} alt="" />
                      ) : moduleToChange.module === "ppsr" ? (
                        <img src={images.ppsr} alt="" />
                      ) : moduleToChange.module === "d365" ? (
                        <img src={images.d365} alt="" />
                      ) : moduleToChange.module === "saleslogs" ? (
                        <img src={images.saleslogs} alt="" />
                      ) : moduleToChange.module === "mailchimp" ? (
                        <img src={images.mailchimp} alt="" />
                      ) : moduleToChange.module === "mailjet" ? (
                        <img src={images.mailjet} alt="" />
                      ) : moduleToChange.module === "mailup" ? (
                        <img src={images.mailup} alt="" />
                      ) : moduleToChange.module === "aircall" ? (
                        <img src={images.aircall} alt="" />
                      ) : moduleToChange.module === "justcall" ? (
                        <img src={images.justcall} alt="" />
                      ) : moduleToChange.module === "tss" ? (
                        <img src={images.tss} alt="" />
                      ) : moduleToChange.module === "pandadoc" ? (
                        <img src={images.pandadoc} alt="" />
                      ) : moduleToChange.module === "impel" ? (
                        <img src={images.impel} alt="" />
                      ) : moduleToChange.module === "ringcentral" ? (
                        <img src={images.ringcentral} alt="" />
                      ) : moduleToChange.module === "vision6" ? (
                        <img src={images.vision6} alt="" />
                      ) : moduleToChange.module === "evalexpert" ? (
                        <img src={images.evalexpert} alt="" />
                      ) : moduleToChange.module === "nylas" ? (
                        <img src={images.nylas} alt="" />
                      ) : moduleToChange.module === "adtorqueedge" ? (
                        <img src={images.adtorqueedge} alt="" />
                      ) : moduleToChange.module === "appraisal_solutions" ? (
                        <img src={images.appraisal_solutions} alt="" />
                      ) : moduleToChange.module === "autoit" ? (
                        <img src={images.autoit} alt="" />
                      ) : moduleToChange.module === "pmds" ? (
                        <img src={images.pmds} alt="" />
                      ) : moduleToChange.module === "keyloop" ? (
                        <img src={images.keyloop} alt="" />
                      ) : moduleToChange.module === "bentley_cab" ? (
                        <img src={images.bentley_cab} alt="" />
                      ) : moduleToChange.module === "carsales" ? (
                        <img src={images.carsales} alt="" />
                      ) : moduleToChange.module === "conversica" ? (
                        <img src={images.conversica} alt="" />
                      ) : moduleToChange.module === "creativo" ? (
                        <img src={images.creativo} alt="" />
                      ) : moduleToChange.module === "dubizzle" ? (
                        <img src={images.dubizzle} alt="" />
                      ) : moduleToChange.module === "easycars" ? (
                        <img src={images.easycars} alt="" />
                      ) : moduleToChange.module === "facebook" ? (
                        <img src={images.facebook} alt="" />
                      ) : moduleToChange.module === "autograb" ? (
                        <img src={images.autograb} alt="" />
                      ) : moduleToChange.module === "genericApis" ? (
                        <img src={images.genericApi} alt="" />
                      ) : moduleToChange.module === "fca" ? (
                        <img src={images.fca_digital} alt="" />
                      ) : moduleToChange.module === "ferrari_modis" ? (
                        <img src={images.ferrari_modis} alt="" />
                      ) : moduleToChange.module === "gumtree" ? (
                        <img src={images.gumtree} alt="" />
                      ) : moduleToChange.module === "heroleads" ? (
                        <img src={images.heroleads} alt="" />
                      ) : moduleToChange.module === "c2native" ? (
                        <img src={images.c2native} alt="" />
                      ) : moduleToChange.module === "gubagoo" ? (
                        <img src={images.gubagoo} alt="" />
                      ) : moduleToChange.module === "autoforce" ? (
                        <img src={images.autoforce} alt="" />
                      ) : moduleToChange.module === "amsme" ? (
                        <img src={images.amsme} alt="" />
                      ) : moduleToChange.module === "mattaki" ? (
                        <img src={images.mattaki} alt="" />
                      ) : moduleToChange.module === "motorweb" ? (
                        <img src={images.motorweb} alt="" />
                      ) : moduleToChange.module === "cap" ? (
                        <img src={images.porsche_cap} alt="" />
                      ) : moduleToChange.module === "red_book" ? (
                        <img src={images.red_book} alt="" />
                      ) : moduleToChange.module === "roi" ? (
                        <img src={images.roi} alt="" />
                      ) : moduleToChange.module === "salesforce" ? (
                        <img src={images.salesforce} alt="" />
                      ) : moduleToChange.module === "shiftdigital" ? (
                        <img src={images.shiftdigital} alt="" />
                      ) : moduleToChange.module === "sms_broadcast" ? (
                        <img src={images.sms_broadcast} alt="" />
                      ) : moduleToChange.module === "wildjar" ? (
                        <img src={images.wildjar} alt="" />
                      ) : moduleToChange.module === "dubaicars" ? (
                        <img src={images.dubicars} alt="" />
                      ) : moduleToChange.module === "aapwebsite" ? (
                        <img src={images.integrations} alt="" />
                      ) : moduleToChange.module === "projectcoavme" ? (
                        <img src={images.integrations} alt="" />
                      ) : moduleToChange.module === "nexa" ? (
                        <img src={images.integrations} alt="" />
                      ) : moduleToChange.module === "gforces" ? (
                        <img src={images.gforces} alt="" />
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* <p className='configure-integration-text'>
										Lorem ipsum is placeholder text commonly used in the
										graphic, print, and publishing industries for previewing
										layouts and visual mockups.
									</p> */}
                  </div>
                </div>
                <div className="form-row ">
                  {moduleToChange.module === "titan" ? (
                    <>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"Database Name"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["DatabaseName"]}`}
                          value={state.DatabaseName ? state.DatabaseName : ""}
                          placeholder={"enter database name"}
                          onChange={handleChange}
                          name="DatabaseName"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Appraisal API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["appraisalapikey"]}`}
                          value={state.appraisalapikey ? state.appraisalapikey : ""}
                          placeholder={"enter appraisal api key"}
                          onChange={handleChange}
                          name="appraisalapikey"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Appraisal Location"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["appraisalloc"]}`}
                          value={state.appraisalloc ? state.appraisalloc : ""}
                          placeholder={"enter appraisal location"}
                          onChange={handleChange}
                          name="appraisalloc"
                        />
                      </div>
                      <div className="form-group col-md-12 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`enableTradeIn`}
                            name={"enableTradeIn"}
                            checked={
                              _.isBoolean(state.enableTradeIn)
                                ? state.enableTradeIn
                                : false
                            }
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`enableTradeIn`}>
                            <Translate text={"Enable Trade-In"} />
                          </label>
                        </span>
                      </div>
                      <div className="common-table w-100 mt-2">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="head-light"
                                width="33%"
                              >
                                {" "}
                                <Translate text={"make"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="33%"
                              >
                                {" "}
                                <Translate text={"Sales type"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="33%"
                              >
                                {" "}
                                <Translate text={"locationCode"} />
                              </th>
                              {/* <th scope="col" className="head-light" width="33%">Value</th> */}
                              <th
                                scope="col"
                                className="head-light border-right-0"
                                width="10%"
                              >
                                {" "}
                                <Translate text={"Actions"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="form-group mb-0">
                                  <ReactSelect
                                    options={makes}
                                    name={"make"}
                                    placeholder={"select make"}
                                    onChange={(e, data) => {
                                      handleDynamicSelectChange(e, data, true);
                                    }}
                                    value={addSubList.make}
                                    classNamePrefix={` ${errorFields["target-make"]} basic-select`}
                                    id={`target-make`}
                                  ></ReactSelect>
                                </div>
                              </td>
                              <td>
                                <div className="form-group mb-0">
                                  <ReactSelect
                                    options={saleTypeOptions}
                                    name={"saleType"}
                                    placeholder={"select sales type"}
                                    onChange={(e, data) => {
                                      handleDynamicSelectChange(e, data, true);
                                    }}
                                    value={addSubList.saleType}
                                    classNamePrefix={`${errorFields["target-saleType"]} basic-select`}
                                    id={`target-saleType`}
                                  ></ReactSelect>
                                </div>
                              </td>
                              <td>
                                <div className="form-group mb-0">
                                  <InputText
                                    id={`target-id`}
                                    autoComplete="off"
                                    placeholder={"enter location code"}
                                    className={`form-control ${errorFields["target-id"]}`}
                                    name="id"
                                    onChange={handleOnSubChange}
                                    value={addSubList.id}
                                  />
                                </div>
                              </td>
                              <td className="border-right-0">
                                <div className="button-height-fix">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleSublistSave(addSubList, true);
                                    }}
                                  >
                                    {" "}
                                    <Translate text={"add"} />
                                  </button>
                                </div>
                              </td>
                            </tr>
                            {subList.length > 0 ? (
                              subList.map((rec, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onDoubleClick={(e) => {
                                      e.preventDefault();
                                      if (_.isEmpty(textEditor))
                                        handleEditSubList(rec);
                                    }}
                                  >
                                    <td>
                                      {textEditor[rec.value] ? (
                                        <>
                                          <ReactSelect
                                            options={makes}
                                            name={"make"}
                                            placeholder={"select make"}
                                            onChange={(e, data) => {
                                              handleDynamicSelectChange(
                                                e,
                                                data,
                                                false
                                              );
                                            }}
                                            value={editSubList.make}
                                            classNamePrefix={`${errorFields["target-edit-make"]} basic-select`}
                                            id={`target-edit-make`}
                                          ></ReactSelect>
                                        </>
                                      ) : (
                                        <>{rec.make}</>
                                      )}
                                    </td>
                                    <td>
                                      {textEditor[rec.value] ? (
                                        <>
                                          <ReactSelect
                                            options={saleTypeOptions}
                                            name={"saleType"}
                                            placeholder={"select sales type"}
                                            onChange={(e, data) => {
                                              handleDynamicSelectChange(
                                                e,
                                                data,
                                                false
                                              );
                                            }}
                                            value={editSubList.saleType}
                                            classNamePrefix={`${errorFields["target-edit-saleType"]} basic-select`}
                                            id={`target-edit-saleType`}
                                          ></ReactSelect>
                                        </>
                                      ) : (
                                        <>{rec.saleType}</>
                                      )}
                                    </td>
                                    <td>
                                      {textEditor[rec.value] ? (
                                        <>
                                          <InputText
                                            id={`target-edit-id`}
                                            autoComplete="off"
                                            placeholder={"location"}
                                            className={`form-control ${errorFields["target-edit-id"]}`}
                                            name="id"
                                            onChange={handleOnEditSubChange}
                                            value={editSubList.id}
                                          />
                                        </>
                                      ) : (
                                        <>{rec.id}</>
                                      )}
                                    </td>
                                    {/* <td>{
                                                            textEditor ? (<><InputText
                                                             id="editsublistvalue"
                                                                autoComplete="off"
                                                                placeholder={'value'}
                                                                className={`form-control ${errorFields["editsublistvalue"]}`}
                                                                name="value"
                                                                onChange={handleOnEditSubChange}
                                                                value={editSubList.value}
                                                                disabled={true}
                                                            /></>) : (<>{rec.value}</>)
                                                        }</td> */}
                                    <td className="border-right-0">
                                      <div className="action-fix">
                                        {textEditor[rec.value] ? (
                                          <>
                                            <div className="table-edit float-left mr-2">
                                              {" "}
                                              <a
                                                href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleSublistSave(
                                                    editSubList,
                                                    false
                                                  );
                                                }}
                                              >
                                                {" "}
                                                <i className="ico icon-check"></i>
                                              </a>
                                            </div>
                                            <div className="table-edit float-left mr-2">
                                              {" "}
                                              <a
                                                href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleCancelSubList(rec);
                                                }}
                                              >
                                                {" "}
                                                <i className="ico icon-remove"></i>
                                              </a>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="table-edit float-left mr-2">
                                              {" "}
                                              <a
                                                href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleEditSubList(rec);
                                                }}
                                              >
                                                {" "}
                                                <i className="ico icon-edit"></i>
                                              </a>
                                            </div>
                                            <div className="table-edit float-left mr-2">
                                              {" "}
                                              <a
                                                href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleDeleteSubList(rec);
                                                }}
                                              >
                                                {" "}
                                                <i className="ico icon-delete"></i>
                                              </a>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : moduleToChange.module === "revolution" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Leads Api"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["leadsapi"]}`}
                          value={state.leadsapi ? state.leadsapi : ""}
                          placeholder={"enter leads api"}
                          onChange={handleChange}
                          name="leadsapi"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Leads Api Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["leadsapikey"]}`}
                          value={state.leadsapikey ? state.leadsapikey : ""}
                          placeholder={"enter leads api key"}
                          onChange={handleChange}
                          name="leadsapikey"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Appraisal Api"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["appraisalapi"]}`}
                          value={state.appraisalapi ? state.appraisalapi : ""}
                          placeholder={"enter appraisal api"}
                          onChange={handleChange}
                          name="appraisalapi"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Appraisal Api Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["appraisalapikey"]}`}
                          value={state.appraisalapikey ? state.appraisalapikey : ""}
                          placeholder={"enter appraisal api key"}
                          onChange={handleChange}
                          name="appraisalapikey"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Customer Prefrence Api"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["custprefapi"]}`}
                          value={state.custprefapi ? state.custprefapi : ""}
                          placeholder={"enter cust pref api"}
                          onChange={handleChange}
                          name="custprefapi"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Customer Prefrence Api Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["custprefapikey"]}`}
                          value={state.custprefapikey ? state.custprefapikey : ""}
                          placeholder={"enter cust pref api key"}
                          onChange={handleChange}
                          name="custprefapikey"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Customer Prefrence Update Api"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["custprefupdateapi"]}`}
                          value={state.custprefupdateapi ? state.custprefupdateapi : ""}
                          placeholder={"enter cust pref update api"}
                          onChange={handleChange}
                          name="custprefupdateapi"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Department ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["departmentID"]}`}
                          value={state.departmentID ? state.departmentID : ""}
                          placeholder={"enter department id"}
                          onChange={handleChange}
                          name="departmentID"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"User Name"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["username"]}`}
                          value={state.username ? state.username : ""}
                          placeholder={"enter user name"}
                          onChange={handleChange}
                          name="username"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Password"} />{" "}
                        </label>
                        <input
                          type="password"
                          className={`form-control ${errorFields["password"]}`}
                          value={state.password ? state.password : ""}
                          placeholder={"enter password"}
                          onChange={handleChange}
                          name="password"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Dealer Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["revDealerCode"]}`}
                          value={state.revDealerCode ? state.revDealerCode : ""}
                          placeholder={"enter dealer code"}
                          onChange={handleChange}
                          name="revDealerCode"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <div className="autograb-subscription-user-tablehead mt-2">
                          Department
                        </div>
                        <CustomTable
                          dealersettings={dealersettings}
                          cols={revolutionDynCols}
                          subList={state.departments}
                          setSubList={(list) => { setState({ ...state, departments: list }) }}
                          make={makes}
                          saleTypes={data?.saleTypeOptionsForSalesLogs?.filter(a => a.value !== 'ALL')}
                          dragDisabled={true}
                        ></CustomTable>
                      </div>

                    </>
                  ) : moduleToChange.module === "fca" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"User Name"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["username"]}`}
                          value={state.username ? state.username : ""}
                          placeholder={"enter user name"}
                          onChange={handleChange}
                          name="username"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Password"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["password"]}`}
                          value={state.password ? state.password : ""}
                          placeholder={"enter password"}
                          onChange={handleChange}
                          name="password"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_id"]}`}
                          value={state.client_id ? state.client_id : ""}
                          placeholder={"enter client id"}
                          onChange={handleChange}
                          name="client_id"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client Secret"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_secret"]}`}
                          value={state.client_secret ? state.client_secret : ""}
                          placeholder={"enter client secret"}
                          onChange={handleChange}
                          name="client_secret"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Auth URL"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["authURL"]}`}
                          value={state.authURL ? state.authURL : ""}
                          placeholder={"enter auth url"}
                          onChange={handleChange}
                          name="authURL"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Base Url"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["baseUrl"]}`}
                          value={state.baseUrl ? state.baseUrl : ""}
                          placeholder={"enter base url"}
                          onChange={handleChange}
                          name="baseUrl"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"FCA Category"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["fcaCategory"]}`}
                          value={state.fcaCategory ? state.fcaCategory : ""}
                          placeholder={"enter fca category"}
                          onChange={handleChange}
                          name="fcaCategory"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"FCA Dealer Code"} />{" "}
                        </label>
                        <InputMultipleText
                          placeholder={`fcaDealerCode`}
                          className={`form-control react-multi-tag`}
                          name="fcaDealerCode"
                          onChange={(fcaDealerCode) => {
                            handleOnChangeMultipleText(fcaDealerCode, "fcaDealerCode");
                          }}
                          value={state?.fcaDealerCode ? state.fcaDealerCode : []}
                          validateEmail={false}
                          getLabel={(fcaDealerCode, index) => {
                            return (
                              <div data-tag key={index}>
                                {fcaDealerCode}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index, "fcaDealerCode")}
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Lead Source"} />
                        </label>
                        <ReactSelect
                          options={data.leadSources}
                          name={"leadSource"}
                          placeholder={"select lead source"}
                          onChange={handleSelectChange}
                          value={state.leadSource ? state.leadSource : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Campaign"} />
                        </label>
                        <ReactSelect
                          options={data.campaigns}
                          name={"campaign"}
                          placeholder={"select campaign"}
                          onChange={handleSelectChange}
                          value={state.campaign ? state.campaign : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""} basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="enquiryType"
                              className="nav-fill"
                            >
                              <Tab eventKey="enquiryType" title="Enquiry Type">
                                <CustomTable
                                  dealersettings={dealersettings}
                                  cols={dynCols}
                                  subList={state?.enquiryTypeKW}
                                  setSubList={(list) => { setState({ ...state, enquiryTypeKW: list }) }}
                                  v={data.enquiryTypes}
                                  dragDisabled={true}
                                ></CustomTable>
                              </Tab>
                              <Tab eventKey="origin" title="Origin">
                                <CustomTable
                                  dealersettings={dealersettings}
                                  cols={dynCols}
                                  subList={state?.originKW}
                                  setSubList={(list) => { setState({ ...state, originKW: list }) }}
                                  v={data.origins}
                                  dragDisabled={true}
                                ></CustomTable>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <CustomTable
                                  dealersettings={dealersettings}
                                  cols={dynCols}
                                  subList={state?.leadSourceKW}
                                  setSubList={(list) => { setState({ ...state, leadSourceKW: list }) }}
                                  v={data.leadSources}
                                  dragDisabled={true}
                                ></CustomTable>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <CustomTable
                                  dealersettings={dealersettings}
                                  cols={dynCols}
                                  subList={state?.campaignKW}
                                  setSubList={(list) => { setState({ ...state, campaignKW: list }) }}
                                  v={data.campaigns}
                                  dragDisabled={true}
                                ></CustomTable>
                              </Tab>
                            </Tabs>
                          </nav>
                        </div>
                      </div>
                    </>
                  ) : moduleToChange.module === "digitallicence_nsw" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://www.service.nsw.gov.au/campaign/nsw-digital-driver-licence"
                          target="_blank"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Club Name"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["clubName"]}`}
                          value={state.clubName ? state.clubName : ""}
                          placeholder={"enter club name"}
                          onChange={handleChange}
                          name="clubName"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"QR Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["qr"]}`}
                          value={state.qr ? state.qr : ""}
                          placeholder={"enter QR code"}
                          onChange={handleChange}
                          name="qr"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "messagemedia" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://messagemedia.com/au/our-partners/fusionsd/"
                          target="_blank"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Secret"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_secret"]}`}
                          value={state.api_secret ? state.api_secret : ""}
                          placeholder={"enter API secret"}
                          onChange={handleChange}
                          name="api_secret"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate
                            text={"Dedicated Number With Country Code"}
                          />{" "}
                        </label>
                        <div
                          className={`input-group country-code ${errorFields["phone"] ? "phone_error" : ""
                            }`}
                        >
                          {/* <div className='input-group-prepend'>
													<Dropdown className={`btn btn-outline-secondary`}>
														<Dropdown.Toggle as={CustomToggle}>
															<span id='spn-code-name' data-name={state.phoneCode}>
																{state.phoneCode &&
																phoneCodes.find(item => item.value === state.phoneCode)
																	? phoneCodes.find(
																			item => item.value === state.phoneCode,
																		).value + ' '
																	: 'Code'}
															</span>
														</Dropdown.Toggle>

														<Dropdown.Menu
															as={CustomSearchMenu}
															className='rm-pointers dropdown-menu-lg dropdown-menu-right'
															ChildClass='pipeline-dropdown'
															xplacement='bottom-end'
														>
															{!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
																phoneCodes.map((rec, index) => {
																	return (
																		<Dropdown.Item
																			key={index}
																			className={`current-pipeline ${
																				state.phoneCode && rec.value === state.phoneCode
																					? 'active'
																					: ''
																			}`}
																			onClick={e => {
																				e.preventDefault();
																				handleAddPhoneCode(
																					'phoneCode',
																					rec.value,
																				);
																			}}
																		>
																			{rec.label}
																		</Dropdown.Item>
																	);
																})
															) : (
																<></>
															)}
														</Dropdown.Menu>
													</Dropdown>
												</div> */}
                          <input
                            type="text"
                            autoComplete="off"
                            placeholder={"enter dedicated number"}
                            pattern="[0-9]*"
                            className={`form-control `}
                            name="phone"
                            onChange={onHandleTelephoneChange}
                            value={state.dedicatednumber}
                          />
                        </div>
                      </div>
                    </>
                  ) : moduleToChange.module === "podium" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          width: "100%",
                        }}
                      >
                        Configure Podium Integration Settings.
                      </p>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"Select Location"} />
                        </label>
                        <ReactSelect
                          options={podiumLocations ? podiumLocations : []}
                          name={"locationUid"}
                          placeholder={"select location"}
                          onChange={handleSelectChange}
                          value={state.locationUid ? state.locationUid : ""}
                          // classNamePrefix={`${state.error ? 'input_error' : ''} basic-select`}
                          classNamePrefix={`${errorFields["locationUid"]} basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-12 mt-3">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`sendSMSOnTestDriveDone`}
                            name={"sendSMSOnTestDriveDone"}
                            checked={Boolean(state.sendSMSOnTestDriveDone)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`sendSMSOnTestDriveDone`}>
                            <Translate
                              text={
                                "Send survey/feedback sms on test drive done."
                              }
                            />{" "}
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-12 mt-3">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`sendEnquiryFeedback`}
                            name={"sendEnquiryFeedback"}
                            checked={Boolean(state.sendEnquiryFeedback)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`sendEnquiryFeedback`}>
                            <Translate
                              text={"Send Enquiry Feedback (Open/Won/Lost)"}
                            />{" "}
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "driva" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://driva.com.au/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                        {/* Also read 
											<a
												href='https://www.driva.com.au/pss/'
												target='_blank'
												rel="noopener noreferrer"
												style={{ color: '#2b57e2', fontWeight: '600' }}
											>
												{' '}terms
											</a>
											. */}
                      </p>
                      <div className="testdrive-terms-wrap">
                        <div
                          className="terms-list"
                          dangerouslySetInnerHTML={{
                            __html: drivaTC ? drivaTC : "",
                          }}
                          style={{ marginTop: "0px" }}
                        />
                      </div>
                      <div className="check-list w-100 int-driva-check">
                        <div
                          className="checkbox icheck-success w-100"
                          onClick={() =>
                            handleTermsChange(
                              "isTerms",
                              state.isTerms ? !state.isTerms : true
                            )
                          }
                        >
                          <InputCheckBox
                            id="agree-terms"
                            className="switch"
                            name={"isTerms"}
                            checked={Boolean(state.isTerms)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label
                            htmlFor="enable-mfa"
                            style={{ margin: "10px 0px 10px 0px" }}
                          >
                            Do you agree to the terms and conditions?
                          </label>
                        </div>
                        {state.lastTermsAcceptedBy &&
                          state.lastTermsAcceptedByTime &&
                          clientUsers.filter(
                            (a) => a.documentID === state.lastTermsAcceptedBy
                          ).length && (
                            <div
                              className="alert alert-success"
                              role="alert"
                              style={{ margin: "8px 0px 12px 0px" }}
                            >
                              Last Terms Accepted By{" "}
                              <b>
                                {
                                  clientUsers.filter(
                                    (a) =>
                                      a.documentID === state.lastTermsAcceptedBy
                                  )[0].name
                                }
                              </b>{" "}
                              on{" "}
                              {moment(
                                state.lastTermsAcceptedByTime.toDate()
                              ).format("DD/MM/YYYY hh:mm A")}
                            </div>
                          )}
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Partner ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["partnerId"]}`}
                          value={state.partnerId ? state.partnerId : ""}
                          placeholder={"enter partner ID"}
                          onChange={handleChange}
                          name="partnerId"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "facebook" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://facebook.com/"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Campaign"} />
                        </label>
                        <ReactSelect
                          options={data.campaigns}
                          name={"campaign"}
                          placeholder={"select campaign"}
                          onChange={handleSelectChange}
                          value={state.campaign ? state.campaign : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Dealer Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["facebookDealerCode"]}`}
                          value={
                            state.facebookDealerCode
                              ? state.facebookDealerCode
                              : ""
                          }
                          placeholder={"enter dealer code"}
                          onChange={handleChange}
                          name="facebookDealerCode"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"Access Token"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["access_token"]}`}
                          value={state.access_token ? state.access_token : ""}
                          placeholder={"enter access token"}
                          onChange={handleChange}
                          name="access_token"
                        />
                      </div>
                      <div className="form-group col-md-12 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`pushTradeinInbound`}
                            name={"pushTradeinInbound"}
                            checked={
                              _.isBoolean(state.pushTradeinInbound)
                                ? state.pushTradeinInbound
                                : false
                            }
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`pushTradeinInbound`}>
                            <Translate text={"Push Trade-In Pro Inbound"} />
                          </label>
                        </span>
                      </div>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="origin"
                              className="nav-fill"
                            >
                              <Tab eventKey="origin" title="Origin">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Origin"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`target-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["target-kw"]}`}
                                              name="kw"
                                              onChange={handleOnSubChange}
                                              value={addSubList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.origins}
                                              name={"v"}
                                              placeholder={"select origin"}
                                              onChange={(e, data) => {
                                                handleDynamicSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addSubList.v}
                                              classNamePrefix={`${errorFields["target-v"]} basic-select`}
                                              id={`target-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistOriginSave(
                                                  addSubList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {subList.length > 0 ? (
                                        subList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (_.isEmpty(textEditor))
                                                  handleEditSubList(rec);
                                              }}
                                            >
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <InputText
                                                      id={`target-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["target-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditSubChange
                                                      }
                                                      value={editSubList.kw}
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.origins}
                                                      name={"v"}
                                                      placeholder={
                                                        "select origin"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleDynamicSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editSubList.v}
                                                      classNamePrefix={`${errorFields["target-edit-v"]} basic-select`}
                                                      id={`target-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.origins.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.origins.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {textEditor[rec.value] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistOriginSave(
                                                              editSubList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Lead Source"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`leadSource-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["leadSource-kw"]}`}
                                              name="kw"
                                              onChange={
                                                handleLeadSourceSubChange
                                              }
                                              value={addleadSourceList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.leadSources}
                                              name={"v"}
                                              placeholder={"select lead source"}
                                              onChange={(e, data) => {
                                                handleLeadSourceSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addleadSourceList.v}
                                              classNamePrefix={`${errorFields["leadSource-v"]} basic-select`}
                                              id={`leadSource-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistLeadSourceSave(
                                                  addleadSourceList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {leadSourceKWList.length > 0 ? (
                                        leadSourceKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    leadSourcetextEditor
                                                  )
                                                )
                                                  handleEditLeadSourceList(rec);
                                              }}
                                            >
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`leadSource-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["leadSource-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditLeadSourceChange
                                                      }
                                                      value={
                                                        editleadSourceList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.leadSources}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleLeadSourceSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={
                                                        editleadSourceList.v
                                                      }
                                                      classNamePrefix={`${errorFields["leadSource-edit-v"]} basic-select`}
                                                      id={`leadSource-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.leadSources.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.leadSources.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {leadSourcetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistLeadSourceSave(
                                                              editleadSourceList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Campaign"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`campaign-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["campaign-kw"]}`}
                                              name="kw"
                                              onChange={handlecampaignSubChange}
                                              value={addcampaignList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.campaigns}
                                              name={"v"}
                                              placeholder={"select campaign"}
                                              onChange={(e, data) => {
                                                handlecampaignSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addcampaignList.v}
                                              classNamePrefix={`${errorFields["campaign-v"]} basic-select`}
                                              id={`campaign-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistcampaignSave(
                                                  addcampaignList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {campaignKWList.length > 0 ? (
                                        campaignKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(campaigntextEditor)
                                                )
                                                  handleEditcampaignList(rec);
                                              }}
                                            >
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`campaign-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["campaign-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditcampaignChange
                                                      }
                                                      value={
                                                        editcampaignList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.campaigns}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handlecampaignSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editcampaignList.v}
                                                      classNamePrefix={`${errorFields["campaign-edit-v"]} basic-select`}
                                                      id={`campaign-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.campaigns.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.campaigns.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {campaigntextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistcampaignSave(
                                                              editcampaignList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletecampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="tokens" title="Dealer Codes">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="22.5%"
                                        >
                                          {" "}
                                          <Translate text={"Name"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="22.5%"
                                        >
                                          {" "}
                                          <Translate text={"Code"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="22.5%"
                                        >
                                          {" "}
                                          <Translate text={"Token"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="22.5%"
                                        >
                                          {" "}
                                          <Translate text={"Push Trade-In Pro"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`code-name`}
                                              autoComplete="off"
                                              placeholder={"enter name"}
                                              className={`form-control ${errorFields["code-v"]}`}
                                              name="v"
                                              onChange={
                                                handledealercodeSubChange
                                              }
                                              value={adddealercodeList.v}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`code-dealer`}
                                              autoComplete="off"
                                              placeholder={"enter dealer code"}
                                              className={`form-control ${errorFields["code-c"]}`}
                                              name="c"
                                              onChange={
                                                handledealercodeSubChange
                                              }
                                              value={adddealercodeList.c}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`code-token`}
                                              autoComplete="off"
                                              placeholder={"enter token"}
                                              className={`form-control ${errorFields["code-t"]}`}
                                              name="t"
                                              onChange={
                                                handledealercodeSubChange
                                              }
                                              value={adddealercodeList.t}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <span className={`switch switch-sm float-left`}>
                                              <InputCheckBox
                                                className={`switch`}
                                                id={`code-trd`}
                                                name={"trd"}
                                                checked={
                                                  _.isBoolean(adddealercodeList.trd)
                                                    ? adddealercodeList.trd
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  const { name, checked } = e.target;
                                                  //console.log(name, checked);
                                                  setAdddealercodeList({
                                                    ...adddealercodeList,
                                                    [name]: checked,
                                                  });
                                                }}
                                              />
                                              <label htmlFor={`code-trd`}></label>
                                            </span>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistdealercodeSave(
                                                  adddealercodeList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {dealerCodeList.length > 0 ? (
                                        dealerCodeList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    dealercodetextEditor
                                                  )
                                                )
                                                  handleEditdealercodeList(rec);
                                              }}
                                            >
                                              <td>
                                                {dealercodetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`code-edit-v`}
                                                      autoComplete="off"
                                                      placeholder={"enter name"}
                                                      className={`form-control ${errorFields["code-edit-v"]}`}
                                                      name="v"
                                                      onChange={
                                                        handleOnEditdealercodeChange
                                                      }
                                                      value={
                                                        editdealercodeList.v
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.v}</>
                                                )}
                                              </td>
                                              <td>
                                                {dealercodetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`code-edit-c`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter dealer code"
                                                      }
                                                      className={`form-control ${errorFields["code-edit-c"]}`}
                                                      name="c"
                                                      onChange={
                                                        handleOnEditdealercodeChange
                                                      }
                                                      value={
                                                        editdealercodeList.c
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.c}</>
                                                )}
                                              </td>
                                              <td>
                                                {dealercodetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`code-edit-t`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter token"
                                                      }
                                                      className={`form-control ${errorFields["code-edit-t"]}`}
                                                      name="t"
                                                      onChange={
                                                        handleOnEditdealercodeChange
                                                      }
                                                      value={
                                                        editdealercodeList.t
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    {CommonHelper.showLimitedChar(
                                                      rec.t,
                                                      35
                                                    )}
                                                  </>
                                                )}
                                              </td>
                                              <td>
                                                {dealercodetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <span className={`switch switch-sm float-left`}>
                                                      <InputCheckBox
                                                        className={`switch`}
                                                        id={`code-edit-trd`}
                                                        name={"trd"}
                                                        checked={
                                                          _.isBoolean(editdealercodeList.trd)
                                                            ? editdealercodeList.trd
                                                            : false
                                                        }
                                                        onChange={(e) => {
                                                          const { name, checked } = e.target;
                                                          //console.log(name, checked);
                                                          setEditdealercodeList({
                                                            ...editdealercodeList,
                                                            [name]: checked,
                                                          });
                                                        }}
                                                      />
                                                      <label htmlFor={`code-edit-trd`}></label>
                                                    </span>
                                                  </>
                                                ) : (
                                                  <> {rec.trd === true ? 'Yes' : 'No'} </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {dealercodetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistdealercodeSave(
                                                              editdealercodeList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCanceldealercodeList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditdealercodeList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletedealercodeList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="forms" title="Forms">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="30%"
                                        >
                                          {" "}
                                          <Translate text={"Name"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="30%"
                                        >
                                          {" "}
                                          <Translate text={"Form ID"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="30%"
                                        >
                                          {" "}
                                          <Translate text={"Disabled"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`form-name`}
                                              autoComplete="off"
                                              placeholder={"enter name"}
                                              className={`form-control ${errorFields["form-name"]}`}
                                              name="name"
                                              onChange={handleformSubChange}
                                              value={addformList.name}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`form-id`}
                                              autoComplete="off"
                                              placeholder={"enter form id"}
                                              className={`form-control ${errorFields["form-id"]}`}
                                              name="id"
                                              onChange={handleformSubChange}
                                              value={addformList.id}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <span className={`switch switch-sm float-left`}>
                                              <InputCheckBox
                                                className={`switch`}
                                                id={`form-disabled`}
                                                name={"disabled"}
                                                checked={Boolean(addformList.disabled)}
                                                onChange={(e) => {
                                                  handleAddCheckChange(e);
                                                }}
                                              />
                                              <label htmlFor={`form-disabled`}></label>
                                            </span>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistformSave(
                                                  addformList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {formList.length > 0 ? (
                                        formList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(formtextEditor)
                                                )
                                                  handleEditformList(rec);
                                              }}
                                            >
                                              <td>
                                                {formtextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`form-edit-name`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter name"
                                                      }
                                                      className={`form-control ${errorFields["form-edit-name"]}`}
                                                      name="name"
                                                      onChange={
                                                        handleOnEditformChange
                                                      }
                                                      value={
                                                        editformList.name
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.name}</>
                                                )}
                                              </td>
                                              <td>
                                                {formtextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`form-edit-id`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter form id"
                                                      }
                                                      className={`form-control ${errorFields["form-edit-id"]}`}
                                                      name="id"
                                                      onChange={
                                                        handleOnEditformChange
                                                      }
                                                      value={
                                                        editformList.id
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.id}</>
                                                )}
                                              </td>
                                              <td>
                                                {formtextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <span className={`switch switch-sm float-left`}>
                                                      <InputCheckBox
                                                        className={`switch`}
                                                        id={`form-disabled-edit`}
                                                        name={"disabled"}
                                                        checked={Boolean(editformList.disabled)}
                                                        onChange={(e) => {
                                                          handleEditCheckChange(e);
                                                        }}
                                                      />
                                                      <label htmlFor={`form-disabled-edit`}></label>
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>{rec.disabled ? 'Yes' : 'No'}</>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {formtextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistformSave(
                                                              editformList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelformList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditformList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteformList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                            </Tabs>
                          </nav>
                        </div>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`facebook-oem`}
                            name={"showinOEM"}
                            checked={Boolean(state.showinOEM)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`facebook-oem`}>Show in OEM</label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`facebook-region`}
                            name={"showinRegion"}
                            checked={Boolean(state.showinRegion)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`facebook-region`}>
                            Show in Region
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`facebook-group`}
                            name={"showinGroup"}
                            checked={Boolean(state.showinGroup)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`facebook-group`}>
                            Show in Group
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`facebook-client`}
                            name={"hideinClient"}
                            checked={Boolean(state.hideinClient)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`facebook-client`}>
                            Hidden in Client
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-consent`}
                            name={"customerConsent"}
                            checked={Boolean(state.customerConsent)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-consent`}>
                            Customer Consent (Digital)
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-createAutoCampaign`}
                            name={"createAutoCampaign"}
                            checked={Boolean(state.createAutoCampaign)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-createAutoCampaign`}>
                            Create Auto Campaign from Form
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "genericApis" ? (
                    <>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <p
                            style={{
                              margin: "0px auto 15px auto",
                              color: "#0000007a",
                              paddingBottom: "15px",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            Generic Api Description .
                          </p>
                          <nav>
                            <div className="common-table w-100 mt-2">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="head-light"
                                      width="15%"
                                    >
                                      {" "}
                                      <Translate text={"Integration"} />
                                    </th>
                                    {/* <th
                                    scope="col"
                                    className="head-light"
                                  // width="33%"
                                  >
                                    {" "}
                                    <Translate text={"Values"} />
                                  </th> */}
                                    <th
                                      scope="col"
                                      className="head-light"
                                      width="13%"
                                    >
                                      {" "}
                                      <Translate text={"Enquiry Type"} />
                                    </th>
                                    <th
                                      scope="col"
                                      className="head-light"
                                      width="15%"
                                    >
                                      {" "}
                                      <Translate text={"Origin"} />
                                    </th>
                                    <th
                                      scope="col"
                                      className="head-light"
                                      width="15%"
                                    >
                                      {" "}
                                      <Translate text={"Campaign"} />
                                    </th>

                                    <th
                                      scope="col"
                                      className="head-light"
                                      width="5%"
                                    >
                                      {" "}
                                      <Translate text={"Appraisal"} />
                                    </th>
                                    <th
                                      scope="col"
                                      className="head-light border-right-0"
                                      width="15%"
                                    >
                                      {" "}
                                      <Translate text={"Actions"} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="form-group mb-0">
                                        <ReactSelect
                                          options={dealersettings?.integrations?.map(
                                            (integration) => {
                                              return {
                                                label: integration.name,
                                                value: integration.value,
                                              };
                                            }
                                          )}
                                          name={"integration"}
                                          placeholder={"select integration"}
                                          onChange={(e, data) => {
                                            if (e?.value) {
                                              setAddGenericList({
                                                ...addGenericList,
                                                integration: e.value,
                                                name: e.label,
                                                value: e.value,
                                              });
                                            } else {
                                              setAddGenericList({
                                                ...addGenericList,
                                                integration: "",
                                                name: "",
                                                value: "",
                                              });
                                            }
                                          }}
                                          value={
                                            addGenericList?.integration || null
                                          }
                                          classNamePrefix={`${errorFields["target-integration"]} basic-select`}
                                        ></ReactSelect>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group mb-0">
                                        <ReactSelect
                                          options={data.enquiryTypes}
                                          name={"enquiryType"}
                                          placeholder={"select enquiry type"}
                                          onChange={(e, data) => {
                                            setAddGenericList({
                                              ...addGenericList,
                                              enquiryType: e.data.value,
                                            });
                                          }}
                                          value={
                                            addGenericList?.enquiryType || null
                                          }
                                          classNamePrefix={`${state.error ? "input_error" : ""
                                            } basic-select`}
                                        ></ReactSelect>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group mb-0">
                                        <ReactSelect
                                          options={data.origins}
                                          name={"origin"}
                                          placeholder={"select origin"}
                                          onChange={(e, data) => {
                                            setAddGenericList({
                                              ...addGenericList,
                                              origin: e.data.value,
                                            });
                                          }}
                                          value={addGenericList?.origin || null}
                                          classNamePrefix={`${errorFields["target-origin"]} basic-select`}
                                          id={`target-origin`}
                                        ></ReactSelect>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group">
                                        <ReactSelect
                                          options={data.campaigns}
                                          name={"campaign"}
                                          placeholder={"select campaign"}
                                          onChange={(e, data) => {
                                            setAddGenericList({
                                              ...addGenericList,
                                              campaign: e.data.value,
                                            });
                                          }}
                                          value={
                                            addGenericList?.campaign || null
                                          }
                                          classNamePrefix={`${state.error ? "input_error" : ""
                                            } basic-select`}
                                        ></ReactSelect>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group col-md-6 mt-2">
                                        <span
                                          className={`switch switch-sm float-left`}
                                        >
                                          <InputCheckBox
                                            className={`switch`}
                                            id={`source-appraisal`}
                                            name={"appraisal"}
                                            checked={Boolean(
                                              addGenericList.appraisal
                                            )}
                                            onChange={(e, data) => {
                                              setAddGenericList({
                                                ...addGenericList,
                                                appraisal: e.target.checked
                                              });
                                            }}
                                          />
                                          <label htmlFor={`source-appraisal`}>Appraisal</label>
                                        </span>
                                      </div>
                                    </td>

                                    <td className="border-right-0">
                                      <div className="button-height-fix">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => {
                                            handleSublistGenericIntegrationSave(
                                              addGenericList,
                                              true,
                                              null,
                                              false
                                            );
                                          }}
                                        >
                                          {" "}
                                          <Translate text={"add"} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                  {genericList.length > 0 ? (
                                    genericList.map((rec, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          onDoubleClick={(e) => {
                                            e.preventDefault();
                                            if (_.isEmpty(textEditor))
                                              handleEditSubList(rec);
                                          }}
                                        >
                                          <td>
                                            {textEditor[rec.docID] ? (
                                              <>
                                                <ReactSelect
                                                  options={dealersettings?.integrations?.map(
                                                    (integration) => {
                                                      return {
                                                        label: integration.name,
                                                        value:
                                                          integration.value,
                                                      };
                                                    }
                                                  )}
                                                  name={"integration"}
                                                  placeholder={
                                                    "select integration"
                                                  }
                                                  onChange={(e, data) => {
                                                    console.log("test vev", e);
                                                    if (e?.value) {
                                                      setEditSubList({
                                                        ...editSubList,
                                                        integration: e.value,
                                                        name: e.label,
                                                        value: e.value,
                                                      });
                                                    } else {
                                                      setEditSubList({
                                                        ...editSubList,
                                                        integration: "",
                                                        name: "",
                                                        value: "",
                                                      });
                                                    }
                                                  }}
                                                  value={
                                                    editSubList?.value || null
                                                  }
                                                  classNamePrefix={`${errorFields["target-edit-integration"]} basic-select`}
                                                  id={`target-edit-integration`}
                                                ></ReactSelect>
                                              </>
                                            ) : (
                                              <>
                                                {dealersettings?.integrations.filter(
                                                  (a) => a.name === rec.name
                                                ).length
                                                  ? dealersettings?.integrations.filter(
                                                    (a) => a.name === rec.name
                                                  )[0].name
                                                  : rec.name || rec.type || rec.value}
                                              </>
                                            )}
                                          </td>

                                          <td>
                                            {textEditor[rec.docID] ? (
                                              <>
                                                <ReactSelect
                                                  options={data?.enquiryTypes}
                                                  name={"enquiryType"}
                                                  placeholder={
                                                    "select enquiry type"
                                                  }
                                                  onChange={(e, data) => {
                                                    handleDynamicSelectChange(
                                                      e,
                                                      data,
                                                      false
                                                    );
                                                  }}
                                                  value={
                                                    editSubList?.enquiryType
                                                  }
                                                  classNamePrefix={`${errorFields["target-edit-enquiryType"]} basic-select`}
                                                  id={`target-edit-enquiryType`}
                                                ></ReactSelect>
                                              </>
                                            ) : (
                                              <>
                                                {data.enquiryTypes.filter(
                                                  (a) =>
                                                    a.value === rec.enquiryType
                                                ).length
                                                  ? data.enquiryTypes.filter(
                                                    (a) =>
                                                      a.value ===
                                                      rec.enquiryType
                                                  )[0].label
                                                  : rec.enquiryType}
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            {textEditor[rec.docID] ? (
                                              <>
                                                <ReactSelect
                                                  options={data?.origins}
                                                  name={"origin"}
                                                  placeholder={"select origin"}
                                                  onChange={(e, data) => {
                                                    handleDynamicSelectChange(
                                                      e,
                                                      data,
                                                      false
                                                    );
                                                  }}
                                                  value={editSubList?.origin}
                                                  classNamePrefix={`${errorFields["target-edit-origins"]} basic-select`}
                                                  id={`target-edit-origins`}
                                                ></ReactSelect>
                                              </>
                                            ) : (
                                              <>
                                                {data.origins.filter(
                                                  (a) => a.value === rec.origin
                                                ).length
                                                  ? data.origins.filter(
                                                    (a) =>
                                                      a.value === rec.origin
                                                  )[0].label
                                                  : rec.origin}
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            {textEditor[rec.docID] ? (
                                              <>
                                                <ReactSelect
                                                  options={data.campaigns}
                                                  name={"campaign"}
                                                  placeholder={
                                                    "select campaign"
                                                  }
                                                  onChange={(e, data) => {
                                                    handleDynamicSelectChange(
                                                      e,
                                                      data,
                                                      false
                                                    );
                                                  }}
                                                  value={editSubList?.campaign}
                                                  classNamePrefix={`${errorFields["target-edit-campaign"]} basic-select`}
                                                  id={`target-edit-campaign`}
                                                ></ReactSelect>
                                              </>
                                            ) : (
                                              <>
                                                {data.campaigns.filter(
                                                  (a) =>
                                                    a.value === rec.campaign
                                                ).length
                                                  ? data.campaigns.filter(
                                                    (a) =>
                                                      a.value === rec.campaign
                                                  )[0].label
                                                  : rec.campaign}
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            {textEditor[rec.docID] ? (
                                              <div className="form-group col-md-6 mt-2">
                                                <span
                                                  className={`switch switch-sm float-left`}
                                                >
                                                  <InputCheckBox
                                                    className={`switch`}
                                                    id={`sublist-appraisal`}
                                                    name={"appraisal"}
                                                    checked={Boolean(
                                                      editSubList?.appraisal
                                                    )}
                                                    // onChange={handleOnEditSubChange}

                                                    onChange={(e, data) => {
                                                      setEditSubList({
                                                        ...editSubList,
                                                        appraisal: e.target.checked,
                                                      });
                                                    }}
                                                  />
                                                  <label htmlFor={`sublist-appraisal`}>
                                                    Appraisal
                                                  </label>
                                                </span>
                                              </div>
                                            ) : (
                                              <>{rec.appraisal ? "True" : "False"}</>
                                            )}
                                          </td>
                                          <td className="border-right-0">
                                            <div className="d-flex ">
                                              {textEditor[rec?.docID] ? (
                                                <>
                                                  <div className="table-edit float-left mr-2">
                                                    {" "}
                                                    <a
                                                      href="#"
                                                      className="mini-button"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        // handleSublistOriginSave(
                                                        //   editSubList,
                                                        //   false
                                                        // );
                                                        handleSublistGenericIntegrationSave(
                                                          editSubList,
                                                          false,
                                                          rec?.docID
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      <i className="ico icon-check"></i>
                                                    </a>
                                                  </div>
                                                  <div className="table-edit float-left mr-2">
                                                    {" "}
                                                    <a
                                                      href="#"
                                                      className="mini-button"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleCancelSubList(
                                                          rec
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      <i className="ico icon-remove"></i>
                                                    </a>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="table-edit float-left mr-2">
                                                    {/* <button
				                                	type='button'
			                                		className='btn btn-secondary float-left mr-2'
			                                		style={{ padding: `3px 8px`, fontSize: `11px` }}
			                                		onClick={e =>  handleSublistGenericIntegrationSave(
                                            {...rec, enabled: !rec?.enabled,
                                              active: !rec?.active,},
                                            false,
                                            rec?.docID
                                          )}
			                                	>
			                                		{' '}
			                                		<span><Translate text={`${rec?.active ?'Deactive':'Active'}`} /></span>{' '}
			                                	</button> */}
                                                    <span
                                                      className={`switch switch-sm float-left  mr-2`}
                                                    >
                                                      <InputCheckBox
                                                        className={`switch`}
                                                        id={`active-${rec?.docID}`}
                                                        name={"active"}
                                                        checked={Boolean(
                                                          rec?.active
                                                        )}
                                                        onChange={(e) =>
                                                          handleSublistGenericIntegrationSave(
                                                            {
                                                              ...rec,
                                                              enabled:
                                                                !rec?.enabled,
                                                              active:
                                                                !rec?.active,
                                                            },
                                                            false,
                                                            rec?.docID,
                                                            false
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        htmlFor={`active-${rec?.docID}`}
                                                      ></label>
                                                    </span>
                                                    <button
                                                      type="button"
                                                      className="btn btn-secondary float-left mr-2"
                                                      style={{
                                                        padding: `3px 8px`,
                                                        fontSize: `11px`,
                                                      }}
                                                      onClick={(e) =>
                                                        handleEmbedOpen(e, rec)
                                                      }
                                                    >
                                                      {" "}
                                                      <i
                                                        className="fa fa-link custom-icon-preview"
                                                        aria-hidden="true"
                                                        style={{
                                                          paddingTop: "0px",
                                                          marginRight: "2px",
                                                        }}
                                                      ></i>{" "}
                                                    </button>
                                                  </div>
                                                  <div className="table-edit float-left mr-2">
                                                    {" "}
                                                    <a
                                                      href="#"
                                                      className="mini-button"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEditGenericSubList(
                                                          rec
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      <i className="ico icon-edit"></i>
                                                    </a>
                                                  </div>

                                                  <div className="table-edit float-left mr-2">
                                                    {" "}
                                                    <a
                                                      href="#"
                                                      className="mini-button"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDeleteGenericSubList(
                                                          rec
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      <i className="ico icon-delete"></i>
                                                    </a>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </nav>
                        </div>
                      </div>
                      {/* <div className="form-group col-md-6 mt-2">
                      <span className={`switch switch-sm float-left`}>
                        <InputCheckBox
                          className={`switch`}
                          id={`facebook-oem`}
                          name={"showinOEM"}
                          checked={Boolean(state.showinOEM)}
                          onChange={(e) => {
                            handleCheckChange(e);
                          }}
                        />
                        <label htmlFor={`facebook-oem`}>Show in OEM</label>
                      </span>
                    </div>
                    <div className="form-group col-md-6 mt-2">
                      <span className={`switch switch-sm float-left`}>
                        <InputCheckBox
                          className={`switch`}
                          id={`facebook-region`}
                          name={"showinRegion"}
                          checked={Boolean(state.showinRegion)}
                          onChange={(e) => {
                            handleCheckChange(e);
                          }}
                        />
                        <label htmlFor={`facebook-region`}>
                          Show in Region
                        </label>
                      </span>
                    </div>
                    <div className="form-group col-md-6 mt-2">
                      <span className={`switch switch-sm float-left`}>
                        <InputCheckBox
                          className={`switch`}
                          id={`facebook-group`}
                          name={"showinGroup"}
                          checked={Boolean(state.showinGroup)}
                          onChange={(e) => {
                            handleCheckChange(e);
                          }}
                        />
                        <label htmlFor={`facebook-group`}>Show in Group</label>
                      </span>
                    </div>
                    <div className="form-group col-md-6 mt-2">
                      <span className={`switch switch-sm float-left`}>
                        <InputCheckBox
                          className={`switch`}
                          id={`facebook-client`}
                          name={"hideinClient"}
                          checked={Boolean(state.hideinClient)}
                          onChange={(e) => {
                            handleCheckChange(e);
                          }}
                        />
                        <label htmlFor={`facebook-client`}>
                          Hidden in Client
                        </label>
                      </span>
                    </div>
                    <div className="form-group col-md-6 mt-2">
                      <span className={`switch switch-sm float-left`}>
                        <InputCheckBox
                          className={`switch`}
                          id={`${moduleToChange.module}-consent`}
                          name={"customerConsent"}
                          checked={Boolean(state.customerConsent)}
                          onChange={(e) => {
                            handleCheckChange(e);
                          }}
                        />
                        <label htmlFor={`${moduleToChange.module}-consent`}>
                          Customer Consent (Digital)
                        </label>
                      </span>
                    </div> */}
                    </>
                  ) : moduleToChange.module === "autograb" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://docs.autograb.com.au/"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter api key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-12 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`fetch-reg-status`}
                            name={"fetchRegStatus"}
                            checked={Boolean(state.fetchRegStatus)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`fetch-reg-status`}>
                            Include Reg Status check in vehicle search
                          </label>
                        </span>
                      </div>
                      <div className="autograb-subscription-user-tablehead mt-2">
                        Additional Factors
                      </div>
                      <CustomTable
                        dealersettings={dealersettings}
                        cols={autograbDynCols}
                        subList={state.subList}
                        setSubList={(list) => { setState({ ...state, subList: list }) }}
                      ></CustomTable>
                    </>
                  ) : moduleToChange.module === "mailchimp" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://mailchimp.com"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-12 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`pushTemplates`}
                            name={"pushTemplates"}
                            checked={
                              _.isBoolean(state.pushTemplates)
                                ? state.pushTemplates
                                : false
                            }
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`pushTemplates`}>
                            <Translate text={"Push Email Templates"} />
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "mailjet" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://dev.mailjet.com/"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Secret"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_secret"]}`}
                          value={state.api_secret ? state.api_secret : ""}
                          placeholder={"enter api secret"}
                          onChange={handleChange}
                          name="api_secret"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Sender Name"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["senderName"]}`}
                          value={state.senderName ? state.senderName : ""}
                          placeholder={"enter sender name"}
                          onChange={handleChange}
                          name="senderName"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Sender Email"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["senderEmail"]}`}
                          value={state.senderEmail ? state.senderEmail : ""}
                          placeholder={"enter sender email"}
                          onChange={handleChange}
                          name="senderEmail"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "mailup" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://mailup.com"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_id"]}`}
                          value={state.client_id ? state.client_id : ""}
                          placeholder={"enter client id"}
                          onChange={handleChange}
                          name="client_id"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client Secret"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_secret"]}`}
                          value={state.client_secret ? state.client_secret : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="client_secret"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "vision6" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://www.vision6.com.au"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "evalexpert" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://www.evalexpert.com"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"User Email"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["userEmail"]}`}
                          value={state.userEmail ? state.userEmail : ""}
                          placeholder={"enter user email"}
                          onChange={handleChange}
                          name="userEmail"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api"]}`}
                          value={state.api ? state.api : ""}
                          placeholder={"enter api"}
                          onChange={handleChange}
                          name="api"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["apiKey"]}`}
                          value={state.apiKey ? state.apiKey : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="apiKey"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "nylas" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                        }}
                      >
                        You can get more information about nylas from{" "}
                        <a
                          href="https://www.nylas.com/products/email-api/"
                          target="_blank"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="subscription-box-wrapper">
                        <div className="subscription-box-left">
                          <h4> Your Subscription</h4>
                          <table
                            width="100%"
                            cellSpacing="0"
                            cellPadding="0"
                            border="0"
                          >
                            <tbody>
                              <tr>
                                <td width="90">
                                  <div className="subscription-users-input">
                                    <input
                                      type="text"
                                      className={`form-control ${errorFields["nylasSeats"]}`}
                                      value={
                                        state.nylasSeats ? state.nylasSeats : ""
                                      }
                                      placeholder={""}
                                      onChange={handleChange}
                                      name="nylasSeats"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <span className="subscription-user-title">
                                    Seats
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <p>
                            We charge you at the end of each month according to
                            the number of subscribed users in the system. Once a
                            subscription expires, the user should lose access to
                            the integration.{" "}
                          </p>
                        </div>
                        <div className="subscription-box-right">
                          <div className="subscription-price-wrapper">
                            <h3>${state.nylasSeats ? state.nylasSeats : 0}</h3>
                            <h5>Per Month</h5>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : moduleToChange.module === "smshub" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://axonme.com/index.html"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Url"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api"]}`}
                          value={state.api ? state.api : ""}
                          placeholder={"enter API Url"}
                          onChange={handleChange}
                          name="api"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"User Name"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["username"]}`}
                          value={state.username ? state.username : ""}
                          placeholder={"enter username"}
                          onChange={handleChange}
                          name="username"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Sender ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["sender"]}`}
                          value={state.sender ? state.sender : ""}
                          placeholder={"enter sender"}
                          onChange={handleChange}
                          name="sender"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "cmdotcom" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://cm.com/"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Sender ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["sender"]}`}
                          value={state.sender ? state.sender : ""}
                          placeholder={"enter sender"}
                          onChange={handleChange}
                          name="sender"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "sms_broadcast" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://www.smsbroadcast.com.au/"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Sender ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["sender_id"]}`}
                          value={state.sender_id ? state.sender_id : ""}
                          placeholder={"enter sender ID"}
                          onChange={handleChange}
                          name="sender_id"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "autogate" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Lead Source"} />
                        </label>
                        <ReactSelect
                          options={data.leadSources}
                          name={"leadSource"}
                          placeholder={"select lead source"}
                          onChange={handleSelectChange}
                          value={state.leadSource ? state.leadSource : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Seller Identifier"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control`}
                          value={
                            state.sellerIdentifier ? state.sellerIdentifier : ""
                          }
                          placeholder={"enter seller identifier"}
                          onChange={handleChange}
                          name="sellerIdentifier"
                        />
                      </div>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="origin"
                              className="nav-fill"
                            >
                              <Tab eventKey="origin" title="Origin">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Origin"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`target-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["target-kw"]}`}
                                              name="kw"
                                              onChange={handleOnSubChange}
                                              value={addSubList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.origins}
                                              name={"v"}
                                              placeholder={"select origin"}
                                              onChange={(e, data) => {
                                                handleDynamicSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addSubList.v}
                                              classNamePrefix={`${errorFields["target-v"]} basic-select`}
                                              id={`target-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistOriginSave(
                                                  addSubList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {subList.length > 0 ? (
                                        subList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (_.isEmpty(textEditor))
                                                  handleEditSubList(rec);
                                              }}
                                            >
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <InputText
                                                      id={`target-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["target-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditSubChange
                                                      }
                                                      value={editSubList.kw}
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.origins}
                                                      name={"v"}
                                                      placeholder={
                                                        "select origin"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleDynamicSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editSubList.v}
                                                      classNamePrefix={`${errorFields["target-edit-v"]} basic-select`}
                                                      id={`target-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.origins.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.origins.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {textEditor[rec.value] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistOriginSave(
                                                              editSubList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Lead Source"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`leadSource-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["leadSource-kw"]}`}
                                              name="kw"
                                              onChange={
                                                handleLeadSourceSubChange
                                              }
                                              value={addleadSourceList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.leadSources}
                                              name={"v"}
                                              placeholder={"select lead source"}
                                              onChange={(e, data) => {
                                                handleLeadSourceSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addleadSourceList.v}
                                              classNamePrefix={`${errorFields["leadSource-v"]} basic-select`}
                                              id={`leadSource-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistLeadSourceSave(
                                                  addleadSourceList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {leadSourceKWList.length > 0 ? (
                                        leadSourceKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    leadSourcetextEditor
                                                  )
                                                )
                                                  handleEditLeadSourceList(rec);
                                              }}
                                            >
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`leadSource-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["leadSource-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditLeadSourceChange
                                                      }
                                                      value={
                                                        editleadSourceList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.leadSources}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleLeadSourceSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={
                                                        editleadSourceList.v
                                                      }
                                                      classNamePrefix={`${errorFields["leadSource-edit-v"]} basic-select`}
                                                      id={`leadSource-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.leadSources.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.leadSources.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {leadSourcetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistLeadSourceSave(
                                                              editleadSourceList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Campaign"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`campaign-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["campaign-kw"]}`}
                                              name="kw"
                                              onChange={handlecampaignSubChange}
                                              value={addcampaignList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.campaigns}
                                              name={"v"}
                                              placeholder={"select campaign"}
                                              onChange={(e, data) => {
                                                handlecampaignSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addcampaignList.v}
                                              classNamePrefix={`${errorFields["campaign-v"]} basic-select`}
                                              id={`campaign-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistcampaignSave(
                                                  addcampaignList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {campaignKWList.length > 0 ? (
                                        campaignKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(campaigntextEditor)
                                                )
                                                  handleEditcampaignList(rec);
                                              }}
                                            >
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`campaign-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["campaign-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditcampaignChange
                                                      }
                                                      value={
                                                        editcampaignList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.campaigns}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handlecampaignSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editcampaignList.v}
                                                      classNamePrefix={`${errorFields["campaign-edit-v"]} basic-select`}
                                                      id={`campaign-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.campaigns.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.campaigns.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {campaigntextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistcampaignSave(
                                                              editcampaignList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletecampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                            </Tabs>
                          </nav>
                        </div>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`autogate-oem`}
                            name={"showinOEM"}
                            checked={Boolean(state.showinOEM)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`autogate-oem`}>Show in OEM</label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`autogate-region`}
                            name={"showinRegion"}
                            checked={Boolean(state.showinRegion)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`autogate-region`}>
                            Show in Region
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`autogate-group`}
                            name={"showinGroup"}
                            checked={Boolean(state.showinGroup)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`autogate-group`}>
                            Show in Group
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`autogate-client`}
                            name={"hideinClient"}
                            checked={Boolean(state.hideinClient)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`autogate-client`}>
                            Hidden in Client
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-consent`}
                            name={"customerConsent"}
                            checked={Boolean(state.customerConsent)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-consent`}>
                            Customer Consent (Digital)
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "autotrader" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["Code"]}`}
                          value={state.Code ? state.Code : ""}
                          placeholder={"enter Code"}
                          onChange={handleChange}
                          name="Code"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Email From"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["EmailFrom"]}`}
                          value={state.EmailFrom ? state.EmailFrom : ""}
                          placeholder={"enter email from"}
                          onChange={handleChange}
                          name="EmailFrom"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Last N Days"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["LastNDays"]}`}
                          value={state.LastNDays ? state.LastNDays : ""}
                          placeholder={"enter last n days"}
                          onChange={handleChange}
                          name="LastNDays"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Check Subject"} />{" "}
                        </label>
                        <div
                          className={`integration-switch ${errorFields["checkSubject"]}`}
                        >
                          <span className="switch switch-sm float-right">
                            <InputCheckBox
                              className="switch"
                              id={"checkSubject"}
                              name={"checkSubject"}
                              checked={
                                _.isBoolean(state.checkSubject)
                                  ? state.checkSubject
                                  : false
                              }
                              onChange={(e) => {
                                handleCheckChange(e);
                              }}
                            />
                            <label htmlFor={"checkSubject"}></label>
                          </span>
                        </div>
                      </div>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="origin"
                              className="nav-fill"
                            >
                              <Tab eventKey="origin" title="Origin">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Origin"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`target-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["target-kw"]}`}
                                              name="kw"
                                              onChange={handleOnSubChange}
                                              value={addSubList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.origins}
                                              name={"v"}
                                              placeholder={"select origin"}
                                              onChange={(e, data) => {
                                                handleDynamicSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addSubList.v}
                                              classNamePrefix={`${errorFields["target-v"]} basic-select`}
                                              id={`target-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistOriginSave(
                                                  addSubList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {subList.length > 0 ? (
                                        subList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (_.isEmpty(textEditor))
                                                  handleEditSubList(rec);
                                              }}
                                            >
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <InputText
                                                      id={`target-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["target-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditSubChange
                                                      }
                                                      value={editSubList.kw}
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.origins}
                                                      name={"v"}
                                                      placeholder={
                                                        "select origin"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleDynamicSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editSubList.v}
                                                      classNamePrefix={`${errorFields["target-edit-v"]} basic-select`}
                                                      id={`target-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.origins.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.origins.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {textEditor[rec.value] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistOriginSave(
                                                              editSubList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Lead Source"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`leadSource-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["leadSource-kw"]}`}
                                              name="kw"
                                              onChange={
                                                handleLeadSourceSubChange
                                              }
                                              value={addleadSourceList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.leadSources}
                                              name={"v"}
                                              placeholder={"select lead source"}
                                              onChange={(e, data) => {
                                                handleLeadSourceSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addleadSourceList.v}
                                              classNamePrefix={`${errorFields["leadSource-v"]} basic-select`}
                                              id={`leadSource-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistLeadSourceSave(
                                                  addleadSourceList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {leadSourceKWList.length > 0 ? (
                                        leadSourceKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    leadSourcetextEditor
                                                  )
                                                )
                                                  handleEditLeadSourceList(rec);
                                              }}
                                            >
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`leadSource-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["leadSource-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditLeadSourceChange
                                                      }
                                                      value={
                                                        editleadSourceList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.leadSources}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleLeadSourceSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={
                                                        editleadSourceList.v
                                                      }
                                                      classNamePrefix={`${errorFields["leadSource-edit-v"]} basic-select`}
                                                      id={`leadSource-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.leadSources.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.leadSources.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {leadSourcetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistLeadSourceSave(
                                                              editleadSourceList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Campaign"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`campaign-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["campaign-kw"]}`}
                                              name="kw"
                                              onChange={handlecampaignSubChange}
                                              value={addcampaignList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.campaigns}
                                              name={"v"}
                                              placeholder={"select campaign"}
                                              onChange={(e, data) => {
                                                handlecampaignSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addcampaignList.v}
                                              classNamePrefix={`${errorFields["campaign-v"]} basic-select`}
                                              id={`campaign-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistcampaignSave(
                                                  addcampaignList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {campaignKWList.length > 0 ? (
                                        campaignKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(campaigntextEditor)
                                                )
                                                  handleEditcampaignList(rec);
                                              }}
                                            >
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`campaign-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["campaign-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditcampaignChange
                                                      }
                                                      value={
                                                        editcampaignList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.campaigns}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handlecampaignSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editcampaignList.v}
                                                      classNamePrefix={`${errorFields["campaign-edit-v"]} basic-select`}
                                                      id={`campaign-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.campaigns.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.campaigns.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {campaigntextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistcampaignSave(
                                                              editcampaignList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletecampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                            </Tabs>
                          </nav>
                        </div>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`autotrader-oem`}
                            name={"showinOEM"}
                            checked={Boolean(state.showinOEM)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`autotrader-oem`}>Show in OEM</label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`autotrader-region`}
                            name={"showinRegion"}
                            checked={Boolean(state.showinRegion)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`autotrader-region`}>
                            Show in Region
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`autotrader-group`}
                            name={"showinGroup"}
                            checked={Boolean(state.showinGroup)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`autotrader-group`}>
                            Show in Group
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`autotrader-client`}
                            name={"hideinClient"}
                            checked={Boolean(state.hideinClient)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`autotrader-client`}>
                            Hidden in Client
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-consent`}
                            name={"customerConsent"}
                            checked={Boolean(state.customerConsent)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-consent`}>
                            Customer Consent (Digital)
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "dealersolutions" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["Code"]}`}
                          value={state.Code ? state.Code : ""}
                          placeholder={"enter Code"}
                          onChange={handleChange}
                          name="Code"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Email From"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["EmailFrom"]}`}
                          value={state.EmailFrom ? state.EmailFrom : ""}
                          placeholder={"enter email from"}
                          onChange={handleChange}
                          name="EmailFrom"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Last N Days"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["LastNDays"]}`}
                          value={state.LastNDays ? state.LastNDays : ""}
                          placeholder={"enter last n days"}
                          onChange={handleChange}
                          name="LastNDays"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Check Subject"} />{" "}
                        </label>
                        <div
                          className={`integration-switch ${errorFields["checkSubject"]}`}
                        >
                          <span className="switch switch-sm float-right">
                            <InputCheckBox
                              className="switch"
                              id={"checkSubject"}
                              name={"checkSubject"}
                              checked={
                                _.isBoolean(state.checkSubject)
                                  ? state.checkSubject
                                  : false
                              }
                              onChange={(e) => {
                                handleCheckChange(e);
                              }}
                            />
                            <label htmlFor={"checkSubject"}></label>
                          </span>
                        </div>
                      </div>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="origin"
                              className="nav-fill"
                            >
                              <Tab eventKey="origin" title="Origin">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Origin"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`target-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["target-kw"]}`}
                                              name="kw"
                                              onChange={handleOnSubChange}
                                              value={addSubList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.origins}
                                              name={"v"}
                                              placeholder={"select origin"}
                                              onChange={(e, data) => {
                                                handleDynamicSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addSubList.v}
                                              classNamePrefix={`${errorFields["target-v"]} basic-select`}
                                              id={`target-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistOriginSave(
                                                  addSubList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {subList.length > 0 ? (
                                        subList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (_.isEmpty(textEditor))
                                                  handleEditSubList(rec);
                                              }}
                                            >
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <InputText
                                                      id={`target-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["target-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditSubChange
                                                      }
                                                      value={editSubList.kw}
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.origins}
                                                      name={"v"}
                                                      placeholder={
                                                        "select origin"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleDynamicSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editSubList.v}
                                                      classNamePrefix={`${errorFields["target-edit-v"]} basic-select`}
                                                      id={`target-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.origins.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.origins.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {textEditor[rec.value] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistOriginSave(
                                                              editSubList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Lead Source"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`leadSource-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["leadSource-kw"]}`}
                                              name="kw"
                                              onChange={
                                                handleLeadSourceSubChange
                                              }
                                              value={addleadSourceList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.leadSources}
                                              name={"v"}
                                              placeholder={"select lead source"}
                                              onChange={(e, data) => {
                                                handleLeadSourceSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addleadSourceList.v}
                                              classNamePrefix={`${errorFields["leadSource-v"]} basic-select`}
                                              id={`leadSource-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistLeadSourceSave(
                                                  addleadSourceList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {leadSourceKWList.length > 0 ? (
                                        leadSourceKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    leadSourcetextEditor
                                                  )
                                                )
                                                  handleEditLeadSourceList(rec);
                                              }}
                                            >
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`leadSource-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["leadSource-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditLeadSourceChange
                                                      }
                                                      value={
                                                        editleadSourceList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.leadSources}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleLeadSourceSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={
                                                        editleadSourceList.v
                                                      }
                                                      classNamePrefix={`${errorFields["leadSource-edit-v"]} basic-select`}
                                                      id={`leadSource-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.leadSources.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.leadSources.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {leadSourcetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistLeadSourceSave(
                                                              editleadSourceList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Campaign"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`campaign-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["campaign-kw"]}`}
                                              name="kw"
                                              onChange={handlecampaignSubChange}
                                              value={addcampaignList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.campaigns}
                                              name={"v"}
                                              placeholder={"select campaign"}
                                              onChange={(e, data) => {
                                                handlecampaignSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addcampaignList.v}
                                              classNamePrefix={`${errorFields["campaign-v"]} basic-select`}
                                              id={`campaign-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistcampaignSave(
                                                  addcampaignList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {campaignKWList.length > 0 ? (
                                        campaignKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(campaigntextEditor)
                                                )
                                                  handleEditcampaignList(rec);
                                              }}
                                            >
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`campaign-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["campaign-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditcampaignChange
                                                      }
                                                      value={
                                                        editcampaignList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.campaigns}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handlecampaignSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editcampaignList.v}
                                                      classNamePrefix={`${errorFields["campaign-edit-v"]} basic-select`}
                                                      id={`campaign-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.campaigns.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.campaigns.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {campaigntextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistcampaignSave(
                                                              editcampaignList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletecampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                            </Tabs>
                          </nav>
                        </div>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`dealersolutions-oem`}
                            name={"showinOEM"}
                            checked={Boolean(state.showinOEM)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`dealersolutions-oem`}>
                            Show in OEM
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`dealersolutions-region`}
                            name={"showinRegion"}
                            checked={Boolean(state.showinRegion)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`dealersolutions-region`}>
                            Show in Region
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`dealersolutions-group`}
                            name={"showinGroup"}
                            checked={Boolean(state.showinGroup)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`dealersolutions-group`}>
                            Show in Group
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`dealersolutions-client`}
                            name={"hideinClient"}
                            checked={Boolean(state.hideinClient)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`dealersolutions-client`}>
                            Hidden in Client
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-consent`}
                            name={"customerConsent"}
                            checked={Boolean(state.customerConsent)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-consent`}>
                            Customer Consent (Digital)
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "drivechat" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["Code"]}`}
                          value={state.Code ? state.Code : ""}
                          placeholder={"enter Code"}
                          onChange={handleChange}
                          name="Code"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Email From"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["EmailFrom"]}`}
                          value={state.EmailFrom ? state.EmailFrom : ""}
                          placeholder={"enter email from"}
                          onChange={handleChange}
                          name="EmailFrom"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Last N Days"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["LastNDays"]}`}
                          value={state.LastNDays ? state.LastNDays : ""}
                          placeholder={"enter last n days"}
                          onChange={handleChange}
                          name="LastNDays"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Check Subject"} />{" "}
                        </label>
                        <div
                          className={`integration-switch ${errorFields["checkSubject"]}`}
                        >
                          <span className="switch switch-sm float-right">
                            <InputCheckBox
                              className="switch"
                              id={"checkSubject"}
                              name={"checkSubject"}
                              checked={
                                _.isBoolean(state.checkSubject)
                                  ? state.checkSubject
                                  : false
                              }
                              onChange={(e) => {
                                handleCheckChange(e);
                              }}
                            />
                            <label htmlFor={"checkSubject"}></label>
                          </span>
                        </div>
                      </div>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="origin"
                              className="nav-fill"
                            >
                              <Tab eventKey="origin" title="Origin">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Origin"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`target-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["target-kw"]}`}
                                              name="kw"
                                              onChange={handleOnSubChange}
                                              value={addSubList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.origins}
                                              name={"v"}
                                              placeholder={"select origin"}
                                              onChange={(e, data) => {
                                                handleDynamicSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addSubList.v}
                                              classNamePrefix={`${errorFields["target-v"]} basic-select`}
                                              id={`target-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistOriginSave(
                                                  addSubList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {subList.length > 0 ? (
                                        subList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (_.isEmpty(textEditor))
                                                  handleEditSubList(rec);
                                              }}
                                            >
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <InputText
                                                      id={`target-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["target-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditSubChange
                                                      }
                                                      value={editSubList.kw}
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.origins}
                                                      name={"v"}
                                                      placeholder={
                                                        "select origin"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleDynamicSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editSubList.v}
                                                      classNamePrefix={`${errorFields["target-edit-v"]} basic-select`}
                                                      id={`target-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.origins.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.origins.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {textEditor[rec.value] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistOriginSave(
                                                              editSubList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Lead Source"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`leadSource-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["leadSource-kw"]}`}
                                              name="kw"
                                              onChange={
                                                handleLeadSourceSubChange
                                              }
                                              value={addleadSourceList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.leadSources}
                                              name={"v"}
                                              placeholder={"select lead Source"}
                                              onChange={(e, data) => {
                                                handleLeadSourceSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addleadSourceList.v}
                                              classNamePrefix={`${errorFields["leadSource-v"]} basic-select`}
                                              id={`leadSource-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistLeadSourceSave(
                                                  addleadSourceList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {leadSourceKWList.length > 0 ? (
                                        leadSourceKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    leadSourcetextEditor
                                                  )
                                                )
                                                  handleEditLeadSourceList(rec);
                                              }}
                                            >
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`leadSource-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["leadSource-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditLeadSourceChange
                                                      }
                                                      value={
                                                        editleadSourceList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.leadSources}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleLeadSourceSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={
                                                        editleadSourceList.v
                                                      }
                                                      classNamePrefix={`${errorFields["leadSource-edit-v"]} basic-select`}
                                                      id={`leadSource-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.leadSources.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.leadSources.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {leadSourcetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistLeadSourceSave(
                                                              editleadSourceList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Campaign"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`campaign-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["campaign-kw"]}`}
                                              name="kw"
                                              onChange={handlecampaignSubChange}
                                              value={addcampaignList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.campaigns}
                                              name={"v"}
                                              placeholder={"select campaign"}
                                              onChange={(e, data) => {
                                                handlecampaignSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addcampaignList.v}
                                              classNamePrefix={`${errorFields["campaign-v"]} basic-select`}
                                              id={`campaign-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistcampaignSave(
                                                  addcampaignList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {campaignKWList.length > 0 ? (
                                        campaignKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(campaigntextEditor)
                                                )
                                                  handleEditcampaignList(rec);
                                              }}
                                            >
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`campaign-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["campaign-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditcampaignChange
                                                      }
                                                      value={
                                                        editcampaignList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.campaigns}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handlecampaignSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editcampaignList.v}
                                                      classNamePrefix={`${errorFields["campaign-edit-v"]} basic-select`}
                                                      id={`campaign-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.campaigns.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.campaigns.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {campaigntextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistcampaignSave(
                                                              editcampaignList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletecampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                            </Tabs>
                          </nav>
                        </div>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`drivechat-oem`}
                            name={"showinOEM"}
                            checked={Boolean(state.showinOEM)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`drivechat-oem`}>Show in OEM</label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`drivechat-region`}
                            name={"showinRegion"}
                            checked={Boolean(state.showinRegion)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`drivechat-region`}>
                            Show in Region
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`drivechat-group`}
                            name={"showinGroup"}
                            checked={Boolean(state.showinGroup)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`drivechat-group`}>
                            Show in Group
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`drivechat-client`}
                            name={"hideinClient"}
                            checked={Boolean(state.hideinClient)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`drivechat-client`}>
                            Hidden in Client
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-consent`}
                            name={"customerConsent"}
                            checked={Boolean(state.customerConsent)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-consent`}>
                            Customer Consent (Digital)
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "carsguide" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["Code"]}`}
                          value={state.Code ? state.Code : ""}
                          placeholder={"enter Code"}
                          onChange={handleChange}
                          name="Code"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Email From"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["EmailFrom"]}`}
                          value={state.EmailFrom ? state.EmailFrom : ""}
                          placeholder={"enter email from"}
                          onChange={handleChange}
                          name="EmailFrom"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Last N Days"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["LastNDays"]}`}
                          value={state.LastNDays ? state.LastNDays : ""}
                          placeholder={"enter last n days"}
                          onChange={handleChange}
                          name="LastNDays"
                        />
                      </div>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="origin"
                              className="nav-fill"
                            >
                              <Tab eventKey="origin" title="Origin">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Origin"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`target-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["target-kw"]}`}
                                              name="kw"
                                              onChange={handleOnSubChange}
                                              value={addSubList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.origins}
                                              name={"v"}
                                              placeholder={"select origin"}
                                              onChange={(e, data) => {
                                                handleDynamicSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addSubList.v}
                                              classNamePrefix={`${errorFields["target-v"]} basic-select`}
                                              id={`target-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistOriginSave(
                                                  addSubList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {subList.length > 0 ? (
                                        subList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (_.isEmpty(textEditor))
                                                  handleEditSubList(rec);
                                              }}
                                            >
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <InputText
                                                      id={`target-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["target-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditSubChange
                                                      }
                                                      value={editSubList.kw}
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.origins}
                                                      name={"v"}
                                                      placeholder={
                                                        "select origin"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleDynamicSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editSubList.v}
                                                      classNamePrefix={`${errorFields["target-edit-v"]} basic-select`}
                                                      id={`target-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.origins.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.origins.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {textEditor[rec.value] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistOriginSave(
                                                              editSubList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Lead Source"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`leadSource-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["leadSource-kw"]}`}
                                              name="kw"
                                              onChange={
                                                handleLeadSourceSubChange
                                              }
                                              value={addleadSourceList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.leadSources}
                                              name={"v"}
                                              placeholder={"select lead source"}
                                              onChange={(e, data) => {
                                                handleLeadSourceSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addleadSourceList.v}
                                              classNamePrefix={`${errorFields["leadSource-v"]} basic-select`}
                                              id={`leadSource-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistLeadSourceSave(
                                                  addleadSourceList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {leadSourceKWList.length > 0 ? (
                                        leadSourceKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    leadSourcetextEditor
                                                  )
                                                )
                                                  handleEditLeadSourceList(rec);
                                              }}
                                            >
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`leadSource-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["leadSource-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditLeadSourceChange
                                                      }
                                                      value={
                                                        editleadSourceList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.leadSources}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleLeadSourceSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={
                                                        editleadSourceList.v
                                                      }
                                                      classNamePrefix={`${errorFields["leadSource-edit-v"]} basic-select`}
                                                      id={`leadSource-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.leadSources.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.leadSources.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {leadSourcetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistLeadSourceSave(
                                                              editleadSourceList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Campaign"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`campaign-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["campaign-kw"]}`}
                                              name="kw"
                                              onChange={handlecampaignSubChange}
                                              value={addcampaignList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.campaigns}
                                              name={"v"}
                                              placeholder={"select campaign"}
                                              onChange={(e, data) => {
                                                handlecampaignSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addcampaignList.v}
                                              classNamePrefix={`${errorFields["campaign-v"]} basic-select`}
                                              id={`campaign-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistcampaignSave(
                                                  addcampaignList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {campaignKWList.length > 0 ? (
                                        campaignKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(campaigntextEditor)
                                                )
                                                  handleEditcampaignList(rec);
                                              }}
                                            >
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`campaign-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["campaign-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditcampaignChange
                                                      }
                                                      value={
                                                        editcampaignList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.campaigns}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handlecampaignSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editcampaignList.v}
                                                      classNamePrefix={`${errorFields["campaign-edit-v"]} basic-select`}
                                                      id={`campaign-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.campaigns.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.campaigns.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {campaigntextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistcampaignSave(
                                                              editcampaignList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletecampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                            </Tabs>
                          </nav>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Check Subject"} />{" "}
                        </label>
                        <div
                          className={`integration-switch ${errorFields["checkSubject"]}`}
                        >
                          <span className="switch switch-sm float-right">
                            <InputCheckBox
                              className="switch"
                              id={"checkSubject"}
                              name={"checkSubject"}
                              checked={
                                _.isBoolean(state.checkSubject)
                                  ? state.checkSubject
                                  : false
                              }
                              onChange={(e) => {
                                handleCheckChange(e);
                              }}
                            />
                            <label htmlFor={"checkSubject"}></label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`carsguide-oem`}
                            name={"showinOEM"}
                            checked={Boolean(state.showinOEM)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`carsguide-oem`}>Show in OEM</label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`carsguide-region`}
                            name={"showinRegion"}
                            checked={Boolean(state.showinRegion)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`carsguide-region`}>
                            Show in Region
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`carsguide-group`}
                            name={"showinGroup"}
                            checked={Boolean(state.showinGroup)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`carsguide-group`}>
                            Show in Group
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`carsguide-client`}
                            name={"hideinClient"}
                            checked={Boolean(state.hideinClient)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`carsguide-client`}>
                            Hidden in Client
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-consent`}
                            name={"customerConsent"}
                            checked={Boolean(state.customerConsent)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-consent`}>
                            Customer Consent (Digital)
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "erapower" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"X Connection"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["xConnection"]}`}
                          value={state.xConnection ? state.xConnection : ""}
                          placeholder={"enter x connection"}
                          onChange={handleChange}
                          name="xConnection"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Lead Source"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["leadSource"]}`}
                          value={state.leadSource ? state.leadSource : ""}
                          placeholder={"enter lead source"}
                          onChange={handleChange}
                          name="leadSource"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"Lead Source ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["leadSourceID"]}`}
                          value={state.leadSourceID ? state.leadSourceID : ""}
                          placeholder={"enter lead source id"}
                          onChange={handleChange}
                          name="leadSourceID"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Username"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["username"]}`}
                          value={state.username ? state.username : ""}
                          placeholder={"enter username"}
                          onChange={handleChange}
                          name="username"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Password"} />{" "}
                        </label>
                        <input
                          type="password"
                          className={`form-control ${errorFields["password"]}`}
                          value={state.password ? state.password : ""}
                          placeholder={"enter password"}
                          onChange={handleChange}
                          name="password"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "ppsr" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://transact.ppsr.gov.au/ppsr/Login"
                          target="_blank"
                          style={{
                            color: "#2b57e2",
                            fontWeight: "600",
                            width: "100%",
                          }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Username"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["username"]}`}
                          value={state.username ? state.username : ""}
                          placeholder={"enter username"}
                          onChange={handleChange}
                          name="username"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Password"} />{" "}
                        </label>
                        <input
                          type="password"
                          className={`form-control ${errorFields["password"]}`}
                          value={state.password ? state.password : ""}
                          placeholder={"enter password"}
                          onChange={handleChange}
                          name="password"
                        />
                      </div>
                      {/* <div className='form-group col-md-6'>
											<label>
												<Translate text={'Environment'} />{' '}
											</label>
											<input
												type='environment'
												className={`form-control ${errorFields['environment']}`}
												value={state.environment ? state.environment : ''}
												placeholder={'enter environment'}
												onChange={handleChange}
												name='environment'
											/>
										</div>
										<div className='form-group col-md-6'>
											<label>
												<Translate text={'Endpoint'} />{' '}
											</label>
											<input
												type='endpoint'
												className={`form-control ${errorFields['endpoint']}`}
												value={state.endpoint ? state.endpoint : ''}
												placeholder={'enter endpoint'}
												onChange={handleChange}
												name='endpoint'
											/>
										</div> */}
                    </>
                  ) : moduleToChange.module === "emailIntg" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Campaign"} />
                        </label>
                        <ReactSelect
                          options={data.campaigns}
                          name={"campaign"}
                          placeholder={"select campaign"}
                          onChange={handleSelectChange}
                          value={state.campaign ? state.campaign : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Lead Source"} />
                        </label>
                        <ReactSelect
                          options={data.leadSources}
                          name={"leadSource"}
                          placeholder={"select lead source"}
                          onChange={handleSelectChange}
                          value={state.leadSource ? state.leadSource : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      {
                        hideEmailFields ? (<></>) : (
                          <>
                            <div className="form-group col-md-6">
                              <label>
                                <Translate text={"Code"} />{" "}
                              </label>
                              <input
                                type="text"
                                className={`form-control ${errorFields["Code"]}`}
                                value={state.Code ? state.Code : ""}
                                placeholder={"enter Code"}
                                onChange={handleChange}
                                name="Code"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                <Translate text={"Email From"} />{" "}
                              </label>
                              <input
                                type="text"
                                className={`form-control ${errorFields["EmailFrom"]}`}
                                value={state.EmailFrom ? state.EmailFrom : ""}
                                placeholder={"enter email from"}
                                onChange={handleChange}
                                name="EmailFrom"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                <Translate text={"Last N Days"} />{" "}
                              </label>
                              <input
                                type="text"
                                className={`form-control ${errorFields["LastNDays"]}`}
                                value={state.LastNDays ? state.LastNDays : ""}
                                placeholder={"enter last n days"}
                                onChange={handleChange}
                                name="LastNDays"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                <Translate text={"Check Subject"} />{" "}
                              </label>
                              <div
                                className={`integration-switch ${errorFields["checkSubject"]}`}
                              >
                                <span className="switch switch-sm float-right">
                                  <InputCheckBox
                                    className="switch"
                                    id={"checkSubject"}
                                    name={"checkSubject"}
                                    checked={
                                      _.isBoolean(state.checkSubject)
                                        ? state.checkSubject
                                        : false
                                    }
                                    onChange={(e) => {
                                      handleCheckChange(e);
                                    }}
                                  />
                                  <label htmlFor={"checkSubject"}></label>
                                </span>
                              </div>
                            </div>
                          </>
                        )
                      }
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="origin"
                              className="nav-fill"
                            >
                              <Tab eventKey="origin" title="Origin">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Origin"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`target-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["target-kw"]}`}
                                              name="kw"
                                              onChange={handleOnSubChange}
                                              value={addSubList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.origins}
                                              name={"v"}
                                              placeholder={"select origin"}
                                              onChange={(e, data) => {
                                                handleDynamicSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addSubList.v}
                                              classNamePrefix={`${errorFields["target-v"]} basic-select`}
                                              id={`target-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistOriginSave(
                                                  addSubList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {subList.length > 0 ? (
                                        subList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (_.isEmpty(textEditor))
                                                  handleEditSubList(rec);
                                              }}
                                            >
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <InputText
                                                      id={`target-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["target-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditSubChange
                                                      }
                                                      value={editSubList.kw}
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.origins}
                                                      name={"v"}
                                                      placeholder={
                                                        "select origin"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleDynamicSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editSubList.v}
                                                      classNamePrefix={`${errorFields["target-edit-v"]} basic-select`}
                                                      id={`target-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.origins.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.origins.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {textEditor[rec.value] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistOriginSave(
                                                              editSubList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Lead Source"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`leadSource-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["leadSource-kw"]}`}
                                              name="kw"
                                              onChange={
                                                handleLeadSourceSubChange
                                              }
                                              value={addleadSourceList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.leadSources}
                                              name={"v"}
                                              placeholder={"select lead source"}
                                              onChange={(e, data) => {
                                                handleLeadSourceSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addleadSourceList.v}
                                              classNamePrefix={`${errorFields["leadSource-v"]} basic-select`}
                                              id={`leadSource-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistLeadSourceSave(
                                                  addleadSourceList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {leadSourceKWList.length > 0 ? (
                                        leadSourceKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    leadSourcetextEditor
                                                  )
                                                )
                                                  handleEditLeadSourceList(rec);
                                              }}
                                            >
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`leadSource-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["leadSource-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditLeadSourceChange
                                                      }
                                                      value={
                                                        editleadSourceList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.leadSources}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleLeadSourceSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={
                                                        editleadSourceList.v
                                                      }
                                                      classNamePrefix={`${errorFields["leadSource-edit-v"]} basic-select`}
                                                      id={`leadSource-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.leadSources.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.leadSources.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {leadSourcetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistLeadSourceSave(
                                                              editleadSourceList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Campaign"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`campaign-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["campaign-kw"]}`}
                                              name="kw"
                                              onChange={handlecampaignSubChange}
                                              value={addcampaignList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.campaigns}
                                              name={"v"}
                                              placeholder={"select campaign"}
                                              onChange={(e, data) => {
                                                handlecampaignSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addcampaignList.v}
                                              classNamePrefix={`${errorFields["campaign-v"]} basic-select`}
                                              id={`campaign-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistcampaignSave(
                                                  addcampaignList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {campaignKWList.length > 0 ? (
                                        campaignKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(campaigntextEditor)
                                                )
                                                  handleEditcampaignList(rec);
                                              }}
                                            >
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`campaign-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["campaign-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditcampaignChange
                                                      }
                                                      value={
                                                        editcampaignList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.campaigns}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handlecampaignSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editcampaignList.v}
                                                      classNamePrefix={`${errorFields["campaign-edit-v"]} basic-select`}
                                                      id={`campaign-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.campaigns.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.campaigns.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {campaigntextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistcampaignSave(
                                                              editcampaignList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletecampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>


                              <Tab eventKey="enquiryType" title="Enquiry Type">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Enquiry Type"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`enquiryType-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["enquiryType-kw"]}`}
                                              name="kw"
                                              onChange={handleenquirytypeSubChange}
                                              value={addenquirytypeList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.enquiryTypes}
                                              name={"v"}
                                              placeholder={"select enquiry type"}
                                              onChange={(e, data) => {
                                                handleenquirytypeSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addenquirytypeList.v}
                                              classNamePrefix={`${errorFields["enquiryType-v"]} basic-select`}
                                              id={`enquiryType-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistenquirytypeSave(
                                                  addenquirytypeList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {enquirytypeKWList.length > 0 ? (
                                        enquirytypeKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(enquirytypetextEditor)
                                                )
                                                  handleEditenquirytypeList(rec);
                                              }}
                                            >
                                              <td>
                                                {enquirytypetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`enquiryType-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["enquiryType-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditenquirytypeChange
                                                      }
                                                      value={
                                                        editenquirytypeList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {enquirytypetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.enquiryTypes}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleenquirytypeSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editenquirytypeList.v}
                                                      classNamePrefix={`${errorFields["enquiryType-edit-v"]} basic-select`}
                                                      id={`enquiryType-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.enquiryTypes.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.enquiryTypes.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {enquirytypetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistenquirytypeSave(
                                                              editenquirytypeList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelenquirytypeList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditenquirytypeList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteenquirytypeList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>

                            </Tabs>
                          </nav>
                        </div>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`emailIntg-oem`}
                            name={"showinOEM"}
                            checked={Boolean(state.showinOEM)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`emailIntg-oem`}>Show in OEM</label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`emailIntg-region`}
                            name={"showinRegion"}
                            checked={Boolean(state.showinRegion)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`emailIntg-region`}>
                            Show in Region
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`emailIntg-group`}
                            name={"showinGroup"}
                            checked={Boolean(state.showinGroup)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`emailIntg-group`}>
                            Show in Group
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`emailIntg-client`}
                            name={"hideinClient"}
                            checked={Boolean(state.hideinClient)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`emailIntg-client`}>
                            Hidden in Client
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-consent`}
                            name={"customerConsent"}
                            checked={Boolean(state.customerConsent)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-consent`}>
                            Customer Consent (Digital)
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "d365" ? (
                    <>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"Retailer"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["mclRetailer"]}`}
                          value={state.mclRetailer ? state.mclRetailer : ""}
                          placeholder={"enter retailer"}
                          onChange={handleChange}
                          name="mclRetailer"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "saleslogs" ? (
                    <>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["Code"]}`}
                          value={state.Code ? state.Code : ""}
                          placeholder={"enter code"}
                          onChange={handleChange}
                          name="Code"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Username"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["username"]}`}
                          value={state.username ? state.username : ""}
                          placeholder={"enter username"}
                          onChange={handleChange}
                          name="username"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Password"} />{" "}
                        </label>
                        <input
                          type="password"
                          className={`form-control ${errorFields["password"]}`}
                          value={state.password ? state.password : ""}
                          placeholder={"enter password"}
                          onChange={handleChange}
                          name="password"
                        />
                      </div>
                      <div className="common-table w-100 mt-2">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="head-light"
                                width="18%"
                              >
                                {" "}
                                <Translate text={"make"} />
                              </th>

                              <th
                                scope="col"
                                className="head-light"
                                width="18%"
                              >
                                {" "}
                                <Translate text={"Sales type"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="18%"
                              >
                                {" "}
                                <Translate text={"Status"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="18%"
                              >
                                {" "}
                                <Translate text={"Department Name"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="18%"
                              >
                                {" "}
                                <Translate text={"Model Keywords"} />
                              </th>
                              {/* <th scope="col" className="head-light" width="33%">Value</th> */}
                              <th
                                scope="col"
                                className="head-light border-right-0"
                                width="10%"
                              >
                                {" "}
                                <Translate text={"Actions"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="form-group mb-0">
                                  <ReactSelect
                                    options={makes}
                                    name={"make"}
                                    placeholder={"select make"}
                                    onChange={(e, data) => {
                                      handleDynamicSelectChange(e, data, true);
                                    }}
                                    value={addSubList.make}
                                    classNamePrefix={` ${errorFields["target-make"]} basic-select`}
                                    id={`target-make`}
                                  ></ReactSelect>
                                </div>
                              </td>
                              <td>
                                <div className="form-group mb-0">
                                  <ReactMultiSelect
                                    options={data.saleTypeOptionsForSalesLogs}
                                    name={"saleType"}
                                    placeholder={"select sales type"}
                                    onChange={(values) => {
                                      handleMultiSelectChange(
                                        values,
                                        "saleType",
                                        true
                                      );
                                    }}
                                    value={
                                      !_.isEmpty(addSubList.saleType)
                                        ? addSubList.saleType.split(",")
                                        : []
                                    }
                                    classNamePrefix={`${errorFields["target-saleType"]} basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                  ></ReactMultiSelect>
                                </div>
                              </td>
                              <td>
                                <div className="form-group mb-0">
                                  <InputText
                                    id={`target-status`}
                                    autoComplete="off"
                                    placeholder={"enter status"}
                                    className={`form-control ${errorFields["target-status"]}`}
                                    name="status"
                                    onChange={handleOnSubChange}
                                    value={addSubList.status}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group mb-0">
                                  <InputText
                                    id={`target-id`}
                                    autoComplete="off"
                                    placeholder={"enter department name"}
                                    className={`form-control ${errorFields["target-id"]}`}
                                    name="id"
                                    onChange={handleOnSubChange}
                                    value={addSubList.id}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group mb-0">
                                  <InputText
                                    id={`target-id`}
                                    autoComplete="off"
                                    placeholder={"enter keywords"}
                                    className={`form-control ${errorFields["target-keywords"]}`}
                                    name="keywords"
                                    onChange={handleOnSubChange}
                                    value={addSubList.keywords}
                                  />
                                </div>
                              </td>

                              <td className="border-right-0">
                                <div className="button-height-fix">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleSublistSave(addSubList, true);
                                    }}
                                  >
                                    {" "}
                                    <Translate text={"add"} />
                                  </button>
                                </div>
                              </td>
                            </tr>
                            {subList.length > 0 ? (
                              subList.map((rec, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onDoubleClick={(e) => {
                                      e.preventDefault();
                                      if (_.isEmpty(textEditor))
                                        handleEditSubList(rec);
                                    }}
                                  >
                                    <td>
                                      {textEditor[rec.value] ? (
                                        <>
                                          <ReactSelect
                                            options={makes}
                                            name={"make"}
                                            placeholder={"select make"}
                                            onChange={(e, data) => {
                                              handleDynamicSelectChange(
                                                e,
                                                data,
                                                false
                                              );
                                            }}
                                            value={editSubList.make}
                                            classNamePrefix={`${errorFields["target-edit-make"]} basic-select`}
                                            id={`target-edit-make`}
                                          ></ReactSelect>
                                        </>
                                      ) : (
                                        <>{rec.make}</>
                                      )}
                                    </td>
                                    <td>
                                      {textEditor[rec.value] ? (
                                        <>
                                          <ReactMultiSelect
                                            options={
                                              data.saleTypeOptionsForSalesLogs
                                            }
                                            name={"saleType"}
                                            placeholder={"select sales type"}
                                            onChange={(values) => {
                                              handleMultiSelectChange(
                                                values,
                                                "saleType",
                                                false
                                              );
                                            }}
                                            value={
                                              !_.isEmpty(editSubList.saleType)
                                                ? editSubList.saleType.split(
                                                  ","
                                                )
                                                : []
                                            }
                                            classNamePrefix={`${errorFields["target-edit-saleType"]} basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                          ></ReactMultiSelect>
                                        </>
                                      ) : (
                                        <>
                                          {data.saleTypeOptionsForSalesLogs.filter(
                                            (a) =>
                                              rec.saleType
                                                .split(",")
                                                .includes(a.value)
                                          ).length ? (
                                            <div className="Vehicle-availability">
                                              {data.saleTypeOptionsForSalesLogs
                                                .filter((a) =>
                                                  rec.saleType
                                                    .split(",")
                                                    .includes(a.value)
                                                )
                                                .map((r, i) => {
                                                  return (
                                                    <div key={i}>
                                                      <span>{r.label}</span>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          ) : (
                                            rec.saleType
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {textEditor[rec.value] ? (
                                        <>
                                          <InputText
                                            id={`target-edit-status`}
                                            autoComplete="off"
                                            placeholder={"status"}
                                            className={`form-control ${errorFields["target-edit-status"]}`}
                                            name="status"
                                            onChange={handleOnEditSubChange}
                                            value={editSubList.status}
                                          />
                                        </>
                                      ) : (
                                        <>{rec.status}</>
                                      )}
                                    </td>
                                    <td>
                                      {textEditor[rec.value] ? (
                                        <>
                                          <InputText
                                            id={`target-edit-id`}
                                            autoComplete="off"
                                            placeholder={"department name"}
                                            className={`form-control ${errorFields["target-edit-id"]}`}
                                            name="id"
                                            onChange={handleOnEditSubChange}
                                            value={editSubList.id}
                                          />
                                        </>
                                      ) : (
                                        <>{rec.id}</>
                                      )}
                                    </td>
                                    <td>
                                      {textEditor[rec.value] ? (
                                        <>
                                          <InputText
                                            id={`target-edit-keywords`}
                                            autoComplete="off"
                                            placeholder={"keywords"}
                                            className={`form-control ${errorFields["target-edit-keywords"]}`}
                                            name="keywords"
                                            onChange={handleOnEditSubChange}
                                            value={editSubList.keywords}
                                          />
                                        </>
                                      ) : (
                                        <>{rec.keywords}</>
                                      )}
                                    </td>
                                    <td className="border-right-0">
                                      <div className="action-fix">
                                        {textEditor[rec.value] ? (
                                          <>
                                            <div className="table-edit float-left mr-2">
                                              {" "}
                                              <a
                                                href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleSublistSave(
                                                    editSubList,
                                                    false
                                                  );
                                                }}
                                              >
                                                {" "}
                                                <i className="ico icon-check"></i>
                                              </a>
                                            </div>
                                            <div className="table-edit float-left mr-2">
                                              {" "}
                                              <a
                                                href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleCancelSubList(rec);
                                                }}
                                              >
                                                {" "}
                                                <i className="ico icon-remove"></i>
                                              </a>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="table-edit float-left mr-2">
                                              {" "}
                                              <a
                                                href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleEditSubList(rec);
                                                }}
                                              >
                                                {" "}
                                                <i className="ico icon-edit"></i>
                                              </a>
                                            </div>
                                            <div className="table-edit float-left mr-2">
                                              {" "}
                                              <a
                                                href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleDeleteSubList(rec);
                                                }}
                                              >
                                                {" "}
                                                <i className="ico icon-delete"></i>
                                              </a>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : moduleToChange.module === "autoit" ||
                    moduleToChange.module === "pmds" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"xSecret Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["xSecretKey"]}`}
                          value={state.xSecretKey ? state.xSecretKey : ""}
                          placeholder={"enter xsecret key"}
                          onChange={handleChange}
                          name="xSecretKey"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"xAccess Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["xAccessKey"]}`}
                          value={state.xAccessKey ? state.xAccessKey : ""}
                          placeholder={"enter xaccess-key"}
                          onChange={handleChange}
                          name="xAccessKey"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Database ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["DatabaseId"]}`}
                          value={state.DatabaseId ? state.DatabaseId : ""}
                          placeholder={"enter database id"}
                          onChange={handleChange}
                          name="DatabaseId"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"End Point URL"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["endpoint"]}`}
                          value={state.endpoint ? state.endpoint : ""}
                          placeholder={"enter endpoint"}
                          onChange={handleChange}
                          name="endpoint"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Stock Make"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["stockMake"]}`}
                          value={state.stockMake ? state.stockMake : ""}
                          placeholder={"enter stock make"}
                          onChange={handleChange}
                          name="stockMake"
                        />
                      </div>
                      <div className="form-group col-md-12 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`enableTradeIn`}
                            name={"enableTradeIn"}
                            checked={
                              _.isBoolean(state.enableTradeIn)
                                ? state.enableTradeIn
                                : false
                            }
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`enableTradeIn`}>
                            <Translate text={"Enable Trade-In"} />
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-12 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`updateAddress`}
                            name={"updateAddress"}
                            checked={
                              _.isBoolean(state.updateAddress)
                                ? state.updateAddress
                                : false
                            }
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`updateAddress`}>
                            <Translate text={"Update Postal Address"} />
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-12 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`pushManually`}
                            name={"pushManually"}
                            checked={
                              _.isBoolean(state.pushManually)
                                ? state.pushManually
                                : false
                            }
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`pushManually`}>
                            <Translate text={"Push Lead Manually"} />
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "keyloop" ? (
                    <>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"Base URL"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["baseUrl"]}`}
                          value={state.baseUrl ? state.baseUrl : ""}
                          placeholder={"enter base url"}
                          onChange={handleChange}
                          name="baseUrl"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_id"]}`}
                          value={state.client_id ? state.client_id : ""}
                          placeholder={"enter client id"}
                          onChange={handleChange}
                          name="client_id"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client Secret ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_secret"]}`}
                          value={state.client_secret ? state.client_secret : ""}
                          placeholder={"enter secret id"}
                          onChange={handleChange}
                          name="client_secret"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Enterprise ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["enterpriseId"]}`}
                          value={state.enterpriseId ? state.enterpriseId : ""}
                          placeholder={"enter enterprise id"}
                          onChange={handleChange}
                          name="enterpriseId"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Store ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["storeId"]}`}
                          value={state.storeId ? state.storeId : ""}
                          placeholder={"enter store id"}
                          onChange={handleChange}
                          name="storeId"
                        />
                      </div>
                      <div className="form-group col-md-12 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`searchContact`}
                            name={"searchContact"}
                            checked={
                              _.isBoolean(state.searchContact)
                                ? state.searchContact
                                : false
                            }
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`searchContact`}>
                            <Translate text={"Enable search contacts in DMS"} />
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "cap" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Region"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["region"]}`}
                          value={state.region ? state.region : ""}
                          placeholder={"enter region"}
                          onChange={handleChange}
                          name="region"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Auth Group"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["AuthGroup"]}`}
                          value={state.AuthGroup ? state.AuthGroup : ""}
                          placeholder={"enter auth group"}
                          onChange={handleChange}
                          name="AuthGroup"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Campaign ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["CampaignId"]}`}
                          value={state.CampaignId ? state.CampaignId : ""}
                          placeholder={"enter campaign id"}
                          onChange={handleChange}
                          name="CampaignId"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Campaign Guid"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["campaignGuid"]}`}
                          value={state.campaignGuid ? state.campaignGuid : ""}
                          placeholder={"enter campaign Guid"}
                          onChange={handleChange}
                          name="campaignGuid"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Auth URL"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["authURL"]}`}
                          value={state.authURL ? state.authURL : ""}
                          placeholder={"enter auth url"}
                          onChange={handleChange}
                          name="authURL"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Base Url"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["baseUrl"]}`}
                          value={state.baseUrl ? state.baseUrl : ""}
                          placeholder={"enter base url"}
                          onChange={handleChange}
                          name="baseUrl"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_id"]}`}
                          value={state.client_id ? state.client_id : ""}
                          placeholder={"enter client id"}
                          onChange={handleChange}
                          name="client_id"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client Secret"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_secret"]}`}
                          value={state.client_secret ? state.client_secret : ""}
                          placeholder={"enter client secret"}
                          onChange={handleChange}
                          name="client_secret"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Porsche Client ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["porsche_client_id"]}`}
                          value={state.porsche_client_id ? state.porsche_client_id : ""}
                          placeholder={"enter porsche client id"}
                          onChange={handleChange}
                          name="porsche_client_id"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Porsche Client Secret"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["porsche_client_secret"]}`}
                          value={state.porsche_client_secret ? state.porsche_client_secret : ""}
                          placeholder={"enter porsche client secret"}
                          onChange={handleChange}
                          name="porsche_client_secret"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Pag Importer Id"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["PagImporterId"]}`}
                          value={state.PagImporterId ? state.PagImporterId : ""}
                          placeholder={"enter pag importer id"}
                          onChange={handleChange}
                          name="PagImporterId"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Responsible Sales Dealer Id"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["ResponsibleSalesDealerId"]}`}
                          value={state.ResponsibleSalesDealerId ? state.ResponsibleSalesDealerId : ""}
                          placeholder={"enter responsible sales dealer id"}
                          onChange={handleChange}
                          name="ResponsibleSalesDealerId"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"PPN Resource"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["ppn_resource"]}`}
                          value={state.ppn_resource ? state.ppn_resource : ""}
                          placeholder={"enter ppn resource"}
                          onChange={handleChange}
                          name="ppn_resource"
                        />
                      </div>

                    </>
                  ) : moduleToChange.module === "salesforce" ? (
                    <>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"End point"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["endpoint"]}`}
                          value={state.endpoint ? state.endpoint : ""}
                          placeholder={"enter end point"}
                          onChange={handleChange}
                          name="endpoint"
                          disabled={state.success}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["clientId"]}`}
                          value={state.clientId ? state.clientId : ""}
                          placeholder={"enter client id"}
                          onChange={handleChange}
                          name="clientId"
                          disabled={state.success}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client Secret"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["clientSecret"]}`}
                          value={state.clientSecret ? state.clientSecret : ""}
                          placeholder={"enter client secret"}
                          onChange={handleChange}
                          name="clientSecret"
                          disabled={state.success}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Record Type Inbound"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["recTypeInbound"]}`}
                          value={
                            state.recTypeInbound ? state.recTypeInbound : ""
                          }
                          placeholder={"enter rec type inbound"}
                          onChange={handleChange}
                          name="recTypeInbound"
                        //disabled={state.success}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Record Type Company"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["recTypeCompany"]}`}
                          value={
                            state.recTypeCompany ? state.recTypeCompany : ""
                          }
                          placeholder={"enter rec type inbound"}
                          onChange={handleChange}
                          name="recTypeCompany"
                        //disabled={state.success}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Record Type Enquiry"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["recTypeEnquiry"]}`}
                          value={
                            state.recTypeEnquiry ? state.recTypeEnquiry : ""
                          }
                          placeholder={"enter rec type inbound"}
                          onChange={handleChange}
                          name="recTypeEnquiry"
                        //disabled={state.success}
                        />
                      </div>
                      {state.success ? (
                        <div className="mfa-green mt-2">
                          <div className="float-left mt-1">
                            <i className="ico icon-settings-security"></i>{" "}
                            Salesforce integration is active
                          </div>
                          {selectedLevel.level === "oem" ? (
                            <div className="float-right ">
                              <button
                                type="button"
                                className="btn btn-default float-left ml-2 mfa-enable-btn"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    success: false,
                                  });
                                }}
                              >
                                Change
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <div className="mfa-red mt-2">
                          <div className="float-left mt-1">
                            <i className="ico icon-settings-security"></i>{" "}
                            Settings updated. Please reauthenticate
                          </div>
                        </div>
                      )}
                      {selectedLevel.level === "oem" && state.success ? (
                        <div className="common-table w-100 mt-2">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="head-light"
                                  width="60%"
                                >
                                  {" "}
                                  <Translate text={"Dealer"} />
                                </th>
                                <th
                                  scope="col"
                                  className="head-light"
                                  width="20%"
                                >
                                  {" "}
                                  <Translate text={"Pentana ID"} />
                                </th>
                                <th
                                  scope="col"
                                  className="head-light border-right-0"
                                  width="20%"
                                >
                                  {" "}
                                  <Translate text={"Status"} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {clients.length > 0 ? (
                                _.filter(
                                  clients,
                                  (v) =>
                                    _.indexOf(
                                      dealersettings.clients.map(
                                        (v) => _.pick(v, ["id"]).id
                                      ),
                                      v.documentID
                                    ) >= 0
                                ).map((rec, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{rec.name}</td>
                                      <td>
                                        {!Boolean(
                                          rec?.clientSettings?.salesforceOEM
                                        ) ? (
                                          <InputText
                                            id={`salesforcePentanaID`}
                                            autoComplete="off"
                                            placeholder={"enter keywords"}
                                            className={`form-control ${errorFields["salesforcePentanaID"]}`}
                                            name="salesforcePentanaID"
                                            onChange={(e) => {
                                              setTdLoader({
                                                ...tdLoader,
                                                [`${rec.documentID}-salesforcePentanaID`]:
                                                  e.target.value,
                                              });
                                            }}
                                            value={
                                              tdLoader[
                                              `${rec.documentID}-salesforcePentanaID`
                                              ]
                                            }
                                          />
                                        ) : (
                                          rec?.clientSettings
                                            ?.salesforcePentanaID
                                        )}
                                      </td>
                                      <td className="border-right-0">
                                        {rec &&
                                          rec.clientSettings &&
                                          rec.clientSettings.salesforceOEM ? (
                                          <div className="d-inline-block">
                                            <button
                                              type="button"
                                              className="btn btn-green float-left mr-2"
                                              style={{
                                                padding: `3px 8px 4px`,
                                                fontSize: `11px`,
                                              }}
                                              onClick={() => {
                                                Swal.fire({
                                                  title:
                                                    CommonHelper.showLocale(
                                                      { dealersettings },
                                                      "Are you sure?"
                                                    ),
                                                  text: CommonHelper.showLocale(
                                                    { dealersettings },
                                                    "Do you want to disable."
                                                  ),
                                                  icon: "info",
                                                  showCancelButton: true,
                                                  confirmButtonText:
                                                    CommonHelper.showLocale(
                                                      { dealersettings },
                                                      "Yes"
                                                    ),
                                                  cancelButtonText:
                                                    CommonHelper.showLocale(
                                                      { dealersettings },
                                                      "No"
                                                    ),
                                                }).then(async (result) => {
                                                  if (result.value) {
                                                    setTdLoader({
                                                      ...tdLoader,
                                                      [rec.documentID]: true,
                                                    });
                                                    const _cintegrations =
                                                      await window.firebase
                                                        .firestore()
                                                        .collection(
                                                          `clients/${rec.documentID}/integrations`
                                                        )
                                                        .where(
                                                          "type",
                                                          "==",
                                                          "salesforce"
                                                        )
                                                        .limit(1)
                                                        .get();
                                                    if (
                                                      _cintegrations.docs &&
                                                      _cintegrations.docs
                                                        .length > 0
                                                    ) {
                                                      window.firebase
                                                        .firestore()
                                                        .doc(
                                                          `clients/${rec.documentID}/integrations/${_cintegrations.docs[0].id}`
                                                        )
                                                        .set(
                                                          {
                                                            enabled: false,
                                                          },
                                                          { merge: true }
                                                        );
                                                    }
                                                    window.firebase
                                                      .firestore()
                                                      .doc(
                                                        `clients/${rec.documentID}`
                                                      )
                                                      .update({
                                                        "clientSettings.salesforceOEM": false,
                                                      })
                                                      .then((doc) => {
                                                        setTdLoader({
                                                          [rec.documentID]: false,
                                                        });
                                                      });
                                                  }
                                                });
                                              }}
                                            >
                                              {" "}
                                              <i
                                                className="ico icon-check"
                                                style={{ fontSize: `10px` }}
                                              ></i>
                                              {tdLoader[rec.documentID] ? (
                                                <span
                                                  className="spinner-border spinner-button mr-1"
                                                  role="status"
                                                  aria-hidden="true"
                                                ></span>
                                              ) : (
                                                <></>
                                              )}
                                              Enabled
                                            </button>
                                          </div>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            style={{
                                              padding: `3px 8px 4px`,
                                              fontSize: `11px`,
                                            }}
                                            onClick={async () => {
                                              setTdLoader({
                                                tdLoader,
                                                [rec.documentID]: true,
                                              });
                                              const _cintegrations =
                                                await window.firebase
                                                  .firestore()
                                                  .collection(
                                                    `clients/${rec.documentID}/integrations`
                                                  )
                                                  .where(
                                                    "type",
                                                    "==",
                                                    "salesforce"
                                                  )
                                                  .limit(1)
                                                  .get();
                                              if (
                                                _cintegrations.docs &&
                                                _cintegrations.docs.length > 0
                                              ) {
                                                window.firebase
                                                  .firestore()
                                                  .doc(
                                                    `clients/${rec.documentID}/integrations/${_cintegrations.docs[0].id}`
                                                  )
                                                  .set(
                                                    {
                                                      enabled: true,
                                                      settings: state,
                                                    },
                                                    { merge: true }
                                                  );
                                              } else {
                                                const _id = window.firebase
                                                  .firestore()
                                                  .collection("clients")
                                                  .doc().id;
                                                window.firebase
                                                  .firestore()
                                                  .doc(
                                                    `clients/${rec.documentID}/integrations/${_id}`
                                                  )
                                                  .set({
                                                    active: true,
                                                    enabled: true,
                                                    settings: state,
                                                    type: "salesforce",
                                                  });
                                              }
                                              window.firebase
                                                .firestore()
                                                .doc(
                                                  `clients/${rec.documentID}`
                                                )
                                                .update({
                                                  "clientSettings.salesforcePentanaID":
                                                    tdLoader[
                                                      `${rec.documentID}-salesforcePentanaID`
                                                    ]
                                                      ? tdLoader[
                                                      `${rec.documentID}-salesforcePentanaID`
                                                      ]
                                                      : null,
                                                  "clientSettings.salesforceOEM": true,
                                                })
                                                .then((doc) => {
                                                  setTdLoader({
                                                    [rec.documentID]: false,
                                                  });
                                                });
                                            }}
                                          >
                                            {tdLoader[rec.documentID] ? (
                                              <span
                                                className="spinner-border spinner-button mr-1"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                            ) : (
                                              <></>
                                            )}
                                            Enable{" "}
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : moduleToChange.module === "gforces" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Base URL"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["baseUrl"]}`}
                          value={state.baseUrl ? state.baseUrl : ""}
                          placeholder={"enter base url"}
                          onChange={handleChange}
                          name="baseUrl"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_id"]}`}
                          value={state.client_id ? state.client_id : ""}
                          placeholder={"enter client id"}
                          onChange={handleChange}
                          name="client_id"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Client Secret ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["client_secret"]}`}
                          value={state.client_secret ? state.client_secret : ""}
                          placeholder={"enter secret id"}
                          onChange={handleChange}
                          name="client_secret"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Franchise Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["franchise_hash"]}`}
                          value={
                            state.franchise_hash ? state.franchise_hash : ""
                          }
                          placeholder={"enter franchise code"}
                          onChange={handleChange}
                          name="franchise_hash"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Group Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["group_hash"]}`}
                          value={state.group_hash ? state.group_hash : ""}
                          placeholder={"enter group code"}
                          onChange={handleChange}
                          name="group_hash"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Location Code"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["location_hash"]}`}
                          value={state.location_hash ? state.location_hash : ""}
                          placeholder={"enter location code"}
                          onChange={handleChange}
                          name="location_hash"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "aircall" ? (
                    <>
                      <p className="addons-request-text w-100">
                        <Translate text={"aircallPrivateRecordingEmail"} />
                      </p>
                      <div className="form-group col-md-12">
                        <InputMultipleText
                          placeholder={`emails`}
                          className={`form-control react-multi-tag`}
                          name="emails"
                          onChange={(emails) => {
                            handleOnChangeMultipleText(emails, "emails");
                          }}
                          value={state?.emails ? state.emails : []}
                          validateEmail={true}
                          getLabel={(email, index) => {
                            return (
                              <div data-tag key={index}>
                                {email}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index, "emails")}
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "justcall" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://justcall.io/"
                          target="_blank"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Secret"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_secret"]}`}
                          value={state.api_secret ? state.api_secret : ""}
                          placeholder={"enter API secret"}
                          onChange={handleChange}
                          name="api_secret"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Default Number"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["defaultPhone"]}`}
                          value={state.defaultPhone ? state.defaultPhone : ""}
                          placeholder={"enter default number"}
                          onChange={handleChange}
                          name="defaultPhone"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Webhook ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["webhookID"]}`}
                          value={state.webhookID ? state.webhookID : ""}
                          placeholder={"enter webhook id"}
                          onChange={handleChange}
                          name="webhookID"
                        />
                      </div>
                      <div className="form-group col-md-12 mt-4">
                        <label>
                          <Translate text={"Phone Numbers for current client"} />
                        </label>
                        <InputMultipleText
                          placeholder={`phones`}
                          className={`form-control react-multi-tag`}
                          name="phones"
                          onChange={(phones) => {
                            handleOnChangeMultipleText(phones, "phones");
                          }}
                          value={state?.phones ? state.phones : []}
                          validateEmail={false}
                          getLabel={(email, index) => {
                            return (
                              <div data-tag key={index}>
                                {email}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index, "phones")}
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className="form-group col-md-12 mt-4">
                        <label>
                          <Translate text={"aircallPrivateRecordingEmail"} />
                        </label>
                        <InputMultipleText
                          placeholder={`emails`}
                          className={`form-control react-multi-tag`}
                          name="emails"
                          onChange={(emails) => {
                            handleOnChangeMultipleText(emails, "emails");
                          }}
                          value={state?.emails ? state.emails : []}
                          validateEmail={true}
                          getLabel={(email, index) => {
                            return (
                              <div data-tag key={index}>
                                {email}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index, "emails")}
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "tss" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://totalsellingsolutions.com.au/"
                          target="_blank"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-12">
                        <label>
                          <Translate text={"Department ID"} />{" "}
                        </label>
                        <InputMultipleText
                          placeholder={`tss Dept Id`}
                          className={`form-control react-multi-tag ${errorFields["tssDeptId"]}`}
                          name="tssDeptId"
                          onChange={(tssDeptId) => {
                            handleOnChangeMultipleText(tssDeptId, "tssDeptId");
                          }}
                          value={_.isArray(state?.tssDeptId) ? state.tssDeptId : []}
                          validateEmail={false}
                          getLabel={(tssDeptId, index) => {
                            return (
                              <div data-tag key={index}>
                                {tssDeptId}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index, "tssDeptId")}
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "pandadoc" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://pandadoc.com/"
                          target="_blank"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Api Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Organization ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["organization_id"]}`}
                          value={state.organization_id ? state.organization_id : ""}
                          placeholder={"enter organization id"}
                          onChange={handleChange}
                          name="organization_id"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Workspace ID"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["workspace_id"]}`}
                          value={state.workspace_id ? state.workspace_id : ""}
                          placeholder={"enter workspace id"}
                          onChange={handleChange}
                          name="workspace_id"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Member Token"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["member_token"]}`}
                          value={state.member_token ? state.member_token : ""}
                          placeholder={"enter member token"}
                          onChange={handleChange}
                          name="member_token"
                        />
                      </div>
                    </>
                  ) : moduleToChange.module === "impel" ? (
                    <>
                      <p
                        style={{
                          margin: "0px auto 15px auto",
                          color: "#0000007a",
                          paddingBottom: "15px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        If you do not have these credentials, you can get them
                        from{" "}
                        <a
                          href="https://impel.ai/"
                          target="_blank"
                          style={{ color: "#2b57e2", fontWeight: "600" }}
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Origin"} />{" "}
                        </label>
                        <ReactMultiSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={(values) => { handlemultiSelectChange(values, "origin"); }}
                          value={state.origin || []}
                          classNamePrefix={`${errorFields["origin"]} basic-select`}
                          isMulti={true}
                          isSearchable={true}
                        ></ReactMultiSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"API Key"} />{" "}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errorFields["api_key"]}`}
                          value={state.api_key ? state.api_key : ""}
                          placeholder={"enter API key"}
                          onChange={handleChange}
                          name="api_key"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Default User"} />{" "}
                        </label>
                        <ReactSelect
                          options={clientUsers?.map(rec => {
                            return {
                              value: rec.email,
                              active: _.isBoolean(rec.active) ? rec.active : true,
                              searchlabel: rec.name,
                              label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                              </div>
                            }
                          }) || []}
                          name={"defaultOwner"}
                          placeholder={"select user"}
                          onChange={handleSelectChange}
                          value={state.defaultOwner ? state.defaultOwner : ""}
                          classNamePrefix={`${errorFields["defaultOwner"]} basic-select`}
                        ></ReactSelect>
                      </div>

                    </>
                  ) : moduleToChange.module === "ringcentral" ? (
                    <>
                      <p className="addons-request-text w-100">
                        <Translate text={"aircallPrivateRecordingEmail"} />
                      </p>
                      <div className="form-group col-md-12">
                        <InputMultipleText
                          placeholder={`emails`}
                          className={`form-control react-multi-tag`}
                          name="emails"
                          onChange={(emails) => {
                            handleOnChangeMultipleText(emails, "emails");
                          }}
                          value={state?.emails ? state.emails : []}
                          validateEmail={true}
                          getLabel={(email, index) => {
                            return (
                              <div data-tag key={index}>
                                {email}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index, "emails")}
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-autoRecording`}
                            name={"autoRecording"}
                            checked={Boolean(state.autoRecording)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label
                            htmlFor={`${moduleToChange.module}-autoRecording`}
                          >
                            Enable Automatic Call Recoding{" "}
                          </label>
                        </span>
                      </div>
                    </>
                  ) : moduleToChange.module === "imotor" ||
                    moduleToChange.module === "adtorqueedge" ||
                    moduleToChange.module === "appraisal_solutions" ||
                    moduleToChange.module === "bentley_cab" ||
                    moduleToChange.module === "carsales" ||
                    moduleToChange.module === "conversica" ||
                    moduleToChange.module === "creativo" ||
                    moduleToChange.module === "dubizzle" ||
                    moduleToChange.module === "easycars" ||
                    moduleToChange.module === "facebook" ||
                    moduleToChange.module === "autograb" ||
                    moduleToChange.module === "ferrari_modis" ||
                    moduleToChange.module === "gumtree" ||
                    moduleToChange.module === "heroleads" ||
                    moduleToChange.module === "c2native" ||
                    moduleToChange.module === "gubagoo" ||
                    moduleToChange.module === "autoforce" ||
                    moduleToChange.module === "amsme" ||
                    moduleToChange.module === "mattaki" ||
                    moduleToChange.module === "motorweb" ||
                    moduleToChange.module === "red_book" ||
                    moduleToChange.module === "roi" ||
                    moduleToChange.module === "shiftdigital" ||
                    //moduleToChange.module === 'sms_broadcast' ||
                    moduleToChange.module === "wildjar" ||
                    moduleToChange.module === "aapwebsite" ||
                    moduleToChange.module === "projectcoavme" ||
                    moduleToChange.module === "nexa" ||
                    moduleToChange.module === "dubaicars" ? (
                    <>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Origin"} />
                        </label>
                        <ReactSelect
                          options={data.origins}
                          name={"origin"}
                          placeholder={"select origin"}
                          onChange={handleSelectChange}
                          value={state.origin ? state.origin : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          <Translate text={"Select Enquiry Type"} />
                        </label>
                        <ReactSelect
                          options={data.enquiryTypes}
                          name={"enquiryType"}
                          placeholder={"select enquiry type"}
                          onChange={handleSelectChange}
                          value={state.enquiryType ? state.enquiryType : ""}
                          classNamePrefix={`${state.error ? "input_error" : ""
                            } basic-select`}
                        ></ReactSelect>
                      </div>
                      <div className="settings-tab-wrap tabs col-md-12">
                        <div className="tabs-wraper">
                          <nav>
                            <Tabs
                              defaultActiveKey="origin"
                              className="nav-fill"
                            >
                              <Tab eventKey="origin" title="Origin">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Origin"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`target-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["target-kw"]}`}
                                              name="kw"
                                              onChange={handleOnSubChange}
                                              value={addSubList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.origins}
                                              name={"v"}
                                              placeholder={"select origin"}
                                              onChange={(e, data) => {
                                                handleDynamicSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addSubList.v}
                                              classNamePrefix={`${errorFields["target-v"]} basic-select`}
                                              id={`target-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistOriginSave(
                                                  addSubList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {subList.length > 0 ? (
                                        subList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (_.isEmpty(textEditor))
                                                  handleEditSubList(rec);
                                              }}
                                            >
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <InputText
                                                      id={`target-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["target-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditSubChange
                                                      }
                                                      value={editSubList.kw}
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {textEditor[rec.value] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.origins}
                                                      name={"v"}
                                                      placeholder={
                                                        "select origin"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleDynamicSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editSubList.v}
                                                      classNamePrefix={`${errorFields["target-edit-v"]} basic-select`}
                                                      id={`target-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.origins.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.origins.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {textEditor[rec.value] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistOriginSave(
                                                              editSubList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteSubList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="leadSource" title="Lead Source">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Lead Source"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`leadSource-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["leadSource-kw"]}`}
                                              name="kw"
                                              onChange={
                                                handleLeadSourceSubChange
                                              }
                                              value={addleadSourceList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.leadSources}
                                              name={"v"}
                                              placeholder={"select lead source"}
                                              onChange={(e, data) => {
                                                handleLeadSourceSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addleadSourceList.v}
                                              classNamePrefix={`${errorFields["leadSource-v"]} basic-select`}
                                              id={`leadSource-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistLeadSourceSave(
                                                  addleadSourceList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {leadSourceKWList.length > 0 ? (
                                        leadSourceKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(
                                                    leadSourcetextEditor
                                                  )
                                                )
                                                  handleEditLeadSourceList(rec);
                                              }}
                                            >
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`leadSource-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["leadSource-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditLeadSourceChange
                                                      }
                                                      value={
                                                        editleadSourceList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {leadSourcetextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.leadSources}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handleLeadSourceSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={
                                                        editleadSourceList.v
                                                      }
                                                      classNamePrefix={`${errorFields["leadSource-edit-v"]} basic-select`}
                                                      id={`leadSource-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.leadSources.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.leadSources.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {leadSourcetextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistLeadSourceSave(
                                                              editleadSourceList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteLeadSourceList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                              <Tab eventKey="campaign" title="Campaign">
                                <div className="common-table w-100 mt-2">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Keywords"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light"
                                          width="33%"
                                        >
                                          {" "}
                                          <Translate text={"Campaign"} />
                                        </th>
                                        <th
                                          scope="col"
                                          className="head-light border-right-0"
                                          width="10%"
                                        >
                                          {" "}
                                          <Translate text={"Actions"} />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div className="form-group mb-0">
                                            <InputText
                                              id={`campaign-kw`}
                                              autoComplete="off"
                                              placeholder={"enter keywords"}
                                              className={`form-control ${errorFields["campaign-kw"]}`}
                                              name="kw"
                                              onChange={handlecampaignSubChange}
                                              value={addcampaignList.kw}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group mb-0">
                                            <ReactSelect
                                              options={data.campaigns}
                                              name={"v"}
                                              placeholder={"select campaign"}
                                              onChange={(e, data) => {
                                                handlecampaignSelectChange(
                                                  e,
                                                  data,
                                                  true
                                                );
                                              }}
                                              value={addcampaignList.v}
                                              classNamePrefix={`${errorFields["campaign-v"]} basic-select`}
                                              id={`campaign-v`}
                                            ></ReactSelect>
                                          </div>
                                        </td>
                                        <td className="border-right-0">
                                          <div className="button-height-fix">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => {
                                                handleSublistcampaignSave(
                                                  addcampaignList,
                                                  true
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Translate text={"add"} />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      {campaignKWList.length > 0 ? (
                                        campaignKWList.map((rec, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onDoubleClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                  _.isEmpty(campaigntextEditor)
                                                )
                                                  handleEditcampaignList(rec);
                                              }}
                                            >
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <InputText
                                                      id={`campaign-edit-kw`}
                                                      autoComplete="off"
                                                      placeholder={
                                                        "enter keywords"
                                                      }
                                                      className={`form-control ${errorFields["campaign-edit-kw"]}`}
                                                      name="kw"
                                                      onChange={
                                                        handleOnEditcampaignChange
                                                      }
                                                      value={
                                                        editcampaignList.kw
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>{rec.kw}</>
                                                )}
                                              </td>
                                              <td>
                                                {campaigntextEditor[
                                                  rec.value
                                                ] ? (
                                                  <>
                                                    <ReactSelect
                                                      options={data.campaigns}
                                                      name={"v"}
                                                      placeholder={
                                                        "select lead source"
                                                      }
                                                      onChange={(e, data) => {
                                                        handlecampaignSelectChange(
                                                          e,
                                                          data,
                                                          false
                                                        );
                                                      }}
                                                      value={editcampaignList.v}
                                                      classNamePrefix={`${errorFields["campaign-edit-v"]} basic-select`}
                                                      id={`campaign-edit-v`}
                                                    ></ReactSelect>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data.campaigns.filter(
                                                      (a) => a.value === rec.v
                                                    ).length
                                                      ? data.campaigns.filter(
                                                        (a) =>
                                                          a.value === rec.v
                                                      )[0].label
                                                      : rec.v}
                                                  </>
                                                )}
                                              </td>
                                              <td className="border-right-0">
                                                <div className="action-fix">
                                                  {campaigntextEditor[
                                                    rec.value
                                                  ] ? (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSublistcampaignSave(
                                                              editcampaignList,
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-check"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleCancelcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-remove"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEditcampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-edit"></i>
                                                        </a>
                                                      </div>
                                                      <div className="table-edit float-left mr-2">
                                                        {" "}
                                                        <a
                                                          href="#"
                                                          className="mini-button"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeletecampaignList(
                                                              rec
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          <i className="ico icon-delete"></i>
                                                        </a>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Tab>
                            </Tabs>
                          </nav>
                        </div>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-oem`}
                            name={"showinOEM"}
                            checked={Boolean(state.showinOEM)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-oem`}>
                            Show in OEM
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-region`}
                            name={"showinRegion"}
                            checked={Boolean(state.showinRegion)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-region`}>
                            Show in Region
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-group`}
                            name={"showinGroup"}
                            checked={Boolean(state.showinGroup)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-group`}>
                            Show in Group
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-client`}
                            name={"hideinClient"}
                            checked={Boolean(state.hideinClient)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-client`}>
                            Hidden in Client
                          </label>
                        </span>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <span className={`switch switch-sm float-left`}>
                          <InputCheckBox
                            className={`switch`}
                            id={`${moduleToChange.module}-consent`}
                            name={"customerConsent"}
                            checked={Boolean(state.customerConsent)}
                            onChange={(e) => {
                              handleCheckChange(e);
                            }}
                          />
                          <label htmlFor={`${moduleToChange.module}-consent`}>
                            Customer Consent (Digital)
                          </label>
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          {moduleToChange.module === "salesforce" &&
            (_.isEmpty(state) || !Boolean(state.success)) ? (
            <button
              type="button"
              className="btn btn-primary float-right ml-2 "
              aria-label="Save"
              disabled={
                moduleToChange.module === "driva" && !state.isTerms
                  ? true
                  : false
              }
              onClick={() => {
                if (!state.endpoint) {
                  let errors = {};
                  if (!state.endpoint) {
                    errors.endpoint = "input_error";
                  }
                  if (!state.clientId) {
                    errors.clientId = "input_error";
                  }
                  if (!state.clientSecret) {
                    errors.clientSecret = "input_error";
                  }
                  setErrorFields({ ...errors });
                } else {
                  setErrorFields({});
                  handleClose();
                  authenticateSalesForce(
                    moduleToChange.module,
                    moduleToChange.checked,
                    state
                  );
                }
              }}
            >
              {" "}
              <Translate text={"Authenticate"} />
            </button>
          ) : moduleToChange.module === "mailup" ? (
            <button
              type="button"
              className="btn btn-primary float-right ml-2 "
              aria-label="Save"
              onClick={() => {
                if (!state.client_id || !state.client_secret) {
                  let errors = {};
                  if (!state.client_id) {
                    errors.client_id = "input_error";
                  }
                  if (!state.client_secret) {
                    errors.client_secret = "input_error";
                  }
                  setErrorFields({ ...errors });
                } else {
                  setErrorFields({});
                  handleClose();
                  authenticateMailup(
                    moduleToChange.module,
                    moduleToChange.checked,
                    state
                  );
                }
              }}
            >
              {" "}
              <Translate text={"Authenticate"} />
            </button>
          ) : moduleToChange.module !== "genericApis" ? (
            <button
              type="button"
              className="btn btn-primary float-right ml-2 "
              aria-label="Save"
              disabled={
                moduleToChange.module === "driva" && !state.isTerms
                  ? true
                  : false
              }
              onClick={async () => {
                // console.log('onclick chala')
                if (moduleToChange.module === "titan") {
                  // console.log('titan is active');
                  if (!state.DatabaseName || isEmpty(subList)) {
                    if (!state.DatabaseName && isEmpty(subList)) {
                      setErrorFields({
                        DatabaseName: "input_error",
                        "target-id": "input_error",
                        "target-make": "input_error",
                        "target-saleType": "input_error",
                      });
                    } else if (!state.DatabaseName) {
                      setErrorFields({
                        DatabaseName: "input_error",
                      });
                    } else {
                      setErrorFields({
                        "target-id": "input_error",
                        "target-make": "input_error",
                        "target-saleType": "input_error",
                      });
                    }
                  } else {
                    let lookupsToReturn = lookup.subList.map((item) => {
                      delete item["value"];
                      return item;
                    });
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state,
                      lookupsToReturn
                    );
                  }
                } else if (moduleToChange.module === "revolution") {
                  // console.log('titan is active');
                  if (!state.username || !state.password || !state.revDealerCode) {
                    let errors = {};
                    if (!state.username) {
                      errors.username = "input_error";
                    }
                    if (!state.password) {
                      errors.password = "input_error";
                    }
                    if (!state.revDealerCode) {
                      errors.revDealerCode = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "fca") {
                  // console.log('titan is active');
                  if (!state.username || !state.password) {
                    let errors = {};
                    if (!state.username) {
                      errors.username = "input_error";
                    }
                    if (!state.password) {
                      errors.password = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                }
                else if (moduleToChange.module === "messagemedia") {
                  if (!state.api_secret || !state.api_key) {
                    let errors = {};
                    if (!state.api_secret) {
                      errors.api_secret = "input_error";
                    }
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "justcall") {
                  if (!state.api_secret || !state.api_key || !state.webhookID || !state.defaultPhone) {
                    let errors = {};
                    if (!state.api_secret) {
                      errors.api_secret = "input_error";
                    }
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    if (!state.webhookID) {
                      errors.webhookID = "input_error";
                    }
                    if (!state.defaultPhone) {
                      errors.defaultPhone = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    const apiURL =
                      window.location.hostname === "ams-pro-live.web.app" ||
                        window.location.hostname === "app.fusionamspro.com"
                        ? "https://us-central1-ams-pro-live.cloudfunctions.net/"
                        : "https://us-central1-ams-pro-qa.cloudfunctions.net/";
                    setBtnLoader(true)
                    const response = await axios.post(`${apiURL}justcall-checkAUth`,
                      {
                        "data": {
                          "api_key": state.api_key,
                          "api_secret": state.api_secret
                        }
                      }
                    );
                    setBtnLoader(false)
                    if (response?.data?.result?.success) {
                      setErrorFields({});
                      handleClose();
                      enableAddons(
                        moduleToChange.module,
                        moduleToChange.checked,
                        state
                      );
                    } else {
                      Swal.fire('Authentication failed. Please enter a valid API/Secret Key.', '', 'error');
                    }
                  }
                } else if (moduleToChange.module === "tss") {
                  if (_.isEmpty(state.tssDeptId)) {
                    let errors = {};
                    if (_.isEmpty(state.tssDeptId)) {
                      errors.tssDeptId = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "pandadoc") {
                  if (_.isEmpty(state.api_key) || _.isEmpty(state.member_token) || _.isEmpty(state.workspace_id) || _.isEmpty(state.organization_id)) {
                    let errors = {};
                    if (_.isEmpty(state.api_key)) {
                      errors.api_key = "input_error";
                    }
                    if (_.isEmpty(state.member_token)) {
                      errors.member_token = "input_error";
                    }
                    if (_.isEmpty(state.workspace_id)) {
                      errors.workspace_id = "input_error";
                    }
                    if (_.isEmpty(state.organization_id)) {
                      errors.organization_id = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "impel") {
                  if (!state.api_key || !state.origin || !state.defaultOwner) {
                    let errors = {};
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    if (!state.origin) {
                      errors.origin = "input_error";
                    }
                    if (!state.defaultOwner) {
                      errors.defaultOwner = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "podium") {
                  if (!state.locationUid) {
                    let errors = {};
                    if (!state.locationUid) {
                      errors.locationUid = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "driva") {
                  if (!state.api_key || !state.partnerId) {
                    let errors = {};
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    if (!state.partnerId) {
                      errors.partnerId = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "digitallicence_nsw") {
                  if (!state.clubName || !state.qr) {
                    let errors = {};
                    if (!state.clubName) {
                      errors.clubName = "input_error";
                    }
                    if (!state.qr) {
                      errors.qr = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "facebook") {
                  if (!state.access_token || !state.facebookDealerCode) {
                    let errors = {};
                    if (!state.access_token) {
                      errors.access_token = "input_error";
                    }
                    if (!state.facebookDealerCode) {
                      errors.facebookDealerCode = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    if (
                      !_.isEmpty(lookup) &&
                      (!_.isEmpty(lookup.subList) ||
                        !_.isEmpty(lookup.leadSourceList) ||
                        !_.isEmpty(lookup.campaignList) ||
                        !_.isEmpty(lookup.formList) ||
                        !_.isEmpty(lookup.dealerCodes))
                    ) {
                      let lookupsToReturn =
                        lookup.subList &&
                        lookup.subList.map((item) => {
                          delete item["id"];
                          delete item["make"];
                          delete item["saleType"];
                          return item;
                        });
                      let leaedSourceToReturn =
                        lookup.leadSourceList &&
                        lookup.leadSourceList.map((item) => {
                          delete item["id"];
                          delete item["make"];
                          delete item["saleType"];
                          return item;
                        });
                      let campaignToReturn =
                        lookup.campaignList &&
                        lookup.campaignList.map((item) => {
                          delete item["id"];
                          delete item["make"];
                          delete item["saleType"];
                          return item;
                        });
                      let dealercodesToReturn =
                        lookup.dealerCodes &&
                        lookup.dealerCodes.map((item) => {
                          delete item["id"];
                          delete item["make"];
                          delete item["saleType"];
                          return item;
                        });
                      let formListToReturn =
                        lookup.formList &&
                        lookup.formList.map((item) => {
                          delete item["make"];
                          delete item["saleType"];
                          return item;
                        });
                      setErrorFields({});
                      handleClose();
                      enableAddons(
                        moduleToChange.module,
                        moduleToChange.checked,
                        state,
                        lookupsToReturn,
                        leaedSourceToReturn,
                        campaignToReturn,
                        dealercodesToReturn,
                        formListToReturn
                      );
                    } else {
                      setErrorFields({});
                      handleClose();
                      enableAddons(
                        moduleToChange.module,
                        moduleToChange.checked,
                        state
                      );
                    }
                  }
                } else if (moduleToChange.module === "genericApis") {
                  if (!_.isEmpty(lookup) && !_.isEmpty(lookup.subList)) {
                    let lookupsToReturn =
                      lookup.subList &&
                      lookup.subList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return {
                          inbound: true,
                          generic: true,
                          enabled: true,
                          active: item?.active,
                          name: item?.name,
                          type: item?.value,
                          settings: {
                            enquiryType: item?.enquiryType,
                            origin: item?.origin,
                            campaign: item?.campaign,
                          },
                        };
                      });

                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state,
                      lookupsToReturn
                    );
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "autograb") {
                  if (!state.api_key) {
                    let errors = {};
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "mailchimp") {
                  if (!state.api_key) {
                    let errors = {};
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "mailjet") {
                  if (!state.api_key || !state.api_secret || !state.senderName || !state.senderEmail) {
                    let errors = {};
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    if (!state.api_secret) {
                      errors.api_secret = "input_error";
                    }
                    if (!state.senderName) {
                      errors.senderName = "input_error";
                    }
                    if (!state.senderEmail) {
                      errors.senderEmail = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    const apiURL =
                      window.location.hostname === "ams-pro-live.web.app" ||
                        window.location.hostname === "app.fusionamspro.com"
                        ? "https://us-central1-ams-pro-live.cloudfunctions.net/"
                        : "https://us-central1-ams-pro-qa.cloudfunctions.net/";
                    const apiResp = await axios.post(`${apiURL}mailjet-verifySenderEmail`,
                      {
                        "data": state
                      }
                    );
                    if (apiResp?.data?.result?.data?.Status === 'Active' || apiResp?.data?.result?.success) {
                      setErrorFields({});
                      handleClose();
                      enableAddons(
                        moduleToChange.module,
                        moduleToChange.checked,
                        state
                      );
                    } else {
                      Swal.fire(apiResp?.data?.result?.message || 'Somethig went wrong. Please contact support', '', apiResp?.data?.result?.data?.ValidationMethod ? 'info' : 'error');
                    }

                  }
                } else if (moduleToChange.module === "vision6") {
                  if (!state.api_key) {
                    let errors = {};
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "smshub") {
                  if (
                    !state.api_key ||
                    !state.api ||
                    !state.sender ||
                    !state.username
                  ) {
                    // console.log('smshub');
                    let errors = {};
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    if (!state.api) {
                      errors.api = "input_error";
                    }
                    if (!state.sender) {
                      errors.sender = "input_error";
                    }
                    if (!state.username) {
                      errors.username = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "cmdotcom") {
                  if (!state.api_key || !state.sender) {
                    let errors = {};
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    if (!state.sender) {
                      errors.sender = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "evalexpert") {
                  if (!state.apiKey || !state.api || !state.userEmail) {
                    // console.log('evalexpert');
                    let errors = {};
                    if (!state.apiKey) {
                      errors.apiKey = "input_error";
                    }
                    if (!state.api) {
                      errors.api = "input_error";
                    }
                    if (!state.userEmail) {
                      errors.userEmail = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "sms_broadcast") {
                  if (!state.sender_id || !state.api_key) {
                    // console.log('axon');
                    let errors = {};
                    if (!state.sender_id) {
                      errors.sender_id = "input_error";
                    }
                    if (!state.api_key) {
                      errors.api_key = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "autogate") {
                  if (
                    !_.isEmpty(lookup) &&
                    (!_.isEmpty(lookup.subList) ||
                      !_.isEmpty(lookup.leadSourceList) ||
                      !_.isEmpty(lookup.campaignList))
                  ) {
                    let lookupsToReturn =
                      lookup.subList &&
                      lookup.subList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    let leadSourceToReturn =
                      lookup.leadSourceList &&
                      lookup.leadSourceList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    let campaignToReturn =
                      lookup.campaignList &&
                      lookup.campaignList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state,
                      lookupsToReturn,
                      leadSourceToReturn,
                      campaignToReturn
                    );
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "nylas") {
                  if (!state.nylasSeats) {
                    setErrorFields({
                      nylasSeats: "input_error",
                    });
                  } else {
                    setErrorFields({});
                    handleClose();
                    console.log(
                      "THIS SHOULD BE THE PARAMS",
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (
                  moduleToChange.module === "autoit" ||
                  moduleToChange.module === "pmds"
                ) {
                  if (
                    !state.DatabaseId ||
                    !state.xAccessKey ||
                    !state.xSecretKey
                  ) {
                    let errors = {};
                    if (!state.DatabaseId) {
                      errors.DatabaseId = "input_error";
                    }
                    if (!state.xAccessKey) {
                      errors.xAccessKey = "input_error";
                    }
                    if (!state.xSecretKey) {
                      errors.xSecretKey = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "keyloop") {
                  if (
                    !state.baseUrl ||
                    !state.client_id ||
                    !state.client_secret ||
                    !state.enterpriseId ||
                    !state.storeId
                  ) {
                    let errors = {};
                    if (!state.baseUrl) {
                      errors.baseUrl = "input_error";
                    }
                    if (!state.client_id) {
                      errors.client_id = "input_error";
                    }
                    if (!state.client_secret) {
                      errors.client_secret = "input_error";
                    }
                    if (!state.enterpriseId) {
                      errors.enterpriseId = "input_error";
                    }
                    if (!state.storeId) {
                      errors.storeId = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "cap") {
                  if (
                    !state.region ||
                    !state.AuthGroup ||
                    !state.CampaignId ||
                    !state.campaignGuid ||
                    !state.ResponsibleSalesDealerId ||
                    !state.authURL ||
                    !state.baseUrl ||
                    !state.client_id ||
                    !state.client_secret ||
                    !state.porsche_client_id ||
                    !state.porsche_client_secret ||
                    !state.ppn_resource ||
                    !state.PagImporterId
                  ) {
                    let errors = {};
                    if (!state.region) {
                      errors.region = "input_error";
                    }
                    if (!state.AuthGroup) {
                      errors.AuthGroup = "input_error";
                    }
                    if (!state.CampaignId) {
                      errors.CampaignId = "input_error";
                    }
                    if (!state.campaignGuid) {
                      errors.campaignGuid = "input_error";
                    }
                    if (!state.ResponsibleSalesDealerId) {
                      errors.ResponsibleSalesDealerId = "input_error";
                    }
                    if (!state.authURL) {
                      errors.authURL = "input_error";
                    }
                    if (!state.baseUrl) {
                      errors.baseUrl = "input_error";
                    }
                    if (!state.client_id) {
                      errors.client_id = "input_error";
                    }
                    if (!state.client_secret) {
                      errors.client_secret = "input_error";
                    } if (!state.porsche_client_id) {
                      errors.porsche_client_id = "input_error";
                    } if (!state.porsche_client_secret) {
                      errors.porsche_client_secret = "input_error";
                    } if (!state.ppn_resource) {
                      errors.ppn_resource = "input_error";
                    } if (!state.PagImporterId) {
                      errors.PagImporterId = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    state.searchContact = true;
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "salesforce") {
                  if (!state.endpoint) {
                    let errors = {};
                    if (!state.endpoint) {
                      errors.endpoint = "input_error";
                    }
                    if (!state.clientId) {
                      errors.clientId = "input_error";
                    }
                    if (!state.clientSecret) {
                      errors.clientSecret = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (moduleToChange.module === "gforces") {
                  if (
                    !state.baseUrl ||
                    !state.client_id ||
                    !state.client_secret ||
                    !state.location_hash ||
                    !state.franchise_hash ||
                    !state.group_hash
                  ) {
                    let errors = {};
                    if (!state.baseUrl) {
                      errors.baseUrl = "input_error";
                    }
                    if (!state.client_id) {
                      errors.client_id = "input_error";
                    }
                    if (!state.client_secret) {
                      errors.client_secret = "input_error";
                    }
                    if (!state.location_hash) {
                      errors.location_hash = "input_error";
                    }
                    if (!state.franchise_hash) {
                      errors.franchise_hash = "input_error";
                    }
                    if (!state.group_hash) {
                      errors.group_hash = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (
                  moduleToChange.module === "imotor" ||
                  moduleToChange.module === "adtorqueedge" ||
                  moduleToChange.module === "appraisal_solutions" ||
                  moduleToChange.module === "bentley_cab" ||
                  moduleToChange.module === "carsales" ||
                  moduleToChange.module === "conversica" ||
                  moduleToChange.module === "creativo" ||
                  moduleToChange.module === "dubizzle" ||
                  moduleToChange.module === "easycars" ||
                  moduleToChange.module === "ferrari_modis" ||
                  moduleToChange.module === "gumtree" ||
                  moduleToChange.module === "heroleads" ||
                  moduleToChange.module === "c2native" ||
                  moduleToChange.module === "dubaicars" ||
                  moduleToChange.module === "aapwebsite" ||
                  moduleToChange.module === "projectcoavme" ||
                  moduleToChange.module === "nexa" ||
                  moduleToChange.module === "mattaki" ||
                  moduleToChange.module === "motorweb" ||
                  moduleToChange.module === "cap" ||
                  moduleToChange.module === "red_book" ||
                  moduleToChange.module === "roi" ||
                  moduleToChange.module === "shiftdigital" ||
                  //moduleToChange.module === 'sms_broadcast' ||
                  moduleToChange.module === "wildjar"
                ) {
                  if (
                    !_.isEmpty(lookup) &&
                    (!_.isEmpty(lookup.subList) ||
                      !_.isEmpty(lookup.leadSourceList) ||
                      !_.isEmpty(lookup.campaignList))
                  ) {
                    let lookupsToReturn =
                      lookup.subList &&
                      lookup.subList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    let leadSourceToReturn =
                      lookup.leadSourceList &&
                      lookup.leadSourceList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    let campaignToReturn =
                      lookup.campaignList &&
                      lookup.campaignList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state,
                      lookupsToReturn,
                      leadSourceToReturn,
                      campaignToReturn
                    );
                  } else {
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                } else if (
                  moduleToChange.module === "autotrader" ||
                  moduleToChange.module === "drivechat" ||
                  moduleToChange.module === "carsguide" ||
                  moduleToChange.module === "dealersolutions"
                ) {
                  // handleCheckChange
                  if (!state.Code || !state.EmailFrom || !state.LastNDays) {
                    let errors = {};
                    if (!state.Code) {
                      errors.Code = "input_error";
                    }
                    if (!state.EmailFrom) {
                      errors.EmailFrom = "input_error";
                    }
                    if (!state.LastNDays) {
                      errors.LastNDays = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    if (
                      !_.isEmpty(lookup) &&
                      (!_.isEmpty(lookup.subList) ||
                        !_.isEmpty(lookup.leadSourceList) ||
                        !_.isEmpty(lookup.campaignList))
                    ) {
                      let lookupsToReturn =
                        lookup.subList &&
                        lookup.subList.map((item) => {
                          delete item["id"];
                          delete item["make"];
                          delete item["saleType"];
                          return item;
                        });
                      let leadSourceToReturn =
                        lookup.leadSourceList &&
                        lookup.leadSourceList.map((item) => {
                          delete item["id"];
                          delete item["make"];
                          delete item["saleType"];
                          return item;
                        });
                      let campaignToReturn =
                        lookup.campaignList &&
                        lookup.campaignList.map((item) => {
                          delete item["id"];
                          delete item["make"];
                          delete item["saleType"];
                          return item;
                        });
                      let settings = {
                        ...state,
                        checkSubject: _.isBoolean(state.checkSubject)
                          ? state.checkSubject
                          : false,
                      };
                      setErrorFields({});
                      handleClose();
                      enableAddons(
                        moduleToChange.module,
                        moduleToChange.checked,
                        settings,
                        lookupsToReturn,
                        leadSourceToReturn,
                        campaignToReturn
                      );
                    } else {
                      let settings = {
                        ...state,
                        checkSubject: _.isBoolean(state.checkSubject)
                          ? state.checkSubject
                          : false,
                      };
                      setErrorFields({});
                      handleClose();
                      enableAddons(
                        moduleToChange.module,
                        moduleToChange.checked,
                        settings
                      );
                    }
                  }
                } else if (moduleToChange.module === "erapower") {
                  // handleCheckChange
                  if (
                    !state.xConnection ||
                    !state.leadSource ||
                    !state.leadSourceID ||
                    !state.username ||
                    !state.password
                  ) {
                    let errors = {};
                    if (!state.xConnection) {
                      errors.xConnection = "input_error";
                    }
                    if (!state.leadSource) {
                      errors.leadSource = "input_error";
                    }
                    if (!state.leadSourceID) {
                      errors.leadSourceID = "input_error";
                    }
                    if (!state.username) {
                      errors.username = "input_error";
                    }
                    if (!state.password) {
                      errors.password = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    let settings = {
                      ...state,
                      checkSubject: _.isBoolean(state.checkSubject)
                        ? state.checkSubject
                        : false,
                    };
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      settings
                    );
                  }
                } else if (moduleToChange.module === "ppsr") {
                  // handleCheckChange
                  if (!state.username || !state.password) {
                    let errors = {};
                    // if (!state.environment) {
                    // 	errors.environment = 'input_error'
                    // }
                    if (!state.username) {
                      errors.username = "input_error";
                    }
                    if (!state.password) {
                      errors.password = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    let settings = {
                      ...state,
                      checkSubject: _.isBoolean(state.checkSubject)
                        ? state.checkSubject
                        : false,
                    };
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      settings
                    );
                  }
                } else if (moduleToChange.module === "d365") {
                  // handleCheckChange
                  if (!state.mclRetailer) {
                    let errors = {};
                    // if (!state.environment) {
                    // 	errors.environment = 'input_error'
                    // }
                    if (!state.mclRetailer) {
                      errors.mclRetailer = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    let settings = { ...state };
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      settings
                    );
                  }
                } else if (moduleToChange.module === "saleslogs") {
                  if (
                    !state.Code ||
                    !state.username ||
                    !state.password ||
                    isEmpty(subList)
                  ) {
                    let errors = {};
                    if (!state.Code) {
                      errors.Code = "input_error";
                    }
                    if (!state.username) {
                      errors.username = "input_error";
                    }
                    if (!state.password) {
                      errors.password = "input_error";
                    }
                    if (isEmpty(subList)) {
                      errors["target-id"] = "input_error";
                      errors["target-make"] = "input_error";
                      errors["target-saleType"] = "input_error";
                    }
                    setErrorFields({ ...errors });
                  } else {
                    let lookupsToReturn = lookup.subList.map((item) => {
                      item.DeptName = item.id;
                      delete item["value"];
                      delete item["id"];
                      return item;
                    });
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state,
                      lookupsToReturn
                    );
                  }
                } else if (moduleToChange.module === 'emailIntg') {
                  if (
                    !_.isEmpty(lookup) &&
                    (!_.isEmpty(lookup.subList) ||
                      !_.isEmpty(lookup.leadSourceList) ||
                      !_.isEmpty(lookup.campaignList) ||
                      !_.isEmpty(lookup.enquirytypeList))
                  ) {
                    let lookupsToReturn =
                      lookup.subList &&
                      lookup.subList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    let leadSourceToReturn =
                      lookup.leadSourceList &&
                      lookup.leadSourceList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    let campaignToReturn =
                      lookup.campaignList &&
                      lookup.campaignList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    let enquirytypeToReturn =
                      lookup.enquiryTypeList &&
                      lookup.enquiryTypeList.map((item) => {
                        delete item["id"];
                        delete item["make"];
                        delete item["saleType"];
                        return item;
                      });
                    let settings = {
                      ...state,
                      id: moduleToChange?.data?.id || null,
                      checkSubject: _.isBoolean(state.checkSubject)
                        ? state.checkSubject
                        : false,
                    };
                    settings.enquiryTypeKW = enquirytypeToReturn || [];
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange?.data?.type,
                      moduleToChange.checked,
                      settings,
                      lookupsToReturn,
                      leadSourceToReturn,
                      campaignToReturn
                    );
                  } else {
                    let settings = {
                      ...state,
                      id: moduleToChange?.data?.id || null,
                      checkSubject: _.isBoolean(state.checkSubject)
                        ? state.checkSubject
                        : false,
                    };
                    setErrorFields({});
                    handleClose();
                    enableAddons(
                      moduleToChange?.data?.type,
                      moduleToChange.checked,
                      settings
                    );
                  }
                } else {
                  if (isEmpty(errorFields)) {
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                }
              }}
            >
              {
                btnLoader ?
                  (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                  : (<></>)
              }
              <Translate text={"Save"} />
            </button>
          ) : (
            ""
          )}

          <button
            type="button"
            className="btn btn-default float-left"
            onClick={() => handleClose(null)}
          >
            {/* {
								loader ?
									(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
									: (<></>)
							} */}
            <Translate text={"close"} />
          </button>
        </Modal.Footer>
      </Modal >
      <PopUpModal show={publishForm.show}>
        <PublishForm
          {...publishForm}
          dealersettings={dealersettings}
          handleClose={() => {
            setPublishForm({ show: false, title: "", feedbackForm: null });
          }}
          isFromTableView={true}
        />
      </PopUpModal>
    </>
  );
};

export default Options;
