import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Translate from '../../constants/translate'
import CommonHelper from '../../services/common'
import _, { pick } from 'lodash'
import { InputCheckBox, InputMultipleText, InputText, PopUpModal, ReactAsyncSelect, ReactMultiSelect } from '../../components'
import MentionEditor from '../../components/mentionEditor/mentionEditor';
import Swal from 'sweetalert2'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddRecipient from './addRecipient'
import { MainContext } from '../../views/layout/provider';
import moment from 'moment'
import { storageBucket } from '../../services/helper'


const ViewRecipient = (props) => {
    const [payload, setpayload] = useState(props.data || {})
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [addModel, setaddModel] = useState({ show: false })
    const [pageloader, setpageLoader] = useState(false)
    const { dealersettings } = useContext(MainContext);
    const [pdfLoader, setPdfLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.document_id)) {
            return
        }
        setpageLoader(true)
        const getDocument = window.firebase.functions().httpsCallable('pandadoc-getDocument');
        getDocument({
            "api_key": props.apikey,
            "id": props.document_id
        }).then((resp) => {
            setpageLoader(false);
            if (resp?.data?.success) {
                const metadata = resp?.data?.data?.metadata || {};
                let url = '';
                if (metadata.origin) {
                    url = `${metadata.origin}${metadata.pathname1}${metadata.pathname2 || ''}${metadata.search}`;
                }
                setpayload({
                    id: resp?.data?.data?.id,
                    url: url,
                    ...(resp?.data?.data?.metadata || {}),
                    recipients: resp?.data?.data?.recipients?.map(r => {
                        return {
                            id: r.id,
                            firstName: r.first_name,
                            lastName: r.last_name,
                            name: `${r.first_name || ''} ${r.last_name || ''}`,
                            email: r.email
                        }
                    })
                })
            }
        });
    }, [])

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setpayload({
                ...payload,
                [name]: newarray1.join(' ')

            })
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setpayload({
                ...payload,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const onMentionChange = notesText => {
        setpayload({
            ...payload,
            ['message']: notesText
        });
        setErrorFields({
            ...errorFields,
            ['message']: ''
        });
    };

    const onMentionSelect = (option, ownerID) => {
        console.log('select', option);
    };

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setpayload({
            ...payload,
            [name]: value
        });
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleOnChangeMultipleText = (val, name) => {
        setpayload({
            ...payload,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const removeEmail = (index, name) => {
        let arr = payload[name] ? _.isArray(payload[name]) ? payload[name] : payload[name].split(',') : []
        arr.splice(index, 1)
        setpayload({
            ...payload,
            [name]: arr.join(',')
        })
    }

    const handleSubmit = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(payload['subject'])) {
            formIsValid = false;
            errors['subject'] = errorClass;
        }
        if (_.isEmpty(payload['recipients'])) {
            formIsValid = false;
            Swal.fire('Please add recipients', '', 'error')
        }
        if (_.isEmpty(payload['message'])) {
            formIsValid = false;
            errors['message'] = errorClass;
        }
        if (props.saveDoc && _.isEmpty(props?.data?.url) && _.isEmpty(payload.url)) {
            formIsValid = false;
            errors['pdffile'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        if (props.saveDoc) {
            setLoader(true)
            const _payload = {
                "name": payload.subject,
                "url": props?.data?.url || payload.url,
                "parse_form_fields": false,
                "metadata": {
                    ...(props?.data?.metadata) || {},
                    ..._.pick(payload, ['subject', 'message', 'signingOrder', 'fileName', 'fileSize'])
                }
            }
            const _emails = [];
            if (payload?.recipients) {
                payload.recipients.map((rec, index) => {
                    return _emails.push(CommonHelper.removeEmptyOrNull({
                        id: rec.id,
                        first_name: rec.firstName || rec?.name?.split(' ')[0] || rec.name,
                        last_name: rec.lastName || rec?.name?.split(' ')[1] || '',
                        email: rec.email,
                        signing_order: payload?.signingOrder ? index + 1 : 1
                    }))
                })
            }
            _payload.recipients = _emails;
            if (!_payload.metadata.projectId) {
                delete _payload.metadata.projectId;
            }
            const createDocument = window.firebase.functions().httpsCallable('pandadoc-createDocument');
            createDocument({
                "api_key": props.apikey,
                "id": payload.id,
                "payload": _payload
            }).then(async (resp) => {
                console.log(resp)
                if (resp.data.success === true) {
                    setTimeout(() => {
                        props.handleClose(payload, (!_.isEmpty(resp.data.data) ? resp.data.data : payload))
                    }, 1000)
                }
                else {
                    Swal.fire(resp.data.message || 'Something went wrong', '', 'error')
                }
            });
        } else {
            props.handleClose(payload)
        }
    }

    const handleClientCheckChange = (e) => {
        setpayload({
            ...payload,
            [e.target.name]: e.target.checked
        })
    }

    const handleReactSelectChange = (e, data) => {
        setpayload({
            ...payload,
            recipients: [
                ...(payload.recipients || []),
                {
                    email: e.email,
                    name: e.searchLabel,
                    imageURL: e.userImageURL,
                }
            ]
        })
    }

    const handleCreate = (inputValue) => {
        setaddModel({
            show: true,
            title: 'Add Recipient',
            data: {
                firstName: inputValue,
                index: payload?.recipients?.length || 0
            },
            new: true
        });
    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = payload.recipients.splice(result.source.index, 1);
        payload.recipients.splice(result.destination.index, 0, removed);
        setpayload(payload)
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    const handleEditList = (data, index) => {
        setaddModel({
            show: true,
            title: 'Edit Recipient',
            data: {
                ...data,
                firstName: data.firstName || data?.name?.split(' ')[0] || data.name,
                lastName: data.lastName || data?.name?.split(' ')[1] || '',
                index: index
            }
        });
    }

    const handleDeleteList = (data, index) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete?.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {
                if (data.id && props.document_id) {
                    setpageLoader(true)
                    const removeRecipient = window.firebase.functions().httpsCallable('pandadoc-removeRecipient');
                    await removeRecipient({
                        "api_key": props.apikey,
                        "id": props.document_id,
                        "recipient_id": data.id
                    })
                    setpageLoader(false)
                }
                payload.recipients.splice(index, 1)
                setpayload({
                    ...payload,
                    recipients: payload.recipients
                })
            }
        })
    }

    const handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (value.length > 0) {
            setErrorFields({ ...errorFields, [name]: '' });
        }
        setpayload({
            ...payload,
            [name]: value
        });
    }

    const onSelectFile = (e, type, id, title, rec) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(pdf)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'Please select pdf file'), '', 'info')
                return;
            }

            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                saveImageToStorage(reader.result, id, type, file.name.substr((file.name.lastIndexOf('.') + 1)), file.name, file.size)
            }
            reader.readAsDataURL(file)
        }
    }

    const saveImageToStorage = (url, id, type, ext, name, size) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        setPdfLoader(true)
        var storageRef = storageBucket(props.dealersettings).ref(`${props.dealersettings.client.id}/files/${uid}`).putString(url, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                //console.log(error);
            },
            () => {
                storageBucket(props.dealersettings).ref(`${props.dealersettings.client.id}/files`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        setpayload({
                            ...payload,
                            ['url']: dataURL,
                            ['fileName']: name,
                            ['fileSize']: size,
                        })
                        setPdfLoader(false)
                    })
            })
    };

    return <><Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-review-recipients"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body >
            {
                pageloader
                    ?
                    <>
                        <div className="dashboard-widget-loader h-100">
                            <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>

                        </div>
                    </>
                    :
                    <>
                    </>
            }
            {/* <div className="review-recipients-text">Confirm the recipients below before sending the document.</div> */}
            <div className="review-recipients-borderbox">
                <div className="review-recipients-title">
                    <div className="form-group w-100 d-block">
                        <label> <Translate text={'Subject'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'subject'}
                            className={`form-control ${errorFields["subject"]}`}
                            name="subject"
                            onChange={handleOnChange}
                            value={payload.subject}
                        />
                    </div>
                </div>
            </div>
            <div className="review-recipients-borderbox">
                <div className="review-recipients-head">Add your recipients</div>
                <div className="review-recipients-check">
                    <span className="switch switch-sm ">
                        <InputCheckBox
                            id="enable-signingOrder"
                            className="switch"
                            name={'signingOrder'}
                            checked={Boolean(payload.signingOrder)}
                            onChange={(e) => { handleClientCheckChange(e) }} />
                        <label htmlFor="enable-signingOrder"> Enable signing order</label>
                    </span>
                </div>
                {
                    payload?.recipients?.length ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="rearrange-stage-wrap">
                                        {

                                            payload?.recipients.map((rec, index) => {
                                                return <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={!Boolean(payload.signingOrder)}>
                                                    {(provided1, snapshot1) => (
                                                        <div
                                                            ref={provided1.innerRef}
                                                            {...provided1.draggableProps}
                                                            {...provided1.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot1.isDragging,
                                                                provided1.draggableProps.style
                                                            )}
                                                            className="review-recipients-rearrange-box review-recipients-flex-queue review-recipients-green">
                                                            <div className="review-recipients-user-avatar">
                                                                <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.imageURL, rec.name)} />
                                                            </div>
                                                            <div className="review-recipients-rearrange-detail">
                                                                <h3>{rec.name}       </h3>
                                                                <h5>{rec.email}</h5>
                                                            </div>
                                                            <div className="table-edit float-right mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditList(rec, index) }}> <i className="ico icon-edit"></i></a></div>
                                                            <div className="table-edit float-right mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteList(rec, index) }}> <i className="ico icon-delete"></i></a></div>
                                                            {
                                                                !Boolean(payload.signingOrder) ? (<></>) : (<div className="review-recipients-field-drag"> <i className="ico icon-drag"></i></div>)
                                                            }
                                                        </div>
                                                    )}
                                                </Draggable>
                                            })
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    ) : (<></>)
                }
                <div className="review-recipients-rearrange-box review-recipients-flex-queue review-recipients-grey">

                    <div className="form-group w-100 d-block form-style review-recipients-add">
                        <label> <Translate text={'Add Recipient'} /></label>
                        <ReactAsyncSelect
                            options={props.clientUsers.filter(e => Boolean(e.superAdmin) === false)
                                .map(rec => {
                                    return {
                                        value: rec.email,
                                        email: rec.email,
                                        searchLabel: rec.name,
                                        active: _.isBoolean(rec.active) ? rec.active : true,
                                        profileImage: rec.profileImage,
                                        label: (
                                            <div className='drop-image'>
                                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                {rec.name}
                                                <br /><span >{rec.email}</span>
                                            </div>
                                        ),
                                    };
                                })}
                            name={"customer"}
                            placeholder={'search recipient'}
                            search={true}
                            onChange={handleReactSelectChange}
                            value={[]}
                            classNamePrefix={`basic-select`}
                            onCreate={handleCreate}
                            searchContact={true}
                            searchOptions={true}
                            clientID={dealersettings?.client?.id}
                            groupID={dealersettings?.client?.group}
                            enableSharedContact={dealersettings?.group?.enableSharedContact}
                            dealersettings={dealersettings}
                        >
                        </ReactAsyncSelect>


                    </div>
                </div>
            </div>
            {
                props.uploadDoc ? (
                    <div className="review-recipients-borderbox">
                        <div className="review-recipients-head mt-3"> <Translate text={'Upload Pdf File'} /></div>
                        <div className={`tv-device-upload-box`}>
                            {
                                payload.url ? (
                                    <div className="uploaded-list-video">
                                        <div className="flex-upload">
                                            {
                                                props.newDoc ? (
                                                    <div className="upload-clear">
                                                        <a href="#" onClick={(e) => {
                                                            setpayload({
                                                                ...payload,
                                                                url: null
                                                            })
                                                        }} className={''}>
                                                            <i className="fa fa-times" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className="file-upload-icon">
                                                <i className="ico icon-files-pdf"></i>
                                            </div>
                                            <div className="upload-data cursor-pointer" onClick={(e) => {
                                                e.preventDefault();
                                                window.open(payload.url, '_blank').focus();
                                            }}>
                                                {payload.fileName}
                                                {' (' + CommonHelper.showFileSize(payload.fileSize) + ')'}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <a href="#" className={` ${errorFields['pdffile']}`}>
                                        <label htmlFor='pdfUpload' className="tv-device-upload-iconwrap" style={{ margin: 0, cursor: 'pointer' }}>
                                            {
                                                pdfLoader ? (
                                                    <span className="spinner-border text-primary mt-1 float-none" role="status" aria-hidden="true"></span>
                                                ) : (<> <i className="ico icon-files-upload"></i> <span className="upload-text"><Translate text={'Upload File'} /></span></>)
                                            }
                                            <input className="fileInput"
                                                type="file"
                                                id="pdfUpload"
                                                accept="pdf"
                                                style={{ display: 'none' }}
                                                onChange={(e) => onSelectFile(e, 'profile', 'url', 'Add PDF')} />
                                        </label>
                                    </a>
                                )
                            }

                        </div>
                    </div>
                ) : (<></>)
            }
            <div className="review-recipients-borderbox">
                <div className="review-recipients-head mt-3"> <Translate text={'Message'} /></div>
                <div class="review-recipients-textarea-wrap">
                    <textarea
                        tarea
                        className={`${errorFields['message']} form-control notes`}
                        name={'message'}
                        onChange={(e) => handleNotesChanges(e)}
                        value={payload.message}
                        placeholder="message"
                    ></textarea>
                </div>
            </div>
            {/* <div className="review-recipients-textarea-wrap">
                <MentionEditor

                    mentionsData={[]}
                    onMentionChange={onMentionChange}
                    onMentionSelect={onMentionSelect}
                    notes={payload.message || ''}
                    focusOnMount={false}
                    ownerID={''}
                    showCustomSuggestions={true}
                    isNoteEmpty={errorFields['message'] ? true : false}
                />
            </div> */}

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button
                type="button"
                className="btn btn-primary float-right ml-2"
                onClick={(e) => { handleSubmit() }}

            >
                {loader ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                <Translate text={'continueText'} />
            </button>
            <button
                type="button"
                className="btn btn-default float-right"
                onClick={(e) => { props.handleClose() }}
            ><Translate text={'cancel'} />
            </button>


        </Modal.Footer>
    </Modal >
        <PopUpModal show={addModel.show}>
            <AddRecipient
                {...addModel}
                handleClose={(val, add) => {
                    if (val) {
                        if (!payload?.recipients?.length) {
                            payload.recipients = [];
                        }
                        setpayload({
                            ...payload,
                            recipients: add ? [
                                ...payload.recipients,
                                val
                            ] : payload.recipients.map((r, i) => {
                                return i === val.index ? val : r
                            })
                        })
                    }
                    setaddModel({ show: false });
                }}
            />
        </PopUpModal>
    </>
}

export default ViewRecipient