import React, { useState, useEffect } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import Translate from "../../../../constants/translate";
import _ from 'lodash'
import toast from "toasted-notes";
import CustomTable from "../../../../components/customTable";
import { InputCheckBox, InputText, ReactSelect } from "../../../../components";
import Swal from "sweetalert2";
import CommonHelper from "../../../../services/common";

const originDynCols = [
    { name: 'Keywords', value: 'kw', type: 'text', mandatory: true, width: '42.5' },
    { name: 'Origin', value: 'v', type: 'select', mandatory: true, width: '42.5' }
]

const mappingDynCols = [
    { name: 'Fields', value: 'field', type: 'select', mandatory: true, width: '30' },
    { name: 'Mapping Columns', value: 'mKey', type: 'select', mandatory: true, width: '30' },
    { name: 'Field Type', value: 'type', type: 'select', width: '25' }
]

const inboundFields = [
    { label: 'Title', value: 'contact.title' },
    { label: 'First Name', value: 'contact.firstName' },
    { label: 'Middle Name', value: 'contact.middleName' },
    { label: 'Last Name', value: 'contact.lastName' },
    { label: 'Phone', value: 'contact.phone' },
    { label: 'Email', value: 'contact.email' },
    { label: 'Address', value: 'contact.address' },
    { label: 'Area Code', value: 'contact.areaCode' },
    { label: 'Date Of Birth', value: 'contact.dob' },
    { label: 'City', value: 'contact.suburb' },
    { label: 'Make', value: 'requirement.make' },
    { label: 'Model', value: 'requirement.model' },
    { label: 'Sale Type', value: 'requirement.saleType' },
    { label: 'Year', value: 'requirement.year' },
    { label: 'Origin', value: 'origin' },
    { label: 'Campaign', value: 'campaign' },
    { label: 'Enquiry Type', value: 'enquiryType' },
    { label: 'Lead Source', value: 'leadSource' },
    { label: 'Added Date', value: 'addedDate' },
    { label: 'Notes', value: 'notes' },
]

export const fieldTypes = [
    { value: 'date', label: (<><i className="ico icon-dynamic-datefield mr-1"></i> Date Field</>) },
]


const AddGoogleSheetIntegration = (props) => {
    const [errorFields, setErrorFields] = useState({});
    const [state, setState] = useState(props.data);
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})
    const [models, setModels] = useState({})
    const [fetchLoader, setfetchLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.currentSettings)) return;

        let _settings = {};

        if (!_.isEmpty(props.currentSettings.settings)) {
            _settings = { ...props.currentSettings.settings };
        } else if (!_.isEmpty(props.currentSettings) && _.isEmpty(props.currentSettings.settings)) {
            _settings = { ...props.currentSettings };
        }

        let _campaigns = [];
        let _origins = [];
        let _enquiryTypes = [];
        let _leadSource = [];

        !_.isEmpty(_settings.campaigns) &&
            _settings.campaigns.forEach((campaign) => {
                _campaigns.push({
                    label: campaign.name,
                    value: campaign.value,
                    data: campaign,
                    active: _.isBoolean(campaign.active) ? campaign.active : true,
                });
            });

        !_.isEmpty(_settings.origins) &&
            _settings.origins.forEach((origin) => {
                _origins.push({
                    label: origin.name,
                    value: origin.value,
                    data: origin,
                    active: _.isBoolean(origin.active) ? origin.active : true,
                });
            });

        !_.isEmpty(_settings.enquiryTypes) &&
            _settings.enquiryTypes.forEach((enquiryType) => {
                _enquiryTypes.push({
                    label: enquiryType.name,
                    value: enquiryType.value,
                    data: enquiryType,
                    active: _.isBoolean(enquiryType.active) ? enquiryType.active : true,
                });
            });

        !_.isEmpty(_settings.leadSource) &&
            _settings.leadSource.forEach((leadSource) => {
                _leadSource.push({
                    label: leadSource.name,
                    value: leadSource.value,
                    data: leadSource,
                    active: _.isBoolean(leadSource.active) ? leadSource.active : true,
                });
            });


        setData({
            campaigns: _campaigns,
            origins: _origins,
            enquiryTypes: _enquiryTypes,
            leadSources: _leadSource,
        });

    }, [props.currentSettings]);

    useEffect(() => {
        if (_.isEmpty(state?.defaultMake)) {
            return
        }
        const brands = props.dealersettings.allMakeModels;
        const __models = [];
        const __makes = [];
        _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
            __makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true
            });
        });
        let _models = !_.isEmpty(__makes.filter(m => m.value === state?.defaultMake)[0]) ? __makes.filter(m => m.value === state?.defaultMake)[0].models : [];
        _.orderBy(_models, ['name'], ['asc']).map((data, index) => {
            __models.push({
                value: data.value,
                active: _.isBoolean(data.active) ? data.active : true,
                label: CommonHelper.displayModelName(data),
            });
        });

        setModels(__models)

    }, [state?.defaultMake])

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
        if (e.target.value.length > 0) {
            setErrorFields({
                ...errorFields,
                [e.target.name]: "",
            });
        }
    };

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;
        //console.log(name, checked);
        setState({ ...state, [name]: checked });
    };

    const handleNumberChange = (e, edit) => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setState({
                ...state,
                [name]: Number(number)
            });
            setErrorFields({
                ...errorFields,
                [e.target.name]: "",
            });
        }


    };

    const handleSubmit = (e) => {
        let errors = {};
        let formvalid = true;
        if (!state?.sheetName) {
            errors.sheetName = "input_error";
            formvalid = false
        }
        if (!state?.sheetKey) {
            errors.sheetKey = "input_error";
            formvalid = false
        }
        if (!state?.name) {
            errors.name = "input_error";
            formvalid = false
        }
        // if (!state?.dateCheck) {
        //     errors.dateCheck = "input_error";
        //     formvalid = false
        // }
        setErrorFields({ ...errors });
        if (!formvalid) {
            return
        }
        let integrationRef;
        let clientRef;
        if (props.selectedLevel.level === "oem") {
            integrationRef = window.firebase
                .firestore()
                .collection(`clientSettings`)
                .doc(props.dealersettings.client.settingsID)
                .collection("integrations");
            clientRef = window.firebase
                .firestore()
                .collection(`clientSettings`)
                .doc(props.dealersettings.client.settingsID);
        } else if (props.selectedLevel.level === "region") {
            integrationRef = window.firebase
                .firestore()
                .collection(`clientSettings`)
                .doc(props.dealersettings.client.settingsID)
                .collection("regions")
                .doc(props.selectedLevel.levelID)
                .collection("integrations");
            clientRef = window.firebase
                .firestore()
                .collection(`clientSettings`)
                .doc(props.dealersettings.client.settingsID)
                .collection("regions")
                .doc(props.selectedLevel.levelID);
        } else if (props.selectedLevel.level === "group") {
            integrationRef = window.firebase
                .firestore()
                .collection(`clientSettings`)
                .doc(props.dealersettings.client.settingsID)
                .collection("groups")
                .doc(props.selectedLevel.levelID)
                .collection("integrations");
            clientRef = window.firebase
                .firestore()
                .collection(`clientSettings`)
                .doc(props.dealersettings.client.settingsID)
                .collection("groups")
                .doc(props.selectedLevel.levelID);
        } else if (props.selectedLevel.level === "individual") {
            integrationRef = window.firebase
                .firestore()
                .collection(`clients`)
                .doc(props.selectedLevel.levelID)
                .collection("integrations");
            clientRef = window.firebase
                .firestore()
                .collection(`clients`)
                .doc(props.selectedLevel.levelID);
        }
        if (_.isEmpty(state.documentID))
            state.documentID = window.firebase.firestore().collection("clients").doc().id;

        state.EmailFrom = 'googlesheet';
        state.Code = 'googlesheet';
        let reqData = {
            enabled: true,
            active: true,
            googleSheet: true,
            inbound: true,
            type: 'googlesheet',
            name: state?.name || '',
            settings: state
        };
        setLoader(true)
        if (state.minutes >= 0) {
            clientRef.update({ 'clientSettings.emailleads.time': state.minutes })
        }

        integrationRef
            .doc(state.documentID)
            .set(reqData, { merge: true })
            .then(async (snapshot) => {
                setLoader(false)
                toast.notify(
                    `Google Sheet added successfully.`,
                    {
                        duration: 2000,
                    }
                );
                props.handleClose()
            })
    }

    const handleReactSelectChange = (e, data, edit) => {

        setState({
            ...state,
            [data.name]: e?.value || ''
        });

        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const getColumns = () => {
        let errors = {};
        let formvalid = true;
        if (!state?.sheetName) {
            errors.sheetName = "input_error";
            formvalid = false
        }
        if (!state?.sheetKey) {
            errors.sheetKey = "input_error";
            formvalid = false
        }
        setErrorFields({ ...errors });
        if (!formvalid) {
            return
        }
        setfetchLoader(true)
        const cols = window.firebase.functions().httpsCallable('googlesheet-getSheetHeader');
        cols({
            "key": state.sheetKey,
            "name": state.sheetName,
        }).then((data) => {
            setfetchLoader(false)
            if (data.data.success) {
                setState({
                    ...state,
                    sheetColumns: data.data.data
                })
            } else {
                Swal.fire(data.data.message, '', 'error')
            }

        })
    }

    return (<>
        <Modal
            id="add-cafe"
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? "overlay-modal active" : ""}
            enforceFocus={false}
            dialogClassName={"modal-dialog modal-dialog-scrollable modal-dialog-centered modal-configure-integration-generic-api"}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className="modal-title">
                        <Translate text={props.title} />
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>
                                        <Translate text={"Name"} />{" "}
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errorFields["name"]}`}
                                        value={state?.name || ""}
                                        placeholder={"enter name"}
                                        onChange={handleChange}
                                        name="name"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        <Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'enquiryType')} />
                                    </label>
                                    <ReactSelect
                                        options={data.enquiryTypes}
                                        name={'enquiryType'}
                                        placeholder={`select enquiryType`}
                                        onChange={handleReactSelectChange}
                                        value={state?.enquiryType}
                                        classNamePrefix={`${errorFields['enquiryType']} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>
                                        <Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'origin')} />
                                    </label>
                                    <ReactSelect
                                        options={data.origins}
                                        name={'origin'}
                                        placeholder={`select origin`}
                                        onChange={handleReactSelectChange}
                                        value={state?.origin}
                                        classNamePrefix={`${errorFields['origin']} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>
                                        <Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'leadSource')} />
                                    </label>
                                    <ReactSelect
                                        options={data.leadSources}
                                        name={'leadSource'}
                                        placeholder={`select leadSource`}
                                        onChange={handleReactSelectChange}
                                        value={state?.leadSource}
                                        classNamePrefix={`${errorFields['leadSource']} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        <Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'campaign')} />
                                    </label>
                                    <ReactSelect
                                        options={data.campaigns}
                                        name={'campaign'}
                                        placeholder={`select campaign`}
                                        onChange={handleReactSelectChange}
                                        value={state?.campaign}
                                        classNamePrefix={`${errorFields['campaign']} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        <Translate text={"Sheet Name"} />{" "}
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errorFields["sheetName"]}`}
                                        value={state?.sheetName || ""}
                                        placeholder={"enter sheet name"}
                                        onChange={handleChange}
                                        name="sheetName"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        <Translate text={"Sheet Key"} />{" "}
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errorFields["sheetKey"]}`}
                                        value={state?.sheetKey || ""}
                                        placeholder={"enter key"}
                                        onChange={handleChange}
                                        name="sheetKey"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        <Translate text={"Default Make"} />{" "}
                                    </label>
                                    <ReactSelect
                                        options={props?.dealersettings?.client?.brands?.map(r => { return { value: r.value, label: r.name } })}
                                        name={'defaultMake'}
                                        placeholder={`select make`}
                                        onChange={handleReactSelectChange}
                                        value={state?.defaultMake}
                                        classNamePrefix={`${errorFields['defaultMake']} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>
                                        <Translate text={"Row Check to fetch new data"} />{" "}
                                    </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={"enter row no"}
                                        className={`form-control ${errorFields["rowCheck"]}`}
                                        name="rowCheck"
                                        onChange={handleNumberChange}
                                        value={state?.rowCheck}
                                        id={`rowCheck`}
                                    />
                                </div>
                                <div className="form-group col-md-2">
                                    <label className="w-100">
                                        &nbsp;
                                    </label>
                                    <button
                                        type="button"
                                        className="btn btn-green float-left"
                                        onClick={getColumns}
                                    >
                                        {fetchLoader ? (
                                            <span
                                                className="spinner-border spinner-button mr-1"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <></>
                                        )}
                                        <Translate text={"Fetch Columns"} />
                                    </button>
                                </div>
                                <div className="form-group col-md-2">
                                    <label>
                                        <Translate text={"Schedule Every"} />{" "}
                                    </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={"enter min"}
                                        className={`form-control ${errorFields["minutes"]}`}
                                        name="minutes"
                                        onChange={handleNumberChange}
                                        value={state?.minutes}
                                        id={`minutes`}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="w-100">
                                        &nbsp;
                                    </label>
                                    <span className="float-left mt-2">
                                        Minutes (0 to disable)
                                    </span>

                                </div>
                                <div className="form-group col-md-3">
                                    <label>
                                        <Translate text={"Dealer Mapping Key"} />{" "}
                                    </label>
                                    <ReactSelect
                                        options={state?.sheetColumns?.map((r, i) => { return { value: `${i}`, label: r } }) || []}
                                        name={'dealerMappingKey'}
                                        placeholder={`select key`}
                                        onChange={handleReactSelectChange}
                                        value={state?.dealerMappingKey}
                                        classNamePrefix={`${errorFields['dealerMappingKey']} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>
                                        <Translate text={"Keywords"} />{" "}
                                    </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={"enter keywords"}
                                        className={`form-control ${errorFields["dealerMappingKW"]}`}
                                        name="dealerMappingKW"
                                        onChange={handleChange}
                                        value={state?.dealerMappingKW}
                                        id={`dealerMappingKW`}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="settings-tab-wrap tabs col-md-12">
                            <div className="tabs-wraper">
                                <nav>
                                    <Tabs
                                        defaultActiveKey="mapping"
                                        className="nav-fill"
                                    >
                                        <Tab eventKey="mapping" title="Mapping Columns">
                                            <CustomTable
                                                dealersettings={props.dealersettings}
                                                cols={mappingDynCols}
                                                subList={state?.mappingColums}
                                                setSubList={(list) => { setState({ ...state, mappingColums: list }) }}
                                                field={inboundFields}
                                                mKey={state?.sheetColumns?.map((r, i) => { return { value: `${i}`, label: r } }) || []}
                                                type={fieldTypes}
                                                dragDisabled={true}
                                            ></CustomTable>
                                        </Tab>
                                        <Tab eventKey="enquiryType" title={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'enquiryType')}>
                                            <CustomTable
                                                dealersettings={props.dealersettings}
                                                cols={originDynCols.map(r => { return r.name === 'Origin' ? { ...r, name: CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'enquiryType') } : { ...r } })}
                                                subList={state?.enquiryTypeKW}
                                                setSubList={(list) => { setState({ ...state, enquiryTypeKW: list }) }}
                                                v={data.enquiryTypes}
                                                dragDisabled={true}
                                            ></CustomTable>
                                        </Tab>
                                        <Tab eventKey="origin" title={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'origin')}>
                                            <CustomTable
                                                dealersettings={props.dealersettings}
                                                cols={originDynCols.map(r => { return { ...r, name: CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'origin') } })}
                                                subList={state?.originKW}
                                                setSubList={(list) => { setState({ ...state, originKW: list }) }}
                                                v={data.origins}
                                                dragDisabled={true}
                                            ></CustomTable>
                                        </Tab>
                                        <Tab eventKey="leadSource" title={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'leadSource')}>
                                            <CustomTable
                                                dealersettings={props.dealersettings}
                                                cols={originDynCols.map(r => { return r.name === 'Origin' ? { ...r, name: CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'leadSource') } : { ...r } })}
                                                subList={state?.leadSourceKW}
                                                setSubList={(list) => { setState({ ...state, leadSourceKW: list }) }}
                                                v={data.leadSources}
                                                dragDisabled={true}
                                            ></CustomTable>
                                        </Tab>
                                        <Tab eventKey="campaign" title={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'campaign')}>
                                            <CustomTable
                                                dealersettings={props.dealersettings}
                                                cols={originDynCols.map(r => { return r.name === 'Origin' ? { ...r, name: CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'campaign') } : { ...r } })}
                                                subList={state?.campaignKW}
                                                setSubList={(list) => { setState({ ...state, campaignKW: list }) }}
                                                v={data.campaigns}
                                                dragDisabled={true}
                                            ></CustomTable>
                                        </Tab>
                                        <Tab eventKey="models" title="Models">
                                            <CustomTable
                                                dealersettings={props.dealersettings}
                                                cols={originDynCols.map(r => { return r.name === 'Origin' ? { ...r, name: 'Model' } : { ...r } })}
                                                subList={state?.modelKW}
                                                setSubList={(list) => { setState({ ...state, modelKW: list }) }}
                                                v={models}
                                                dragDisabled={true}
                                            ></CustomTable>
                                        </Tab>
                                        <Tab eventKey="settings" title="Settings">
                                            <div className="common-table col-md-12 pl-0 pr-0">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6 mt-2">
                                                        <span className={`switch switch-sm float-left`}>
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                id={`facebook-oem`}
                                                                name={"showinOEM"}
                                                                checked={Boolean(state.showinOEM)}
                                                                onChange={(e) => {
                                                                    handleCheckChange(e);
                                                                }}
                                                            />
                                                            <label htmlFor={`facebook-oem`}>Show in OEM</label>
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-6 mt-2">
                                                        <span className={`switch switch-sm float-left`}>
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                id={`facebook-region`}
                                                                name={"showinRegion"}
                                                                checked={Boolean(state.showinRegion)}
                                                                onChange={(e) => {
                                                                    handleCheckChange(e);
                                                                }}
                                                            />
                                                            <label htmlFor={`facebook-region`}>
                                                                Show in Region
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-6 mt-2">
                                                        <span className={`switch switch-sm float-left`}>
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                id={`facebook-group`}
                                                                name={"showinGroup"}
                                                                checked={Boolean(state.showinGroup)}
                                                                onChange={(e) => {
                                                                    handleCheckChange(e);
                                                                }}
                                                            />
                                                            <label htmlFor={`facebook-group`}>
                                                                Show in Group
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-6 mt-2">
                                                        <span className={`switch switch-sm float-left`}>
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                id={`facebook-client`}
                                                                name={"hideinClient"}
                                                                checked={Boolean(state.hideinClient)}
                                                                onChange={(e) => {
                                                                    handleCheckChange(e);
                                                                }}
                                                            />
                                                            <label htmlFor={`facebook-client`}>
                                                                Hidden in Client
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary float-right ml-2 "
                    aria-label="Save"
                    onClick={handleSubmit}
                >
                    {loader ? (
                        <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    ) : (
                        <></>
                    )}
                    <Translate text={"save"} />
                </button>
                <button
                    type="button"
                    className="btn btn-default float-left"
                    onClick={() => props.handleClose(null)}
                >
                    <Translate text={"close"} />
                </button>
            </Modal.Footer>
        </Modal>
    </>)
};

export default AddGoogleSheetIntegration;
