import React, { useState, useEffect } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import Swal from 'sweetalert2'
import moment from 'moment';
import { ReactSelect, PopUpModal } from '../../components';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { firestoreDB } from '../../services/helper';
import { objActivitylog } from './viewModel';
import { objRequirement } from '../requirement/viewModel';
import enquiryHelper from '../../services/enquiryHelper';
import { contactResponseCode, enqUnActionStatus } from '../../services/enum';
import { objContact, objOwnedVehicle } from '../contacts/viewModel';

import CapInfo from '../contacts/list/porsche'
import ContactInfo from '../contacts/contactInfo'

import { useHistory } from 'react-router-dom';
import { companyBasicVM } from '../company/viewModel';

const OEMLeadAllocation = (props) => {

    const [fields, setFields] = useState({ clientID: '', groupID: '', regionID: '', notes: '', owner: '', enquiryID: props.enquiryID, enqID: props.enquiryID });
    const [regions, setRegions] = useState([]);
    const [groups, setGroups] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [errors, setErrors] = useState({});
    const [activeTab, setActiveTab] = useState('clientID');
    const [loader, setLoader] = useState(false);
    const [contact, setContact] = useState({});


    const [defaulStages, setDefaulStages] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [activeStageIndex, setActiveStageIndex] = useState(0);
    const [allUsers, setAllusers] = useState([])
    const [clientSettings, setClientSettings] = useState({});

    const [showContactInfo, setshowContactInfo] = useState(false)
    const [existingContactInfo, setexistingContactInfo] = useState({})
    const [isContactMerged, setContactMerged] = useState(false);
    const [showCapInfo, setShowCapInfo] = useState({ show: false, contactID: '', contact: null })

    const _dmsSettings = Object.assign({}, clientSettings?.integrations &&
        clientSettings.integrations.filter(e => e.type === "cap")[0]);
    const _dmsEnabled = _dmsSettings && _dmsSettings.active && _dmsSettings.enabled && _dmsSettings?.settings?.searchContact ? true : false;
    const history = useHistory();
    useEffect(() => {
        const { dealersettings, groupUsers, clientUsers, enquiry } = props;
        if (dealersettings) {

            let _dealers = [];
            let _allClients = dealersettings.clients;
            if (_allClients) {
                _dealers = Object.keys(_allClients).map(cid => {
                    return {
                        value: _allClients[cid].id ? _allClients[cid].id : _allClients[cid].documentID,
                        label: _allClients[cid].name,
                        group: _allClients[cid].group,
                        region: _allClients[cid].region,
                        projectId: _allClients[cid].projectId
                    }
                })
            }


            let _regions = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.region === doc.id)) {
                    _regions.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _groups = [];
            if (dealersettings.groups)
                dealersettings.groups && dealersettings.groups.forEach((doc) => {
                    if (_dealers && _dealers.some(client => client.group === doc.id)) {
                        _groups.push({
                            value: doc.id,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name,

                        });
                    }
                });

            // let options = [];
            // let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            // allDealerUsers && allDealerUsers.map(rec => {
            //     if (!options.some(m => m.value === rec.id))
            //         return options.push({
            //             value: rec.id,
            //             active: _.isBoolean(rec.active) ? rec.active : true,
            //             searchlabel: rec.name,
            //             label: <div className="drop-image">
            //                 <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
            //                 {rec.name}
            //             </div>
            //         })
            // })
            // setAllusers(options)

            //let _activeTab = dealersettings.level === 'oem' ? 'regionID' : 'clientID';
            setDealers(_dealers);
            setGroups(_groups);
            setRegions(_regions);
            if (props.enquiry?.dealerID) {
                handleDealerChange({ value: props.enquiry?.dealerID })
            }
            // //setActiveTab(_activeTab)
            // console.log('_dealers', _dealers);
            //console.log('groupUsers', groupUsers);
            //console.log('clientUsers', clientUsers);
            //console.log('enquiry', props.enquiry);
        }
    }, [])

    useEffect(() => {
        async function getContactDetails(contactID) {
            firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${contactID}`).get().then(doc => {
                //console.log('contactDetails', doc.data());
                if (!_.isEmpty(doc.data())) {
                    setContact({
                        ...doc.data(),
                        'documentID': doc.id
                    });
                }
                else {
                    setContact(Object.assign({}, props.enquiry.contact));
                }

            });
        }

        if (!_.isEmpty(props.enquiry) && !_.isEmpty(props.enquiry.contact) && !_.isEmpty(props.enquiry.contact.documentID)) {
            getContactDetails(props.enquiry.contact.documentID)
        }
        else if (!_.isEmpty(props.enquiry) && !_.isEmpty(props.enquiry.contact) && _.isEmpty(props.enquiry.contact.documentID)) {
            setContact(Object.assign({}, props.enquiry.contact));
        }




    }, [props.enquiry])

    // const handleDealerChange = (e, data) => {
    //     const { groupUsers, clientUsers } = props;
    //     let objFilds = {
    //         ...fields,
    //         'clientID': e.value
    //     }

    //     let options = [];
    //     let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
    //     allDealerUsers && allDealerUsers.filter(item =>
    //         (item.arrayClientID && item.arrayClientID.some(e => e === objFilds.clientID)) ||
    //         (item.clientIDs && item.clientIDs.some(e => e === objFilds.clientID))).map(rec => {
    //             if (!options.some(m => m.value === rec.id))
    //                 return options.push({
    //                     value: rec.id,
    //                     active: _.isBoolean(rec.active) ? rec.active : true,
    //                     searchlabel: rec.name,
    //                     label: <div className="drop-image">
    //                         <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
    //                         {rec.name}
    //                     </div>
    //                 })
    //         })

    //     let _currentUser = fields.owner ? options.filter(v => v.value === fields.owner)[0] : {};
    //     if (!_currentUser) {
    //         objFilds.owner = '';
    //     }

    //     setAllusers(options)       

    //     setFields(objFilds);
    // }

    const handleDealerChange = async (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;

        const _clientSettings = await window.firebase.firestore().doc(`clients/${e.value}/currentSettings/${e.value}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });

        if (_clientSettings) {
            _clientSettings.id = e.value;
            //console.log('handleDealerChange', _clientSettings, e, data);
            setClientSettings(Object.assign({}, {
                ..._clientSettings,
                client: _clientSettings
            }));
        }

        console.log(_clientSettings)
        let objFilds = {
            ...fields,
            'clientID': e.value,
            'projectId': (e?.projectId || null)
        }

        if (!_.isEmpty(_clientSettings) && !_.isEmpty(_clientSettings.settings)) {

            const setttings = _clientSettings ? _clientSettings.settings : {};
            const pipelines = [];
            let _stages = null;

            let _allpipelines = enquiryHelper.allOpenPipelines((!_.isEmpty(setttings.allPipelines) ? setttings.allPipelines : setttings.pipelines), dealersettings)
            _allpipelines = _allpipelines.filter(a => a.value !== 'LeadsBucket')
            let _pipeline = Object.assign({}, _allpipelines[0]);
            //let _pipeline = Object.assign({}, (setttings.allPipelines ? setttings.allPipelines[0] : setttings.pipelines[0]));
            let defaultstages = _pipeline?.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            _allpipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            objFilds.pipeline = _pipeline ? _pipeline.value : '';
            objFilds.stage = _stages[0];

            setDefaulStages(defaultstages)
            setPipelines(pipelines)
            setActiveStageIndex(_stages.indexOf(_stages[0]))

            // // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            if (!_.isEmpty(dealersettings.client.roles) && !_.isEmpty(_clientSettings.users)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = _clientSettings.users;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

                let options = [];
                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                //console.log('allDealerUsers', allDealerUsers, roleIds, options)
                setAllusers(options)
                let _currentUser = fields.owner ? options.filter(v => v.value === fields.owner)[0] : {};
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            else {
                setAllusers([])
                objFilds.owner = '';
            }
        }
        else {
            objFilds.pipeline = '';
            objFilds.stage = '';
            objFilds.owner = '';

            setDefaulStages([])
            setPipelines([])
            setActiveStageIndex(0)
            setAllusers([])
        }
        if (!objFilds.enqID)
            objFilds.enqID = `OEM-${firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id}`;

        setFields(objFilds);
    }
    const handlePipelineChange = (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;

        let objFilds = {
            ...fields,
            'pipeline': e.value
        }

        if (!_.isEmpty(clientSettings) && !_.isEmpty(clientSettings.settings)) {

            const setttings = Object.assign({}, clientSettings.settings);
            let _stages = null;

            let defaultstages = setttings.allPipelines.filter(v => v.value === e.value)[0].stages;
            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            objFilds.stage = _stages[0];

            setDefaulStages(defaultstages)
            setActiveStageIndex(_stages.indexOf(_stages[0]))

            // // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            if (!_.isEmpty(dealersettings.client.roles) && !_.isEmpty(clientSettings.users)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = clientSettings.users;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

                let options = [];
                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                //console.log('handlePipelineChange-allDealerUsers', allDealerUsers, roleIds, options)
                setAllusers(options)
                let _currentUser = fields.owner ? options.filter(v => v.value === fields.owner)[0] : {};
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            else {
                setAllusers([])
                objFilds.owner = '';
            }
        }
        else {
            objFilds.stage = '';
            objFilds.owner = '';

            setDefaulStages([])
            setActiveStageIndex(0)
            setAllusers([])
        }

        setFields(objFilds);
    }

    const handleStageChange = (e) => {
        e.preventDefault();
        const { id, name } = e.target;

        setFields({ ...fields, ['stage']: name });
        setActiveStageIndex(id)
        setErrors('stage');
    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setFields({
                ...fields,
                [data.name]: e.value
            });
        }
    }

    const handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (value.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let objfields = Object.assign({}, fields);
        const { enquiry, convertLeadStage } = props;

        let errorClass = 'input_error';
        // for (let [key] of Object.entries(objfields)) {
        //     if (!fields[activeTab]) {
        //         formIsValid = false;
        //         errors[activeTab] = errorClass;
        //     }
        // }

        let mandatoryFields = activeTab === 'clientID' ? 'clientID,pipeline,owner' : null;
        if (objfields.pipeline === 'LeadsBucket' && activeTab === 'clientID') {
            mandatoryFields = 'clientID,pipeline'
        }
        for (let [key] of Object.entries(objfields)) {
            if ((activeTab !== 'clientID' && !fields[activeTab]) || (mandatoryFields && mandatoryFields.split(',').indexOf(key) >= 0 && !fields[key])) {
                formIsValid = false;
                errors[activeTab !== 'clientID' ? activeTab : key] = errorClass;
            }
        }

        //console.log('input_error', errors, objfields, mandatoryFields)
        setErrors(errors);

        if (formIsValid === true) {
            try {
                setLoader(true);


                if (!_.isEmpty(props.enquiries)) {
                    const batch = firestoreDB(clientSettings).firestore().batch();
                    for (let [key, value] of Object.entries(props.enquiries)) {
                        let _enqID = (value.enquiryID ? value.enquiryID : (value.stage !== 'InboundLead' ? key : null))
                        //console.log('transferLead', _enqID, value)
                        transferLead(batch, value, _enqID);
                    }

                    batch.commit().then((docRef) => {
                        setLoader(false);

                        toast.notify('Lead allocated successfully.', {
                            duration: 2000
                        })
                        props.handleModalClose(props.returnEnq ? enquiry : props.enquiries);
                    }).catch((error) => {
                        setLoader(false);
                        console.error("Error updating document: ", error);
                    });
                }
                else {
                    if (_dmsEnabled && (contact?.email || contact?.phone) && _.isEmpty(_.find(contact?.tags, { type: 'cap' }))) {
                        searchDMSContact();
                    }
                    else {
                        handleSaveEnquiry();
                    }
                }



            } catch (error) {
                errorLog(error, 'transferLead');
            }

        }
        else
            return formIsValid;

    };

    //#region CONTACT
    const searchDMSContact = () => {
        setLoader(true);
        const { dealersettings, groupUsers, clientUsers } = props;
        let clientID = fields.clientID ? fields.clientID : (contact.clientID ? contact.clientID : (dealersettings.client ? dealersettings.client.id : ''));

        try {
            const dmsContacts = window.firebase.functions().httpsCallable('contact-searchDMS');
            dmsContacts({
                clientID: clientID,
                dms: _dmsSettings.type,
                keywords: contact.email ? contact.email : contact.phone
            }).then((response) => {
                if (response.data.success && !_.isEmpty(response?.data?.data)) {
                    let _contact = response.data.data[0];

                    if (!_.isEmpty(_contact?.Vehicles)) _contact.contactType = 'Current Customer';
                    if (_contact?.capOwner && !_.isEmpty(CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _contact.capOwner))) _contact.owner = _contact.capOwner;

                    setShowCapInfo({ show: true, contact: { ..._contact, type: null } })
                }
                else {
                    handleSaveEnquiry();
                }
            });
        }
        catch (err) {
            setLoader(false);
            console.log(err);
            Swal.fire('Something went wrong on C@P searching.', '', 'error')
        }
    }
    const handleCloseCAPInfo = async (_contact, isEnquiryExist, _enquiry) => {
        console.log('handleCloseCAPInfo', _contact, isEnquiryExist, _enquiry)

        if (isEnquiryExist && _enquiry?.documentID) {

            //SAVE NOTES , TAGS ON LINKED ENQUIRY
            updateConvertedData(_enquiry.documentID, _enquiry);
        }
        else if (!_.isEmpty(_contact)) {
            handleSaveContact(_contact)
        }
        else {
            setLoader(false);
        }

        setShowCapInfo({ show: false, contact: null })
    }

    const handleSaveContact = (_contact) => {
        try {

            setLoader(true);
            let objData = Object.assign({}, (!_.isEmpty(_contact) ? _contact : contact));
            let _param = {};
            let dealersettings = props.dealersettings;
            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = props.dealersettings ? props.dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.isDeleted = false;
            objData.modifiedFrom = 'web';

            if (objData?.owner && objData?.capOwner) objData.owner = objData.capOwner;
            else if (fields.owner) objData.owner = fields.owner;

            if (fields.campaign && !objData.campaign) objData.campaign = fields.campaign;
            if (fields.origin && !objData.origin) objData.origin = fields.origin;
            let defaultCountry = props.dealersettings.client.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';
            //Remove empty value from object
            for (var propName in objData) {

                if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                    objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
                }

                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            if (_.isEmpty(objData.country)) objData.country = defaultCountry;

            // // // if (!_.isEmpty(enquiry.convertedLeadRefID) &&
            // // //     _.isEmpty(contact.mergeID) &&
            // // //     !_.isEmpty(objData.clientID) &&
            // // //     objData.clientID !== dealersettings.client.id) {
            // // //     objData = copyExistingContact(objData);
            // // // }

            if (!objData.documentID)
                objData.documentID = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id;

            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, props.dealersettings, objData.clientID, true);

            if (objData.documentID)
                _param['contactID'] = objData.documentID;

            const _systemType = Object.assign({}, !_.isEmpty(clientSettings?.integrations) ?
                clientSettings.integrations.filter(e => e.type === fields.systemType)[0] : {});

            if (!_.isEmpty(_systemType) && _systemType?.settings?.customerConsent)
                objData.terms = {
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    verbalConsent: false,
                    signature: '',
                    termsAndConditions: !_.isEmpty(dealersettings?.client?.digitalConsent) ? dealersettings.client.digitalConsent : (!_.isEmpty(dealersettings?.client?.customerConsent) ? dealersettings.client.customerConsent : ''),
                    consentType: 'Digital'
                };


            const _pmds = Object.assign({}, !_.isEmpty(clientSettings?.integrations) ?
                clientSettings.integrations.filter(e => e.type === "pmds")[0] : {});
            if (!_.isEmpty(_pmds) && _pmds.active && _pmds.enabled)
                _param['pmds'] = _pmds;

            if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'pmds')))
                _param['pmds'] = null;

            const _keyloop = Object.assign({}, !_.isEmpty(clientSettings?.integrations) ?
                clientSettings.integrations.filter(e => e.type === "keyloop")[0] : {});
            _param['keyloop'] = !_.isEmpty(_keyloop) && _keyloop.active && _keyloop.enabled ? true : false;

            if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'keyloop')))
                _param['keyloop'] = null;

            if (objData.mergeID) {
                _param['mergeID'] = objData.mergeID;
                delete objData['mergeID'];
            }

            _param = CommonHelper.setClientOrGroupOrRegionOrOemID(_param, props.dealersettings);
            _param['isShared'] = ((!_.isEmpty(props.dealersettings) &&
                !_.isEmpty(props.dealersettings.group) &&
                props.dealersettings.group.enableSharedContact) ? true : false);
            setContact(objData);
            //console.log('contact-saveContact', objData, _param)
            const addContact = window.firebase.functions().httpsCallable('contact-saveContact');
            addContact({
                "model": objData,
                "param": _param
            }).then((res) => {

                let response = res.data;
                if (response.success === false) {
                    //console.log(response);
                    if (response.code === contactResponseCode.PhoneAlreadyExists ||
                        response.code === contactResponseCode.EmailAlreadyExists ||
                        response.code === contactResponseCode.LicenseAlreadyExists ||
                        response.code === contactResponseCode.PMDSAlreadyExists ||
                        response.code === contactResponseCode.KeyloopAlreadyExists) {
                        setLoader(false);
                        handleExistingContact(response);
                    }
                    else {
                        toast.notify(response.message, {
                            duration: 2000
                        })
                    }
                    return false;
                }
                else {
                    handleSaveEnquiry(objData);
                    return true;
                }
            });
        } catch (error) {
            errorLog(error, 'handleSaveContact');
        }
    }

    const handleExistingContact = (response) => {

        let _entity;
        let _msg;
        let _title;
        let _fullName = response.data ? CommonHelper.displayContactName([], response.data) : '';

        if (response.code === contactResponseCode.PhoneAlreadyExists) {
            _entity = "mobile no.";
            _title = "Mobile No. already exist";
        } else if (response.code === contactResponseCode.EmailAlreadyExists) {
            _entity = "email address";
            _title = "Email already exist";
        } else if (response.code === contactResponseCode.LicenseAlreadyExists) {
            _entity = "license no.";
            _title = "License No. already exist";
        }

        _msg = _entity ? `A contact ${_fullName} already has this ${_entity}.` : '';
        if (response.code === contactResponseCode.PMDSAlreadyExists || response.code === contactResponseCode.KeyloopAlreadyExists)
            _title = response.message;


        Swal.fire({
            title: CommonHelper.showLocale(props, (_title ? _title : '')),
            html: _msg ? _msg : '',
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#17232D',
            confirmButtonText: CommonHelper.showLocale(props, 'View Contact'),
            cancelButtonText: CommonHelper.showLocale(props, 'Okay'),
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                handleXContactShow(response.data)
            }
        });
    }

    const handleXContactShow = (data) => {
        try {

            if (!_.isEmpty(data)) {
                data = convertContactVM(data);
                setshowContactInfo(true);
                setexistingContactInfo(data);
            }
        } catch (error) {
            errorLog(error, 'handleXContactShow');
        }
    }

    const handleXContactClose = (type, objData) => {
        try {

            if (type && !_.isEmpty(objData)) {
                if (type === 'fillMissing') {
                    fillMissingContact(objData);
                }
                else if (type === 'replaceAll' || type === 'merge') {
                    setContactMerged(true);
                    replaceContact(objData)
                }
            }
            setshowContactInfo(false);
            setexistingContactInfo({})
        } catch (error) {
            errorLog(error, 'handleXContactClose');
        }
    }

    const convertContactVM = (doc) => {
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key))
                contact[key] = value;
        }
        return contact
    }
    const fillMissingContact = (objData) => {
        const _fields = Object.assign({}, contact);

        for (let [key, value] of Object.entries(objData)) {
            if (_fields[key] === null || _fields[key] === undefined || _fields[key] === false || _fields[key] === '' || (_.isObject(_fields[key]) && _.isEmpty(_fields[key]))) {
                _fields[key] = value;
            }
        }
        _fields.documentID = objData.documentID ? objData.documentID : '';
        _fields.owner = objData.owner ? objData.owner : '';
        _fields.addedBy = objData.addedBy ? objData.addedBy : '';
        _fields.addedDate = objData.addedDate ? objData.addedDate : null;
        if (_.isEmpty(_fields.owner)) _fields.owner = localStorage.uid;
        if (_.isEmpty(_fields.country) && !_.isEmpty(_fields.state)) _fields.country = 'AU';
        _fields.isDeleted = false;
        setContact(_fields);
    }
    const replaceContact = (objData) => {
        const _fields = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objData)) {
            _fields[key] = value;
        }
        _fields.isDeleted = false;
        if (_.isEmpty(_fields.owner)) _fields.owner = localStorage.uid;
        if (_.isEmpty(_fields.country) && !_.isEmpty(_fields.state)) _fields.country = 'AU';
        setContact(_fields);
    }

    const copyExistingContact = (objData) => {
        const _fields = Object.assign({}, objData);

        _fields.documentID = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id;
        _fields.displayID = null;
        _fields.refContactID = objData.documentID;
        _fields.addedBy = props.dealersettings ? props.dealersettings.id : '';
        _fields.addedDate = window.firebase.firestore.Timestamp.now();
        _fields.owner = props.dealersettings ? props.dealersettings.id : '';
        _fields.isDeleted = false;

        // if (objData.clientID !== props.dealersettings.id) {

        // }
        return _fields
    }

    const bindCompany = (objData) => {

        if (!_.isEmpty(objData) && !_.isEmpty(objData.company)) {
            let _company = Object.assign({});
            if (_.isString(objData.company)) {
                _company.name = objData.company;
                _company.documentID = firestoreDB(props.dealersettings).firestore().collection('companies').doc().id;
            }
            else if (_.isObject(objData.company) && _.isEmpty(objData.company.documentID)) {
                _company = Object.assign({}, companyBasicVM);
                for (let [key, value] of Object.entries(objData.company)) {
                    if (_company.hasOwnProperty(key) && value)
                        _company[key] = value;
                }
                _company.documentID = firestoreDB(props.dealersettings).firestore().collection('companies').doc().id;
            }

            if (!_.isEmpty(_company)) {
                objData.company = _company;
                objData.isCompany = true;
            }
            else
                objData.company = null;

        }

        return objData;

    }
    //#endregion CONTACT

    const handleCancel = (e) => {
        e.preventDefault();
        props.handleModalClose({});
    }

    //#region ENQUIRY

    const handleSaveEnquiry = (_contact) => {
        const { enquiry } = props;

        const batch = firestoreDB(clientSettings).firestore().batch();

        transferLead(batch, enquiry, null, _contact);

        batch.commit().then((docRef) => {
            setLoader(false);

            toast.notify('Lead allocated successfully', {
                duration: 2000
            })
            props.handleModalClose(props.returnEnq ? enquiry : props.enquiries);
        }).catch((error) => {
            setLoader(false);
            console.error("Error updating document: ", error);
        });

    }
    const transferLead = (batch, enquiry, enquiryID, __contact) => {
        let objFields = Object.assign({}, fields);
        let __enquiryID = enquiryID ? enquiryID : objFields.enquiryID;
        const { dealersettings, clientUsers } = props;

        let _enquiry = Object.assign({}, enquiry);
        let transferHistory = Object.assign([], enquiry.transferHistory);

        let objEnqData = {
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedFrom: 'web',
        }
        let objInboundData = {
            settingsID: dealersettings.client && dealersettings.client.settingsID ? dealersettings.client.settingsID : null,
            oemID: dealersettings.client && dealersettings.client.settingsID ? dealersettings.client.settingsID : null
        };

        let _newTransferHistory = {
            by: localStorage.uid,
            date: window.firebase.firestore.Timestamp.now(),
        }
        let _transferredTo = 'Dealer';
        let isLeadExistinInbound = false;
        if (dealersettings.level === 'oem') {
            _newTransferHistory.fromOEM = dealersettings.oemID ? dealersettings.oemID : null;
        }
        if (dealersettings.level === 'region') {
            _newTransferHistory.fromRegion = dealersettings.regionID ? dealersettings.regionID : null;
        }
        if (dealersettings.level === 'group') {
            _newTransferHistory.fromGroup = dealersettings.groupID ? dealersettings.groupID : null;
        }

        if (objFields.regionID) {
            objEnqData.regionID = objFields.regionID;
            objInboundData.regionID = objFields.regionID;

            _newTransferHistory.toRegion = objFields.regionID;
            _transferredTo = _.find(dealersettings.regions, { id: objFields.regionID }) ? _.find(dealersettings.regions, { id: objFields.regionID }).name : 'Region';
            if (objFields.regionID === enquiry.regionID) isLeadExistinInbound = true;
        }
        else if (objFields.groupID) {
            objEnqData.groupID = objFields.groupID;
            objInboundData.groupID = objFields.groupID;

            _newTransferHistory.toGroup = objFields.groupID;
            _transferredTo = _.find(dealersettings.groups, { id: objFields.groupID }) ? _.find(dealersettings.groups, { id: objFields.groupID }).name : 'Group';
            if (objFields.groupID === enquiry.groupID) isLeadExistinInbound = true;
        }
        else if (objFields.clientID) {
            objEnqData.clientID = objFields.clientID;
            objEnqData.projectId = (objFields.projectId || (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null));
            objInboundData.clientID = objFields.clientID;
            objInboundData.projectId = (objFields.projectId || (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null));

            _newTransferHistory.toClient = objFields.clientID;
            _transferredTo = _.find(dealersettings.clients, { id: objFields.clientID }) ? _.find(dealersettings.clients, { id: objFields.clientID }).name : 'Dealer';
            if (objFields.clientID === enquiry.clientID) isLeadExistinInbound = true;
        }

        transferHistory.push(_newTransferHistory);
        objEnqData.transferHistory = transferHistory;
        objInboundData.transferHistory = transferHistory;
        objInboundData.oemStatus = 'converted';
        objInboundData.oemConvertedBy = localStorage.uid;
        objInboundData.oemConvertedDate = window.firebase.firestore.Timestamp.now();

        if (fields.owner) {
            objEnqData.owner = fields.owner;
            let _userEmail = clientUsers && clientUsers.filter(m => m.id === fields.owner)[0] ? props.clientUsers.filter(m => m.id === fields.owner)[0].email : null;
            if (_userEmail) objInboundData.userEmail = _userEmail
        }

        const isClientTransfer = (activeTab === 'clientID' && fields.pipeline && fields.pipeline !== 'LeadsBucket') ? true : false;
        const isFromInbound = (_enquiry.stage === 'InboundLead' || _enquiry.oldStage === 'InboundLead') ? true : false;
        const isNewEnquiryTransfer = (_.isEmpty(__enquiryID) && ((_enquiry.stage === 'InboundLead') || _enquiry.oldStage === 'InboundLead')) ? true : false;

        //console.log('isNewEnquiryTransfer', isNewEnquiryTransfer, 'isFromInbound', isFromInbound, 'isClientTransfer', isClientTransfer, __enquiryID, _enquiry)
        //console.log('objFields', objFields);
        //console.log('contact', contact);

        if (objFields.pipeline && objFields.stage) {
            let logNotes = [];
            if (objFields.enquiryID) {
                // existing enquiry update
                if (fields.pipeline) objEnqData.pipeline = fields.pipeline;
                if (fields.stage) objEnqData.stage = fields.stage;
                if (fields.owner) objEnqData.owner = fields.owner;
                objEnqData.documentID = objFields.enquiryID
                objEnqData.isConverted = true;
                objEnqData.unactionedNotify = enqUnActionStatus.PENDING;
                objEnqData.convertedBy = dealersettings ? dealersettings.id : localStorage.uid;
                objEnqData.convertedDate = window.firebase.firestore.Timestamp.now();

                let stageHistory = Object.assign([], _enquiry.stageHistory);
                stageHistory.push(CommonHelper.handleStageHistory(stageHistory, fields.pipeline, fields.stage));
                objEnqData.stageHistory = Object.assign([], stageHistory);

                objEnqData.status = 'open';
                objEnqData.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: objEnqData.status, user: localStorage.uid }]);

                objEnqData.oemStatus = 'converted';
                objEnqData.stageDate = window.firebase.firestore.Timestamp.now();

                console.log('build-1', objInboundData, objEnqData)
            }
            else {
                // new enquiry creation
                let resultData = handleBindNewEnquiry(objEnqData);
                objEnqData = resultData.enquiry;
                logNotes = resultData.logNotes;

                if (!objEnqData.documentID)
                    objEnqData.documentID = (objFields.enqID || firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id);

                objEnqData.isConverted = true;
                objEnqData.unactionedNotify = enqUnActionStatus.PENDING;
                objEnqData.convertedBy = dealersettings ? dealersettings.id : localStorage.uid;
                objEnqData.convertedDate = window.firebase.firestore.Timestamp.now();

                objEnqData.settingsID = dealersettings.client && dealersettings.client.settingsID ? dealersettings.client.settingsID : null;
                objEnqData.oemID = dealersettings.client && dealersettings.client.settingsID ? dealersettings.client.settingsID : null;

                objEnqData.convertedLeadRefID = _enquiry.documentID ? _enquiry.documentID : null;
                objEnqData.inboundLeadID = _enquiry?.documentID || null;
                objEnqData.oemStatus = 'converted';
                if (_enquiry?.oemID) objEnqData.oemID = _enquiry?.oemID;
                if (_enquiry?.groupID) objEnqData.groupID = _enquiry?.groupID;
                if (_enquiry?.regionID) objEnqData.regionID = _enquiry?.regionID;

                objInboundData.enquiryID = objEnqData.documentID;
                //objInboundData.refID = objEnqData.documentID;
                objInboundData.documentID = _enquiry.documentID ? _enquiry.documentID : null;
                objInboundData.status = 1;
                objInboundData.oemStatus = 'converted';
                if (objEnqData.documentID) objFields.enquiryID = objEnqData.documentID

                console.log('build-2', objInboundData, objEnqData, objFields, logNotes)
                batch.set(firestoreDB(clientSettings).firestore().doc(`inboundLeads/${objInboundData.documentID}`), objInboundData, { merge: true });
            }

            if (!_.isEmpty(__contact)) {
                let objContactData = Object.assign({}, __contact);
                //Remove empty value from object
                for (var propName in objContactData) {
                    if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                        delete objContactData[propName];
                    }
                }
                objContactData = bindCompany(objContactData);
                objEnqData.contact = objContactData;
                objEnqData.isContact = true;
                objEnqData.contactID = objContactData.documentID;
                objEnqData.contact = objContactData;
                objEnqData.isNewEnquiry = false;
                objEnqData.engageNewContact = {};

                if (!_.isEmpty(__contact?.Vehicles))
                    createOwnedVehicles(objEnqData.contactID, __contact?.Vehicles, __contact, batch);
            }

            if (enquiry?.dealerID) objEnqData.dealerID = enquiry.dealerID
            if (enquiry?.clientName) objEnqData.clientName = enquiry.clientName

            batch.set(firestoreDB(clientSettings).firestore().doc(`enquiries/${objEnqData.documentID}`), objEnqData, { merge: true });

            if (!_.isEmpty(fields.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = window.firebase.firestore.Timestamp.now();
                newlogVM.notes = fields.notes;
                logNotes.push(newlogVM)

            }

            logNotes && logNotes.forEach(newlogVM => {
                newlogVM.enquiryID = objEnqData.documentID;
                newlogVM['enquiry'] = CommonHelper.getMinifiedData(objEnqData, 'enquiry');
                newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(objEnqData)
                    ? objEnqData.clientID
                    : null));
                if (!newlogVM.addedBy) newlogVM.addedBy = localStorage.uid;
                if (!newlogVM.modifiedBy) newlogVM.modifiedBy = localStorage.uid;
                Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                newlogVM.projectId = (objEnqData.projectId || (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null));
                batch.set(firestoreDB(clientSettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            })
        }
        else if (isNewEnquiryTransfer) {

            if (objFields.notes)
                objInboundData.notes = _enquiry.notes ? `${_enquiry.notes} \n${objFields.notes}` : objFields.notes;
            updateInBoundData(batch, objInboundData, _enquiry.documentID)

            console.log('build-3', objInboundData, objEnqData)
        }
        else if (__enquiryID) {

            objInboundData.documentID = firestoreDB(props.dealersettings).firestore().collection('inboundLeads').doc().id;
            objEnqData.convertedLeadRefID = objInboundData.documentID;
            objEnqData.status = null;
            objEnqData.oemStatus = null;
            objEnqData.stage = null;
            objEnqData.clientID = null;
            objEnqData.isDeleted = null; //SaMeeR For OEM 
            objEnqData.projectId = null;
            objInboundData.enquiryID = __enquiryID;
            objInboundData.refID = __enquiryID;
            console.log('build-4', objInboundData, objEnqData)
            batch.set(firestoreDB(clientSettings).firestore().doc(`enquiries/${__enquiryID}`), objEnqData, { merge: true });

            if (objFields.notes) {
                objInboundData.notes = _enquiry.notes ? `${_enquiry.notes} \n${objFields.notes}` : objFields.notes;
            }

            if (_.isEmpty(objEnqData.pipeline))
                saveOEMInboundLead(batch, objInboundData, _enquiry);

        }
    }

    const createOwnedVehicles = (_contactID, _vehicles, objEnqData, batch) => {
        if (_.isEmpty(_contactID)) return;

        _vehicles && _vehicles.forEach(async (_tdData) => {
            let objData = Object.assign({});
            if (_tdData.vin) objData.vinNo = _tdData.vin;
            for (let [key, value] of Object.entries(_tdData)) {
                if (objOwnedVehicle.hasOwnProperty(key) && value !== undefined) objData[key] = value;
            }
            objData.addedBy = localStorage.uid;
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
            objData.modifiedBy = localStorage.uid;
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';
            objData.isDeleted = false;
            objData.currentlyOwned = true;
            objData.documentID = window.firebase.firestore().collection('contactDetails').doc().id;
            objData.projectId = (objEnqData?.projectId || (props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null));
            Object.keys(objData).forEach(key => objData[key] === undefined ? delete objData[key] : {});
            if (_tdData.capID) objData.documentID = _tdData.capID;
            if (_tdData.hasOwnProperty('owned')) {
                objData.owned = (_tdData.owned || false);
                objData.currentlyOwned = objData.owned;
            }
            if (_tdData.commissionNumber) objData.commissionNumber = _tdData.commissionNumber;

            if (batch)
                batch.set(firestoreDB(clientSettings).firestore().doc(`contactDetails/${_contactID}/ownedVehicles/${objData.documentID}`), objData, { merge: true });
            else
                firestoreDB(clientSettings).firestore().doc(`contactDetails/${_contactID}/ownedVehicles/${objData.documentID}`).set(objData, { merge: true })
        });
    }

    const updateConvertedData = async (enquiryID, __enquiryData) => {
        try {
            const { dealersettings, enquiry } = props;
            const batch = firestoreDB(clientSettings).firestore().batch();
            let logNotes = [];
            if (fields.enquiryID) {
                batch.set(firestoreDB(clientSettings).firestore().doc(`enquiries/${fields.enquiryID}`), {
                    isDeleted: true,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                }, { merge: true });
            }
            else {

                let _tags = Object.assign([], __enquiryData?.tags);
                if (enquiry.systemType) {
                    _tags.push({
                        type: (enquiry.systemType ? enquiry.systemType : ''),
                        refID: (enquiry.refID ? enquiry.refID : ''),
                        subType: (enquiry.subType ? enquiry.subType : '')
                    })
                    batch.set(firestoreDB(clientSettings).firestore().doc(`enquiries/${enquiryID}`), { tags: _tags }, { merge: true });
                }

                if (!_.isEmpty(enquiry) && !_.isEmpty(enquiry.notes)) {
                    let newlogVM = Object.assign({}, objActivitylog);
                    newlogVM.type = 'log';
                    newlogVM.subType = 'note';
                    newlogVM.isDone = true;
                    newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                    newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                    newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
                    newlogVM.startDate = window.firebase.firestore.Timestamp.now();
                    newlogVM.notes = enquiry.notes;
                    newlogVM.tags = enquiry.systemType ? [enquiry.systemType] : [];
                    logNotes.push(newlogVM)
                }

                let _objReq = {
                    status: 1,
                    enquiryID: enquiryID,
                    oemStatus: null,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                }
                if (enquiry.documentID)
                    batch.set(firestoreDB(clientSettings).firestore().doc(`inboundLeads/${enquiry.documentID}`), _objReq, { merge: true });

            }

            if (!_.isEmpty(fields.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = window.firebase.firestore.Timestamp.now();
                newlogVM.notes = fields.notes;
                logNotes.push(newlogVM)

            }

            logNotes && logNotes.forEach(newlogVM => {
                newlogVM.enquiryID = enquiryID;
                newlogVM['enquiry'] = CommonHelper.getMinifiedData(__enquiryData, 'enquiry');
                newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
                    ? __enquiryData.clientID
                    : null));
                if (!newlogVM.addedBy) newlogVM.addedBy = localStorage.uid;
                if (!newlogVM.modifiedBy) newlogVM.modifiedBy = localStorage.uid;
                Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                newlogVM.projectId = (__enquiryData.projectId || (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null));
                batch.set(firestoreDB(clientSettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            })


            try {
                if (fields.enquiryID && !_.isEmpty(__enquiryData) && __enquiryData.clientID) {

                    const refwalkins = await firestoreDB(props.dealersettings).firestore().collection(`enquiries/${fields.enquiryID}/walkins`).get();
                    refwalkins.docs && refwalkins.docs.forEach((newlogVM) => {

                        if (__enquiryData.clientID) newlogVM.clientID = __enquiryData.clientID;
                        if (__enquiryData.projectId) newlogVM.projectId = __enquiryData.projectId;
                        newlogVM.isDeleted = false;
                        if (newlogVM.documentID)
                            batch.set(firestoreDB(clientSettings).firestore().doc(`enquiries/${fields.enquiryID}/walkins/${newlogVM.documentID}`), newlogVM, { merge: true });

                    });

                    // UPDATE WITH NEW CLIENT ID
                    let _objDAta =
                    {
                        clientID: __enquiryData.clientID,
                        contact: __enquiryData && __enquiryData.contact ? Object.assign({}, __enquiryData.contact) : null,
                        contactID: contact ? contact.documentID : null
                    }
                    await firestoreDB(clientSettings).firestore().collection('testdrives')
                        .where('enquiryID', '==', fields.enquiryID)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, _objDAta); });
                        });

                    await firestoreDB(clientSettings).firestore().collection('tradeins')
                        .where('enquiryID', '==', fields.enquiryID)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, _objDAta); });
                        });

                    await firestoreDB(clientSettings).firestore().collection('activities')
                        .where('enquiryID', '==', fields.enquiryID)
                        .where('isDeleted', '==', false)
                        .get().then(response => {
                            response.docs.forEach((doc) => { batch.update(doc.ref, _objDAta); });
                        });

                }

            } catch (error) {
                errorLog(error, 'updateConvertedData');
            }


            batch.commit().then(() => {
                setLoader(false);
                props.handleModalClose(props.returnEnq ? enquiry : props.enquiries);

                if (props.setNotifyClose) props.setNotifyClose(true);

                history.push("/enquiry/details/" + enquiryID);
            }).catch((error) => {
                console.error("Error updating document: ", error);
            });
        } catch (error) {
            errorLog(error, 'updateConvertedData');
        }

    }

    const saveOEMInboundLead = (batch, _objOEMInbound, _objEnq) => {
        //const { dealersettings } = props;
        const _newLead = Object.assign({}, _objOEMInbound);
        let _objEnquiry = Object.assign({}, _objEnq);
        for (let [key, value] of Object.entries(_objEnquiry)) {
            if (_newLead.hasOwnProperty(key) && _.isEmpty(_newLead[key]) && !_.isEmpty(_objEnquiry[key]))
                _newLead[key] = value;
        }

        //_newLead.documentID = window.firebase.firestore().collection('inboundLeads').doc().id;
        _newLead.pipeline = 'LeadsBucket';
        _newLead.stage = 'InboundLead';
        _newLead.isDeleted = false;
        _newLead.status = 0;
        _newLead.addedBy = localStorage.uid;
        _newLead.modifiedBy = localStorage.uid;
        _newLead.addedDate = window.firebase.firestore.Timestamp.now();
        _newLead.modifiedDate = window.firebase.firestore.Timestamp.now();
        _newLead.leadCreated = window.firebase.firestore.Timestamp.now();
        _newLead.leadModified = window.firebase.firestore.Timestamp.now();
        _newLead.addedFrom = 'web';
        _newLead.modifiedFrom = 'web';
        _newLead.origin = _objEnquiry.origin ? _objEnquiry.origin : null;
        _newLead.campaign = _objEnquiry.campaign ? _objEnquiry.campaign : null;
        _newLead.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

        //_newLead.systemType = dealersettings.level ? dealersettings.level : null;
        //_newLead.browserName = CommonHelper.checkBrowser();

        if (!_.isEmpty(_objEnquiry.requirement)) {
            let _requirement = Object.assign({}, _objEnquiry.requirement);
            let _newReq = {};
            if (!_.isEmpty(_requirement.stock)) {
                let _stockReq = Object.assign({}, _requirement.stock);
                _newReq.make = _stockReq.make ? _stockReq.make : '';
                _newReq.model = _stockReq.model ? _stockReq.model : '';
                _newReq.type = _stockReq.type ? _stockReq.type : '';
                _newReq.year = _stockReq.year ? _stockReq.year : '';
                _newReq.extColor = _stockReq.extColor ? _stockReq.extColor : '';
                _newReq.intColor = _stockReq.intColor ? _stockReq.intColor : '';
                _newReq.saleType = _stockReq.saleType ? _stockReq.saleType : '';
                _newReq.price = _stockReq.price ? _stockReq.price.toString() : null;
                _newReq.stockNo = _stockReq.stockNo ? _stockReq.stockNo : '';
                _newReq.regNo = _stockReq.regNo ? _stockReq.regNo : '';
                _newReq.vinNo = _stockReq.vinNo ? _stockReq.vinNo : '';
            }
            else {
                _newReq.make = _requirement.make ? _requirement.make : '';
                _newReq.model = _requirement.model ? _requirement.model : '';
                _newReq.type = _requirement.type ? _requirement.type : '';
                _newReq.year = _requirement.year ? _requirement.year : '';
                _newReq.extColor = _requirement.extColor ? _requirement.extColor : '';
                _newReq.intColor = _requirement.intColor ? _requirement.intColor : '';
                _newReq.saleType = _requirement.saleType ? _requirement.saleType : '';
                _newReq.stockNo = _requirement.stockNo ? _requirement.stockNo : '';
                _newReq.regNo = _requirement.regNo ? _requirement.regNo : '';
                _newReq.vinNo = _requirement.vinNo ? _requirement.vinNo : '';
                _newReq.price = _requirement.price ? _requirement.price.toString() : null;
            }
            _newLead.requirement = _newReq;
        }

        if (!_.isEmpty(_objEnquiry.contact)) {
            let objContactData = Object.assign({}, _objEnquiry.contact);

            //Remove empty value from object
            for (var propName in objContactData) {
                if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || objContactData[propName] === [] || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                    delete objContactData[propName];
                }
            }
            delete objContactData['documentID'];
            delete objContactData['displayID'];
            delete objContactData['clientID'];
            delete objContactData['groupID'];
            delete objContactData['regionID'];
            delete objContactData['oemID'];
            delete objContactData['keywords'];
            delete objContactData['owner'];
            delete objContactData['terms'];
            delete objContactData['addedBy'];
            delete objContactData['addedDate'];
            delete objContactData['modifiedBy'];
            delete objContactData['modifiedFrom'];
            delete objContactData['modifiedDate'];
            if (!_.isEmpty(objContactData['company'])) {
                let _company = Object.assign({}, objContactData['company']);
                objContactData.company = _company.name ? _company.name : null;
            }

            _newLead.contact = objContactData;
        }

        //console.log('saveOEMInboundLead', _objOEMInbound, _newLead);

        batch.set(firestoreDB(clientSettings).firestore().doc(`inboundLeads/${_newLead.documentID}`), _newLead, { merge: true });

    }

    const updateInBoundData = (batch, _objReq, documentID) => {
        const { dealersettings } = props;
        _objReq.modifiedBy = dealersettings ? dealersettings.id : '';
        _objReq.modifiedDate = window.firebase.firestore.Timestamp.now();
        _objReq.modifiedFrom = 'web';
        _objReq.addedBy = dealersettings ? dealersettings.id : '';
        _objReq.addedDate = window.firebase.firestore.Timestamp.now();

        batch.set(firestoreDB(clientSettings).firestore().doc(`inboundLeads/${documentID}`), _objReq, { merge: true });
    }

    const handleBindNewEnquiry = (__objData) => {

        try {

            const { dealersettings, stage, enquiry } = props;


            let state = Object.assign({}, __objData);


            state.addedBy = dealersettings ? dealersettings.id : '';
            state.addedDate = window.firebase.firestore.Timestamp.now();
            state.addedFrom = 'web';
            state.modifiedBy = dealersettings ? dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state.modifiedFrom = 'web';
            state.isNewEnquiry = true;

            //state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings, null, fields.clientID);

            if (fields.pipeline) state.pipeline = fields.pipeline;

            if (fields.stage) state.stage = fields.stage;

            if (fields.owner) state.owner = fields.owner;

            if (fields.campaign) state.campaign = fields.campaign;
            if (fields.origin) state.origin = fields.origin;
            if (fields.enquiryType) state.enquiryType = fields.enquiryType;

            if (!_.isEmpty(enquiry.contact)) {
                state.engageNewContact = Object.assign({}, {
                    ...enquiry.contact,
                    documentID: null,
                    displayID: null,
                    clientID: null,
                    projectId: null,
                    groupID: null,
                    regionID: null,
                    oemID: null,
                });
                state.contact = {};
                state.contactID = null;
                state.isContact = false;
            }


            if (fields.pipeline !== 'LeadsBucket') {
                state.status = 'open';
            }
            else {
                state.status = null;
                state.ownerInbound = state.owner;
            }

            state.stageDate = window.firebase.firestore.Timestamp.now();

            if (state.status)
                state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);

            if (state.pipeline && state.stage)
                state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);

            state.isDeleted = false;
            if (enquiry.systemType)
                state.tags = [
                    {
                        type: (enquiry.systemType ? enquiry.systemType : ''),
                        refID: (enquiry.refID ? enquiry.refID : ''),
                        subType: (enquiry.subType ? enquiry.subType : '')
                    }
                ]

            // if ((stage && stage === 'Converted') || (state.pipeline !== 'LeadsBucket')) {
            //     state.isConverted = true;
            //     state.convertedBy = dealersettings ? dealersettings.id : localStorage.uid;
            //     state.convertedDate = window.firebase.firestore.Timestamp.now();
            // }

            let objData = Object.assign({}, state);
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }




            let notes = [];
            if (!_.isEmpty(enquiry) && !_.isEmpty(enquiry.notes)) {

                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
                newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = window.firebase.firestore.Timestamp.now();
                newlogVM.notes = enquiry.notes;
                newlogVM.tags = enquiry.systemType ? [enquiry.systemType] : [];
                notes.push(newlogVM)

            }

            let _requiremntData = enquiry.requirement ? enquiry.requirement : (['facebook', 'eventsPro', 'tss'].includes(enquiry.systemType)) ? null : enquiry.request;
            if (!_.isEmpty(_requiremntData)) {

                if (_requiremntData.make && _requiremntData.model && !state.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value.toLowerCase() === _requiremntData.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        const _objRequirement = Object.assign({}, objRequirement);
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = _requiremntData.model ? _requiremntData.model : '';
                        _objRequirement.type = _requiremntData.type ? _requiremntData.type : '';
                        _objRequirement.saleType = _requiremntData.saleType ? _requiremntData.saleType : '';
                        _objRequirement.price = _requiremntData.price ? parseInt(_requiremntData.price) : null;
                        _objRequirement.extColor = _requiremntData.extColor ? _requiremntData.extColor : null;
                        _objRequirement.year = _requiremntData.year ? _requiremntData.year : null;
                        _objRequirement.intColor = _requiremntData.intColor ? _requiremntData.intColor : null;
                        _objRequirement.stock = null;

                        _objRequirement.stockNo = _requiremntData.stockNo ? _requiremntData.stockNo : '';
                        _objRequirement.regNo = _requiremntData.regNo ? _requiremntData.regNo : '';
                        _objRequirement.vinNo = _requiremntData.vinNo ? _requiremntData.vinNo : '';
                        state.isVehicle = true;
                        state.requirement = _objRequirement;

                        let _model = _make.models && _make.models.filter(m => m.value.toLowerCase() === _requiremntData.model.toLowerCase())[0];
                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                        }
                    }
                }
            }

            if ((!state.isVehicle || (state.isVehicle && !_.isEmpty(state.requirement) && !_.isEmpty(state.requirement.stockNo))) && !_.isEmpty(_requiremntData)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(_requiremntData);
                newlogVM.tags = enquiry.systemType ? [enquiry.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }


            return {
                enquiry: objData,
                logNotes: notes
            };


        } catch (error) {
            errorLog(error, 'handleBindNewEnquiry');
        }
    }

    const getRequirementnotes = (_requirement) => {
        let notes = '';
        if (!_.isEmpty(_requirement)) {
            for (let [key, value] of Object.entries(_requirement)) {
                if (!_.isEmpty(_requirement[key]) && _.isString(value))
                    notes = notes.concat([key] + ': ' + value + '\n')
            }
        }

        return notes;
    }
    //#endregion ENQUIRY

    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: props.dealersettings.client.id,
            oemID: props.dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }


    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleModalClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-company"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={'Allocate Leads'} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">

                    <div className="container-fluid">
                        <div className="row">
                            <div className=" pop-layout form-style">

                                {/* {
                                    activeTab === 'regionID'
                                        ?
                                        <>
                                            <div className="form-row ">
                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'Regions'} /></label>
                                                    <ReactSelect
                                                        options={regions}
                                                        name={"regionID"}
                                                        placeholder={'select region'}
                                                        onChange={handleReactSelectChange}
                                                        value={fields.regionID}
                                                        classNamePrefix={`${errors["regionID"]} basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <>
                                            {
                                                activeTab === 'groupID'
                                                    ?
                                                    <>
                                                        <div className="form-row ">
                                                            <div className="form-group col-md-12">
                                                                <label > <Translate text={'Groups'} /></label>
                                                                <ReactSelect
                                                                    options={groups}
                                                                    name={"groupID"}
                                                                    placeholder={'select group'}
                                                                    onChange={handleReactSelectChange}
                                                                    value={fields.groupID}
                                                                    classNamePrefix={`${errors["groupID"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>

                                                    </>
                                                    :
                                                    <> */}
                                <div className="form-row ">
                                    <div className="form-group col-md-12">
                                        <label > <Translate text={'Dealers'} /></label>
                                        <ReactSelect
                                            options={dealers}
                                            name={"clientID"}
                                            placeholder={'select dealer'}
                                            onChange={handleDealerChange}
                                            value={fields.clientID}
                                            classNamePrefix={`${errors["clientID"]} basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>

                                    {/* <div className="form-group col-md-12">
                                                                <label ><Translate text={'owner'} /></label>
                                                                <ReactSelect
                                                                    options={allUsers}
                                                                    name={"owner"}
                                                                    onChange={handleReactSelectChange}
                                                                    value={fields.owner ? fields.owner : ''}
                                                                    classNamePrefix={`${errors["owner"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div> */}

                                    {
                                        !_.isEmpty(pipelines)
                                            ?
                                            <>

                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'pipeline'} /></label>
                                                    <ReactSelect
                                                        options={pipelines}
                                                        name={"pipeline"}
                                                        placeholder={'select pipeline'}
                                                        onChange={handlePipelineChange}
                                                        value={fields.pipeline ? fields.pipeline : ''}
                                                        classNamePrefix={`${errors["pipeline"]} basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                {
                                                    fields.pipeline && (fields.pipeline !== 'LeadsBucket' || (props.enquiry && props.enquiry.stage === 'InboundLead'))
                                                        ?
                                                        <>

                                                            <div className="form-group col-md-12">
                                                                <label ><Translate text={'Pipeline Stage'} /> </label>
                                                                <div className={`${errors["stage"] ? 'custom_error' : ''}`}>
                                                                    <ul className={`pipeline-step `}>
                                                                        {
                                                                            defaulStages.length > 0 && defaulStages.map((_stage, index) => {
                                                                                return (
                                                                                    <OverlayTrigger
                                                                                        key={index}
                                                                                        placement='bottom'
                                                                                        overlay={
                                                                                            <Tooltip>{_stage.name}</Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <li key={index} className={activeStageIndex >= index ? 'active' : ''}>
                                                                                            <a href="#" id={index} name={_stage.value} onClick={(e) => handleStageChange(e)}>
                                                                                                {/* {_stage.name} */}
                                                                                                {<>&nbsp;</>}
                                                                                            </a>
                                                                                        </li>
                                                                                    </OverlayTrigger>

                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                <label ><Translate text={'owner'} /></label>
                                                                <ReactSelect
                                                                    options={allUsers}
                                                                    name={"owner"}
                                                                    onChange={handleReactSelectChange}
                                                                    value={fields.owner ? fields.owner : ''}
                                                                    classNamePrefix={`${errors["owner"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>

                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                </div>

                                {/* </>
                                            }
                                        </>
                                } */}

                                <div className="form-group">
                                    <label ><Translate text={'notes'} /></label>
                                    <textarea
                                        className={`${errors["notes"]} form-control notes`}
                                        name="notes" onChange={(e) => handleNotesChanges(e)}
                                        value={fields.notes ? fields.notes : ''}
                                        placeholder="notes"
                                        cols="80"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className={`modal-footer ${loader ? 'form-disable' : ''}`}>

                    <button
                        type="button"
                        className={`btn btn-primary float-right ml-2 ${loader ? 'form-disable' : ''}`}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'done'} />
                    </button>
                    <button
                        type="button"
                        className="btn btn-default float-right"
                        onClick={(e) => handleCancel(e)}
                    ><Translate text={'cancel'} />
                    </button>


                </Modal.Footer>
            </Modal>

            <PopUpModal show={showContactInfo}>
                <ContactInfo
                    show={showContactInfo}
                    existingContact={existingContactInfo}
                    newContact={contact ? contact : {}}
                    isNewContact={isContactMerged ? false : true}
                    handleClose={handleXContactClose}
                    title={'contactDetails'}
                    dealersettings={props.dealersettings}
                    clientUsers={!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers}
                >
                </ContactInfo>
            </PopUpModal>

            <PopUpModal show={showCapInfo.show}>
                <CapInfo
                    show={showCapInfo.show}
                    contact={showCapInfo.contact}
                    handleClose={handleCloseCAPInfo}
                    dealersettings={props.dealersettings}
                    clientSettings={clientSettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    enquiryID={fields?.enqID}
                    //enquiry={{}}
                    inbound={true}
                />
            </PopUpModal>

        </>
    )
}

export default OEMLeadAllocation;