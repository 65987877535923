import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import moment from 'moment'
import ReactExport from 'react-export-excel-xlsx-fix';
import _, { filter } from 'lodash'
import CommonHelper from '../../services/common';
import { ContentOverlay, TableView, SidePanel, PopoverPanel, PopUpModal } from '../../components'
import toast from 'toasted-notes'
import { StageProvider, StageConsumer } from '../pipeline/provider'
import QuickView from "../pipeline/quickview";
import InBoundLeadConvert from '../pipeline/inBoundLeadConvert'
import LostApprovalNotes from '../common/lostApprovalNotes';
import _images from '../../images'
import { enquiryStatus, enqUnActionStatus } from '../../services/enum';
import ReAuthenticate from '../common/reAuthenticate';
import LeadTransfer from '../pipeline/leadTransfer'
import { objEnquiryListVM, allenquiryFields, objActivitylog, objNewEnquiryVM, objEnquiry } from '../pipeline/viewModel'
import { objContact } from '../contacts/viewModel'
import { objRequirement } from '../requirement/viewModel';

import Filters from '../pipeline/filters'
import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';
import enquiryHelper from '../../services/enquiryHelper';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;



const EnquiryList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();

    const [hasMore, setHasMore] = useState([1])

    const [enquiries, setEnquiries] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [enquiryFields, setEnquiryFields] = useState(props.dealersettings.enquiryFields)
    const [searchText, setSearchText] = useState('')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [csvHeader, setHeader] = useState([])
    const [selectedIds, setSelectedIds] = useState({})
    const [lostApprovalPopOver, setLostApprovalPopOver] = useState({ showpopover: false, targetpopover: null })
    const [inboundModal, setInboundModal] = useState({ show: false, docID: null });

    const [pageNum, setPageNum] = useState(0);
    const [filterpageNum, setfilterPageNum] = useState(0);

    const [loader, setLoader] = useState(true);
    const [pipelineFilter, setPipelineFilter] = useState({})
    const [searchKeywords, setSearchKeywords] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [showReAuthentication, setReAuthentication] = useState(false);
    const [csvData, setCSVData] = useState([])

    const [modalMoveShow, setModalMoveShow] = useState(false)
    const [sortConfig, setSortConfig] = useState();
    const [tableLoader, setTableLoader] = useState(false)

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? true : false);

    const enableSharedStock = ((props.dealersettings?.group?.enableSharedStock) ? true : false);

    const fetchEnquiries = async (type = 'generic', _pageNum, localFilter, _sortConfig) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled && type === 'excel') {
            handleReAuthOpen();
            return;
        }

        let num = _pageNum
        let pageLimit = props.counts ? props.counts > 49 ? 50 : props.counts : 50
        let filters = props.filter
        // on export fetch success csvBtn.current && csvBtn.current.link.click();
        // console.log('enquiriesList')
        // console.log('filters', filters)
        let _filter = {
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        //console.log('custom filters', filters)
        if (filters.customPipeline) {
            _filter.pipeline = filters.customPipeline
        } else if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (!_.isEmpty(props.pipeline) && !props.fromOEM) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        } else if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (filters && filters.status && filters.status !== 'all') {
            _filter.status = filters.status === 'wondelivered' ? 'won' : filters.status
        }
        if (props.dateRange && props.dateRange.startDate && props.dateRange.endDate && filters?.type !== 'unattended') {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (filters && filters.startDate && filters.endDate && filters.startDate.toDate && filters.endDate.toDate) {
            _filter.startDate = moment(filters.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(filters.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (filters && filters.startDate && filters.endDate && !filters.startDate.toDate) {
            _filter.startDate = moment(filters.startDate).format('YYYY-MM-DD')
            _filter.endDate = moment(filters.endDate).format('YYYY-MM-DD')
        }
        if (filters && filters.campaign) {
            _filter.campaign = filters.campaign
        }
        if (filters && filters.origin) {
            _filter.origin = filters.origin
        }
        if (filters && filters.enquiryType) {
            _filter.enquiryType = filters.enquiryType
        }
        if (filters && filters.leadSource) {
            _filter.leadSource = filters.leadSource
        }
        if (filters && filters.label) {
            _filter.label = filters.label
        }
        if (filters && filters.lostReason) {
            _filter.lostReason = filters.lostReason
        }
        if (filters && filters.stage) {
            _filter.stage = filters.stage
            // _filter.status = filters.stage
        }
        if (filters && filters.dateType) {
            _filter.dateType = filters.dateType
        }
        if (filters && filters.status === 'pending' && filters.pipeline) {
            _filter.pipeline = filters.pipeline[0]
            delete _filter.startDate
            delete _filter.endDate
        }
        if (filters && filters.status === 'pendingLost' && filters.pipeline) {
            delete _filter.startDate
            delete _filter.endDate
        }
        if (filters && filters.searchType === 'overallPending') {
            delete _filter.startDate;
            delete _filter.endDate;
            _filter.searchType = filters.searchType
        }
        if (filters && filters.activityOption) {
            delete _filter.startDate;
            delete _filter.endDate;
            // if (_.isEmpty(props.selectedPipeline)) {
            //     delete _filter.pipeline;
            // }
            _filter.activityOption = filters.activityOption;
            _filter.activityOptionType = filters.activityOptionType || '';
        }
        if (filters && filters.type === 'unattended') {
            _filter.rottenDays = '0'
            _filter.rottenType = '>'
        }
        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }
        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }
        if (filters && filters.documentIDs) {
            _filter = {
                documentIDs: filters.documentIDs.join(','),
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
        }
        else if (props.isFromDashboard) {
            _filter.fromDashboard = 'true';
            if (calcCurrentStatus) {
                _filter.calcCurrentStatus = 'true'
            }
        }
        if (!_.isEmpty(localFilter)) {
            _filter = { ..._filter, ...localFilter }
            // num = 0
            pageLimit = 50
        }
        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        /* MAKE & MODEL FILTER */
        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

        if (_.isEmpty(_filter.saleType) && _.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0 && _.isEmpty(_filter.documentIDs)) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        else if (!_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
            _filter.origin = excludeOrigins.includes(_filter.origin) ? null : _filter.origin

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.models?.length > 0) _filter.model = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')

        if (props.fromOEM) {
            _filter.inclInboundBuckeinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundBuckeinRpt;
            _filter.inclInboundLeadsinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundLeadsinRpt;
        }

        if (filters.type) {
            _filter.type = filters.type;
        }

        if (filters.model) {
            _filter.model = filters.model;
        }

        if (filters.custShowNoShowStatus) {
            _filter.custShowNoShow = true;
            _filter.custShowNoShowStatus = filters.custShowNoShowStatus;
        }
        if (filters.cap === true) {
            _filter.inboundSource = 'cap';
        }
        if (_.isBoolean(filters.emptyCap)) {
            _filter.emptyCap = filters.emptyCap;
        }

        let _searchObject = {
            "type": "searchEnquiries",
            "filters": _filter,
            "sortOrder": !_.isEmpty(_sortConfig) ? `${_sortConfig.key} ${_sortConfig.sort}` : `stageDate desc`,
            "pageNum": num,
            pageLimit,
        }
        if (type === 'excel') {
            _searchObject.pageLimit = (!props.counts || props.counts > 4999) ? 9999 : props.counts
            _searchObject.pageNum = 0
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
        }
        _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, null, null, true);
        if (props.fromOEM === true && props?.dealersettings?.clients?.length > 0 && filters.widget !== 'LeadsBucket') {
            _searchObject.clientID = props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.join(',') :
                props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).map(r => { return r.id }).join(',');
        }
        if (type === 'generic' && _.isEmpty(_sortConfig)) {
            setLoader(true)
        }
        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _clientSettings = props.dealersettings.client.settings;
            try {
                if (localStorage.defaultModule === 'oem' && type === 'excel') {
                    const _clientids = _.uniq(resp.data.data.map(r => { return r.clientID }));
                    if (_clientids.length > 0) {
                        for (let i = 0; i < _clientids.length; i++) {
                            const _currentSettings = await window.firebase.firestore().doc(`clients/${_clientids[i]}/currentSettings/${_clientids[i]}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });
                            if (!_.isEmpty(_currentSettings)) {
                                Object.keys(_currentSettings.settings).forEach(setting => {
                                    _clientSettings = {
                                        ..._clientSettings,
                                        [setting]: _.isArray(_currentSettings.settings[setting]) ? _.uniqBy(_.union(_clientSettings[setting], _currentSettings.settings[setting]), 'value') : _clientSettings[setting]
                                    }
                                })
                            }
                        }
                    }
                }
            } catch (err) { }
            //console.log('resp', resp)
            let _pageNum = num + 1;
            let enquiries_ = resp.data.data.map(a => CommonHelper.convertEnquiryVM(a, {
                ...props, dealersettings: { ...props.dealersettings, client: { ...props.dealersettings.client, settings: _clientSettings } }
            }))
            if (!_.isEmpty(localFilter)) {
                setfilterPageNum(_pageNum)
            } else {
                setPageNum(_pageNum)
            }
            if (type === 'excel') {
                setEnquiries(enquiries_)
                setCSVData(CommonHelper.generateExcelData(enquiries_, csvHeader, props, props?.dealersettings?.client?.settings?.enquiryOptionsStatic))
                setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })
                toast.closeAll();

                let _objLogData = {
                    notes: 'Excel export from dashboard - enquiries',
                    type: 'excelExport',
                    subType: 'enquiries',
                    params: JSON.stringify(_filter),
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);

            } else {
                if (type === 'paging') {
                    setEnquiries(_.uniqBy([...enquiries, ...enquiries_], 'documentID'))
                } else {
                    setEnquiries(enquiries_)
                }
            }
            if (type === 'generic') {
                setLoader(false)
            }
            setTableLoader(false)
        }
        else {
            console.log('error: ', resp.data.message)
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
            if (type === 'generic') {
                setLoader(false)
            }
            setTableLoader(false)
        }
    }



    const fetchDirectExcel = () => {
        // console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)

    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard) {
                    if (!_.isEmpty(pipelineFilter) && !_.isEmpty(pipelineFilter.value)) {
                        fetchEnquiries('excel', filterpageNum, pipelineFilter.value, sortConfig)
                    } else {
                        fetchEnquiries('excel', pageNum, null, sortConfig)
                    }
                }
                else
                    fetchDirectExcel();

            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        if (props.isFromDashboard) {
            fetchEnquiries('generic', pageNum)
        }
    }, [])

    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false



    useEffect(() => {
        let headerFields = enquiryFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allenquiryFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allenquiryFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allenquiryFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        if (localStorage.defaultModule === 'oem') {
            _.filter(allenquiryFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
                var index = _.findIndex(_headers, (e) => {
                    return e.key == rec.value;
                }, 0)
                if (index > 0) {
                    _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
                }
                else {
                    _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
                }
            })
        }
        setHeader(CommonHelper.bindContactName(_headers, 'contactName'));

    }, [enquiryFields])

    useEffect(() => {

        const enquiryOptions = ((!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.settings)) ? props.dealersettings.client.settings.enquiryOptionsDF : []);
        enquiryOptions.forEach(rec => {
            if (!allenquiryFields.some(e => e.value === rec.value))
                allenquiryFields.push({
                    name: rec.name,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        enquiryFields.forEach(rec => {
            if (!allenquiryFields.some(e => e.value === rec))
                deleteids.push(rec)
        })

        allenquiryFields.forEach((obj) => {
            if (obj.value === 'wonOn') {
                obj.name = CommonHelper.getNameByValue(props.dealersettings.client.settings.enquiryStatus, 'won', 'Won') + ' Date';
            }
            else if (obj.value === 'deliveredOn') {
                obj.name = CommonHelper.getNameByValue(props.dealersettings.client.settings.enquiryStatus, 'delivered', 'Delivered') + ' Date';
            }
            else if (obj.value === 'lostOn') {
                obj.name = CommonHelper.getNameByValue(props.dealersettings.client.settings.enquiryStatus, 'lost', 'Lost') + ' Date';
            }
        });
        if (!enquiryFields.some(e => e === 'checkbox')) {
            enquiryFields.push('checkbox')
        }
        setEnquiryFields(_.differenceBy(enquiryFields, deleteids))
        if (!props.isFromDashboard) {
            setLoader(false)
        }
    }, [props.dealersettings])

    useEffect(() => {
        if (_.isEmpty(props.filter) || props.isFromDashboard || _.isEmpty(csvHeader)) {
            return;
        }
        let _enquiries = []
        var _pipelines = props.filter.pipeline
        if (!_.isEmpty(props.selectedPipeline) && props.filter.status !== 'pending') {
            _pipelines = [props.selectedPipeline.value]
        }
        if (_.isArray(props.filter.enquiryIDs)) {
            _enquiries = props.dataList.filter(a =>
                (_.isArray(props.filter.enquiryIDs) ? props.filter.enquiryIDs.some(e => e === a.documentID) : true)
                && (!_.isEmpty(searchText) ?
                    (!_.isEmpty(a.displayID) ? a.displayID.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.email) ? a.email.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.contactPhone) ? a.contactPhone.includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.campaign) ? a.campaign.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.origin) ? a.origin.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.enquiryType) ? a.enquiryType.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.leadSource) ? a.leadSource.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.companyName) ? a.companyName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.owner) ? a.owner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.pipeline) ? a.pipeline.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.stage) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false)
                    : true)
            );
        }
        else {
            _enquiries = props.dataList.filter(a =>
                (!_.isEmpty(props.filter.enquiryIDs) ? props.filter.enquiryIDs.some(e => e === a.documentID) : true)
                && (_pipelines ? _pipelines.some(e => e === a.pipelineValue) : true)
                && (props.filter.status === 'pending' ? (a.stageValue !== 'Archived' && a.statusValue !== 'prospectLost' && a.statusValue !== 'lost') : true)
                && (props.filter.status === 'bdcshow' ? a.bdcStatus === 'show' : true)
                && (props.filter.status === 'bdcnoshow' ? a.bdcStatus === 'noshow' : true)
                && ((!_.isEmpty(props.filter.status) && props.filter.status !== 'all' && props.filter.status !== 'bdcshow' && props.filter.status !== 'bdcnoshow' && props.filter.status !== 'pending' && !_.isEmpty(a.statusValue)) ? (props.filter.status === 'wondelivered' ? (a.statusValue.toLowerCase() === 'won' || a.statusValue.toLowerCase() === 'delivered') : a.statusValue.toLowerCase() === props.filter.status.toLowerCase()) : true)
                && (!_.isEmpty(props.filter.stage) ? a.stageValue === props.filter.stage : true)
                && (!_.isEmpty(props.filter.campaign) ? a.campaignValue === props.filter.campaign : true)
                && (!_.isEmpty(props.filter.origin) ? a.originValue === props.filter.origin : true)
                && (!_.isEmpty(props.filter.enquiryType) ? a.enquiryTypeValue === props.filter.enquiryType : true)
                && (!_.isEmpty(props.filter.leadSource) ? a.leadSourceValue === props.filter.leadSource : true)
                && (!_.isEmpty(props.filter.label) ? a.labelValue === props.filter.label : true)
                && (!_.isEmpty(props.filter.leadType) ? (props.filter.leadType === 'existing' ? (
                    a.addedDate.seconds < props.filter.startDate.seconds
                    && (((a.statusValue === 'won' || a.statusValue === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= props.filter.startDate.seconds
                        && a.wonDate.seconds <= props.filter.endDate.seconds) ||
                        (a.statusValue === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= props.filter.startDate.seconds
                            && a.lostDate.seconds <= props.filter.endDate.seconds) ||
                        (a.testdrives && a.testdrives.some(item => item.startDateValue.seconds >= props.filter.startDate.seconds
                            && item.startDateValue.seconds <= props.filter.endDate.seconds)
                        ) ||
                        (
                            a.walkins && a.walkins.some(item => item.walkinDate.seconds >= props.filter.startDate.seconds
                                && item.walkinDate.seconds <= props.filter.endDate.seconds)
                        ))) : props.filter.leadType === 'walkins' ? (
                            a.walkins && a.walkins.some(item => item.walkinDate.seconds >= props.filter.startDate.seconds
                                && item.walkinDate.seconds <= props.filter.endDate.seconds)
                        ) :
                    props.filter.leadType === 'totalexisting' ? (
                        (a.addedDate.seconds >= props.filter.startDate.seconds
                            && a.addedDate.seconds <= props.filter.endDate.seconds)
                        ||
                        (a.addedDate.seconds < props.filter.startDate.seconds
                            && (((a.statusValue === 'won' || a.statusValue === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= props.filter.startDate.seconds
                                && a.wonDate.seconds <= props.filter.endDate.seconds) ||
                                (a.statusValue === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= props.filter.startDate.seconds
                                    && a.lostDate.seconds <= props.filter.endDate.seconds) ||
                                (a.testdrives && a.testdrives.some(item => item.startDateValue.seconds >= props.filter.startDate.seconds
                                    && item.startDateValue.seconds <= props.filter.endDate.seconds)
                                ) ||
                                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= props.filter.startDate.seconds
                                    && item.walkinDate.seconds <= props.filter.endDate.seconds)
                                )))) :
                        props.filter.leadType === 'convertedtotal' ? (
                            (a.convertedDate.seconds >= props.filter.startDate.seconds
                                && a.convertedDate.seconds <= props.filter.endDate.seconds)
                            ||
                            (a.convertedDate.seconds < props.filter.startDate.seconds
                                && (((a.statusValue === 'won' || a.statusValue === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= props.filter.startDate.seconds
                                    && a.wonDate.seconds <= props.filter.endDate.seconds) ||
                                    (a.statusValue === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= props.filter.startDate.seconds
                                        && a.lostDate.seconds <= props.filter.endDate.seconds) ||
                                    (a.testdrives && a.testdrives.some(item => item.startDateValue.seconds >= props.filter.startDate.seconds
                                        && item.startDateValue.seconds <= props.filter.endDate.seconds)
                                    ) ||
                                    (
                                        a.walkins && a.walkins.some(item => item.walkinDate.seconds >= props.filter.startDate.seconds
                                            && item.walkinDate.seconds <= props.filter.endDate.seconds)
                                    )))) :
                            props.filter.leadType === 'convertedexisting' ? (
                                a.convertedDate.seconds < props.filter.startDate.seconds
                                && (((a.statusValue === 'won' || a.statusValue === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= props.filter.startDate.seconds
                                    && a.wonDate.seconds <= props.filter.endDate.seconds) ||
                                    (a.statusValue === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= props.filter.startDate.seconds
                                        && a.lostDate.seconds <= props.filter.endDate.seconds) ||
                                    (a.testdrives && a.testdrives.some(item => item.startDateValue.seconds >= props.filter.startDate.seconds
                                        && item.startDateValue.seconds <= props.filter.endDate.seconds)
                                    ) ||
                                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= props.filter.startDate.seconds
                                        && item.walkinDate.seconds <= props.filter.endDate.seconds)
                                    ))) : false) : true)
                && (!_.isEmpty(props.filter.dateType) ? !_.isEmpty(a[props.filter.dateType]) : true)
                && (!_.isEmpty(props.filter.fieldType) ? !_.isEmpty(a[props.filter.fieldType]) : true)
                && ((props.filter.startDate && !_.isEmpty(a[props.filter.dateType])) ? a[props.filter.dateType].seconds >= props.filter.startDate.seconds : true)
                && ((props.filter.endDate && !_.isEmpty(a[props.filter.dateType])) ? a[props.filter.dateType].seconds <= props.filter.endDate.seconds : true)
                && ((props.filter.dateType === 'convertedDate') ? a.isConverted === true : true)
                && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)
                && ((ownerDashboard && props.filter.status !== 'pending') ? a.ownerValue === localStorage.uid : true)
                && (!_.isEmpty(props.selectedMake) ? (a.requirements && a.requirements.some(item => item.make === props.selectedMake.value || (item.stock && item.stock.make === props.selectedMake.value))) : true)
                && (!_.isEmpty(props.selectedModel) ? (a.requirements && a.requirements.some(item => item.model === props.selectedModel.value || (item.stock && item.stock.model === props.selectedModel.value))) : true)
                && (!_.isEmpty(props.selectedDMSType) ? (a.tags && a.tags.some(item => item.type === props.selectedDMSType.value)) : true)
                && ((!_.isEmpty(props.selectedLeadType) && props.selectedLeadType.value === 'new') ? (a.addedDate.seconds >= props.filter.startDate.seconds && a.addedDate.seconds <= props.filter.endDate.seconds) : true)
                && ((!_.isEmpty(props.selectedLeadType) && props.selectedLeadType.value === 'newExistingSold' && props.filter.colType === 'newExisting') ? (a.addedDate.seconds >= props.filter.startDate.seconds && a.addedDate.seconds <= props.filter.endDate.seconds) : true)
                && ((!_.isEmpty(props.selectedLeadType) && props.selectedLeadType.value === 'existing') ? (a.addedDate.seconds < props.filter.startDate.seconds) : true)
                && (!_.isEmpty(props.selectedOrigin) ? a.originValue === props.selectedOrigin.value : true)
                && (!_.isEmpty(props.selectedEnqType) ? a.enquiryTypeValue === props.selectedEnqType.value : true)
                && (!_.isEmpty(props.selectedCampaign) ? a.campaignValue === props.selectedCampaign.value : true)
                && (!_.isEmpty(searchText) ?
                    (!_.isEmpty(a.displayID) ? a.displayID.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.email) ? a.email.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.contactPhone) ? a.contactPhone.includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.campaign) ? a.campaign.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.origin) ? a.origin.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.enquiryType) ? a.enquiryType.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.leadSource) ? a.leadSource.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.companyName) ? a.companyName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.owner) ? a.owner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.pipeline) ? a.pipeline.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.stage) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false)
                    : true)
            );
        }
        setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
        setEnquiries(_enquiries)

    }, [props.dataList, searchText, csvHeader])


    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])
    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setLoader(true)
        setSettingsLoader(true)
        if (!fields.some(e => e === 'checkbox')) {
            fields.push('checkbox')
        }
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ enquiryFields: fields }, { merge: true })
            .then(() => {
                setEnquiryFields(fields)
                setSettingsLoader(false)
                setLoader(false)
                props.updateDealerSettings('enquiryFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                setLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, data) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" data-id={id} onClick={(e) => {
            if (data.pipelineValue === 'LeadsBucket' && data.stageValue === 'InboundLead') {
                //sameer show inbound lead popup
                handleShowInboundLead(id);
            }
            else {
                sidepanelOpen(id)
            }
        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id, data) => {
        if (data.pipelineValue === 'LeadsBucket' && data.stageValue === 'InboundLead') {
            //sameer show inbound lead popup
            handleShowInboundLead(id);
        }
        else {
            sidepanelOpen(id)
        }

    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0]);
                _selectedIds[file.documentID] = !_.isEmpty(record.objDoc) ? record.objDoc : record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = !_.isEmpty(record.objDoc) ? record.objDoc : record;
        }
        else {
            delete _selectedIds[name];
        }

        setSelectedIds(_selectedIds);
    }



    const handleFavorite = (id, val, data) => {
        const enq = props.dataList.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            // dispatch({
            //     type: "UPDATE_ENQUIRY_LIST",
            //     data: enq
            // });
            var query = `enquiries/${id}`
            if (data.pipelineValue === 'LeadsBucket' && data.stageValue === 'InboundLead') {
                query = `inboundLeads/${id}`
            }
            firestoreDB(props.dealersettings).firestore().doc(query)
                .set({
                    isFav: val
                }, { merge: true })
                .then(snapshot => {
                    // toast.notify('Enquiry updated successfully', {
                    //     duration: 2000
                    // })

                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }

    const handleShowLostApproval = (e) => {
        let _selectedIds = Object.assign({}, selectedIds);
        // console.log('selectedIds', selectedIds)
        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to approve.'), '', 'info');
            return;
        }
        else {
            setLostApprovalPopOver({ showpopover: true, targetpopover: e.target })
        }
    }
    const handleLostApprovalClose = async (approvalStatus, notes, mentions) => {
        if (approvalStatus) {
            let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            let modifiedDate = window.firebase.firestore.Timestamp.now();

            const batch = window.firebase.firestore().batch();
            let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
                result[key] = selectedIds[key];
                return result;
            }, {});

            for (let [key, value] of Object.entries(_selectedIds)) {
                let state = Object.assign({}, value);
                let objData = {};

                if (approvalStatus === 'approved') {
                    objData.status = enquiryStatus.LOST;
                    //objData = handleMoveStageForStatusChange(enquiryStatus.LOST, objData, state);
                    objData.lastStatus = '';
                    objData.lostDate = modifiedDate;
                    objData = CommonHelper.removeBatchNumberOnLost(objData, state, props.dealersettings?.client?.settings?.enquiryOptionsDF);
                }

                if (approvalStatus === 'rejected') {
                    objData.status = state.lastStatus;
                    objData.lastStatus = '';
                    objData.lostReason = '';
                    objData.lostSubReason = '';
                    objData.lostNotes = '';
                    objData.lostDate = null;
                    objData = handleRejectLostStatusChange(objData, state);
                }

                if (objData.status)
                    objData.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
                        {
                            date: window.firebase.firestore.Timestamp.now(),
                            status: objData.status,
                            user: localStorage.uid
                        }
                    )

                if (!_.isEmpty(mentions)) {
                    if (mentions.length > 0) {
                        let convertedIDs = []
                        let rawIDs = mentions.map(mentionObject => mentionObject.id)
                        rawIDs.forEach(id => {
                            if (id.includes(',')) {
                                let allIDs = id.split(',')
                                allIDs.forEach(a => {
                                    convertedIDs.push(a)
                                })
                            } else {
                                convertedIDs.push(id)
                            }
                        })

                        if (objData.mentions && objData.mentions.length > 0) {
                            objData.mentions = _.uniq([...objData.mentions, ...convertedIDs])
                        } else {
                            objData.mentions = _.uniq(convertedIDs)
                        }
                    }
                }


                objData.modifiedBy = modifiedBy;
                objData.modifiedDate = modifiedDate;
                await firestoreDB(props.dealersettings).firestore().collection('enquiries')
                    .doc(key)
                    .set(objData, { merge: true }).then((docRef) => {
                        if (notes) {
                            saveCustomerlog(key, (state.contact ? state.contact.documentID : null), notes, objData.mentions, state);
                        }
                    }).catch((error) => {
                        console.error("Error updating contactDetails: ", error);

                    });
            }

            batch.commit()
                .then(() => {
                    setEnquiries(_.filter(enquiries, (v) => _.indexOf(Object.keys(_selectedIds), v.documentID) < 0));
                    setSelectedIds({});
                    setTimeout(() => {
                        props.reloadData && props.reloadData();
                    }, 2000);
                    setLostApprovalPopOver({ showpopover: false, targetpopover: '' })
                    toast.notify(`Lead ${approvalStatus} successfully`, {
                        duration: 2000
                    })
                })
                .catch((error) => {
                    console.log(error);

                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else
            setLostApprovalPopOver({ showpopover: false, targetpopover: '' })
    }

    const handleRejectLostStatusChange = (state, stateEnquiry) => {

        const { stageHistory, pipelineValue, stageValue } = stateEnquiry;

        let _selPipeline = props.dealersettings.allPipelines.filter(e => e.value === stateEnquiry.pipelineValue)[0];
        let statusSettings = _selPipeline && _selPipeline.statusSettings;

        if (_.isEmpty(statusSettings)) {
            let _previousStage = !_.isEmpty(stageHistory) ? _.orderBy(stageHistory, ["date"], ["desc"])[1] : null
            let _selPipeline2 = !_.isEmpty(_previousStage) && props.dealersettings.allPipelines.filter(e => e.value === _previousStage.pipeline)[0];
            statusSettings = _selPipeline2 && _selPipeline2.statusSettings;
        }

        let _statusSettingData = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === 'lost' && e.pipeline === pipelineValue && e.stage === stageValue)[0];

        let data = !_.isEmpty(_statusSettingData) && !_.isEmpty(stageHistory) ? _.orderBy(stageHistory, ["date"], ["desc"])[1] : null;

        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], stageHistory);
            let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            return state;
        }
        else
            return state;
    }

    const handlePagination = () => {
        // console.log('pagination clicked')
        // console.log('run pagination')
        if (!_.isEmpty(pipelineFilter) && !_.isEmpty(pipelineFilter.value)) {
            fetchEnquiries('paging', filterpageNum, pipelineFilter.value)
        } else {
            if (enquiries.length >= props.counts) {
                return
            }
            fetchEnquiries('paging', pageNum)
        }
    }

    const handlesearchText = (text) => {
        // console.log('handlesearchText', text)
        if (text) {
            fetchEnquiries('generic', 0, { keywords: text })
            setPipelineFilter({
                name: 'Adv Filter',
                type: 'filter',
                value: { keywords: text }
            })
            setPageNum(0);
            setfilterPageNum(0)
        } else {
            setPageNum(0);
            setfilterPageNum(0)
            setPipelineFilter({})
            fetchEnquiries('generic', pageNum)
        }
    }

    const saveCustomerlog = (enquiryID, contactID, notes, mentions, _enquiry) => {
        const newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = 'log';
        newlogVM.subType = 'note';
        newlogVM.isDone = true;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.enquiryID = enquiryID;
        newlogVM.contactID = contactID ? contactID : null;
        newlogVM['enquiry'] = CommonHelper.getMinifiedData(_enquiry, 'enquiry');
        newlogVM['contact'] = CommonHelper.getMinifiedData(_enquiry?.contact, 'contact');
        newlogVM.clientID = props.dealersettings ? props.dealersettings.client.id : null;

        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = window.firebase.firestore.Timestamp.now();
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), localStorage.uid);
        newlogVM.modifiedByName = newlogVM.addedByName;
        newlogVM.notes = notes ? notes : '';
        if (mentions) {
            newlogVM.mentions = mentions
        }
        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
        newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });
    };

    const handleShowInboundLead = (id) => {
        //console.log(id)
        setInboundModal({ show: true, docID: id })
    }

    const handleCloseInboundLead = () => {
        setInboundModal({ show: false, docID: null })
        CommonHelper.refreshStageCount('LeadsBucket', props.dealersettings);
        setTimeout(() => {
            props.reloadData && props.reloadData();
        }, 2000);
    }

    const handleApplyFilter = (_filter) => {
        // console.log('handleApplyFilter', _filter)
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            fetchEnquiries('generic', 0, _filter.value)
            setPipelineFilter(_filter)
            setFilterApplied(true);
            setPageNum(0);
            setfilterPageNum(0)
            if (_filter.value.hasOwnProperty('keywords')) {
                setSearchText(_filter.value.keywords);
            } else {
                setSearchText('');
            }
            setSortConfig(null)
            // localStorage.enquirySearchText = '';
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        //    console.log('handleClearFilter')
        if (!_.isEmpty(pipelineFilter)) {
            setPipelineFilter({})
            fetchEnquiries('generic', pageNum)

            // localStorage.removeItem('pipelineFilter');
            // localStorage.enquirySearchText = '';
            setFilterApplied(false);
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
            setPageNum(0);
            setfilterPageNum(0)
            setSearchText('');
            setSortConfig(null)
        }
    }

    // useEffect(() => {
    //     if (!_.isEmpty(localStorage.pipelineFilter) && Object.keys(JSON.parse(localStorage.pipelineFilter)).length > 0) {
    //         let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
    //         let _filterValue = _pipelineFilter.value;

    //         if (!_.isEmpty(_filterValue) && _filterValue.hasOwnProperty('keywords'))
    //             setSearchKeywords(_filterValue['keywords']);
    //         else
    //             setSearchKeywords('');

    //         setPipelineFilter(_pipelineFilter);
    //     }
    //     else {
    //         setPipelineFilter({});
    //         setSearchKeywords('');
    //     }
    // }, [localStorage.pipelineFilter])


    // const updateListEnquiry = (objEnquiry) => {
    //     if (!_.isEmpty(objEnquiry)) {
    //         let enq = convertEnquiryVM(objEnquiry);

    //         if (!_.isEmpty(enq)) {
    //             dispatch({
    //                 type: "UPDATE_ENQUIRY_LIST",
    //                 data: enq
    //             });
    //         }
    //     }

    // }

    //#region ALLOCATE LEADS
    const handleShowMoveEnquiries = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to allocate.'), '', 'info');
            return;
        }
        else {
            setModalMoveShow(true)
        }
    }

    const handleCloseMoveEnquiries = async (fields) => {
        //    console.log('handleCloseMoveEnquiries',handleCloseMoveEnquiries)
        if (!_.isEmpty(fields)) {

            let ObjData = {
                modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                stageDate: window.firebase.firestore.Timestamp.now(),
                bulkDt: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            if (fields.clientID)
                ObjData.clientID = fields.clientID;

            if (fields.pipeline)
                ObjData.pipeline = fields.pipeline;

            if (fields.stage)
                ObjData.stage = fields.stage;

            if (fields.owner)
                ObjData.owner = fields.owner;

            // if (fields.status)
            //     ObjData.status = fields.status;

            const batch = firestoreDB(props.dealersettings).firestore().batch();
            ObjData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
            let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
                result[key] = selectedIds[key];
                return result;
            }, {});

            for (let [key, value] of Object.entries(_selectedIds)) {
                let _selectedEnq = Object.assign({}, value);
                if (_selectedEnq.pipeline === 'LeadsBucket' && _selectedEnq.stage === 'InboundLead')
                    await updateInboundLead(batch, key, _selectedEnq, ObjData, fields.status);
                else
                    await updateEnquiry(batch, key, _selectedEnq, ObjData, fields.status);
            }

            batch.commit()
                .then(() => {
                    setEnquiries(_.filter(enquiries, (v) => _.indexOf(Object.keys(_selectedIds), v.documentID) < 0));
                    setSelectedIds({});
                    setModalMoveShow(false)
                    setTimeout(() => {
                        props.reloadData && props.reloadData();
                    }, 2000);

                    let _leadsSize = Object.keys(_selectedIds).length;
                    toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Leads allocated successfully`, {
                        duration: 2000
                    })

                    if (ObjData.pipeline) CommonHelper.refreshStageCount(ObjData.pipeline, props.dealersettings);
                })
                .catch((error) => {
                    console.log(error);
                    setModalMoveShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalMoveShow(false);
        }
    }


    //#region ALLOCATE ENQUIRY PIPELINE

    const updateEnquiry = async (batch, enqID, objEnq, ObjData, _enqStatus) => {
        const { dealersettings } = props;
        let _selectedEnq = Object.assign({}, objEnq);
        let _objEnq = Object.assign({}, ObjData);

        let newstagehistory = {
            pipeline: ObjData.pipeline,
            stage: ObjData.stage,
            date: window.firebase.firestore.Timestamp.now()
        };


        if (_selectedEnq.pipeline === 'LeadsBucket' && ObjData.pipeline !== 'LeadsBucket') {
            _objEnq.status = enquiryHelper.bindDefaultStatus(_enqStatus, _selectedEnq.status);
            _objEnq.isConverted = true;
            _objEnq.unactionedNotify = enqUnActionStatus.PENDING;
            _objEnq.convertedBy = props.dealersettings ? props.dealersettings.id : '';
            _objEnq.convertedDate = window.firebase.firestore.Timestamp.now();

            _objEnq.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
                {
                    date: window.firebase.firestore.Timestamp.now(),
                    status: _objEnq.status,
                    user: localStorage.uid
                }
            )

            if (_.isEmpty(_selectedEnq.tags) && _selectedEnq.pipeline === 'LeadsBucket') {
                _objEnq.tags = [{ type: 'inbound', refID: enqID, subType: '' }]
            }

            let transferHistory = Object.assign([], _selectedEnq.transferHistory);
            let _newTransferHistory = {
                by: localStorage.uid,
                date: window.firebase.firestore.Timestamp.now(),
            }

            if (localStorage.defaultModule === 'oem' && dealersettings.level === 'oem') {
                // _objEnq.isConvertedOEM = true;
                // _objEnq.convertedByOEM = localStorage.uid;
                // _objEnq.convertedDateOEM = window.firebase.firestore.Timestamp.now();
                // _objEnq.convertedOEMID = dealersettings.oemID ? dealersettings.oemID : null;
                _newTransferHistory.fromOEM = dealersettings.oemID ? dealersettings.oemID : null;
            }
            else if (localStorage.defaultModule === 'oem' && dealersettings.level === 'region') {
                // _objEnq.isConvertedRegion = true;
                // _objEnq.convertedByRegion = localStorage.uid;
                // _objEnq.convertedDateRegion = window.firebase.firestore.Timestamp.now();
                // _objEnq.convertedRegionID = dealersettings.regionID ? dealersettings.regionID : null;
                _newTransferHistory.fromRegion = dealersettings.regionID ? dealersettings.regionID : null;
            }
            else if (localStorage.defaultModule === 'oem' && dealersettings.level === 'group') {
                // _objEnq.isConvertedGroup = true;
                // _objEnq.convertedByGroup = localStorage.uid;
                // _objEnq.convertedDateGroup = window.firebase.firestore.Timestamp.now();
                // _objEnq.convertedGroupID = dealersettings.groupID ? dealersettings.groupID : null;
                _newTransferHistory.fromGroup = dealersettings.groupID ? dealersettings.groupID : null;
            }

            if (localStorage.defaultModule === 'oem' && ObjData.clientID) {
                _newTransferHistory.toClient = ObjData.clientID;
                transferHistory.push(_newTransferHistory);
                _objEnq.transferHistory = transferHistory;
            }

        }
        else
            _objEnq.ownerInbound = _objEnq.owner ? _objEnq.owner : null;

        let _contactChange = false;
        if (_selectedEnq.isContact && !_.isEmpty(_selectedEnq.contact?.documentID) && ObjData.clientID && _selectedEnq.clientID !== ObjData.clientID) {
            if (!(dealersettings?.group?.enableSharedContact && !_.isEmpty(dealersettings.group?.client_ids) && dealersettings.group?.client_ids.includes(ObjData.clientID))) {
                let _tags = Object.assign([], _selectedEnq.tags);
                _tags.push({
                    type: 'Dealer Transfer',
                    refID: (_selectedEnq.contact.documentID || null),
                    clientID: (_selectedEnq.contact.clientID || null),
                    origin: 'dashboard_list_transfer'
                })
                _objEnq.engageNewContact = Object.assign({}, {
                    ..._selectedEnq.contact,
                    documentID: null,
                    displayID: null,
                    clientID: null,
                    projectId: null,
                    groupID: null,
                    regionID: null,
                    oemID: null,
                });
                _objEnq.contact = {};
                _objEnq.contactID = null;
                _objEnq.isContact = false;
                _objEnq.tags = _objEnq.tags ? _.union(_tags, _objEnq.tags) : _tags;
                _contactChange = true;
            }
        }

        let arrstagehistory = !_.isEmpty(_selectedEnq.stageHistory) ? _selectedEnq.stageHistory : []
        if (arrstagehistory.some(e => e.stage === ObjData.stage && e.pipeline === ObjData.pipeline)) {
            newstagehistory = _.filter(arrstagehistory, { stage: ObjData.stage, pipeline: ObjData.pipeline })[0]
            newstagehistory.date = window.firebase.firestore.Timestamp.now()
        }
        arrstagehistory = [...arrstagehistory.filter(e => e.stage !== ObjData.stage), newstagehistory]
        _objEnq.stageHistory = arrstagehistory;

        //Remove empty value from object
        Object.keys(_objEnq).forEach(key => _objEnq[key] === undefined ? delete _objEnq[key] : {});
        // for (var propName in _objEnq) {
        //     if (_objEnq[propName] === null || _objEnq[propName] === undefined || _objEnq[propName] === '' || (_.isObject(_objEnq[propName]) && _.isEmpty(_objEnq[propName]))) {
        //         delete _objEnq[propName];
        //     }
        // }

        //console.log('_objEnq', _objEnq)

        batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${enqID}`), _objEnq, { merge: true });

        if ((ObjData.owner && _selectedEnq.owner !== ObjData.owner) || (ObjData.clientID && ObjData.clientID !== props.dealersettings.client.id)) {
            let _objAct = {}
            if (ObjData.owner) _objAct.owner = ObjData.owner;
            if (ObjData.clientID) _objAct.clientID = ObjData.clientID;
            if (_contactChange) {
                _objAct.contactID = null;
                _objAct.contact = {};
            }
            if (!_.isEmpty(_objAct)) {
                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', enqID)
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', false)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, _objAct);
                        });
                    });
                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', enqID)
                    .where('isHidden', '==', true)
                    .where('isDeleted', '==', true)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, _objAct);
                        });
                    });

            }
        }

        if ((ObjData.clientID && ObjData.clientID !== props.dealersettings.client.id)) {
            await firestoreDB(props.dealersettings).firestore().collection('tradeins')
                .where('enquiryID', '==', enqID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                });

            await firestoreDB(props.dealersettings).firestore().collection('testdrives')
                .where('enquiryID', '==', enqID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                });

            await firestoreDB(props.dealersettings).firestore().collection('cafeOrders')
                .where('enquiryID', '==', enqID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                });
            await firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enqID}/walkins`)
                .get().then(response => {
                    response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                });
        }
    }
    //#endregion

    //#region CONVERT LEAD & ALLOCATE ENQUIRY PIPELINE

    const updateInboundLead = async (batch, inoundID, objInbound, fields, _enqStatus) => {
        // debugger;
        const { dealersettings } = props;
        let response;
        let notes = [];
        if (!_.isEmpty(objInbound.enquiryID) && objInbound.status === 0) {
            response = await getEnquiryLead(objInbound);

        }
        else {
            if (objInbound.systemType === 'autogate')
                response = await createCarSalesLead(objInbound, fields, notes);
            else if (objInbound.systemType === 'imotor')
                response = await createIMotorLead(objInbound, fields, notes);
            else
                response = await createGenericLead(objInbound, fields, notes);
        }

        if (response) {
            if (!_.isEmpty(response.contact)) {
                // if (_.isEmpty(response.contact['documentID']))
                //     response.contact['documentID'] = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc().id;
                response.contact['isDeleted'] = false;
            }
        }

        objInbound.tags = [CommonHelper.getNameByValue(props.dealersettings.integrations, objInbound.systemType), objInbound.subType];

        let state = Object.assign({}, response);

        //if (_.isEmpty(state.documentID) && _.isEmpty(state.addedBy)) {
        state.addedBy = dealersettings ? dealersettings.id : '';
        state.addedDate = window.firebase.firestore.Timestamp.now();
        state.addedFrom = 'web';
        //}
        state.modifiedBy = dealersettings ? dealersettings.id : '';
        state.modifiedDate = window.firebase.firestore.Timestamp.now();
        state.createdDate = window.firebase.firestore.Timestamp.now();
        state.modifiedFrom = 'web';
        state.isNewEnquiry = true;

        const inboundAllocation = (localStorage.defaultModule === 'oem') ? true : false;

        state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings, null, fields.clientID, inboundAllocation);

        if (!_.isEmpty(state.contact)) {
            let objContactData = Object.assign({}, state.contact);
            //Remove empty value from object
            for (var propName in objContactData) {
                if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                    delete objContactData[propName];
                }
            }

            //save license image
            if (objInbound.request && objInbound.request.LicenseImageURL && objInbound.request.LicenseImageURL.includes('http')) {
                objContactData.licenseFrontURL = objInbound.request.LicenseImageURL;
            }
            if (objInbound.request && objInbound.request.LicenseImageBackURL && objInbound.request.LicenseImageBackURL.includes('http')) {
                objContactData.licenseBackURL = objInbound.request.LicenseImageBackURL;
            }
            if (objInbound.request && objInbound.request.ImageURL && objInbound.request.ImageURL.includes('http')) {
                objContactData.userImageURL = objInbound.request.ImageURL;
            }
            if (objInbound.request && objInbound.request.LicenseExpiry) {
                objContactData.licenseExpiry = moment(objInbound.request.LicenseExpiry).format('YYYY-MM-DD');
            }
            if (objInbound.request && objInbound.request.LicenseNo) {
                objContactData.licenseNo = objInbound.request.LicenseNo;
            }
            //end

            const _systemType = Object.assign({}, !_.isEmpty(dealersettings?.client?.integrations) ?
                dealersettings.client.integrations.filter(e => e.type === objInbound.systemType)[0] : {});
            if (!_.isEmpty(_systemType) && _systemType?.settings?.customerConsent)
                objContactData.terms = {
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    verbalConsent: false,
                    signature: '',
                    termsAndConditions: !_.isEmpty(dealersettings?.client?.digitalConsent) ? dealersettings.client.digitalConsent : (!_.isEmpty(dealersettings?.client?.customerConsent) ? dealersettings.client.customerConsent : ''),
                    consentType: 'Digital'
                };

            state.engageNewContact = objContactData;
            state.contact = null;
        }

        if (fields.clientID) state.clientID = fields.clientID;

        if (fields.pipeline) state.pipeline = fields.pipeline;

        if (fields.stage) state.stage = fields.stage;

        if (fields.owner) state.owner = fields.owner;

        if (objInbound.campaign) state.campaign = objInbound.campaign;
        if (objInbound.origin) state.origin = objInbound.origin;
        if (objInbound.enquiryType) state.enquiryType = objInbound.enquiryType;
        if (objInbound.leadSource) state.leadSource = objInbound.leadSource;
        if (objInbound.eventsProAttendeeID) state.eventsProAttendeeID = objInbound.eventsProAttendeeID;
        if (objInbound.purchaseIntention) state.purchaseIntention = objInbound.purchaseIntention;
        if (objInbound.depositAmount) state.depositAmount = objInbound.depositAmount;
        if (objInbound.depositReciptNumber) state.depositReciptNumber = objInbound.depositReciptNumber;

        if (fields.pipeline !== 'LeadsBucket') {
            state.status = _enqStatus ? _enqStatus : 'open';
        }
        else {
            state.status = null;
            state.ownerInbound = state.owner;
        }
        state.inboundLeadID = objInbound?.documentID || null;
        if (objInbound.leadStatus) state.status = objInbound.leadStatus;

        if (state.status)
            state.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
                {
                    date: window.firebase.firestore.Timestamp.now(),
                    status: state.status,
                    user: localStorage.uid
                }
            )
        state.stageDate = window.firebase.firestore.Timestamp.now();
        state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
        state.isDeleted = false;
        state.tags = [
            {
                type: (objInbound.systemType ? objInbound.systemType : ''),
                refID: (objInbound.refID ? objInbound.refID : ''),
                subType: (objInbound.subType ? objInbound.subType : '')
            }
        ]

        if (state.pipeline !== 'LeadsBucket') {
            state.isConverted = true;
            state.unactionedNotify = enqUnActionStatus.PENDING;
            state.convertedBy = dealersettings ? dealersettings.id : localStorage.uid;
            state.convertedDate = window.firebase.firestore.Timestamp.now();

            let transferHistory = Object.assign([], state.transferHistory);
            let _newTransferHistory = {
                by: localStorage.uid,
                date: window.firebase.firestore.Timestamp.now(),
            }

            if (localStorage.defaultModule === 'oem' && dealersettings.level === 'oem') {
                // state.isConvertedOEM = true;
                // state.convertedByOEM = localStorage.uid;
                // state.convertedDateOEM = window.firebase.firestore.Timestamp.now();
                // state.convertedOEMID = dealersettings.oemID ? dealersettings.oemID : null;
                _newTransferHistory.fromOEM = dealersettings.oemID ? dealersettings.oemID : null;
            }
            else if (localStorage.defaultModule === 'oem' && dealersettings.level === 'region') {
                // state.isConvertedRegion = true;
                // state.convertedByRegion = localStorage.uid;
                // state.convertedDateRegion = window.firebase.firestore.Timestamp.now();
                // state.convertedRegionID = dealersettings.regionID ? dealersettings.regionID : null;
                _newTransferHistory.fromRegion = dealersettings.regionID ? dealersettings.regionID : null;
            }
            else if (localStorage.defaultModule === 'oem' && dealersettings.level === 'group') {
                // state.isConvertedGroup = true;
                // state.convertedByGroup = localStorage.uid;
                // state.convertedDateGroup = window.firebase.firestore.Timestamp.now();
                // state.convertedGroupID = dealersettings.groupID ? dealersettings.groupID : null;
                _newTransferHistory.fromGroup = dealersettings.groupID ? dealersettings.groupID : null;
            }

            if (localStorage.defaultModule === 'oem' && fields.clientID) {
                _newTransferHistory.toClient = fields.clientID;
                transferHistory.push(_newTransferHistory);
                state.transferHistory = transferHistory;
            }
        }

        const objData = Object.assign({}, state);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        //    console.log('handleSaveEnquiry', objData)

        if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

        //salestype access
        if (_.isEmpty(objData.requirement)) {
            const ownerRoleID = props.clientUsers ? props.clientUsers.find(rec => rec.id === objData.owner) : null;
            if (ownerRoleID) {
                const ownerRole = props.dealersettings.client.roles ? props.dealersettings.client.roles.find(rec => rec.documentID === ownerRoleID.roleID) : null;
                if (ownerRole && _.isArray(ownerRole.salesType) && ownerRole.salesType.length > 0) {
                    objData.requirement = {
                        saleType: ownerRole.salesType[0]
                    }
                }
            }
        }
        //end

        batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objData.documentID}`), objData, { merge: true });
        let documentID = objData.documentID;

        if (_.isEmpty(state.documentID) && objData.isVehicle && !_.isEmpty(documentID) && !_.isEmpty(objData.requirement))
            saveReqInfo(batch, documentID, objData.requirement, objData.clientID)

        if (!_.isEmpty(documentID) && !_.isEmpty(objInbound.documentID))
            await updateInBound(batch, documentID, objInbound.documentID, objData);


        if (objData.isConverted) {
            CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, 'Lead converted', 'enquiry', 'updated', batch);
        }

        //add test drive when lead received is from fusion events pro
        if (!_.isEmpty(objInbound?.request?.testDrives))
            enquiryHelper.createTestdrivesFromEventsPro(objData, objInbound.request.testDrives, dealersettings, batch);

        // UPDATE EVENT ATTENDEE - ALLOCATION STATUS
        enquiryHelper.updateEventsAttendee(objInbound.eventsProAttendeeID, documentID, dealersettings, batch);

        saveNotes(batch, documentID, objData, notes);

    }

    const createIMotorLead = async (viewmodel, fields, notes) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM);
        const { dealersettings } = props;

        try {
            const _objRequirement = Object.assign({}, objRequirement)

            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.contact = viewmodel.contact;
                _objEnquiry.isContact = false;
            }

            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.meta_data)) {

                if (viewmodel.request.meta_data.stock_number) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', viewmodel.request.meta_data.stock_number)
                        .where('isDeleted', '==', false)

                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (viewmodel.request.meta_data.make && viewmodel.request.meta_data.model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value.toLowerCase() === viewmodel.request.meta_data.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = viewmodel.request.meta_data.model ? viewmodel.request.meta_data.model : '';
                        _objRequirement.saleType = viewmodel.request.meta_data.veh_type && viewmodel.request.meta_data.veh_type.toLowerCase() == "used" ? "Preowned" : "New";
                        _objRequirement.price = viewmodel.request.meta_data.price ? parseInt(viewmodel.request.meta_data.price) : null;
                        _objRequirement.year = viewmodel.request.meta_data.year ? viewmodel.request.meta_data.year : '';
                        _objRequirement.stock = null;
                        if (viewmodel.request.meta_data.stock_number)
                            _objRequirement.stockNo = viewmodel.request.meta_data.stock_number;
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;

                        let _model = _make.models && _make.models.filter(m => m.value.toLowerCase() === viewmodel.request.meta_data.model.toLowerCase())[0];
                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                        }
                    }
                }
            }


            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo))) && !_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.meta_data)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(viewmodel.request.meta_data);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }


            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {

            errorLog(error, 'createIMotorLead');
            return _objEnquiry;
        }

    }

    const createGenericLead = async (viewmodel, fields, notes) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM);
        const { dealersettings } = props;
        try {
            const _objRequirement = Object.assign({}, objRequirement);

            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.contact = viewmodel.contact;
                _objEnquiry.isContact = false;
            }

            let _requiremntData = viewmodel.requirement ? viewmodel.requirement : (['facebook', 'eventsPro', 'tss'].includes(viewmodel.systemType)) ? null : viewmodel.request;
            if (!_.isEmpty(_requiremntData)) {

                if (_requiremntData.stockNo) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', _requiremntData.stockNo)
                        .where('isDeleted', '==', false)

                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (_requiremntData.make && _requiremntData.model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value.toLowerCase() === _requiremntData.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = _requiremntData.model ? _requiremntData.model : '';
                        _objRequirement.type = _requiremntData.type ? _requiremntData.type : '';
                        _objRequirement.saleType = _requiremntData.saleType ? _requiremntData.saleType : '';
                        _objRequirement.price = _requiremntData.price ? parseInt(_requiremntData.price) : null;
                        _objRequirement.extColor = _requiremntData.extColor ? _requiremntData.extColor : null;
                        _objRequirement.year = _requiremntData.year ? _requiremntData.year : null;
                        _objRequirement.intColor = _requiremntData.intColor ? _requiremntData.intColor : null;
                        _objRequirement.stock = null;

                        _objRequirement.stockNo = _requiremntData.stockNo ? _requiremntData.stockNo : '';
                        _objRequirement.regNo = _requiremntData.regNo ? _requiremntData.regNo : '';
                        _objRequirement.vinNo = _requiremntData.vinNo ? _requiremntData.vinNo : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;

                        let _model = _make.models && _make.models.filter(m => m.value.toLowerCase() === _requiremntData.model.toLowerCase())[0];
                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                        }
                    }
                }
            }


            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo))) && !_.isEmpty(_requiremntData)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(_requiremntData);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            //add test drive notes when lead received is from fusion events
            if (viewmodel.request && _.isArray(viewmodel.request.TestDrives) && viewmodel.request.TestDrives.length > 0) {
                let _tdNotes = 'Test Drives : ' + '\n';
                viewmodel.request.TestDrives.forEach(rec => {
                    if (rec.StartTime) _tdNotes += 'Start Time : ' + moment(rec.StartTime).format('DD/MM/YYYY hh:mm A') + '\n';
                    if (rec.EndTime) _tdNotes += 'End Time : ' + moment(rec.EndTime).format('DD/MM/YYYY hh:mm A') + '\n';
                    if (rec.VehicleModel) _tdNotes += 'Vehicle Model : ' + `${rec.VehicleMake} ${rec.VehicleModel} ${rec.VehicleYear > 0 ? rec.VehicleYear : ''}` + '\n';
                    _tdNotes += '-------' + '\n';
                })

                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = _tdNotes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            //add test drive notes when lead received is from fusion events pro
            // if (viewmodel.request && _.isArray(viewmodel.request.testDrives) && viewmodel.request.testDrives.length > 0) {
            //     let _tdNotes = 'Test Drives : ' + '\n';
            //     viewmodel.request.testDrives.forEach(rec => {
            //         if (rec.startDate) _tdNotes += 'Start Date : ' + moment.unix(rec.startDate.seconds).format('DD/MM/YYYY hh:mm A') + '\n';
            //         if (rec.endDate) _tdNotes += 'End Date : ' + moment.unix(rec.endDate.seconds).format('DD/MM/YYYY hh:mm A') + '\n';
            //         if (rec.model) _tdNotes += 'Vehicle Model : ' + `${rec.make} ${rec.model} ${rec.year ? rec.year : ''}` + '\n';
            //         if (rec.regNo) _tdNotes += 'Reg# : ' + `${rec.regNo}` + '\n';
            //         if (rec.vinNo) _tdNotes += 'VIN# : ' + `${rec.vinNo}` + '\n';
            //         _tdNotes += '-------' + '\n';
            //     })

            //     let newlogVM = Object.assign({}, objActivitylog);
            //     newlogVM.type = 'log';
            //     newlogVM.subType = 'note';
            //     newlogVM.isDone = true;
            //     newlogVM.addedByName = 'System';
            //     newlogVM.addedBy = 'System';
            //     newlogVM.modifiedBy = 'System';
            //     newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
            //     newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            //     newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
            //     newlogVM.startDate = window.firebase.firestore.Timestamp.now();
            //     newlogVM.notes = _tdNotes;
            //     newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
            //     //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
            //     notes.push(newlogVM)
            // }

            if (viewmodel.request && _.isArray(viewmodel.request.Notes) && viewmodel.request.Notes.length > 0) {
                viewmodel.request.Notes.forEach(rec => {
                    if (rec.Notes) {
                        let newlogVM = Object.assign({}, objActivitylog);
                        newlogVM.type = 'log';
                        newlogVM.subType = 'note';
                        newlogVM.isDone = true;
                        newlogVM.addedByName = CommonHelper.getUserNamebyId(!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers, rec.addedBy, 'System');
                        newlogVM.addedBy = rec.addedBy ? rec.addedBy : 'System';
                        newlogVM.modifiedBy = rec.modifiedBy ? rec.modifiedBy : 'System';
                        newlogVM.addedDate = rec.addedDate ? rec.addedDate : rec.ModifiedDate ? window.firebase.firestore.Timestamp.fromDate(moment(rec.ModifiedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                        newlogVM.modifiedDate = newlogVM.addedDate;
                        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                        newlogVM.startDate = newlogVM.addedDate;
                        newlogVM.notes = rec.Notes;
                        newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                        //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                        notes.push(newlogVM)
                    }
                })
            }


            //TSS create call activity
            if (viewmodel?.activity) {
                let newlogVM = Object.assign({}, viewmodel.activity);
                newlogVM.addedDate = newlogVM.addedDate && newlogVM.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.addedDate._seconds)._d) : newlogVM.addedDate;
                newlogVM.modifiedDate = newlogVM.modifiedDate && newlogVM.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.modifiedDate._seconds)._d) : newlogVM.modifiedDate;
                newlogVM.startDate = newlogVM.startDate && newlogVM.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.startDate._seconds)._d) : newlogVM.startDate;
                newlogVM.endDate = newlogVM.endDate && newlogVM.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.endDate._seconds)._d) : newlogVM.endDate;
                newlogVM.callDuration = fields?.activity?.callDuration || 0;
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                notes.push(newlogVM)
            }

            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {
            errorLog(error, 'createGenericLead');
            return _objEnquiry;
        }

    }

    const createCarSalesLead = async (viewmodel, fields, notes) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM)
        const { dealersettings } = props;
        try {

            const _objContact = Object.assign({}, objContact)
            const _objRequirement = Object.assign({}, objRequirement)

            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.isContact = false;
                _objContact.firstName = viewmodel.contact.firstName ? viewmodel.contact.firstName : '';
                _objContact.middleName = viewmodel.contact.middleName ? viewmodel.contact.middleName : '';
                _objContact.lastName = viewmodel.contact.lastName ? viewmodel.contact.lastName : '';
                _objContact.phoneCode = viewmodel.contact.phoneCode ? viewmodel.contact.phoneCode : '';
                _objContact.phone = viewmodel.contact.phone ? viewmodel.contact.phone : '';
                _objContact.email = viewmodel.contact.email ? viewmodel.contact.email : '';
                _objEnquiry.contact = _objContact;
            }

            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.Prospect)) {
                _objEnquiry.isContact = false;
                _objContact.address = viewmodel.request.Prospect.Address ? viewmodel.request.Prospect.Address : '';
                _objContact.state = viewmodel.request.Prospect.State ? viewmodel.request.Prospect.State : '';
                _objContact.areaCode = viewmodel.request.Prospect.Postcode ? viewmodel.request.Prospect.Postcode : '';
                // if (_objContact.state)
                //     _objContact.country = CommonHelper.getNameByValue(states, _objContact.state, '');

                if (!_objContact.phone) {
                    _objContact.phone = viewmodel.request.Prospect.WorkPhone ? viewmodel.request.Prospect.WorkPhone : (viewmodel.request.Prospect.HomePhone ? viewmodel.request.Prospect.HomePhone : '')
                }
                _objEnquiry.contact = _objContact;
            }

            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.Item)) {

                if (viewmodel.request.Item.StockNumber) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', viewmodel.request.Item.StockNumber)
                        .where('isDeleted', '==', false)

                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(async doc => {
                            stock = doc.data();
                            let updateDoc = {};
                            if (parseFloat(viewmodel?.request?.Item?.Price)) {
                                updateDoc = {
                                    ...updateDoc,
                                    price: parseFloat(viewmodel.request.Item.Price)
                                }
                            }
                            if (parseInt(viewmodel?.request?.Item?.Odometer)) {
                                updateDoc = {
                                    ...updateDoc,
                                    miles: parseInt(viewmodel.request.Item.Odometer)
                                }
                            }
                            if (updateDoc) {
                                await doc.ref.update(updateDoc)
                            }
                            stock = {
                                ...stock,
                                ...updateDoc
                            }
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (viewmodel.request.Item.Make && viewmodel.request.Item.Model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value.toLowerCase() === viewmodel.request.Item.Make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = viewmodel.request.Item.Model ? viewmodel.request.Item.Model : '';
                        _objRequirement.saleType = !_.isEmpty(viewmodel.request.LeadType) && viewmodel.request.LeadType.toLowerCase() == "used" ? "Preowned" : "New";
                        _objRequirement.price = viewmodel.request.Item.Price ? parseInt(viewmodel.request.Item.Price) : null;
                        _objRequirement.stock = null;
                        if (viewmodel.request.Item.StockNumber)
                            _objRequirement.stockNo = viewmodel.request.Item.StockNumber;
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;

                        let _model = _make.models && _make.models.filter(m => m.value.toLowerCase() === viewmodel.request.Item.Model.toLowerCase())[0];
                        if (!_.isEmpty(_model)) {

                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';

                        }
                    }
                }

            }

            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo))) && viewmodel.request && viewmodel.request.Item) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(viewmodel.request.Item);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }


            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {
            errorLog(error, 'createCarSalesLead');
            return _objEnquiry;
        }

    }

    const getEnquiryLead = async (viewmodel) => {

        let _objEnquiry = Object.assign({}, objNewEnquiryVM)
        try {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('enquiries').doc(viewmodel.enquiryID).get();
            if (refData.exists) {
                _objEnquiry = Object.assign({}, refData.data());

                const _enquiry = Object.assign({}, objEnquiry);
                for (let [key, value] of Object.entries(_objEnquiry)) {
                    _enquiry[key] = value;
                }
                _enquiry.documentID = refData.id;

                _enquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();


                return _enquiry;
            }

        } catch (error) {
            errorLog(error, 'getEnquiryLead');
            return _objEnquiry;
        }

    }

    const saveReqInfo = (batch, enquiryID, _objReq, clientID) => {
        try {

            const { dealersettings } = props;
            const userID = dealersettings ? dealersettings.id : '';
            let objData = Object.assign({}, _objReq);
            const inboundAllocation = (localStorage.defaultModule === 'oem') ? true : false;
            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings, null, clientID, inboundAllocation);

            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = userID
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = userID
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            if (!objData.enquiryID)
                objData.enquiryID = enquiryID;

            let requirementID = objData['documentID'];

            batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}/requirements/${requirementID}`), objData, { merge: true });

            let _makeModel = !_.isEmpty(objData.stock) ?
                (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') :
                (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');

            let _logNote = (!_.isEmpty(objData.stock) ? 'Requirement stock added' : 'Requirement added') + (_makeModel ? ' (' + _makeModel + ')' : '');

            CommonHelper.saveChangeLog(dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote, 'enquiry', 'updated', batch);


        } catch (error) {
            errorLog(error, 'saveReqInfo');
        }

    };

    const updateInBound = async (batch, enquiryID, documentID, __enquiryData) => {
        try {

            const { dealersettings } = props;
            const userID = dealersettings ? dealersettings.id : '';

            let _objReq = {
                status: 1,
                enquiryID: enquiryID,
                modifiedBy: userID,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }

            if (localStorage.defaultModule === 'oem' && __enquiryData.pipeline !== 'LeadsBucket' && __enquiryData.clientID) {
                _objReq.transferHistory = Object.assign([], __enquiryData.transferHistory);;
            }

            batch.set(firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${documentID}`), _objReq, { merge: true });

            await firestoreDB(props.dealersettings).firestore().collection(`inboundLeads/${documentID}/notesHistory`)
                .get().then(response => {
                    response.docs.forEach((newlogVM) => {

                        newlogVM.type = 'log';
                        newlogVM.subType = 'note';
                        newlogVM.isDone = true;
                        newlogVM.isDeleted = false;
                        newlogVM.startDate = newlogVM.addedDate ? newlogVM.addedDate : window.firebase.firestore.Timestamp.now();
                        newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
                            ? __enquiryData.clientID
                            : null));
                        newlogVM.enquiryID = enquiryID;
                        newlogVM['enquiry'] = CommonHelper.getMinifiedData(__enquiryData, 'enquiry');
                        newlogVM['contact'] = CommonHelper.getMinifiedData(__enquiryData?.contact, 'contact');
                        newlogVM.contactID = __enquiryData && __enquiryData.contact && __enquiryData.contact.documentID ? __enquiryData.contact.documentID : null;

                        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                        newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
                        batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
                    });
                });

        } catch (error) {
            errorLog(error, 'updateInBound');
        }
    }

    const saveNotes = (batch, enquiryID, __enquiryData, logNotes) => {
        try {


            logNotes && logNotes.forEach(newlogVM => {
                newlogVM.enquiryID = enquiryID;
                newlogVM['enquiry'] = CommonHelper.getMinifiedData(__enquiryData, 'enquiry');
                newlogVM['contact'] = CommonHelper.getMinifiedData(__enquiryData?.contact, 'contact');
                newlogVM.contactID = __enquiryData && __enquiryData.contact && __enquiryData.contact.documentID ? __enquiryData.contact.documentID : null;
                //newlogVM.clientID = fields.clientID ? fields.clientID : newlogVM.clientID;
                newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
                    ? __enquiryData.clientID
                    : null));
                newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
                Object.keys(newlogVM).forEach(key => (newlogVM[key] === undefined) ? delete newlogVM[key] : {});
                batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            })


        } catch (error) {
            errorLog(error, 'saveNotes');
        }

    }

    const getRequirementnotes = (_requirement) => {
        let notes = '';
        if (!_.isEmpty(_requirement)) {
            for (let [key, value] of Object.entries(_requirement)) {
                if (!_.isEmpty(_requirement[key]) && _.isString(value))
                    notes = notes.concat([key] + ': ' + value + '\n')
            }
        }

        return notes;
    }

    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: props.dealersettings.client.id,
            oemID: props.dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }

    //#endregion


    //#endregion

    const handleDynamicSort = (sortConfig) => {
        setSortConfig(sortConfig);
        setTableLoader(true);
        setPageNum(0);
        if (!_.isEmpty(pipelineFilter) && !_.isEmpty(pipelineFilter.value)) {
            fetchEnquiries('generic', 0, pipelineFilter.value, sortConfig)
        } else {
            fetchEnquiries('generic', 0, null, sortConfig)
        }
    }

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);
    const lostApproval = ((!_.isEmpty(_permissions) && _permissions.lostApproval) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">
                                    {!props.isFromDashboard ? (
                                        <div className="filter-search search-icon">
                                            <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                        </div>
                                    ) : (
                                        <div className={`filter-search`}>
                                            <div className="input-group ">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="isearch"
                                                    placeholder="quick search..."
                                                    value={searchText ? searchText : ''}
                                                    onChange={(e) => {
                                                        let _val = e.target.value
                                                        setSearchText(_val);
                                                        // localStorage.contactSearchText = _val;

                                                        if (_val && _val.length > 2) {
                                                            handlesearchText(_val)
                                                        }

                                                        if (!_val) {
                                                            handlesearchText('')
                                                        }

                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <div className="input-group-append input-search-clear">
                                                    {
                                                        searchText && searchText.length > 0
                                                            ?
                                                            <a href="#"
                                                                className="input-search-clear-icon"
                                                                title="Clear"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSearchText('');
                                                                    // localStorage.contactSearchText = '';
                                                                    handlesearchText('')
                                                                }}
                                                            >
                                                                <i className="ico icon-remove"></i>
                                                            </a>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                    <button
                                                        className="btn btn-default"
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }}>
                                                        <i className="ico icon-search-fill"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                    {/* <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div> */}
                                </div>



                                <div className="float-right">
                                    {
                                        (lostApproval && !readOnlyEnquiryView && !_.isEmpty(props.filter) && !_.isEmpty(props.filter.status) && props.filter.status === 'pendingLost')
                                            ?
                                            <>
                                                <a href="#" className="btn btn-common button-grey float-left mr-2 lost-btn-fix" onClick={(e) => {
                                                    e.preventDefault();
                                                    handleShowLostApproval(e);
                                                }}>LOST APPROVAL</a>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    {
                                        !_.isEmpty(props.filter) && props.filter.inbound === true
                                            ?
                                            <>
                                                <a href="#" className="btn btn-common button-blue float-left mr-2 lost-btn-fix" onClick={(e) => {
                                                    e.preventDefault();
                                                    handleShowMoveEnquiries();
                                                }}>Allocate</a>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    {
                                        (exportData)
                                            ?
                                            <>
                                                {!props.isFromDashboard ? (
                                                    <div className="filter-item-btn">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            fetchDirectExcel();
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile ref={csvDirectBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                            <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                        </ExcelFile>
                                                        {/* <CSVLink ref={csvDirectBtn} uFEFF={false} data={enquiries} headers={csvHeader}
                                                            style={{ display: 'none' }}
                                                            filename={"ams-pro-download.csv"}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </CSVLink> */}
                                                    </div>
                                                ) : (
                                                    <div className="filter-item-btn ">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!_.isEmpty(pipelineFilter) && !_.isEmpty(pipelineFilter.value)) {
                                                                fetchEnquiries('excel', filterpageNum, pipelineFilter.value, sortConfig)
                                                            } else {
                                                                fetchEnquiries('excel', pageNum, null, sortConfig)
                                                            }
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                            <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                        </ExcelFile>
                                                        {/* <CSVLink
                                                            ref={csvBtn}
                                                            uFEFF={false}
                                                            data={enquiries}
                                                            headers={csvHeader}
                                                            filename={"ams-pro-download.csv"}
                                                            style={{ display: 'none' }}
                                                        >
                                                            <i className="ico icon-excel-download"></i>
                                                        </CSVLink> */}
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                </div>
                                {props.isFromDashboard && (
                                    <Filters
                                        isFromDashboard={true}
                                        searchKeywords={searchKeywords}
                                        pipelineFilter={pipelineFilter}
                                        customFilters={customFilters}
                                        dealersettings={props.dealersettings}
                                        handleApplyFilter={handleApplyFilter}
                                        handleClearFilter={handleClearFilter}
                                        clientUsers={props.clientUsers}
                                        groupUsers={props.groupUsers}
                                        isFilterApplied={isFilterApplied}
                                        noSaveAccess={true}
                                    >
                                    </Filters>
                                )}
                            </div>
                            {props.isFromDashboard ? loader ? (
                                <>
                                    <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                        <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                    </div>
                                </>
                            ) : (
                                <div className="common-table">
                                    {
                                        tableLoader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <TableView
                                        isReorderable={true}
                                        datalist={enquiries}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allenquiryFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(enquiryFields && enquiryFields.length > 0) ? enquiryFields.filter(item => props.filter && Boolean(props.filter.enableMulticheck) ? true : item !== 'checkbox') : allenquiryFields.filter(e => e.default === true && props.filter && (Boolean(props.filter.enableMulticheck) ? true : e.value !== 'checkbox')).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={enquiryDetailclick}
                                        isSettings={true}
                                        handleFavorite={handleFavorite}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                        selectedIds={selectedIds}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        isSorting={true}
                                        dynamicSort={true}
                                        handleDynamicSort={handleDynamicSort}
                                        sortConfig={sortConfig}
                                        displayOptions={Object.assign([], props?.dealersettings?.client?.settings?.enquiryOptionsStatic)}
                                        displayByValue={true}
                                    />
                                </div>
                            ) : (
                                <div className="common-table">
                                    {
                                        loader ? (<></>) : (<TableView
                                            isReorderable={true}
                                            datalist={_.orderBy(enquiries, ['stageDateValue'], ['desc'])}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={allenquiryFields}
                                            handleSavesettings={handleSavesettings}
                                            dynamicFields={(enquiryFields && enquiryFields.length > 0) ? enquiryFields.filter(item => props.filter && Boolean(props.filter.enableMulticheck) ? true : item !== 'checkbox') : allenquiryFields.filter(e => e.default === true && props.filter && (Boolean(props.filter.enableMulticheck) ? true : e.value !== 'checkbox')).map(v => _.pick(v, ['value']).value)}
                                            settingsLoader={settingsLoader}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={enquiryDetailclick}
                                            isSettings={true}
                                            handleFavorite={handleFavorite}
                                            handleAllChecked={handleAllChecked}
                                            handleCheckChange={handleCheckChange}
                                            selectedIds={selectedIds}
                                            displayOptions={Object.assign([], props?.dealersettings?.client?.settings?.enquiryOptionsStatic)}
                                            displayByValue={true}
                                        />)
                                    }
                                </div>
                            )}
                            {
                                showpanel.clsActive ? (
                                    <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers} history={props.history}>
                                        <StageConsumer>
                                            {({ enquiries, isLoading, pipeline, columns, handleEnquiryFav, listenScrollEvent, stageLoading, updateEnquiry, sectionloading }) => (
                                                <SidePanel
                                                    clsActive={showpanel.clsActive}
                                                    clsName={showpanel.clsName}
                                                    sidepanelClose={sidepanelClose}
                                                    title=''
                                                >
                                                    <QuickView {...props} enquiryid={showpanel.enquiryid} isFilterApplied={true} sidepanelClose={sidepanelClose} reloadData={props.reloadData} isReadOnlyView={props.isReadOnlyView} />
                                                </SidePanel>
                                            )}
                                        </StageConsumer>
                                    </StageProvider>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <PopoverPanel
                showpopover={lostApprovalPopOver.showpopover}
                targetpopover={lostApprovalPopOver.targetpopover}
                title="Lost Approval"
                position="bottom"
                id="notes-lost-approval"
                className="popover-pipeline-switch"
                closepopover={() => {
                    setLostApprovalPopOver({ showpopover: false, targetpopover: '' })
                }}
                blockOutsideClick={true}
            >
                <LostApprovalNotes
                    handleClose={handleLostApprovalClose}
                    clientUsers={props.clientUsers}
                    usedWithList={true}
                    dealersettings={props.dealersettings}
                />
            </PopoverPanel>

            <PopUpModal show={inboundModal.show}>
                <InBoundLeadConvert
                    show={inboundModal.show}
                    docID={inboundModal.docID}
                    stage={null}
                    handleModalClose={handleCloseInboundLead}
                    pipeline={null}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                />
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

            <PopUpModal show={modalMoveShow}>
                <LeadTransfer
                    show={modalMoveShow}
                    handleModalClose={handleCloseMoveEnquiries}
                    title={'Allocate Leads'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isList={true}
                >
                </LeadTransfer>
            </PopUpModal>

        </>
    );
}

export default EnquiryList;